import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  profileAvatar: {
    width: 90,
    height: 90,
    border: "4px solid #fff",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      width: 50,
      height: 50,
    },
  },
  profileAvatarDropdown: {
    width: 90,
    height: 90,
    border: "4px solid #fdd808",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      width: 50,
      height: 50,
    },
  },
  profileAvatarCenturion: {
    width: 90,
    height: 90,
    // border: "4px solid gold",

    // borderWidth: 2,
    // borderStyle: "solid",
    // borderImage: "linear-gradient(to right, darkblue, darkorchid) 1",
    // borderRadius: "100%",

    [theme.breakpoints.down("xs")]: {
      position: "relative",
      width: 50,
      height: 50,
    },
  }
}))

export default useStyles;