
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import find from "lodash/find";

import { useStyles } from "../css";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import SvgIcon from "@material-ui/core/SvgIcon";

const SideDrawerList = (props) => {
  const [isOpen, setOpen] = useState(false);
  const { pathname } = useLocation();
  const { isLightMode, children, list, className } = props;

  const { label, icon, scale, highlight, children: listOptions } = list || {};

  const classes = useStyles({
    isLightMode: isLightMode,
  });

  useEffect(() => {
    if (listOptions?.length > 0) {
      let a, b, c;
      a = b = c = false;
      a = find(listOptions, function (el_a) {
        if (!el_a.children) {
          return el_a.to.includes(pathname);
        } else {
          if (!b)
            b = find(el_a.children, function (el_b) {
              if (!el_b.children) {
                return el_b.to.includes(pathname);
              } else {
                if (!c)
                  c = el_b.children.some(function (el_c) {
                    return el_c.to.includes(pathname);
                  });
              }
            });
        }
      });
      if (a || b || c) setOpen(true);
      else setOpen(false);
    }
  }, [listOptions]);

  return (
    <div>
      <div
        onClick={() => setOpen(!isOpen)}
        className={`flex items-center justify-between ${className} px-5 py-4 cursor-pointer text-red-400 ${
          isOpen &&
          "bg-slate-200 dark:bg-dark-neutral-50 border-r-2 border-[#0D8BFF]"
        } ${highlight && "bg-red-100 dark:bg-red-600"}`}
      >
        <div className={`flex items-center ${isOpen && classes.selectedSvg}`}>
          {icon && (
            <span class="side-nav-svgmain">
              <SvgIcon style={{ transform: `scale(${scale})`, color: "#282f48" }}>{icon}</SvgIcon>
            </span>
          )}
          <Typography
            color={isOpen ? "primary" : "textPrimary"}
            className={`!text-base ${isOpen && "!font-semibold"}`}
          >
            {label}
          </Typography>
        </div>
        <ExpandMoreIcon
          classes={{ root: classes.iconRoot }}
          className={`text-[#8A8A8A] ${
            isOpen &&
            "rotate-180 transition-all duration-150 flex-none !text-[#0D8BFF]"
          }`}
        />
      </div>
      <Collapse in={isOpen}>{children}</Collapse>
    </div>
  );
};

export default SideDrawerList;