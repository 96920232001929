import * as actionTypes from "../actions/actionTypes";

const initialState = {
  fundsHoldingsData: null,
  fundsMetadata: null,
  fundsSearchData: null,
  fundsInsidersData: null,
  fundsInsidersMoreData: null,
  fundsTechnicalData: null,
  fundsCostAverageData: null,
  fundsCostAverageSearchData: null,
  jimCramerData: null,
  politiciansData: null,
  politiciansSearchData: null,
  cnbcData: null,
};

const setFundsHoldingsData = (state, action) => {
  return {
    ...state,
    fundsHoldingsData: action.data,
  };
};

const setFundsMetadata = (state, action) => {
  return {
    ...state,
    fundsMetadata: action.data,
  };
};

const setFundsSearchData = (state, action) => {
  return {
    ...state,
    fundsSearchData: action.data,
  };
};

const setFundsInsidersData = (state, action) => {
  return {
    ...state,
    fundsInsidersData: action.data,
  };
};

const setFundsInsidersMoreDataDone = (state, action) => {
  return {
    ...state,
    fundsInsidersMoreData: action.data,
  };
};

const setFundsTechnicalData = (state, action) => {
  return {
    ...state,
    fundsTechnicalData: action.data,
  };
};

const setFundsCostAverageData = (state, action) => {
  return {
    ...state,
    fundsCostAverageData: action.data,
  };
};

const setCostAverageSearchData = (state, action) => {
  return {
    ...state,
    fundsCostAverageSearchData: action.data,
  };
};

const setJimCramerData = (state, action) => {
  return {
    ...state,
    jimCramerData: action.data,
  };
};

const setPoliticiansData = (state, action) => {
  return {
    ...state,
    politiciansData: action.data,
  };
};

const setPoliticiansSearchData = (state, action) => {
  return {
    ...state,
    politiciansSearchData: action.data,
  };
};

const setCnbcData = (state, action) => {
  return {
    ...state,
    cnbcData: action.data
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FUNDS_HOLDINGS_DATA_DONE:
      return setFundsHoldingsData(state, action);
    case actionTypes.GET_FUNDS_METADATA_DONE:
      return setFundsMetadata(state, action);
    case actionTypes.GET_FUNDS_SEARCH_DATA_DONE:
      return setFundsSearchData(state, action);
    case actionTypes.GET_FUNDS_INSIDERS_DATA_DONE:
      return setFundsInsidersData(state, action);
    case actionTypes.GET_FUNDS_INSIDERS_MORE_DATA_DONE:
      return setFundsInsidersMoreDataDone(state, action);
    case actionTypes.GET_FUNDS_TECHNICAL_DATA_DONE:
      return setFundsTechnicalData(state, action);
    case actionTypes.GET_FUNDS_COST_AVERAGE_DATA_DONE:
      return setFundsCostAverageData(state, action);
    case actionTypes.GET_FUNDS_COST_AVERAGE_SEARCH_DATA_DONE:
      return setCostAverageSearchData(state, action);
    case actionTypes.GET_JIM_CRAMER_COMPANIES_DONE:
      return setJimCramerData(state, action);
    case actionTypes.GET_POLITICIANS_DATA_DONE:
      return setPoliticiansData(state, action);
    case actionTypes.GET_POLTITCIANS_SEARCH_DATA_DONE:
      return setPoliticiansSearchData(state, action);
    case actionTypes.GET_CNBC_DATA_DONE:
      return setCnbcData(state, action);
    default:
      return state;
  }
};

export default reducer;
