import * as actionTypes from "../actions/actionTypes"

const initialState = {
  webinarVideos: null,
  stocksVideos: null,
  optionsVideos: null,
  cryptosVideos: null,
  cosyVideos: null,
  coachingVideos: null,
  masterclassVideos: null,
  videoByIdData: null,
  zoomClassesData: null,
  isZoomClassRegisterSuccess: false
}

const setWebinarVideos = (state, action) => {
  return {
    ...state,
    webinarVideos: action.data,
  }
}

const setStocksVideos = (state, action) => {
  return {
    ...state,
    stocksVideos: action.data,
  }
}

const setOptionsVideos = (state, action) => {
  return {
    ...state,
    optionsVideos: action.data,
  }
}

const setCryptosVideos = (state, action) => {
  return {
    ...state,
    cryptosVideos: action.data,
  }
}

const setCosyVideos = (state, action) => {
  return {
    ...state,
    cosyVideos: action.data
  }
}

const setCoachingVideos = (state, action) => {
  return {
    ...state,
    coachingVideos: action.data,
  }
}

const setMasterclassVideos = (state, action) => {
  return {
    ...state,
    masterclassVideos: action.data,
  }
}

const setVideoById = (state, action) => {
  return {
    ...state,
    videoByIdData: action.data,
  }
}

const setZoomClassesData = (state, action) => {
  return {
    ...state,
    zoomClassesData: action.data,
  }
}

const setZoomClassStatus = (state, action) => {
  return {
    ...state,
    isZoomClassRegisterSuccess: action.data
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WEBINAR_VIDEOS_DONE:
      return setWebinarVideos(state, action)
    case actionTypes.GET_STOCKS_VIDEOS_DONE:
      return setStocksVideos(state, action)
    case actionTypes.GET_OPTIONS_VIDEOS_DONE:
      return setOptionsVideos(state, action)
    case actionTypes.GET_CRYPTOS_VIDEOS_DONE:
      return setCryptosVideos(state, action)
    case actionTypes.GET_COSY_VIDEOS_DONE:
      return setCosyVideos(state, action)
    case actionTypes.GET_COACHING_VIDEOS_DONE:
      return setCoachingVideos(state, action)
    case actionTypes.GET_MASTERCLASS_VIDEOS_DONE:
      return setMasterclassVideos(state, action)
    case actionTypes.GET_VIDEO_BY_ID_DONE:
      return setVideoById(state, action)
    case actionTypes.GET_ZOOM_CLASSES_DATA_DONE:
      return setZoomClassesData(state, action)
    case actionTypes.REGISTER_FOR_ZOOM_CLASS_DONE:
      return setZoomClassStatus(state, action)
    default:
      return state
  }
}

export default reducer