import axios from "axios";
import * as actionTypes from "./actionTypes";
import { loading, infiniteScrollLoading } from "./commonAc";
import { getTrillionDollarDataDone } from "./arkAc";

let apiDomain = process.env.REACT_APP_API_URL;
let domainUserDetails = process.env.REACT_APP_API2_URL;
let metadataDomain = process.env.REACT_APP_API2_URL;

export const getDailybreadDataDone = (data) => {
  return {
    type: actionTypes.GET_DAILYBREAD_DATA_DONE,
    data: data,
  };
};

export const getResurrectionDataDone = (data) => {
  return {
    type: actionTypes.GET_RESURRECTION_DATA_DONE,
    data: data,
  };
};

export const getTriangulationDataDone = (data) => {
  return {
    type: actionTypes.GET_TRIANGULATION_DATA_DONE,
    data: data,
  };
};

export const setDashQueryParams = (data) => {
  return {
    type: actionTypes.SET_DASH_QUERY_PARAMS,
    data: data,
  };
};

export const getTagsByBidCidDone = (data) => {
  return {
    type: actionTypes.GET_TAGS_BY_BID_CID_DONE,
    data: data,
  };
};

export const setDashTags = (data) => {
  return {
    type: actionTypes.SET_DASH_TAGS,
    data: data,
  };
};

export const get100BluewhalesDataDone = (data) => {
  return {
    type: actionTypes.GET_100BLUEWHALES_DATA_DONE,
    data: data,
  };
};

export const getUniverseFundsDataDone = (data) => {
  return {
    type: actionTypes.GET_UNIVERSE_FUNDS_DATA_DONE,
    data: data,
  };
};

export const getSovereignWealthFundsDataDone = (data) => {
  return {
    type: actionTypes.GET_SOVEREIGN_WEALTH_FUNDS_DONE,
    data: data,
  };
};

export const getDailyTradesDataDone = (data) => {
  return {
    type: actionTypes.GET_DAILY_TRADES_DATA_DONE,
    data: data,
  };
};

export const clearDailyTradesAllData = () => {
  return {
    type: actionTypes.GET_DAILYTRADES_ALL_DATA_DONE,
    data: null,
  };
};

export const clearCompany100BlueWhaleData = () => {
  return {
    type: actionTypes.GET_COMPANY_100BLUEWHALE_DATA_DONE,
    data: null,
  };
};

export const getDailyTradesAllDataDone = (data) => {
  return {
    type: actionTypes.GET_DAILYTRADES_ALL_DATA_DONE,
    data: data,
  };
};

export const getGOATDataDone = (data) => {
  return {
    type: actionTypes.GET_GOAT_DATA_DONE,
    data: data,
  };
};

export const getGRITDataDone = (data) => {
  return {
    type: actionTypes.GET_GRIT_DATA_DONE,
    data: data,
  };
};

export const getYOLODataDone = (data) => {
  return {
    type: actionTypes.GET_YOLO_DATA_DONE,
    data: data,
  };
};

export const getYOLOBitcoinDataDone = (data) => {
  return {
    type: actionTypes.GET_YOLO_BITCOIN_DATA_DONE,
    data: data,
  };
};

export const getYOLOBitcoinValueDone = (data) => {
  return {
    type: actionTypes.GET_YOLO_BITCOIN_VALUE_DONE,
    data: data,
  };
};

export const setBidsTags = (data) => {
  return {
    type: actionTypes.SET_BIDS_TAGS,
    data: data,
  };
};

export const setCidsTags = (data) => {
  return {
    type: actionTypes.SET_CIDS_TAGS,
    data: data,
  };
};

export const setCommonTags = (data) => {
  return {
    type: actionTypes.SET_COMMON_TAGS,
    data: data,
  };
};

export const clearSelectedCommonTags = () => {
  return {
    type: actionTypes.CLEAR_SELECTED_COMMON_TAGS,
  };
};

export const getWatchlistDataDone = (data) => {
  return {
    type: actionTypes.GET_WATCHLIST_DATA_DONE,
    data: data,
  };
};

export const postWhaleWatchingDone = (data) => {
  return {
    type: actionTypes.POST_WHALEWHATCHING_DONE,
    data: data,
  };
};

export const getDashboardBuybackDataDone = (data) => {
  return {
    type: actionTypes.GET_DASHBOARD_BUYBACK_DATA_DONE,
    data: data,
  };
};

export const getDailybreadPastDataDone = (data) => {
  return {
    type: actionTypes.GET_DAILYBREAD_PAST_DATA_DONE,
    data: data,
  };
};

export const getDailybreadData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    let start = "";
    let end = "";
    let bids = "";
    let tags = "";
    let sortby = "";
    let sortorder = "";

    if (obj.start !== undefined) start = obj.start;
    if (obj.end !== undefined) end = obj.end;
    if (obj.bids !== undefined) bids = obj.bids;
    if (obj.tags !== undefined) tags = obj.tags;
    if (obj.sortby) sortby = obj.sortby;
    if (obj.sortorder) sortorder = obj.sortorder;

    axios
      .get(
        metadataDomain +
          "/v3/dailybread?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset +
          "&tags=" +
          tags +
          "&start=" +
          // "2020-10-05" +
          start +
          "&end=" +
          // "2020-12-31"
          end +
          "&bids=" +
          bids +
          "&sortby=" +
          sortby +
          "&sortorder=" +
          sortorder
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().dashboard.dailybreadData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(setBidsTags(data.bids));
        dispatch(setCidsTags(data.cids));
        dispatch(setCommonTags(data.tags));
        dispatch(getDailybreadDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getResurrectionData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    let start = "";
    let end = "";
    let bids = "";
    let tags = "";
    let sortby = "";
    let sortorder = "";

    if (obj.start !== undefined) start = obj.start;
    if (obj.end !== undefined) end = obj.end;
    if (obj.bids !== undefined) bids = obj.bids;
    if (obj.tags !== undefined) tags = obj.tags;
    if (obj.sortby) sortby = obj.sortby;
    if (obj.sortorder) sortorder = obj.sortorder;

    axios
      .get(
        metadataDomain +
          "/v3/resurrection?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset +
          "&tags=" +
          tags +
          "&start=" +
          // "2020-10-05" +
          start +
          "&end=" +
          // "2020-12-31"
          end +
          "&bids=" +
          bids +
          "&sortby=" +
          sortby +
          "&sortorder=" +
          sortorder
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().dashboard.resurrectionData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(setBidsTags(data.bids));
        dispatch(setCidsTags(data.cids));
        dispatch(setCommonTags(data.tags));
        dispatch(getResurrectionDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getTriangulationData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    let start = "";
    let end = "";
    let bids = "";
    let tags = "";
    let sortby = "";
    let sortorder = "";

    if (obj.start !== undefined) start = obj.start;
    if (obj.end !== undefined) end = obj.end;
    if (obj.bids !== undefined) bids = obj.bids;
    if (obj.tags !== undefined) tags = obj.tags;
    if (obj.sortby) sortby = obj.sortby;
    if (obj.sortorder) sortorder = obj.sortorder;

    axios
      .get(
        metadataDomain +
          "/v3/triangulation?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset +
          "&tags=" +
          tags +
          "&start=" +
          // "2020-10-05" +
          start +
          "&end=" +
          // "2020-12-31"
          end +
          "&bids=" +
          bids +
          "&sortby=" +
          sortby +
          "&sortorder=" +
          sortorder
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().dashboard.triangulationData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(setBidsTags(data.bids));
        dispatch(setCidsTags(data.cids));
        dispatch(setCommonTags(data.tags));
        dispatch(getTriangulationDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const get100BluewhalesData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    let start = "";
    let end = "";
    let bids = "";
    if (obj.start !== undefined) start = obj.start;
    if (obj.end !== undefined) end = obj.end;
    if (obj.bids !== undefined) bids = obj.bids;

    axios
      .get(
        metadataDomain +
          "/v3/100bluewhales?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset +
          "&start=" +
          // "2020-10-05" +
          start +
          "&end=" +
          // "2020-12-31"
          end +
          "&bids=" +
          bids
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().dashboard.b100BluewhalesData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(get100BluewhalesDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getUniverseFundsData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    let start = "";
    let end = "";
    let bids = "";
    if (obj.start !== undefined) start = obj.start;
    if (obj.end !== undefined) end = obj.end;
    if (obj.bids !== undefined) bids = obj.bids;

    axios
      .get(
        metadataDomain +
          "/v3/universeoffunds?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset +
          "&start=" +
          // "2020-10-05" +
          start +
          "&end=" +
          // "2020-12-31"
          end +
          "&bids=" +
          bids
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().dashboard.universeFundsData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(getUniverseFundsDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getSovereignWealthFundsData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    let start = "";
    let end = "";
    let bids = "";
    if (obj.start !== undefined) start = obj.start;
    if (obj.end !== undefined) end = obj.end;
    if (obj.bids !== undefined) bids = obj.bids;

    axios
      .get(
        metadataDomain +
          "/v3/sovereignwealthfunds?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset +
          "&start=" +
          // "2020-10-05" +
          start +
          "&end=" +
          // "2020-12-31"
          end +
          "&bids=" +
          bids
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().dashboard.sovereignWealthFundsData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(getSovereignWealthFundsDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getDailyTradesData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));

    let start = "";
    let end = "";
    let bids = "";
    let tags = "";
    let cids = "";
    if (obj.start !== undefined) start = obj.start;
    if (obj.end !== undefined) end = obj.end;
    if (obj.bids !== undefined) bids = obj.bids;
    if (obj.tags !== undefined) tags = obj.tags;
    if (obj.cids !== undefined) cids = obj.cids;

    let apiUrl = null;

    if (obj.action === "all" && !obj.onLoad)
      apiUrl = metadataDomain + "/v3/daily-trades" + obj.queryString;
    else
      apiUrl =
        metadataDomain +
        "/v3/daily-trades?limit=" +
        obj.limit +
        "&offset=" +
        obj.offset +
        "&tags=" +
        tags +
        "&start=" +
        // "2020-10-05" +
        start +
        "&end=" +
        // "2020-12-31"
        end +
        "&bids=" +
        bids +
        "&cids=" +
        cids +
        "&action=" +
        obj.action;

    axios.get(apiUrl).then((res) => {
      let data = res.data;
      // alert("success");
      if (!obj.onLoad) {
        if (obj.action !== "all") {
          let prevData = getState().dashboard.dailyTradesData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        } else {
          let prevData = getState().dashboard.dailyTradesAllData;
          let appendedData = prevData.data.concat(data.data);
          prevData.next_page_token = data.next_page_token;
          data.data = appendedData;
        }
      }
      dispatch(setBidsTags(data.bids));
      dispatch(setCidsTags(data.cids));
      dispatch(setCommonTags(data.tags));
      if (obj.action === "all") dispatch(getDailyTradesAllDataDone(data));
      else dispatch(getDailyTradesDataDone(data));
      dispatch(loading(false));
      dispatch(infiniteScrollLoading(false));
    });
  };
};

export const getDailybreadPastData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    let start = "";
    let end = "";
    let bids = "";
    let tags = "";
    let sortby = "";
    let sortorder = "";

    if (obj.start !== undefined) start = obj.start;
    if (obj.end !== undefined) end = obj.end;
    if (obj.bids !== undefined) bids = obj.bids;
    if (obj.tags !== undefined) tags = obj.tags;
    if (obj.sortby) sortby = obj.sortby;
    if (obj.sortorder) sortorder = obj.sortorder;

    axios
      .get(
        metadataDomain +
          "/v3/dailybread-past?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset +
          "&tags=" +
          tags +
          "&start=" +
          // "2020-10-05" +
          start +
          "&end=" +
          // "2020-12-31"
          end +
          "&bids=" +
          bids +
          "&sortby=" +
          sortby +
          "&sortorder=" +
          sortorder
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().dashboard.dailybreadData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(setBidsTags(data.bids));
        dispatch(setCidsTags(data.cids));
        dispatch(setCommonTags(data.tags));
        dispatch(getDailybreadPastDataDone(data));
        dispatch(loading(false));
      });
  };
};

// to-do remove this start - moved to my spikes - spike universe

export const getWatchlistData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    axios
      .get(
        metadataDomain +
          "/v3/whale-watching-watchlist?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().dashboard.watchlistData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(getWatchlistDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const postWhaleWatching = (qString, obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .post(domainUserDetails + "/v3/whale_watching" + qString)
      .then((res) => {
        // alert("success")
        if (obj && obj.action === "whalewatching") {
          dispatch(getDailyTradesData(obj));
        }
      });
  };
};

export const deleteWhaleWatching = (qString, obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .delete(domainUserDetails + "/v3/whale_watching" + qString)
      .then((res) => {
        // alert("success")
        if (obj.isWatchlist) {
          dispatch(getWatchlistData(obj));
        } else {
          dispatch(getDailyTradesData(obj));
        }
      });
  };
};

export const getGOATData = () => {
  return (dispatch) => {
    dispatch(loading(true));
    axios.get(metadataDomain + "/v3/gs_goat").then((res) => {
      let data = res.data;
      dispatch(getGOATDataDone(data));
      dispatch(loading(false));
    });
  };
};

export const getGRITData = () => {
  return (dispatch) => {
    dispatch(loading(true));
    axios.get(metadataDomain + "/v3/gs_grit").then((res) => {
      let data = res.data;
      dispatch(getGRITDataDone(data));
      dispatch(loading(false));
    });
  };
};

export const getYOLOData = () => {
  return (dispatch) => {
    dispatch(loading(true));
    axios.get(metadataDomain + "/v3/gs_yolo").then((res) => {
      let data = res.data;
      dispatch(getYOLODataDone(data));
      dispatch(loading(false));
    });
  };
};

export const getYOLOBitcoinData = (obj) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    axios
      .get(
        metadataDomain +
          "/v3/yolo_bitcoin_limit_offset?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data;
        let prevData = getState().dashboard?.yoloBitcoinData?.data || [];
        let appendedData = prevData.concat(data.data);
        data.data = appendedData;
        dispatch(getYOLOBitcoinDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getYOLOBitcoinValue = () => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    axios.get("https://blockchain.info/ticker").then((res) => {
      let data = res.data;
      dispatch(getYOLOBitcoinValueDone(data));
      dispatch(loading(false));
    });
  };
};

export const createYOLOBitcoin = (bitcoin) => {
  return (dispatch) => {
    dispatch(loading(true));
    let body = new FormData();
    body.append("bitcoin", bitcoin);
    axios.post(`${metadataDomain}/v3/yolo_bitcoin`, body).then((res) => {
      dispatch(loading(false));
      window.location.reload();
    });
  };
};

export const getDashboardBuybackData = (obj) => {
  return (dispatch, getState) => {
    if(obj.onload) dispatch(loading(true));

    const params = {
      limit: obj.limit,
      offset: obj.offset,
      sortby: obj.sortby ? obj.sortby : "",
      sortorder: obj.sortorder ? obj.sortorder : "",
      start: obj.start ? obj.start : "",
      end: obj.end ? obj.end : "",
      bids: obj.bids ? obj.bids : "",
      tags: obj.tags ? obj.tags : ""
    };

    axios
      .get(`${metadataDomain}/v3/buyback`, {
        params,
      })
      .then((res) => {
        let data = res.data;
        // let element = document.getElementById("tableScroll");
        // if (element) element.scrollTop = 0;
        if (!obj.onload) {
          let prevData = getState().dashboard.dashboardBuybackData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(getDashboardBuybackDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getTrillionData = (obj) => {
  return (dispatch, getState) => {
    if(obj.onload) dispatch(loading(true));

    const params = {
      limit: obj.limit,
      offset: obj.offset,
      sortby: obj.sortby ? obj.sortby : "",
      sortorder: obj.sortorder ? obj.sortorder : "",
      start: obj.start ? obj.start : "",
      end: obj.end ? obj.end : "",
      bids: obj.bids ? obj.bids : "",
      tags: obj.tags ? obj.tags : ""
    };

    axios
      .get(`${metadataDomain}/v3/trillion`, {
        params,
      })
      .then((res) => {
        let data = res.data;
        // let element = document.getElementById("tableScroll");
        // if (element) element.scrollTop = 0;
        if (!obj.onload) {
          let prevData = getState().dashboard.dashboardBuybackData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(getDashboardBuybackDataDone(data));
        dispatch(loading(false));
      });
  };
};

// to-do remove this end - moved to my spikes - spike universe
