const isAndroid = () => {
  if (typeof window.device !== "undefined") {
    if (window.device.platform === "Android") {
      return true;
    } else {
      return false;
    }
  }
};

const isIos = () => {
  if (typeof window.device !== "undefined") {
    if (
      window.device.platform === "iOS" ||
      window.device.platform === "iPhone" ||
      window.device.platform === "iPhone Simulator"
    ) {
      return true;
    } else {
      return false;
    }
  }
};

const isIosBrowser = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

const isAndroidBrowser = () => /android/i.test(navigator.userAgent)



export { isAndroid, isIos, isIosBrowser, isAndroidBrowser };
