import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

const isWin = navigator.platform.indexOf("Win") > -1;

const DarkTheme = (lightMode, pathname) => {
  if (localStorage.getItem("isLightMode") == null)
    localStorage.setItem("isLightMode", true);

  // if (
  //   pathname.includes("/login-old") ||
  //   pathname.includes("/create-account") ||
  //   pathname.includes("/reset-password") ||
  //   pathname.includes("/forgot-password") ||
  //   pathname.includes("/email-login")
  // )
  //   isLightMode = true;
  // else
  let isLightMode = true;
  isLightMode = localStorage.getItem("isLightMode") === "true" ? true : false;

  const palletType = isLightMode ? "light" : "dark";
  const textPrimary = isLightMode ? "#282f48" : "rgba(255, 255, 255, 0.87)";
  const textSecondary = isLightMode ? "#6f7388" : "rgba(255, 255, 255, 0.6)";
  const backgroundDark = isLightMode ? "#f6f6f9" : "#040D14";
  const backgroundPaper = isLightMode ? "#ffffff" : "#171C22";
  const backgroundCharcoal = isLightMode ? "#e9ecef" : "#1B1F23";
  const backgroundDefault = isLightMode ? "#dee0e0" : "#13131a";
  const avatarBackground = isLightMode ? "#fff" : "#fff";
  const whiteModeToggleButtonsBlue = isLightMode
    ? "#282f48"
    : "rgba(0, 141, 244, 0.2)";
  const tabsGradient = isLightMode
    ? "none"
    : "linear-gradient(180deg, #1D262B 0%, #07101A 100%)";
  const tabsUnselectedColor = isLightMode ? "" : "rgba(255, 255, 255, 0.6)";
  const yellowText = isLightMode ? "#8B8000" : "#D6EE00";
  const sideNavBackground = isLightMode ? "#FFFFFF" : "#040D14";
  const sideNavBackgroundOld = isLightMode ? "#282f48" : "#040D14";
  const sideNavText = isLightMode ? "#fff" : "#fff";
  const greenColor = isLightMode ? "#00873c" : "#68D7AA";
  const mainBorder = isLightMode ? "rgb(171,171,171,1)" : "#ffffff";
  const sideNavProfileBlock = isLightMode ? "#F5F7FA" : "#1B1F23";
  const dividerColor = isLightMode ? "#ECEFF2" : "rgb(59, 59, 59)";
  const guideHover = isLightMode ? "#E5F4F1" : "#757575";
  const dividerColorDarker = isLightMode ? "#DCDEE0" : "rgb(59, 59, 59)";
  const lessondividerColorDarker = isLightMode ? "#a6a6a6" : "rgb(59, 59, 59)";
  const lessonDarkButtons = isLightMode ? "#36394D" : "";
  const lessonOppositeTextColor = isLightMode ? "#fff" : "#000";
  const backgroundHover = isLightMode ? "#F0F0F0" : "#101010";
  const backgroundBlue = isLightMode ? "#F1F6FF" : "#27282A";
  const feedDividerColor = isLightMode ? "#dadada" : "#474747";
  const lightBlueButton = isLightMode ? "#E6F2FF" : "#2D3033";

  return createMuiTheme({
    palette: {
      type: palletType,
      primary: {
        main: "#0D8BFF",
        secondary: "#171C22", //not used much
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
      },
      background: {
        dark: backgroundDark,
        paper: backgroundPaper,
        default: backgroundDefault,
        charcoal: backgroundCharcoal,
      },
      info: {
        main: "#64B6F7",
      },
      error: {
        main: "#EB5A5A",
      },
      success: {
        main: greenColor,
      },
      divider: "#575E64",
      custom: {
        // charcoal: "#4F4F5A",
        lightGrey: "#92929D",
        slate: "#171C22",
        whiteModeButtonsBlue: whiteModeToggleButtonsBlue,
        tabsGradient: tabsGradient,
        tabsUnselectedColor: tabsUnselectedColor,
        yellowText: yellowText,
        sideNavBackground: sideNavBackground,
        sideNavText: sideNavText,
        green: greenColor,
        mainBorder: mainBorder,
        sideNavProfileBlock: sideNavProfileBlock,
        dividerColor: dividerColor,
        sideNavBackgroundOld: sideNavBackgroundOld,
        guideHover: guideHover,
        dividerColorDarker: dividerColorDarker,
        lessondividerColorDarker: lessondividerColorDarker,
        lessonDarkButtons: lessonDarkButtons,
        lessonOppositeTextColor: lessonOppositeTextColor,
        backgroundHover: backgroundHover,
        backgroundBlue: backgroundBlue,
        feedDividerColor: feedDividerColor,
        lightBlueButton: lightBlueButton,
      },
    },
    typography: {
      fontFamily: "Open Sans",
      h1: {
        fontSize: "3.75rem", // 60px
        [breakpoints.down("xs")]: {
          fontSize: "2rem",
        },
      },
      h2: {
        fontSize: "3rem", // 48px
        letterSpacing: "0.25px",
        [breakpoints.down("xs")]: {
          fontSize: "1.75rem",
        },
      },
      h3: {
        fontSize: "2.188rem", // 35px
        [breakpoints.down("xs")]: {
          fontSize: "1.5rem",
        },
      },
      h4: {
        fontSize: "1.875rem", // 30px
        [breakpoints.down("xs")]: {
          fontSize: "1.25rem",
        },
      },
      h5: {
        fontSize: "1.5rem", // 24px
        [breakpoints.down("xs")]: {
          fontSize: "1.125rem",
        },
      },
      h6: {
        fontSize: "1.25rem", // 20px
        letterSpacing: "0.15px",
        [breakpoints.down("xs")]: {
          fontSize: "1rem",
        },
      },
      body1: {
        fontSize: "1.125rem", // 18px
        letterSpacing: "0.15px",
        [breakpoints.down("xs")]: {
          fontSize: "1rem",
        },
      },
      body2: {
        fontSize: "1rem", // 16px
        letterSpacing: "0.1px",
        [breakpoints.down("xs")]: {
          fontSize: "15px",
        },
      },
      subtitle1: {
        fontSize: "0.875rem", // 14px
        letterSpacing: "0.15px",
        [breakpoints.down("xs")]: {
          fontSize: "0.875rem", // 14px
        },
      },
      // subtitle2: {
      //   fontFamily: "Nunito Sans",
      //   fontSize: "0.875rem", // 14px
      //   letterSpacing: "1px",
      //   fontWeight: 700,
      //   [breakpoints.down("xs")]: {
      //     fontSize: "0.75rem",
      //   },
      // },
      button: {
        fontSize: "1rem",
        textTransform: "none",
        letterSpacing: "0.15px",
        fontWeight: 600,
        [breakpoints.down("xs")]: {
          fontSize: "0.875rem",
        },
      },
      overline: {
        fontFamily: "Nunito Sans",
        fontSize: "0.875rem", // 14px
        letterSpacing: "1px",
        fontWeight: 700,
        [breakpoints.down("xs")]: {
          fontSize: "0.75rem",
        },
      },
      caption: {
        fontSize: "0.875rem", // 14px
        letterSpacing: "0.15px",
        [breakpoints.down("xs")]: {
          fontSize: "0.75rem",
        },
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "*": {
            "scrollbar-width": "thin",
          },
          "*::-webkit-scrollbar": {
            width: isWin && "5px",
            height: isWin && "5px",
            // backgroundColor: "blue !important",
            borderRadius: isWin && "5px !important",
            border: isWin && "3px solid black !important",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: isWin && "#808080 !important",
            borderRadius: isWin && "5px !important",
            // border: "3px solid orange !important"
          },
        },
      },
      MuiAvatar: {
        root: {
          [breakpoints.down("md")]: {
            width: "35px",
            height: "35px",
          },
        },
        img: {
          objectFit: "contain",
          backgroundColor: avatarBackground,
        },
      },
      MuiTableCell: {
        head: {
          // padding: "6px 20px",
          // borderBottom: "none",
          // backgroundColor: "#292932",
          // "&:first-child": {
          //   borderTopLeftRadius: "5px",
          //   borderBottomLeftRadius: "5px",
          // },
          // "&:last-child": {
          //   borderTopRightRadius: "5px",
          //   borderBottomRightRadius: "5px",
          // },
        },
        root: {
          padding: "25px 20px",
        },
      },
    },
  });
};

export default DarkTheme;

// const theme =

// export { theme }
