import { makeStyles } from "@material-ui/core";
import zIndex from "@material-ui/core/styles/zIndex";
export const useStyles = makeStyles((theme) => ({
  guideButtonBackground: {
    backgroundImage: 'linear-gradient(to bottom right, #3DB8FC, #120052)',
    height: 50,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    cursor: 'pointer',
    borderRadius: 50,
    WebkitBoxShadow: '0px 2px 10px 5px rgba(0,0,0,0.2)',
    boxShadow: '0px 2px 10px 5px rgba(0,0,0,0.2)',
    "&:hover": {
      opacity: 0.7
    },
  },
  tourContainer: { position: 'fixed', zIndex: 100, bottom: 20, right: 40, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' },
  percentageBarBackground: {
    backgroundColor: '#E5EDF4',
    borderRadius: 50,
    height: 10,
    width: '80%'
  },
  percentageBarValue: {
    backgroundColor: '#0D8BFF',
    borderRadius: 50,
    height: 10
  },
  stepContainer: {
    padding: '10px 5px',
    borderBottom: '1px solid #E4E6E8',
    cursor: 'pointer',
    "&:hover": {
      borderLeft: '2px solid #0D8BFF',
      backgroundColor: theme.palette.custom.guideHover,
    }
  },
  guidedContainer: {
    width: 400,
    height: 500,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },
  contentTopicContainerInactive: {
    position: 'absolute',
    left: '100%',
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    transition: '500ms',
    height: 500,
    width: '100%',
  },
  contentTopicContainerActive: {
    position: 'absolute',
    left: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    transition: '500ms',
    height: 500,
    width: '100%',
  },
}))