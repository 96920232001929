import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import InfiniteScroll from "react-infinite-scroll-component";

import { useStyles } from "../../../css";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import Badge from "@material-ui/core/Badge";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import WillItSpikeIcon from "../../../../../images/willItSpikeIcon.png";
import ListAltIcon from "@material-ui/icons/ListAlt";
import EmailOutlined from "@material-ui/icons/EmailOutlined";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SchoolIcon from "@material-ui/icons/School";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import { loading } from "../../../../../store/actions";
import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const NotificationPopup = (props) => {
  const {
    notiData,
    handleNotificationClick,
    handleViewAll,
    notiLoading,
    isPopup,
    fetchMore,
    handleMarkAllAsRead,
  } = props;
  const classes = useStyles();

  const NotificationItem = ({ noti }) => (
    <Box 
      className={classes.notificationItem}
      onClick={() => handleNotificationClick(noti)}
    >
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item>
          <Box className={classes.dotIndicator}>
            {noti.active && <span className={classes.dot} />}
          </Box>
        </Grid>
        <Grid item xs>
          <Typography className={classes.notificationText}>
            {noti.description_array?.map(item => item.text).join(' ')}
          </Typography>
          <Typography className={classes.timeText}>
            {noti.time_ago}
          </Typography>
        </Grid>
        <Grid item>
          <ChevronRightIcon className={classes.chevronIcon} />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box className={classes.container}>
      {/* Header */}
      <Box className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          Notifications
        </Typography>
        <Typography 
          className={classes.markAllRead}
          onClick={handleMarkAllAsRead}
        >
          Mark all as read
        </Typography>
      </Box>

      {/* Notification List */}
      <Box className={classes.notificationList}>
        {notiLoading ? (
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress size={20} />
          </Box>
        ) : notiData?.data.length > 0 ? (
          <InfiniteScroll
            dataLength={notiData?.data.length || 0}
            next={fetchMore}
            hasMore={notiData && !notiData.is_last}
            loader={<CircularProgress size={20} />}
            style={{ overflow: isPopup ? 'hidden' : 'auto' }}
          >
            {notiData.data.map((noti, index) => (
              noti.type === "notification" && (
                <NotificationItem key={index} noti={noti} />
              )
            ))}
          </InfiniteScroll>
        ) : (
          <Box p={2} textAlign="center">
            <Typography color="textSecondary">
              No notifications
            </Typography>
          </Box>
        )}
      </Box>

      {/* Footer */}
      {isPopup && (
        <Box className={classes.footer}>
          <Typography 
            className={classes.viewAll}
            onClick={handleViewAll}
          >
            View all notifications
          </Typography>
        </Box>
      )}
    </Box>
  );
};

NotificationPopup.propTypes = {
  notiData: PropTypes.object,
  handleNotificationClick: PropTypes.func,
  handleViewAll: PropTypes.func,
  notiLoading: PropTypes.bool,
  isPopup: PropTypes.bool,
  fetchMore: PropTypes.func,
  handleMarkAllAsRead: PropTypes.func,
};

export default NotificationPopup;
