const isToken = () => {
    let token = localStorage.getItem("token");
    if(token !== null && token !== "undefined") {
        return true;
    }
    else {
        return false;
    }
}

export default isToken;