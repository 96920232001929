import React, { useRef, useState, useEffect } from "react";
import useClickOutside from "../../Courses/components/useOutsideClick";

const AutoPositionedDiv = ({
  closePopup,
  relativeElementRect,
  children,
  ...props
}) => {
  function handleClickOutside(event) {
    window.getComputedStyle(divRef.current).display === "block" && closePopup();
  }

  useEffect(() => {
    if (relativeElementRect && divRef.current) {
      // Get the dimensions of the window
      const windowWidth = window.innerWidth;

      // Get the dimensions of the AutoPositionedDiv and relative element
      const divRect = divRef.current.getBoundingClientRect();

      // Calculate the position
      // Calculate the position
      let newPosition = {
        top: relativeElementRect?.top + window.scrollY,
        left: 0,
      };

      // Check if the div can fit to the right of the relative element
      if (relativeElementRect.right + divRect.width <= windowWidth) {
        newPosition.left = relativeElementRect.right + 10;
      } else if (relativeElementRect.left - divRect.width < 0) {
        newPosition.left = relativeElementRect.left;
        newPosition.top =
          relativeElementRect?.top +
          window.scrollY +
          relativeElementRect.height +
          10;
      } else {
        // If it can't fit to the right, position it to the left
        newPosition.left = relativeElementRect.left - divRect.width - 10;
      }
      if (relativeElementRect.width > windowWidth / 3) {
        newPosition.left = relativeElementRect.left;
        newPosition.top =
          relativeElementRect?.top +
          window.scrollY +
          relativeElementRect.height +
          10;
      }

      setPosition(newPosition);
    }
  }, [relativeElementRect]);

  const divRef = useClickOutside(handleClickOutside);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  return (
    <div
      ref={divRef}
      {...props}
      style={{
        position: "absolute",
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
    >
      {children}
    </div>
  );
};

export default AutoPositionedDiv;
