import axios from "axios";

import * as actionTypes from "./actionTypes";
import { loading, searchLoading } from "./commonAc";

let apiDomain = process.env.REACT_APP_API_URL;
let domainUserDetails = process.env.REACT_APP_API2_URL;
let randomNum = 1;

export const getArkCompanyChartDataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_COMPANY_CHART_DATA_DONE,
    data: data,
  };
};

export const getArkTrendChartDataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_TREND_CHART_DATA_DONE,
    data: data,
  };
};

export const getArkFundamentalsTableDataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_FUNDAMENTALS_TABLE_DATA_DONE,
    data: data,
  };
};

export const getArkFundamentalMetadataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_FUNDAMENTALS_METADATA_DONE,
    data: data,
  };
};

export const getArkFundamentalBreakdownDataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_FUNDAMENTAL_BREAKDOWN_DATA_DONE,
    data: data,
  };
};

export const getArkFundsTableSearchDataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_FUNDS_HOLDINGS_SEARCH_DONE,
    data: data,
  };
};

export const getArkInsidersTableDataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_INSIDERS_TABLE_DATA_DONE,
    data: data,
  };
};

export const getArkInvestorInsidersTableQuaterDataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_INVESTOR_INSIDERS_TABLE_QUATER_DATA_DONE,
    data: data,
  };
};

export const getArkCompanyFundamentalsDataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_COMPANY_FUNDAMENTALS_DATA_DONE,
    data: data,
  };
};

export const getArkCompanyInsiderDataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_COMPANY_INSIDER_DATA_DONE,
    data: data,
  };
};

export const getArkNewsDataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_NEWS_DATA_DONE,
    data: data,
  };
};

export const getArkWeightChartDataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_WEIGHT_CHART_DATA_DONE,
    data: data,
  };
};

export const getArkPerformanceChartDataDone = (data) => {
  return {
    type: actionTypes.GET_ARK_PERFORMANCE_CHART_DATA_DONE,
    data: data,
  };
};

export const getArkOwnershipChartDataDone = (data) => {
  return {
    type: actionTypes.GET_OWNERSHIP_CHART_DATA_DONE,
    data: data,
  };
};

export const getMovingTickerDataDone = (data) => {
  return {
    type: actionTypes.GET_MOVING_TICKER_DATA_DONE,
    data: data,
  };
};

export const getTrillionDollarDataDone = (data) => {
  return {
    type: actionTypes.GET_TRILLION_DOLLAR_DATA_DONE,
    data: data,
  };
};

export const getArkCompanyChartData = (obj) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/line-chart?bid=${obj.bid}&cid=${obj.cid}&type=${obj.type}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getArkCompanyChartDataDone(null));
        dispatch(getArkCompanyChartDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getArkTrendChartData = (obj) => {
  return (dispatch, getState) => {
    axios
      .get(domainUserDetails + `/v3/trend-chart?bid=${obj.bid}`)
      .then((res) => {
        let data = res.data;
        console.log(data);
        dispatch(getArkTrendChartDataDone(data));
      });
  };
};

export const getArkFundamentalsTableData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/bluewhale/holdings?bid=${obj.bid}&limit=${obj.limit}&offset=${obj.offset}&sortby=${obj.sortby}&sortorder=${obj.sortorder}&cids=${obj.cids}&tags=${obj.tags}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getArkFundamentalsTableDataDone(data));
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(loading(false));
      });
  };
};

export const getArkFundamentalMetadata = (bid) => {
  return (dispatch) => {
    axios.get(domainUserDetails + `/v3/insider/${bid}`).then((res) => {
      let data = res.data;
      dispatch(getArkFundamentalMetadataDone(data));
    });
  };
};

export const getArkFundamentalBreakdownData = (bid) => {
  return (dispatch) => {
    axios
      .get(domainUserDetails + `/v3/bluewhale/fundamentals?bid=${bid}`)
      .then((res) => {
        let data = res.data;
        dispatch(getArkFundamentalBreakdownDataDone(data));
      });
  };
};

export const getArkFundsTableSearchData = (obj) => {
  return (dispatch) => {
    dispatch(searchLoading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/bluewhale/holdings/search?search=${obj.search}&bid=${obj.bid}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getArkFundsTableSearchDataDone(data));
        dispatch(searchLoading(false));
      });
  };
};

export const getArkInvestorInsidersTableData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/bluewhale/trades?bid=${obj.bid}&limit=${obj.limit}&offset=${obj.offset}&sortby=${obj.sortby}&sortorder=${obj.sortorder}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getArkInsidersTableDataDone(data));
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(loading(false));
        // console.log(data)
      });
  };
};

export const getArkInvestorInsidersTableQuaterData = (obj) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/bluewhale/trades/more?bid=${obj.bid}&limit=20&offset=0&cid=${obj.cid}&date=${obj.date}`
      )
      .then((res) => {
        let data = res.data;
        // alert("success")
        let insiderTableData =
          getState().ark.arkInvestorInsiderTableData.data.table_data;
        // console.log(insiderTableData)
        for (const value of insiderTableData) {
          if (value.id === obj.id) {
            value.more_data = data;
          }
        }

        // let randomNum = Math.floor(Math.random() * 3)
        console.log(insiderTableData);
        dispatch(getArkInvestorInsidersTableQuaterDataDone(randomNum + 1));
        dispatch(loading(false));
        // dispatch(getArkInsidersTableDataDone(getState().ark.arkInvestorInsiderTableData))
        // console.log(data)
      });
  };
};

export const getArkCompanyFundamentalsData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/stock/fundamentals?bid=${obj.bid}&cid=${obj.cid}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getArkCompanyFundamentalsDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getArkCompanyInsiderData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/stock/trades?bid=${obj.bid}&cid=${obj.cid}&sortby=${obj.sortby}&sortorder=${obj.sortorder}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getArkCompanyInsiderDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getArkNewsData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/bluewhale/news?bid=${obj.bid}&cid=${obj.cid}&limit=${obj.limit}&offset=${obj.offset}`
      )
      .then((res) => {
        let data = res.data;

        if (!obj.onLoad) {
          let prevData = getState().ark.arkNewsData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }

        dispatch(getArkNewsDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getArkWeightChartData = (obj) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    axios
      .get(domainUserDetails + `/v3/weight-chart?bid=${obj.bid}`)
      .then((res) => {
        let data = res.data;
        let newArr = [];
        let arr = data.data;
        if (arr) {
          for (let i = 0; i < arr.length; i++) {
            let obj = {};
            obj.date = arr[i].date;
            for (const value of arr[i].data) {
              obj[value.ticker] = value.rank;
            }
            newArr.push(obj);
          }
        }
        let newObj = {
          data: newArr,
          tickers: data.tickers,
        };
        // alert("hi")
        // console.log(newObj)
        dispatch(getArkWeightChartDataDone(newObj));
        dispatch(loading(false));
      });
  };
};

export const getArkPerformanceChartData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails + `/v3/performance-chart?bid=${obj.bid}&day=${obj.id}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getArkPerformanceChartDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getArkOwnershipChartData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/institutional-ownership-chart?bid=${obj.bid}&cids=${obj.cids}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getArkOwnershipChartDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getMovingTickerData = (bid) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(domainUserDetails + `/v3/mighty-network/posts?bid=${bid}`)
      .then((res) => {
        let data = res.data;
        dispatch(getMovingTickerDataDone(data));
        dispatch(loading(false));
      });
  };
};
//api-dev.spiking.com/v3/trillion-dollar-companies?limit=10&offset=0
export const getTrillionDollarData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/trillion-dollar-companies?limit=${obj.limit}&offset=${obj.offset}&sortby=${obj.sortby}&sortorder=${obj.sortorder}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getTrillionDollarDataDone(data));
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(loading(false));
      });
  };
};
