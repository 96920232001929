import axios from "axios";
import {
  serverError,
  redirectUser,
  getAccessToken,
  storeAppSoftHardUpdateData,
  storeSystemUpdateData,
} from "../store/actions/index";
import clearStorage from "../helpers/clear_localStorage";
import isCordova from "../helpers/cordova-check";
import { isAndroid, isIos } from "../helpers/device-check";

let idpDomain = process.env.REACT_APP_IDP_URL;
let isRefreshing = false;
let failedQueue = [];
let hasAppUpdateBeenCalled = false;
let hasSystemUpdateBeenCalled = false;

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};
// 3.5.2/3.0
//5.0.1/5.0

//5.0.1/6.0
const checkSoftHardUpdate = (androidValue, iosValue, store) => {
  if (!hasAppUpdateBeenCalled) {
    let appUpdateCode = null;
    let softUpdate = null;
    let hardUpdate = null;

    if (isAndroid() && androidValue) {
      softUpdate = androidValue.split("/")[0];
      hardUpdate = androidValue.split("/")[1];
    } else if (isIos() && iosValue) {
      softUpdate = iosValue.split("/")[0];
      hardUpdate = iosValue.split("/")[1];
    }

    // if (isAndroid()) {
    //   // softUpdate = androidValue.split("/")[0]
    //   // hardUpdate = androidValue.split("/")[1]
    //   softUpdate = "3.5.2"
    //   hardUpdate = "4.0"
    // } else if (isIos()) {
    //   // softUpdate = iosValue.split("/")[0]
    //   // hardUpdate = iosValue.split("/")[1]
    //   softUpdate = "3.5.2"
    //   hardUpdate = "3.0"
    // }

    window.cordova.getAppVersion.getVersionNumber((version) => {
      // let versiontest = "3.0"

      let hardArr = hardUpdate.split(".");
      let versionArr = version.split(".");
      let softArr = softUpdate.split(".");
      // let versionArr = versiontest.split(".")

      if (
        +hardArr[0] > +versionArr[0] ||
        (+hardArr[1] > +versionArr[1] && +hardArr[0] == +versionArr[0])
      ) {
        appUpdateCode = "hard";
        hasAppUpdateBeenCalled = true;
        store.dispatch(storeAppSoftHardUpdateData(appUpdateCode));
      } else if (
        +softArr[0] > +versionArr[0] ||
        (+softArr[1] > +versionArr[1] && +softArr[0] == +versionArr[0])
      ) {
        // if (softUpdate.localeCompare(version) == "1") {
        appUpdateCode = "soft";
        hasAppUpdateBeenCalled = true;
        store.dispatch(storeAppSoftHardUpdateData(appUpdateCode));
        // }
      } else {
        return null;
      }
    });
  }
};

const axiosFunction = {
  setInterceptors: (store, history) => {
    axios.interceptors.response.use(
      (res) => {
        // alert("success from intercpts");
        if (
          res.error &&
          res.error.code === 404 &&
          res.error.message === "Token not found"
        ) {
          clearStorage();
          store.dispatch(redirectUser({ isRedirect: true }));
          // window.location.href = "/react";
        }
        if (isCordova()) {
          let androidUpdateValue = res.headers["android-soft-hard-update"];
          let iosUpdateValue = res.headers["ios-soft-hard-update"];
          if (androidUpdateValue || iosUpdateValue) {
            checkSoftHardUpdate(androidUpdateValue, iosUpdateValue, store);
          }
        }
        // system update check
        let isSystemUpdate = res.headers["website-mode"];
        if (isSystemUpdate && !hasSystemUpdateBeenCalled) {
          hasSystemUpdateBeenCalled = true;
          store.dispatch(storeSystemUpdateData(true));
          // history.push("/system-update")
        } else {
          hasSystemUpdateBeenCalled = false;
        }

        return res;
      },
      (error) => {
        const originalRequest = error.config;

        // if access token has expired
        if (
          error?.response?.status === 401 &&
          !originalRequest._retry &&
          (error.response.data.message === "Incorrect token" ||
            error.response.data.message === "No token provided") &&
          !error.response.config.url.includes("/oauth")
        ) {
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                // bearer token code start
                if (token) {
                  originalRequest.headers["Authorization"] = "Bearer " + token;
                }
                // bearer token code end

                return axios(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          const refreshToken = window.localStorage.getItem("refresh");
          return new Promise(function (resolve, reject) {
            let body = new FormData();
            body.append("token", refreshToken);
            axios
              .post(idpDomain + "/oauth", body)
              .then(({ data }) => {
                window.localStorage.setItem("token", data.access_token);
                window.localStorage.setItem("refresh", data.refresh_token);

                // bearer token code start
                let token = localStorage.getItem("token");

                originalRequest.headers["Authorization"] = "Bearer " + token;
                // bearer token code end

                processQueue(null, data.access_token);
                resolve(axios(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                reject(err);
                // alert("error");
                // store-old.dispatch(getAccessToken())
                // clearStorage()
                // store-old.dispatch(redirectUser({ isRedirect: true }));
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        } else if (
          // if google/facebook token has expired
          error?.response?.status === 401 &&
          error?.response?.data?.message === "Incorrect token" &&
          error?.response?.config?.url?.includes("/oauth")
        ) {
          let loginMode = localStorage.getItem("login_mode");

          if (loginMode === "google") {
            clearStorage(false);
            // alert("google")
            store.dispatch(redirectUser({ isRedirect: true }));
          } else if (loginMode === "facebook") {
            clearStorage(true);
            window.location.href = "/login";
          }
        } else if (
          error?.response?.status === 401 &&
          error.response.data.message === "refresh_token is expired"
        ) {
          // if refresh token has expired
          if(localStorage.getItem("login_mode") === "email") {
            clearStorage(true);
            store.dispatch(redirectUser({ isRedirect: true }));
            return;
          }
          store.dispatch(getAccessToken(true));
          // store-old.dispatch(redirectUser({ isRedirect: true }));
        } else if (error.response.status >= 500) {
          // for server errors call the same api a couple of times
          if (originalRequest._serverErrorCount) {
            if (originalRequest._serverErrorCount < 2) {
              originalRequest._serverErrorCount += 1;
              return axios(originalRequest);
            } else {
              store.dispatch(serverError());
              history.push("/server-error");
            }
          } else {
            originalRequest._serverErrorCount = 1;
            return axios(originalRequest);
          }
        }
        return Promise.reject(error);
      }
    );

    axios.interceptors.request.use((config) => {
      let token = localStorage.getItem("token");
      if (token === null) token = "";

      if (
        config.url.includes("oauth?facebook=") ||
        config.url.includes("oauth?google=") ||
        config.url.includes("oauth?apple=") ||
        config.url.includes("oauth?token=") ||
        config.url.includes("blockchain.info")
      ) {
        return config;
      }
      config.headers.Authorization = "Bearer " + token;

      return config;
    });
  },
};
export default axiosFunction;
