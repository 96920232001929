import { makeStyles } from "@material-ui/core/styles";
import LoginBackground from "../../../images/login_bg.jpg";
// let loginBanner =

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      flexDirection: (props) =>
        !props.loginMessage ? "column-reverse" : "initial",
    },
    maxWidth: "2000px",
    margin: "0 auto",
    // [theme.breakpoints.down("md")]: {
    //   minHeight: "95vh",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   minHeight: "60vh",
    // },
  },
  reviewGridWrapper: {
    backgroundImage: `url(${LoginBackground})`,
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(7, 4),
    },
  },
  reviewGridOverlay: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  mobileLogo: {
    height: 33,
    [theme.breakpoints.down("sm")]: {
      height: 28,
    },
  },
  header: {
    fontFamily: "Nunito Sans",
    fontSize: "14px",
    color: "#424242",
    fontWeight: 700,
    letterSpacing: "3px",
    textAlign: "center",
  },
  loginWrapper: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: 34,
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(11, 4, 7),
      marginTop: 0,
    },
  },
  divider: {
    width: 40,
    [theme.breakpoints.down("sm")]: {
      width: 20,
    },
  },
  reviewCarousel: {
    "& > div.slider-control-bottomcenter": {
      bottom: "-56px !important",
      "& > ul > li > button": {
        fill: `${theme.palette.common.white} !important`,
      },
    },
  },
  loginButtonWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3),
    },
    // [theme.breakpoints.up("md")]: {
    //   position: "absolute",
    //   bottom: props => !props.loginMessage ? "-120px" : "-234px",
    // },
  },
  loginButton: {
    width: 270,
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "none",
    borderRadius: "4px",
    boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.18)",
    padding: theme.spacing(1.5, 2.5),
    justifyContent: "flex-start",
  },
  loginButtonIconFb: {
    width: 21,
    height: 21,
    marginRight: theme.spacing(2.5),
    marginLeft: 4,
  },
  loginButtonIconGl: {
    width: 35,
    height: 35,
    marginRight: 13,
  },
  loginButtonIconAapl: {
    width: 19,
    height: 21,
    marginRight: 21,
    marginLeft: 5,
    marginRight: 20,
  },
  googleButton: {
    backgroundColor: theme.palette.common.white,
    color: "#757575",
    marginBottom: theme.spacing(2),
  },
  facebookButton: {
    backgroundColor: theme.palette.common.white,
    color: "#757575",
    marginBottom: theme.spacing(2),
  },
  footer: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "12px",
    color: "#9E9E9E",
    lineHeight: "16.34px",
    letterSpacing: "0.4px",
  },
  helpPrivacyText: {
    paddingRight: "10px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  width100: {
    width: "100%",
  },
  firstNameBlock: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  lastNameBlock: {
    width: "100%",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  mb8: {
    marginBottom: theme.spacing(1),
  },
  mainContainer: {
    display: "flex",
    width: "100%",
    height: "100vh",
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      height: "130vh",
    },
  },
  subContainer: {
    margin: "auto",
    width: 700,
  },
  submitButtonBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(7),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3.5),
    },
  },
  spikingLogoBlock: {
    width: 128,
    height: 55,
    margin: "0 auto",
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  firstLastBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(7.5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginTop: theme.spacing(5),
    },
  },
  wealthManaBlock: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3.5),
    },
  },
  mainBlock: {
    padding: "24px 40px",
  },
  popUpPaper: {
    backgroundColor: theme.palette.background.charcoal,
  },
  resendCode: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  emailtext: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  greenTickIcon: {
    textAlign: "center",
    margin: "0 auto",
    width: 121,
    height: 121,
  },
  cursor: {
    cursor: "pointer",
  },
  loginMsg: {
    // backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    padding: theme.spacing(2.5),
    borderRadius: 8,
    // position: "absolute",
    // bottom: 14,
    color: "#424242",
    fontWeight: 600,
  },
  absoluteBlock: {
    // position: 'absolute',
    // bottom: props => props.loginMessage ? "73px" : "136px",
    marginTop: (props) => (props.loginMessage ? "24px" : "-80px"),
    [theme.breakpoints.down("xs")]: {
      marginTop: (props) => (!props.loginMessage ? "-22px" : "40px"),
    },
  },
  emailsNotiText: {
    color: "#9E9E9E",
    fontWeight: 400,
  },
  loginMessSubText: {
    width: "28rem",
    margin: "0 auto",
  },
  freeLoginMain: {
    minHeight: "100vh",
    backgroundImage: (props) => `url(${props.freeUserBackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    maxWidth: "2000px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  freeLoginPaper: {
    padding: theme.spacing(6, 8),
    backgroundColor: "#fff",
    color: "#000",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5, 6),
    },
  },
  weight400: {
    fontWeight: 400,
  },
  weight700: {
    fontWeight: 700,
  },
  loginBannerWrapper: {
    [theme.breakpoints.up("md")]: {
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#fff",
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#000",
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(7, 4),
      },
    },
  },
  testInput: {
    padding: "12px !important",
  },
}));

export { useStyles };
