import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogueRoot: {
    position: 'absolute',
    right: 10,
    top: 50,
    zIndex: `${theme.zIndex.drawer} + 5`,
    borderRadius: 20,
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
  loaderRoot: {
    overflow: "hidden",
  },
}));

export { useStyles };
