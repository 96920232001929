import * as actionTypes from "../actions/actionTypes";
import { setCommonTags } from "../actions/dashboardAc";

const initialState = {
  dailybreadData: null,
  resurrectionData: null,
  triangulationData: null,
  dashQueryParams: null,
  b100BluewhalesData: null,
  universeFundsData: null,
  sovereignWealthFundsData: null,
  dailyTradesData: null,
  dailyTradesAllData: null,
  goatData: null,
  gritData: null,
  yoloData: null,
  yoloBitcoinData: null,
  yoloBitcoinValue: null,
  bidsTags: [],
  cidsTags: [],
  commonTags: [],
  selectedCommomTags: "",
  watchlistData: null,
  dashboardBuybackData: null,
  dailybreadPastData: null,
};

const setDailybreadData = (state, action) => {
  return {
    ...state,
    dailybreadData: action.data,
  };
};

const setResurrectionData = (state, action) => {
  return {
    ...state,
    resurrectionData: action.data,
  };
};

const setTriangulationData = (state, action) => {
  return {
    ...state,
    triangulationData: action.data,
  };
};

const setParams = (state, action) => {
  return {
    ...state,
    dashQueryParams: action.data,
  };
};

const set100BluewhalesData = (state, action) => {
  return {
    ...state,
    b100BluewhalesData: action.data,
  };
};

const setUniverseFundsData = (state, action) => {
  return {
    ...state,
    universeFundsData: action.data,
  };
};

const setSovereignWealthFunds = (state, action) => {
  return {
    ...state,
    sovereignWealthFundsData: action.data,
  };
};

const setTags = (state, action) => {
  return {
    ...state,
    selectedCommomTags: action.data,
  };
};

const setDailyTradesData = (state, action) => {
  return {
    ...state,
    dailyTradesData: action.data,
  };
};

const setDailyTradesAllData = (state, action) => {
  return {
    ...state,
    dailyTradesAllData: action.data,
  };
};

const setGOATData = (state, action) => {
  return {
    ...state,
    goatData: action.data,
  };
};

const setGRITData = (state, action) => {
  return {
    ...state,
    gritData: action.data,
  };
};

const setYOLOData = (state, action) => {
  return {
    ...state,
    yoloData: action.data,
  };
};

const setYOLOBitcoinData = (state, action) => {
  return {
    ...state,
    yoloBitcoinData: action.data,
  };
};

const setYOLOBitcoinValue = (state, action) => {
  return {
    ...state,
    yoloBitcoinValue: action.data,
  };
};

export const setBidsTagss = (state, action) => {
  return {
    ...state,
    bidsTags: action.data,
  };
};

export const setCidsTagss = (state, action) => {
  return {
    ...state,
    cidsTags: action.data,
  };
};

export const setCommonTagss = (state, action) => {
  return {
    ...state,
    commonTags: action.data,
  };
};

export const clearSelectedCommonTagss = (state, action) => {
  return {
    ...state,
    selectedCommomTags: "",
  };
};

// to-do remove this start - moved to my spikes - spike universe
export const setWatchlistData = (state, action) => {
  return {
    ...state,
    watchlistData: action.data,
  };
};
// to-do remove this end - moved to my spikes - spike universe

export const setDashboardBuybackData = (state, action) => {
  return {
    ...state,
    dashboardBuybackData: action.data,
  };
};

const setDailybreadPastData = (state, action) => {
  return {
    ...state,
    dailybreadPastData: action.data,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DAILYBREAD_DATA_DONE:
      return setDailybreadData(state, action);
    case actionTypes.GET_RESURRECTION_DATA_DONE:
      return setResurrectionData(state, action);
    case actionTypes.GET_TRIANGULATION_DATA_DONE:
      return setTriangulationData(state, action);
    case actionTypes.SET_DASH_QUERY_PARAMS:
      return setParams(state, action);
    case actionTypes.GET_100BLUEWHALES_DATA_DONE:
      return set100BluewhalesData(state, action);
    case actionTypes.GET_UNIVERSE_FUNDS_DATA_DONE:
      return setUniverseFundsData(state, action);
    case actionTypes.GET_SOVEREIGN_WEALTH_FUNDS_DONE:
      return setSovereignWealthFunds(state, action);
    case actionTypes.SET_DASH_TAGS:
      return setTags(state, action);
    case actionTypes.GET_DAILY_TRADES_DATA_DONE:
      return setDailyTradesData(state, action);
    case actionTypes.GET_DAILYTRADES_ALL_DATA_DONE:
      return setDailyTradesAllData(state, action);
    case actionTypes.GET_GOAT_DATA_DONE:
      return setGOATData(state, action);
    case actionTypes.GET_GRIT_DATA_DONE:
      return setGRITData(state, action);
    case actionTypes.GET_YOLO_DATA_DONE:
      return setYOLOData(state, action);
    case actionTypes.GET_YOLO_BITCOIN_DATA_DONE:
      return setYOLOBitcoinData(state, action);
    case actionTypes.GET_YOLO_BITCOIN_VALUE_DONE:
      return setYOLOBitcoinValue(state, action);
    case actionTypes.SET_BIDS_TAGS:
      return setBidsTagss(state, action);
    case actionTypes.SET_CIDS_TAGS:
      return setCidsTagss(state, action);
    case actionTypes.SET_COMMON_TAGS:
      return setCommonTagss(state, action);
    case actionTypes.CLEAR_SELECTED_COMMON_TAGS:
      return clearSelectedCommonTagss(state, action);
    case actionTypes.GET_WATCHLIST_DATA_DONE:
      return setWatchlistData(state, action);
    case actionTypes.GET_DASHBOARD_BUYBACK_DATA_DONE:
      return setDashboardBuybackData(state, action);
    case actionTypes.GET_DAILYBREAD_PAST_DATA_DONE:
      return setDailybreadPastData(state, action);
    default:
      return state;
  }
};

export default reducer;
