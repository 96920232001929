import React from "react";
import Content from "../components/Content";

const Strategy = () => {
  const content = {
    dayTitle: "Five Strategies to Build Your Own Investing Legacy",
    heading:
      "Congratulations on reaching the pinnacle of your journey with Spiking.",
    intro:
      "You're now equipped with a comprehensive set of strategies to build a lasting investing legacy and achieve your financial dreams.",
    youtubeVideoId: "OoxLl97Ph8Q",
    about: {
      sectionTitle: "ABOUT Legacy",
      description:
        "Today, we will focus on five strategies to build your own investing legacy. These strategies are tailored to different types of investors and time commitments, helping you to maximize your potential and achieve your financial goals.",
    },
    topicsIntro:
      "Your Spiking journey provides an in-depth look at these key topics:",
    topics: [
      {
        title: "Five Strategies",
        description:
          "Understand the different strategies tailored to your situation, whether you're an employee, self-employed, business owner, IPO investor, or following Warren Buffett’s approach with Berkshire Hathaway.",
      },
      {
        title: "24-Hour Breakdown",
        description:
          "Determine how much time you can commit to investing by dividing your day into three 8-hour intervals: sleeping, working, and investing. This will help you select the most suitable strategy.",
      },
      {
        title: "Limited Capital",
        description:
          "Decide whether to invest equally across the three instruments (cryptos, options, stocks) or focus on just one Holy Grail Portfolio, based on your financial capacity.",
      },
      {
        title: "Equipped for Success",
        description:
          "Reflect on the journey you’ve taken, the knowledge you’ve gained, and the strategies you’ve learned. You are now equipped to become a millionaire and achieve your financial destiny.",
      },
    ],
    nextSteps: {
      title: "NEXT STEPS",
      steps: [
        {
          title: "Your Legacy",
          description:
            "Accelerate your financial freedom journey with the Spiking Lifetime Membership.",
          actions: [
            {
              label: "Explore Membership",
              link: "https://spi.ke/membership",
              type: "primary",
            },
          ],
        },
        {
          title: "Understanding Cashflow Quadrant",
          description:
            "Gain insights into the Cashflow Quadrant to better understand your financial position.",
          actions: [
            {
              label: "Learn About Cashflow Quadrant",
              link: "https://spiking.com/holygrail?portfolio_id=1",
              type: "primary",
            },
          ],
        },
        {
          title: "Make Your Choice",
          description:
            "Participate in our poll on the Facebook Community to share your chosen strategy.",
          actions: [
            {
              label: "Join the Poll",
              link: "https://facebook.com/groups/spiking",
              type: "primary",
            },
          ],
        },
      ],
    },
  };

  return <Content content={content} />;
};

export default Strategy;
