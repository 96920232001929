import {
  Box,
  Button,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import UserAvatar from "../../../../UserAvatar";
import { ReactComponent as FlagIcon } from "../../../../../images/dropdown/flag.svg";
import { ReactComponent as ReferralIcon } from "../../../../../images/dropdown/refer.svg";
import { ReactComponent as EarnIcon } from "../../../../../images/dropdown/money-recive.svg";
import { ReactComponent as WalletIcon } from "../../../../../images/dropdown/wallet-3.svg";
import { ReactComponent as TrackIcon } from "../../../../../images/dropdown/chart.svg";
import { ReactComponent as SettingIcon } from "../../../../../images/dropdown/setting-2.svg";
import { ReactComponent as RateUsIcon } from "../../../../../images/dropdown/like.svg";
import { ReactComponent as SupportIcon } from "../../../../../images/dropdown/headphone.svg";
import { ReactComponent as LogoutIcon } from "../../../../../images/dropdown/logout.svg";

import basic from "../../../../../images/onboarding/welcome_spiking.png";
import brokerage from "../../../../../images/onboarding/brokerage.png";
import explore from "../../../../../images/onboarding/explore.png";
import events from "../../../../../images/onboarding/events.png";
import joincommunity from "../../../../../images/onboarding/joincommunity.png";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";

import isCordova from "../../../../../helpers/cordova-check";
import { ChevronRight } from "@material-ui/icons";
import { Menu, MenuItem } from "@mui/material";
import { isAndroid, isIos } from "../../../../../helpers/device-check";
import { addMonths } from "date-fns";
import { sidebarBottom, sidebarTop } from "../../../../../features/Journey";

const useStyles = makeStyles((theme) => ({
  profileBlock: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  activeTillTypo: {
    fontSize: 14,
    marginTop: theme.spacing(0.5),
  },
  dropdownPaper: {
    marginTop: theme.spacing(0.5),
    minWidth: 200,
    paddingTop: 0,
  },
}));

function ProfileDropdown({ user, history }) {
  const onboardingCompletedAt = user?.OnboardingCompletedAt;
  const isOnboardingExpired = useMemo(() => {
    if (onboardingCompletedAt) {
      return new Date() > addMonths(new Date(onboardingCompletedAt), 1);
    } else {
      return false;
    }
  }, [onboardingCompletedAt]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const stepsNumber = 10;

  const onboardingStep = parseInt(
    localStorage.getItem(`onboarding_step_${user?.Id}`) || 1
  );

  const classes = useStyles();

  const handleUpgradeRenewClick = () => {
    if (isCordova()) {
      history.push("/payment");
    } else window.open(user?.UpgradeOrRenewButtonTextURL, "_blank");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleListItemClick = useCallback(
    (item) => {
      // if (item.id !== "goat") setSelectedOption(item.id);
      if (item.isHref) {
        window.open(item.to, "_blank");
      } else if (item.to !== "") history.push(item.to);
      else return;
    },
    [history]
  );

  const handleClose = (e, value, image) => {
    setAnchorEl(null);
  };

  const handleReview = () => {
    if (isCordova()) {
      let appId = "";
      if (isAndroid()) appId = "com.spiking.spike.android";
      if (isIos()) appId = "1081721327";

      window.LaunchReview.launch(
        () => {
          console.log("Successfully launched store app");
        },
        (error) => {
          console.log("Error launching store app: " + error);
        },
        appId
      );
    } else {
      // window.open("https://www.feefo.com/en-GB/reviews/spiking", "_blank");
      window.open("https://spiking.com/app", "_blank");
    }
  };

  const getImageUrl = () => {
    if (onboardingStep === 1) {
      return basic;
    } else if (onboardingStep === 2) {
      return brokerage;
    } else if (onboardingStep === 3) {
      return user?.MembershipText === "Free User" ? events : explore;
    } else if (onboardingStep === 4) {
      return user?.MembershipText === "Free User" ? joincommunity : events;
    } else if (onboardingStep === 5) {
      return joincommunity;
    }
  };

  const currentStep = [...sidebarTop, ...sidebarBottom].find((element) => {
    return element.step === onboardingStep;
  });

  return (
    <Box ml={{ xs: 0, md: 2.5 }} className={classes.profileBlock}>
      {user && user.Id && (
        <button
          className="cursor-pointer"
          onClick={handleClick}
          onMouseEnter={handleClick}
        >
          <UserAvatar
            imageUrl={user && user.ImageUrl}
            firstName={user && user.Firstname}
            lastName={user && user.Lastname}
            IsCenturion={user?.IsCenturion}
            isTopNav
          />
        </button>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          // "aria-labelledby": "basic-button",
          sx: { py: 0 },
        }}
        classes={{ paper: classes.dropdownPaper }}
      >
        <MenuItem>
          {/* onboarding tracker */}
          {!(onboardingStep / stepsNumber >= 1) && (
            <div className="flex-col flex gap-y-2 group min-w-[300px] py-2">
              <div className="font-semibold text-lg leading-5 -tracking-[0.02em]">
                Recent Activity
              </div>
              <div
                onClick={() => history.push("/onboarding")}
                className="group-hover:border-[#2065F6] group-hover:bg-[#E5F2FF] rounded-lg border border-[#DFE1E6] bg-[#F6F6F9] p-3 gap-3 flex"
              >
                <div className="rounded-lg border group-hover:border-[#2065F6] border-[#E1E1E1] p-1">
                  <div className="flex items-center justify-center group-hover:bg-[#E5F2FF] bg-[#EBECF0] p-[6px] rounded-[4px]">
                    <SvgIcon>
                      <RocketLaunchOutlinedIcon />
                    </SvgIcon>
                  </div>
                </div>
                <div className="gap-y-1 flex flex-col">
                  <div className="text-lg font-semibold group-hover:text-[#2065F6] text-[#253858]">
                    Welcome Onboarding
                  </div>
                  <div className="text-sm leading-5 text-[#6B778C]">
                    Take your first step ⭐
                  </div>
                </div>
                <div className="rounded-full border-4 p-[2px] aspect-square flex-shrink-0 w-11 h-11">
                  <div className="rounded-full bg-[#6E7E95] h-full w-full aspect-square flex items-center justify-center text-xs text-white">
                    1/5
                  </div>
                </div>
              </div>
            </div>
          )}
        </MenuItem>

        <MenuItem>
          {/* wallet */}
          <div
            className="flex items-center cursor-pointer px-4 py-2 gap-x-3 group w-full"
            onClick={() => history.push("/profile/personal-information")}
          >
            <SvgIcon>
              <SettingIcon />
            </SvgIcon>
            <div className="text-lg text-[#6E7E95] group-hover:text-[#2065F6]">
              Settings
            </div>
          </div>
        </MenuItem>

        <MenuItem>
          {/* wallet */}
          <div
            className="flex items-center cursor-pointer px-4 py-2 gap-x-3 group w-full"
            onClick={() =>
              handleListItemClick({
                to: "https://spiking.com/help",
                isHref: true,
                id: "support",
              })
            }
          >
            <SvgIcon>
              <SupportIcon />
            </SvgIcon>
            <div className="text-lg text-[#6E7E95] group-hover:text-[#2065F6]">
              Support
            </div>
          </div>
        </MenuItem>

        <MenuItem>
          {/* Support */}
          <div
            className="flex items-center cursor-pointer px-4 py-2 gap-x-3 group w-full"
            onClick={() => {
              if (isCordova()) {
                window.cordova.plugins.GoogleSignInPlugin.signOut(
                  function (success) {
                    console.log(success);
                    history.push("/logout");
                  },
                  function (error) {
                    console.error(error);
                  }
                );
              } else history.push("/logout");
            }}
          >
            <SvgIcon>
              <LogoutIcon />
            </SvgIcon>
            <div className="text-lg text-[#6E7E95] group-hover:text-[#2065F6]">
              Logout
            </div>
          </div>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default withRouter(ProfileDropdown);
