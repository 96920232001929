import { ChevronRight } from "@material-ui/icons";
import { format } from "date-fns";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Hidden } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";

function DetailsMobile({
  setDateEventsDetails,
  dateEventsDetails,
  groupDataDetails,
  openGroupDateDetails,
}) {
  const [page, setPageIndex] = useState(0);
  const [details, setDetails] = useState(null);

  const closeModal = () => {
    setDateEventsDetails(false);
  };

  const openDetailsEvent = (event) => {
    setDetails(event);
    setPageIndex(1);
  };

  return (
    <>
      <Hidden mdUp>
        <Transition.Root show={!!dateEventsDetails?.dateTitle} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed z-10 bottom-0 overflow-auto w-full">
              <div className="flex items-center justify-center min-h-full md:p-4 p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                  enterTo="opacity-100 translate-y-0 md:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 md:scale-100"
                  leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                >
                  <Dialog.Panel className="relative bg-white w-full h-[600px] overflow-y-auto rounded-xl shadow-xl transition-all md:my-8 md:rounded-2xl md:overflow-hidden">
                    <Dialog.Title className="text-xl font-semibold flex items-center justify-between p-4 border-b border-neutral-300 dark:border-dark-neutral-300">
                      <div className="flex justify-center items-center relative w-full">
                        {page === 1 && (
                          <button
                            onClick={() => setPageIndex(0)}
                            className="absolute left-0"
                          >
                            <ChevronLeftIcon />
                          </button>
                        )}
                        <span>{dateEventsDetails.dateTitle}</span>
                        <button
                          onClick={closeModal}
                          className="absolute right-0"
                        >
                          <CloseIcon />
                        </button>
                      </div>
                    </Dialog.Title>
                    <div
                      style={{
                        transform: `translateX(-${page * 50}%)`,
                      }}
                      className="w-[200%] grid grid-cols-2 transition-all duration-200"
                    >
                      {/* page 1 */}
                      <div className="px-2 pt-4 space-y-2">
                        {/* case 1 : simple events  */}
                        {dateEventsDetails?.events?.map((event, index) => (
                          <div
                            onClick={() => openDetailsEvent(event)}
                            key={event?.id + index}
                            style={{
                              backgroundColor: event?.allDay
                                ? event?.color
                                : "",
                            }}
                            className={`rounded-md ${
                              event?.allDay ? "text-white px-2 py-1" : ""
                            }`}
                          >
                            {event?.allDay ? (
                              event?.title
                            ) : (
                              <div className="flex">
                                <div
                                  style={{ backgroundColor: event?.color }}
                                  className={`rounded-full h-2 w-2 mt-2 flex-shrink-0`}
                                ></div>
                                <div className="ml-2">
                                  <span className="lowercase">
                                    {format(new Date(event?.start), "hh:mm a")}{" "}
                                  </span>
                                  {event?.title}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                        {/* case 2 : groupEvents events should've 3 pages  */}
                      </div>
                      <div className="px-4 pt-4">
                        <div className="flex items-start gap-x-3 mb-[24px]">
                          <div
                            style={{
                              backgroundColor: details?.color,
                            }}
                            className="w-[24px] h-[24px] flex-shrink-0 rounded-md mt-2"
                          ></div>
                          <div>
                            <div className="text-[#3c4043] text-[22px] leading-[28px] max-w-[348px]">
                              {details?.title}
                            </div>
                            <div className="text-[14px] mt-[3px] leading-[18px] text-[#3c4043] max-w-[348px]">
                              {details?.start && details?.type !== "ipo" && (
                                <>
                                  <span>
                                    {format(
                                      new Date(details?.start),
                                      "iiii, MMMM d ⋅ h:mm"
                                    )}
                                  </span>
                                  {!details?.end && (
                                    <span>
                                      {format(new Date(details?.start), "aaa")}
                                    </span>
                                  )}
                                </>
                              )}
                              {details?.start && details?.type === "ipo" && (
                                <>
                                  <span>
                                    {format(
                                      new Date(details?.start),
                                      "iiii, MMMM d"
                                    )}
                                  </span>
                                </>
                              )}
                              {details?.end && details?.type !== "ipo" && (
                                <span>
                                  {" "}
                                  - {format(new Date(details?.end), "hh:mmaaa")}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        {details?.url && (
                          <div className="flex items-start gap-x-3 mb-[14px] max-w-[348px] ">
                            <div className="w-[24px] h-[24px] flex-shrink-0  text-[#5f6368]">
                              <svg
                                focusable="false"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                className="text-[#5f6368] -ml-1"
                                fill="#5f6368"
                              >
                                <path
                                  fill="#5f6368"
                                  d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"
                                ></path>
                                <circle
                                  fill="#5f6368"
                                  cx="12"
                                  cy="9"
                                  r="2.5"
                                ></circle>
                              </svg>
                            </div>
                            <a
                              target="_blank"
                              href={details?.url}
                              className="inline-block text-[14px] truncate leading-[18px] text-[#3c4043] hover:underline"
                              rel="noreferrer"
                            >
                              {details?.url}
                            </a>
                          </div>
                        )}
                        {details?.source && (
                          <div className="flex items-start gap-x-3 mb-[14px] max-w-[348px] ">
                            <div className="w-[24px] h-[24px] flex-shrink-0  text-[#5f6368]">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 -960 960 960"
                                width="24"
                                className="-ml-[2px]"
                                fill="#5f6368"
                              >
                                <path d="M580-240q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z" />
                              </svg>
                            </div>
                            <div className="text-[14px] leading-[18px] text-[#3c4043]">
                              {details?.source}
                            </div>
                          </div>
                        )}
                        {details?.description && (
                          <div className="flex items-start gap-x-3  mb-[14px] max-w-[348px]">
                            <div className="w-[24px] h-[24px] mt-2 flex-shrink-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="var(--ci-primary-color, #5f6368)"
                                  d="M334.627,16H48V496H472V153.373ZM440,166.627V168H320V48h1.373ZM80,464V48H288V200H440V464Z"
                                  className="ci-primary"
                                />
                                <rect
                                  width="224"
                                  height="32"
                                  x="136"
                                  y="296"
                                  fill="var(--ci-primary-color, #5f6368)"
                                  className="ci-primary"
                                />
                                <rect
                                  width="224"
                                  height="32"
                                  x="136"
                                  y="376"
                                  fill="var(--ci-primary-color, #5f6368)"
                                  className="ci-primary"
                                />
                              </svg>
                            </div>
                            <div className="text-[14px] leading-[18px] text-[#3c4043]">
                              <span
                                rel="noreferrer"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {details?.description}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </Hidden>
    </>
  );
}

export default DetailsMobile;
