const getArray = (arr, mode) => {
  let d = [];
  if (mode === "bluewhale" && arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      let name = arr[i].bluewhale.name;
      let id = arr[i].bluewhale.id;
      let image_url = arr[i].bluewhale.image_url;
      d.push({ name, id, image_url });
    }
    return d;
  } else if (mode === "company" && arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      let name =
        arr[i].company.name +
        " (" +
        arr[i].company.ticker +
        " : " +
        arr[i].company.market +
        ")";
      let id = arr[i].company.id;
      let image_url = arr[i].company.image_url;
      let name_only = arr[i].company.name;
      let ticker = arr[i].company.ticker;
      let market = arr[i].company.market;
      let percentage_change = arr[i].company.percentage_change;
      let last_change = arr[i].company.last_change;
      let last_done = arr[i].company.last_done;
      let currency = arr[i].company.currency;
      let verified_tick_image = arr[i].company.verified_tick_image;
      let quadrant = arr[i].company.quadrant
      let earnings_date = arr[i].company.earnings_date
      let earnings_date_time = arr[i].company.earnings_date_time
      
      d.push({
        name,
        id,
        image_url,
        name_only,
        ticker,
        market,
        percentage_change,
        last_change,
        last_done,
        currency,
        verified_tick_image,
        quadrant,
        earnings_date,
        earnings_date_time
      });
    }
    return d;
  }
};

export { getArray };
