import axios from "axios"
import * as actionTypes from "./actionTypes"
import { infiniteScrollLoading, loading } from "./commonAc"
import { getFollowingInsidersData, getFollowingCompaniesData } from "./navbarAc"

let apiDomain = process.env.REACT_APP_API_URL
let metadataDomain = process.env.REACT_APP_API2_URL

export const getWhoBoughtDataDone = (data) => {
  return {
    type: actionTypes.GET_WHO_BOUGHT_DATA_DONE,
    data: data,
  }
}

export const getWhoSoldDataDone = (data) => {
  return {
    type: actionTypes.GET_WHO_SOLD_DATA_DONE,
    data: data,
  }
}

export const getCompanyInsidersCallsDone = (data) => {
  return {
    type: actionTypes.GET_COMPANY_INSIDERS_CALLS_DATA_DONE,
    data: data,
  }
}

export const getCompanyInsidersPutsDone = (data) => {
  return {
    type: actionTypes.GET_COMPANY_INSIDERS_PUTS_DATA_DONE,
    data: data,
  }
}

export const getCompanyInsidersDataDone = (data) => {
  return {
    type: actionTypes.GET_COMPANY_INSIDERS_DATA_DONE,
    data: data,
  }
}

export const getLeaderboardTableDataDone = (data) => {
  return {
    type: actionTypes.GET_LEADERBOARD_TABLE_DATA_DONE,
    data: data,
  }
}

export const getBluewhaleWhoBoughtDataDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_WHO_BOUGHT_DATA_DONE,
    data: data,
  }
}

export const getBluewhaleWhoSoldDataDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_WHO_SOLD_DATA_DONE,
    data: data,
  }
}

export const getBluewhaleInsidersCallsDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_CALLS_DATA_DONE,
    data: data,
  }
}

export const getBluewhaleInsidersPutsDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_PUTS_DATA_DONE,
    data: data,
  }
}

export const storeQid = (qid) => {
  return {
    type: actionTypes.STORE_QID,
    data: qid,
  }
}

export const storeDefaultQidObj = (qidObj) => {
  return {
    type: actionTypes.STORE_DEFAULT_QID_OBJ,
    data: qidObj,
  }
}

export const storeSortOrder = (sortorder) => {
  return {
    type: actionTypes.STORE_SORT_ORDER,
    data: sortorder,
  }
}

export const storeBluewhaleQid = (qid) => {
  return {
    type: actionTypes.STORE_BLUEWHALE_QID,
    data: qid,
  }
}

export const storeBluewhaleIds = (bids) => {
  return {
    type: actionTypes.STORE_BLUEWHALE_IDS,
    data: bids,
  }
}

export const storeCompanyIds = (cids) => {
  return {
    type: actionTypes.STORE_COMPANY_IDS,
    data: cids,
  }
}

export const getShareholdersDataDone = (data) => {
  return {
    type: actionTypes.GET_SHAREHOLDERS_DATA_DONE,
    data: data,
  }
}

export const getBluewhaleHoldingsDataDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_HOLDINGS_DATA_DONE,
    data: data,
  }
}

export const bluewhalePortfolioBreakdownDone = (data) => {
  return {
    type: actionTypes.BLUEWHALE_PORTFOLIO_BREAKDOWN_DONE,
    data: data,
  }
}

export const get100WhoBoughtDataDone = (data) => {
  return {
    type: actionTypes.GET_100_WHO_BOUGHT_DATA_DONE,
    data: data,
  }
}

export const get100WhoSoldDataDone = (data) => {
  return {
    type: actionTypes.GET_100_WHO_SOLD_DATA_DONE,
    data: data,
  }
}

export const getUpcomingEventsDataDone = (data) => {
  return {
    type: actionTypes.GET_UPCOMING_EVENTS_DATA_DONE,
    data: data,
  }
}

export const getCompanyConnectSpikesDataDone = (data) => {
  return {
    type: actionTypes.GET_COMPANY_CONNECT_SPIKES_DATA_DONE,
    data: data,
  }
}

export const getBluewhaleConnectSpikesDataDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_CONNECT_SPIKES_DATA_DONE,
    data: data,
  }
}

export const setQueryParams = (data) => {
  return {
    type: actionTypes.SET_QUERY_PARAMS,
    data: data,
  }
}

export const getCompanyMetadataDone = (data) => {
  return {
    type: actionTypes.GET_COMPANY_METADATA_DONE,
    data: data,
  }
}

export const getBluewhaleMetadataDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_METADATA_DONE,
    data: data,
  }
}

export const getQuarterFilterOptionsDone = (data) => {
  return {
    type: actionTypes.GET_QUARTER_FILTER_OPTIONS_DONE,
    data: data,
  }
}

export const getCompany100BluewhaleDataDone = (data) => {
  return {
    type: actionTypes.GET_COMPANY_100BLUEWHALE_DATA_DONE,
    data: data,
  }
}

export const getCompanyBluewhaleDescriptionDone = (data) => {
  return {
    type: actionTypes.GET_COMPANY_BLUEWHALE_DESCRIPTION_DONE,
    data: data,
  }
}

export const getOptionsDataDone = (data) => {
  return {
    type: actionTypes.GET_OPTIONS_DATA_DONE,
    data: data,
  }
}

export const setOptionsSortData = (data) => {
  return {
    type: actionTypes.SET_OPTIONS_SORT_DATA,
    data: data,
  }
}

export const clearCompanyMetadata = () => {
  return {
    type: actionTypes.CLEAR_COMPANY_METADATA,
  }
}

export const getCompanyFundamentalsDataDone = (data) => {
  return {
    type: actionTypes.GET_COMPANY_FUNDAMENTALS_DATA_DONE,
    data: data,
  }
}

export const getBluewhaleFundamentalsDataDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_FUNDAMENTALS_DATA_DONE,
    data: data,
  }
}

export const getBluewhaleProfileDataDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_PROFILE_DATA_DONE,
    data: data,
  }
}

export const getIsFollowingInvestorDone = (data) => {
  return {
    type: actionTypes.GET_IS_FOLLOWING_INVESTOR_DONE,
    data: data,
  }
}

export const getIsFollowingCompanyDone = (data) => {
  return {
    type: actionTypes.GET_IS_FOLLOWING_COMPANY_DONE,
    data: data,
  }
}

export const getBluewhaleNetExposureDataDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_NET_EXPOSURE_DATA_DONE,
    data: data,
  }
}

export const getBluewhaleManagedFundsDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_MANAGED_FUNDS_DONE,
    data: data,
  }
}

export const getLeaderboardTableData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true))
    let bid = obj.id.split("-")[0]
    let cid = obj.id.split("-")[1]
    axios
      .get(
        metadataDomain +
        "/v3/company-bluewhale?cid=" +
        cid +
        "&bid=" +
        bid +
        "&type=" +
        obj.type
      )
      .then((res) => {
        let data = res.data.data
        dispatch(getLeaderboardTableDataDone(data))
        dispatch(loading(false))
      })
  }
}

export const getWhoBoughtData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))
    if (!obj.onLoad) dispatch(infiniteScrollLoading(true))

    // let cid = obj.id.split("-")[0];
    // let qid = obj.id.split("-")[1];

    let bids = ""
    let cid = ""
    let qid = ""
    let sortby = ""
    let sortorder = ""

    if (obj.cid !== undefined) cid = obj.cid
    if (obj.qid !== undefined) qid = obj.qid
    if (obj.bids !== undefined) bids = obj.bids
    if (obj.sortby !== undefined) sortby = obj.sortby
    if (obj.sortorder !== undefined) sortorder = obj.sortorder

    axios
      .get(
        metadataDomain +
        "/v3/company-quarter-bought?cid=" +
        cid +
        "&qid=" +
        qid +
        "&sortby=" +
        sortby +
        "&sortorder=" +
        sortorder +
        "&limit=" +
        obj.limit +
        "&offset=" +
        obj.offset +
        "&bids=" +
        bids
      )
      .then((res) => {
        let data = res.data || []
        if (!obj.onLoad) {
          let prevData = getState().leaderboard.whoBoughtData.data || {}
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getWhoBoughtDataDone(data))
        dispatch(getQuarterFilterOptionsDone(data.filter_quarters))
        dispatch(loading(false))
        dispatch(infiniteScrollLoading(false))
      })
  }
}

export const getWhoSoldData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    // let cid = obj.id.split("-")[0];
    // let qid = obj.id.split("-")[1];

    let bids = ""
    let cid = ""
    let qid = ""
    let sortby = ""
    let sortorder = ""

    if (obj.cid !== undefined) cid = obj.cid
    if (obj.qid !== undefined) qid = obj.qid
    if (obj.bids !== undefined) bids = obj.bids
    if (obj.sortby !== undefined) sortby = obj.sortby
    if (obj.sortorder !== undefined) sortorder = obj.sortorder

    axios
      .get(
        metadataDomain +
        "/v3/company-quarter-sold?cid=" +
        cid +
        "&qid=" +
        qid +
        "&sortby=" +
        sortby +
        "&sortorder=" +
        sortorder +
        "&limit=" +
        obj.limit +
        "&offset=" +
        obj.offset +
        "&bids=" +
        bids
      )
      .then((res) => {
        let data = res.data || []
        if (!obj.onLoad) {
          let prevData = getState().leaderboard.whoSoldData.data || {}
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getWhoSoldDataDone(data))
        dispatch(getQuarterFilterOptionsDone(data.filter_quarters))
        dispatch(loading(false))
      })
  }
}

export const getCompanyInsidersCallsData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    // let cid = obj.id.split("-")[0];
    // let qid = obj.id.split("-")[1];

    let bids = ""
    let cid = ""
    let qid = ""
    let sortby = ""
    let sortorder = ""

    if (obj.cid !== undefined) cid = obj.cid
    if (obj.qid !== undefined) qid = obj.qid
    if (obj.bids !== undefined) bids = obj.bids
    if (obj.sortby !== undefined) sortby = obj.sortby
    if (obj.sortorder !== undefined) sortorder = obj.sortorder

    axios
      .get(
        metadataDomain +
        "/v3/company-quarter-call?cid=" +
        cid +
        "&qid=" +
        qid +
        "&sortby=" +
        sortby +
        "&sortorder=" +
        sortorder +
        "&limit=" +
        obj.limit +
        "&offset=" +
        obj.offset +
        "&bids=" +
        bids
      )
      .then((res) => {
        let data = res.data || []
        if (!obj.onLoad) {
          let prevData =
            getState().leaderboard.companyInsidersCallsData.data || {}
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getCompanyInsidersCallsDone(data))
        dispatch(getQuarterFilterOptionsDone(data.filter_quarters))
        dispatch(loading(false))
      })
  }
}

export const getCompanyInsidersPutsData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    // let cid = obj.id.split("-")[0];
    // let qid = obj.id.split("-")[1];

    let bids = ""
    let cid = ""
    let qid = ""
    let sortby = ""
    let sortorder = ""

    if (obj.cid !== undefined) cid = obj.cid
    if (obj.qid !== undefined) qid = obj.qid
    if (obj.bids !== undefined) bids = obj.bids
    if (obj.sortby !== undefined) sortby = obj.sortby
    if (obj.sortorder !== undefined) sortorder = obj.sortorder

    axios
      .get(
        metadataDomain +
        "/v3/company-quarter-put?cid=" +
        cid +
        "&qid=" +
        qid +
        "&sortby=" +
        sortby +
        "&sortorder=" +
        sortorder +
        "&limit=" +
        obj.limit +
        "&offset=" +
        obj.offset +
        "&bids=" +
        bids
      )
      .then((res) => {
        let data = res.data || []
        if (!obj.onLoad) {
          let prevData =
            getState().leaderboard.companyInsidersPutsData.data || {}
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getCompanyInsidersPutsDone(data))
        dispatch(getQuarterFilterOptionsDone(data.filter_quarters))
        dispatch(loading(false))
      })
  }
}

export const getCompanyInsidersData = (obj) => {
  return async (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    let bids = ""
    let cid = ""
    let qid = ""
    let limit = obj.limit
    let offset = obj.offset

    if (obj.cid !== undefined) cid = obj.cid
    if (obj.qid !== undefined) qid = obj.qid
    if (obj.bids !== undefined) bids = obj.bids

    const [
      boughtResponse,
      soldResponse,
      shareHoldersResponse,
      top100ManagersResponse,
    ] = await Promise.all([
      axios.get(
        `${metadataDomain}/v3/company-quarter-bought?cid=${cid}&qid=${qid}&limit=${limit}&offset=${offset}&bids=${bids}`
      ),
      axios.get(
        `${metadataDomain}/v3/company-quarter-sold?cid=${cid}&qid=${qid}&limit=${limit}&offset=${offset}&bids=${bids}`
      ),
      axios.get(
        `${metadataDomain}/v3/company-shareholders?cid=${cid}&limit=${limit}&offset=${offset}&bids=${bids}`
      ),
      // not used
      // axios.get(`${metadataDomain}/v3/company-100bluewhales?cid=${cid}`),
    ])

    const { data: getWhoBoughtData } = boughtResponse
    const { data: getWhoSoldData } = soldResponse
    const { data: shareholdersData } = shareHoldersResponse
    const { data: top100ManagersData } = top100ManagersResponse

    const nextData = {
      getWhoBoughtData,
      getWhoSoldData,
      shareholdersData,
      top100ManagersData,
    }
    dispatch(getCompanyInsidersDataDone(nextData))
    // TODO Quarter filter might not apply throughout all tabs
    dispatch(getQuarterFilterOptionsDone(getWhoBoughtData.filter_quarters))
    dispatch(loading(false))
  }
}

export const getBluewhaleWhoBoughtData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    let cids = ""
    let bid = ""
    let qid = ""
    let sortby = ""
    let sortorder = ""

    if (obj.bid !== undefined) bid = obj.bid
    if (obj.qid !== undefined) qid = obj.qid
    if (obj.cids !== undefined) cids = obj.cids
    if (obj.sortby !== undefined) sortby = obj.sortby
    if (obj.sortorder !== undefined) sortorder = obj.sortorder

    axios
      .get(
        `${metadataDomain}/v3/bluewhale-quarter-bought?qid=${qid}&cids=${cids}&bid=${bid}&sortby=${sortby}&sortorder=${sortorder}&limit=${obj.limit}&offset=${obj.offset}`
      )
      .then((res) => {
        let data = res.data
        if (!obj.onLoad) {
          let prevData = getState().leaderboard.bluewhaleWhoBoughtData.data
          let appendedData = prevData && prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getBluewhaleWhoBoughtDataDone(data))
        dispatch(getQuarterFilterOptionsDone(data.filter_quarters))
        if (obj.onLoad) dispatch(loading(false))
      })
  }
}

export const getBluewhaleWhoSoldData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    let cids = ""
    let bid = ""
    let qid = ""
    let sortby = ""
    let sortorder = ""

    if (obj.bid !== undefined) bid = obj.bid
    if (obj.qid !== undefined) qid = obj.qid
    if (obj.cids !== undefined) cids = obj.cids
    if (obj.sortby !== undefined) sortby = obj.sortby
    if (obj.sortorder !== undefined) sortorder = obj.sortorder

    axios
      .get(
        metadataDomain +
        "/v3/bluewhale-quarter-sold?bid=" +
        bid +
        "&qid=" +
        qid +
        "&sortby=" +
        sortby +
        "&sortorder=" +
        sortorder +
        "&limit=" +
        obj.limit +
        "&offset=" +
        obj.offset +
        "&cids=" +
        cids
      )
      .then((res) => {
        let data = res.data
        if (!obj.onLoad) {
          let prevData = getState().leaderboard.bluewhaleWhoSoldData.data
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getBluewhaleWhoSoldDataDone(data))
        dispatch(getQuarterFilterOptionsDone(data.filter_quarters))
        dispatch(loading(false))
      })
  }
}

export const getBluewhaleInsidersCallData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    let cids = ""
    let bid = ""
    let qid = ""
    let sortby = ""
    let sortorder = ""

    if (obj.bid !== undefined) bid = obj.bid
    if (obj.qid !== undefined) qid = obj.qid
    if (obj.cids !== undefined) cids = obj.cids
    if (obj.sortby !== undefined) sortby = obj.sortby
    if (obj.sortorder !== undefined) sortorder = obj.sortorder

    axios
      .get(
        `${metadataDomain}/v3/bluewhale-quarter-call?qid=${qid}&cids=${cids}&bid=${bid}&sortby=${sortby}&sortorder=${sortorder}&limit=${obj.limit}&offset=${obj.offset}`
      )
      .then((res) => {
        let data = res.data
        if (!obj.onLoad) {
          let prevData = getState().leaderboard.bluewhaleInsidersCallsData.data
          let appendedData = prevData && prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getBluewhaleInsidersCallsDone(data))
        dispatch(getQuarterFilterOptionsDone(data.filter_quarters))
        if (obj.onLoad) dispatch(loading(false))
      })
  }
}

export const getBluewhaleInsidersPutData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    let cids = ""
    let bid = ""
    let qid = ""
    let sortby = ""
    let sortorder = ""

    if (obj.bid !== undefined) bid = obj.bid
    if (obj.qid !== undefined) qid = obj.qid
    if (obj.cids !== undefined) cids = obj.cids
    if (obj.sortby !== undefined) sortby = obj.sortby
    if (obj.sortorder !== undefined) sortorder = obj.sortorder

    axios
      .get(
        `${metadataDomain}/v3/bluewhale-quarter-put?qid=${qid}&cids=${cids}&bid=${bid}&sortby=${sortby}&sortorder=${sortorder}&limit=${obj.limit}&offset=${obj.offset}`
      )
      .then((res) => {
        let data = res.data
        if (!obj.onLoad) {
          let prevData = getState().leaderboard.bluewhaleInsidersPutsData.data
          let appendedData = prevData && prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getBluewhaleInsidersPutsDone(data))
        dispatch(getQuarterFilterOptionsDone(data.filter_quarters))
        if (obj.onLoad) dispatch(loading(false))
      })
  }
}

export const getShareholdersData = (obj) => {
  return async (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))
    if (!obj.onLoad) dispatch(infiniteScrollLoading(true))
    // alert("called")

    let bids = ""
    let cid = ""
    let limit = obj.limit
    let offset = obj.offset

    if (obj.cid !== undefined) cid = obj.cid
    if (obj.bids !== undefined) bids = obj.bids

    axios
      .get(
        metadataDomain +
        "/v3/company-shareholders?cid=" +
        cid +
        "&limit=" +
        limit +
        "&offset=" +
        offset +
        "&bids=" +
        bids +
        "&sortby=" +
        obj.sortby +
        "&sortorder=" +
        obj.sortorder
      )
      .then((res) => {
        let data = res.data
        if (!obj.onLoad) {
          let prevData = getState().leaderboard.shareholdersData.data
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getShareholdersDataDone(data))
        dispatch(infiniteScrollLoading(false))
        dispatch(loading(false))
      })
  }
}

export const getBluewhaleHoldingsData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    let url = ""
    let bid = obj.bid
    // alert(obj.sortorder)

    if (obj.limit == 10) {
      url =
        metadataDomain +
        "/v3/bluewhale-holdings?bid=" +
        bid +
        "&limit=" +
        obj.limit +
        "&offset=" +
        obj.offset
    } else {
      url =
        metadataDomain +
        "/v3/bluewhale-holdings?bid=" +
        bid +
        "&limit=" +
        obj.limit +
        "&offset=" +
        obj.offset +
        "&cids=" +
        obj.cids +
        "&sortby=" +
        obj.sortby +
        "&sortorder=" +
        obj.sortorder
    }

    axios.get(url).then((res) => {
      let data = res.data
      if (!obj.onLoad) {
        let prevData = getState().leaderboard.bluewhaleHoldingsData.data
        let appendedData = prevData.concat(data.data)
        data.data = appendedData
      }
      if (obj.limit == 10) dispatch(bluewhalePortfolioBreakdownDone(data))
      else dispatch(getBluewhaleHoldingsDataDone(data))
      dispatch(loading(false))
    })
  }
}

export const get100WhoBoughtData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    let cid = obj.cid
    let qid = obj.qid
    let bids = obj.bids

    axios
      .get(
        metadataDomain +
        "/v3/company-quarter-bought?cid=" +
        cid +
        "&qid=" +
        qid +
        "&limit=" +
        obj.limit +
        "&offset=" +
        obj.offset +
        "&filter=100bluewhales" +
        "&bids=" +
        bids
      )
      .then((res) => {
        let data = res.data
        if (!obj.onLoad) {
          let prevData = getState().leaderboard.b100WhoBoughtData.data
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(get100WhoBoughtDataDone(data))
        dispatch(getQuarterFilterOptionsDone(data.filter_quarters))
        dispatch(loading(false))
      })
  }
}

export const get100WhoSoldData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    let cid = obj.cid
    let qid = obj.qid
    let bids = obj.bids

    axios
      .get(
        metadataDomain +
        "/v3/company-quarter-sold?cid=" +
        cid +
        "&qid=" +
        qid +
        "&limit=" +
        obj.limit +
        "&offset=" +
        obj.offset +
        "&filter=100bluewhales" +
        "&bids=" +
        bids
      )
      .then((res) => {
        let data = res.data
        if (!obj.onLoad) {
          let prevData = getState().leaderboard.b100WhoSoldData.data
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(get100WhoSoldDataDone(data))
        dispatch(getQuarterFilterOptionsDone(data.filter_quarters))
        dispatch(loading(false))
      })
  }
}

// not used
export const getUpcomingEventsData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true))
    let cid = obj.cid

    axios
      .get(apiDomain + "/v3/company-upcoming-events.php?cid=" + cid)
      .then((res) => {
        let data = res.data

        dispatch(getUpcomingEventsDataDone(data))
        dispatch(loading(false))
      })
  }
}

// not used
export const getCompanyConnectSpikesData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    let cid = obj.cid
    let bids = obj.bids

    axios
      .get(
        apiDomain +
        "/v3/company-connect-the-spikes.php?cid=" +
        cid +
        "&limit=" +
        obj.limit +
        "&offset=" +
        obj.offset +
        "&bids=" +
        bids
      )
      .then((res) => {
        let data = res.data

        if (!obj.onLoad) {
          let prevData = getState().leaderboard.companyConnectSpikesData.data
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }

        dispatch(getCompanyConnectSpikesDataDone(data))
        dispatch(loading(false))
      })
  }
}

// not used
export const getBluewhaleConnectSpikesData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    let bid = obj.bid
    let cids = obj.cids

    axios
      .get(
        apiDomain +
        "/v3/bluewhale-connect-the-spikes.php?bid=" +
        bid +
        "&limit=" +
        obj.limit +
        "&offset=" +
        obj.offset +
        "&cids=" +
        cids
      )
      .then((res) => {
        let data = res.data

        if (!obj.onLoad) {
          let prevData = getState().leaderboard.bluewhaleConnectSpikesData.data
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }

        dispatch(getBluewhaleConnectSpikesDataDone(data))
        dispatch(loading(false))
      })
  }
}

export const getCompanyMetadata = (cid, bid) => {
  return (dispatch) => {
    let url = bid ? (metadataDomain + "/v3/company/" + cid + "?bid=" + bid) : (metadataDomain + "/v3/company/" + cid)
    axios.get(url).then((res) => {
      let data = res.data
      dispatch(getCompanyMetadataDone(data))
    })
  }
}

export const getBluewhaleMetadata = (bid) => {
  return (dispatch) => {
    axios.get(metadataDomain + "/v3/investor/" + bid).then((res) => {
      let data = res.data
      dispatch(getBluewhaleMetadataDone(data))
    })
  }
}

export const getCompany100BluewhaleData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))

    let type = obj.type
    let cid = obj.cid
    const nextPageToken = obj.nextPageToken

    const url = nextPageToken
      ? `${metadataDomain}/v3/company-100bluewhales-pagination${nextPageToken}?type=${type}`
      : `${metadataDomain}/v3/company-100bluewhales-pagination?cid=${cid}&type=${type}`

    axios.get(url).then((res) => {
      let data = res.data

      if (!obj.onLoad && nextPageToken) {
        let prevData = getState().leaderboard.company100BluewhaleData.data
        let appendedData = prevData.concat(data.data)
        data.data = appendedData
      }

      dispatch(getCompany100BluewhaleDataDone(data))
      dispatch(loading(false))
      dispatch(infiniteScrollLoading(false))
    })
  }
}

export const getCompanyBluewhaleDescription = (id) => {
  return (dispatch) => {
    dispatch(loading(true))

    let bid = id.split("-")[0]
    let cid = id.split("-")[1]

    axios
      .get(
        metadataDomain +
        "/v3/company-bluewhale-description?bid=" +
        bid +
        "&cid=" +
        cid
      )
      .then((res) => {
        let data = res.data

        dispatch(getCompanyBluewhaleDescriptionDone(data))
        // dispatch(loading(false));
      })
  }
}

export const getOptionsData = (obj) => {
  return (dispatch) => {
    // if (obj.isLoad) dispatch(loading(true))
    axios
      .get(
        metadataDomain +
        "/v3/options-chain?cid=" +
        obj.cid +
        "&date=" +
        obj.date +
        "&bid=" +
        obj.bid
      )
      .then((res) => {
        let data = res.data
        dispatch(getOptionsDataDone(data))
        dispatch(loading(false))
      })
  }
}

export const getCompanyFundamentalsData = (obj) => {
  return async (dispatch) => {
    dispatch(loading(true))

    axios
      .get(metadataDomain + "/v3/company-fundamentals?cid=" + obj.cid)
      .then((res) => {
        let data = res.data
        dispatch(getCompanyFundamentalsDataDone(data))
        dispatch(loading(false))
      })
  }
}

export const getBluewhaleFundamentalsData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true))
    axios
      .get(metadataDomain + "/v3/bluewhale-fundamentals?bid=" + obj.bid)
      .then((res) => {
        let data = res.data
        dispatch(getBluewhaleFundamentalsDataDone(data))
        dispatch(loading(false))
      })
  }
}

export const getBluewhaleProfileData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true))
    axios
      .get(metadataDomain + "/v3/bluewhale_designation?bid=" + obj.bid)
      .then((res) => {
        let data = res.data
        dispatch(getBluewhaleProfileDataDone(data))
        dispatch(loading(false))
      })
  }
}

export const getBluewhaleNetExposureData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true))
    axios
      .get(metadataDomain + "/v3/net_exposure_chart?bid=" + obj.bid)
      .then((res) => {
        let data = res.data
        dispatch(getBluewhaleNetExposureDataDone(data))
        dispatch(loading(false))
      })
  }
}

export const getIsFollowingInvestor = (obj) => {
  return (dispatch) => {
    dispatch(loading(true))
    axios
      .get(metadataDomain + "/v3/is_following_investor?bid=" + obj.bid)
      .then((res) => {
        let data = res.data
        dispatch(getIsFollowingInvestorDone(data))
        dispatch(loading(false))
      })
  }
}

export const followBluewhale = (bid) => {
  return (dispatch) => {
    dispatch(loading(true))
    axios.post(`${metadataDomain}/v3/follow/investor/${bid}`).then((res) => {
      dispatch(loading(false))
    })
  }
}

export const unfollowBluewhale = (bid, obj) => {
  return (dispatch) => {
    dispatch(loading(true))
    axios.delete(`${metadataDomain}/v3/follow/investor/${bid}`).then((res) => {
      if (obj) {
        dispatch(getFollowingInsidersData(obj))
      }
      dispatch(loading(false))
    })
  }
}

export const getBluewhalesManagedFunds = (bid) => {
  return (dispatch) => {
    dispatch(loading(true))
    axios
      .get(metadataDomain + "/v3/bluewhale_managed_funds?bid=" + bid)
      .then((res) => {
        let data = res.data
        dispatch(getBluewhaleManagedFundsDone(data))
        dispatch(loading(false))
      })
  }
}

export const setFundamentalsOptionsScroll = (data) => {
  return {
    type: actionTypes.SET_FUNDAMENTALS_OPTIONS_SCROLL,
    data: data,
  }
}

export const getIsFollowingCompany = (cid) => {
  return (dispatch) => {
    dispatch(loading(true))
    axios
      .get(metadataDomain + "/v3/is_following_company?cid=" + cid)
      .then((res) => {
        let data = res.data
        dispatch(getIsFollowingCompanyDone(data))
        dispatch(loading(false))
      })
  }
}

export const followCompany = (cid) => {
  return (dispatch) => {
    dispatch(loading(true))
    axios.post(`${metadataDomain}/v3/follow/company/${cid}`).then((res) => {
      dispatch(loading(false))
    })
  }
}

export const unfollowCompany = (cid, obj) => {
  return (dispatch) => {
    dispatch(loading(true))
    axios.delete(`${metadataDomain}/v3/follow/company/${cid}`).then((res) => {
      if (obj) {
        dispatch(getFollowingCompaniesData(obj))
      }
      dispatch(loading(false))
    })
  }
}
