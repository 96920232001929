import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import GreenTickIcon from "../../../images/green_tick.jpeg";

import { useStyles } from "./loginPage.styles.js";
import Dailog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";

const VerifyEmailPopup = (props) => {
  const {
    open,
    handleClose,
    email,
    handleCodeSubmit,
    handleResendCode,
    verifyEmailData,
    handleOkClick,
    handleSuccessClose,
    checkEmailData,
    handleAccountsMerged,
  } = props;
  const classes = useStyles();
  const [code, setCode] = useState("");
  const [seconds, setSeconds] = React.useState(59);
  const [disableResendCode, setDisableResendCode] = useState(false);
  const [isCodeError, setCodeError] = useState(false);
  const isWechatLogin = localStorage.getItem("login_mode") === "wechat";

  const handleInputCodeChange = (event) => {
    if (event.target.value) setCode(event.target.value);
  };

  useEffect(() => {
    if (disableResendCode) {
      if (seconds > 0) {
        let secs = seconds - 1;
        if (secs < 10) secs = "0" + secs;
        setTimeout(() => setSeconds(secs), 1000);
      } else {
        // setSeconds("BOOOOM!")
        setSeconds(59);
        setDisableResendCode(false);
      }
    }
  }, [disableResendCode, seconds]);

  const handleResendTimer = () => {
    if (!disableResendCode) handleResendCode();
    setDisableResendCode(true);
  };

  const checkSubmitCode = () => {
    if (code === "") {
      setCodeError(true);
      return;
    } else {
      handleCodeSubmit(code);
    }
  };

  return (
    <Dailog
      //   onClose={handleClose}
      open={open}
      classes={{ paper: classes.popUpPaper }}
    >
      <div className={classes.mainBlock}>
        {/* Invalid or expired verification code - 400 */}
        {(!verifyEmailData ||
          (verifyEmailData && verifyEmailData.code === 400)) && (
          <div>
            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.cursor}
            >
              <CloseIcon onClick={handleClose} />
            </Box>

            <Typography variant="h5">
              {isWechatLogin ? "输入验证码" : "Enter verification code."}
            </Typography>
            <Box mt={2} mb={3.75}>
              {checkEmailData && !checkEmailData.is_email_found && (
                <>
                  <Typography variant="body2" color="textSecondary">
                    {isWechatLogin
                      ? `我们已向您的注册邮箱发送验证码`
                      : `We’ve sent a verification code to your registered email`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {isWechatLogin
                      ? `请输入验证码以验证您的身份`
                      : `Please enter the code below to verify your identity`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {isWechatLogin
                      ? `验证码将在 30 分钟后过期`
                      : `Code expires in 30 minutes`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {isWechatLogin ? (
                      <>
                        没有收到验证码？请检查您的垃圾邮件/垃圾箱或{" "}
                        <b>重新发送</b>
                      </>
                    ) : (
                      <>
                        Didn’t receive the code? Check your spam/junk folder or{" "}
                        <b>Resend.</b>
                      </>
                    )}
                  </Typography>
                </>
              )}

              {checkEmailData && checkEmailData.is_email_found && (
                <Typography variant="body2" color="textSecondary">
                  {isWechatLogin ? (
                    <>
                      <div>
                        您的账户已关联至{" "}
                        <span className={classes.emailtext}>
                          {email && email}
                        </span>
                      </div>
                      <div>
                        我们已向您的邮箱{" "}
                        <span className={classes.emailtext}>
                          {email && email}
                        </span>
                        发送验证码邮件
                      </div>
                      <div>
                        请检查您的收件箱或垃圾邮件夹，并输入验证码以完成验证
                      </div>
                      <div>此操作将确保您的当前登录与现有账户成功关联</div>
                    </>
                  ) : (
                    <>
                      <div>
                        It looks like you already have an account linked to{" "}
                        <span className={classes.emailtext}>
                          {email && email}
                        </span>
                      </div>
                      <div>
                        We’ve just sent an email verification code to{" "}
                        <span className={classes.emailtext}>
                          {email && email}
                        </span>
                      </div>
                      <div>
                        Please check your inbox or spam folder and enter the
                        code below to continue. This will seamlessly merge your
                        current sign-in with your existing account.
                      </div>
                    </>
                  )}
                </Typography>
              )}
            </Box>

            <TextField
              error={
                (verifyEmailData && !verifyEmailData.success) || isCodeError
              }
              id="verification-code"
              label={isWechatLogin ? "验证码" : "Your code"}
              variant="outlined"
              //   classes={{input: }}
              margin="dense"
              onChange={handleInputCodeChange}
              type="number"
              helperText={
                verifyEmailData &&
                !verifyEmailData.success &&
                verifyEmailData.message
              }
            />

            <Box mt={3.75} mb={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => checkSubmitCode()}
              >
                Submit
              </Button>
            </Box>

            <Typography variant="caption" color="textSecondary">
              {isWechatLogin ? `没有收到？` : `Didn't receive the code? `}
              <span className={classes.resendCode} onClick={handleResendTimer}>
                {isWechatLogin ? `重新发送验证码 ` : `Resend code `}
              </span>
              {disableResendCode && <span> {`(00:${seconds})`}</span>}
            </Typography>

            {disableResendCode && (
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
              >
                {isWechatLogin
                  ? `新的验证码已发送至您的邮箱。`
                  : `A code has been sent to your Email.`}
              </Typography>
            )}
          </div>
        )}

        {/* successful verification - 200 */}
        {verifyEmailData && verifyEmailData.code === 200 && (
          <>
            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.cursor}
            >
              <CloseIcon onClick={handleSuccessClose} />
            </Box>
            <Box px={10} py={5}>
              <div className={classes.greenTickIcon}>
                <Avatar
                  src={GreenTickIcon}
                  style={{ width: "100%", height: "100%" }}
                >
                  H
                </Avatar>
              </div>
              <Box mt={3} mb={3}>
                <Typography variant="h6" align="center">
                  {verifyEmailData && verifyEmailData.message}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOkClick}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          </>
        )}

        {/* successful verification, accounts merged - 201 */}
        {verifyEmailData && verifyEmailData.code === 201 && (
          <>
            {/* <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.cursor}
            >
              <CloseIcon onClick={handleSuccessClose} />
            </Box> */}
            <Box px={10} py={5}>
              <div className={classes.greenTickIcon}>
                <Avatar
                  src={GreenTickIcon}
                  style={{ width: "100%", height: "100%" }}
                >
                  H
                </Avatar>
              </div>
              <Box mt={3} mb={3}>
                <Typography variant="h6" align="center">
                  {verifyEmailData && verifyEmailData.message}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAccountsMerged}
                >
                  Logout
                </Button>
              </Box>
            </Box>
          </>
        )}

        {/* email already linked to an existing account - 422 */}
        {verifyEmailData && verifyEmailData.code === 422 && (
          <>
            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.cursor}
            >
              <CloseIcon onClick={handleSuccessClose} />
            </Box>
            <Box px={10} py={5}>
              {/* <div className={classes.greenTickIcon}>
                <Avatar
                  src={GreenTickIcon}
                  style={{ width: "100%", height: "100%" }}
                >
                  H
                </Avatar>
              </div> */}
              <Box mt={3} mb={3}>
                <Typography variant="h6" align="center">
                  {verifyEmailData && verifyEmailData.message}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOkClick}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          </>
        )}
      </div>
    </Dailog>
  );
};

VerifyEmailPopup.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  email: PropTypes.string,
  handleCodeSubmit: PropTypes.func,
  handleResendCode: PropTypes.func,
  verifyEmailData: PropTypes.object,
  handleOkClick: PropTypes.func,
  handleSuccessClose: PropTypes.func,
  checkEmailData: PropTypes.object,
  handleAccountsMerged: PropTypes.func,
};

export default VerifyEmailPopup;
