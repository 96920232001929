import { Box, Grid, SvgIcon, Typography } from '@material-ui/core';
import React from 'react';
import { ReactComponent as TopicGuideIcon } from "../../images/topicGuide.svg";
import { ReactComponent as VideoChecked } from "../../images/videoChecked.svg";
import { ReactComponent as VideoUnchecked } from "../../images/videoUnchecked.svg";
import { useStyles } from './css'

function Step({ clickContent, id, stepTitle, checked, type, content, url, embed_video }) {
  const classes = useStyles()
  return (
    <div className={classes.stepContainer} >
      <Box px={2}>
        <Grid onClick={() => clickContent(id, type, checked)} container >
          <Grid xs={1}>
            <Box mr={1}>
              <Grid container alignItems='center' justify='center'>
                {
                  type === 'text' && <SvgIcon>
                    <TopicGuideIcon />
                  </SvgIcon>
                }
                {
                  type === 'url' && checked &&
                  <SvgIcon>
                    <VideoChecked />
                  </SvgIcon>
                }
                {
                  type === 'url' && !checked && <SvgIcon>
                    <VideoUnchecked />
                  </SvgIcon>
                }
                {
                  type === 'video' && !checked && <SvgIcon>
                    <VideoUnchecked />
                  </SvgIcon>
                }
                {
                  type === 'video' && checked && <SvgIcon>
                    <VideoChecked />
                  </SvgIcon>
                }
              </Grid>
            </Box>
          </Grid>
          <Grid container alignItems='center' xs={11}>
            <div className='one-line-ellipsis'>
              {stepTitle}
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Step;