import React from "react";
import Avatar from "@mui/material/Avatar";
import useStyles from "./styles";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: "#4da772",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

function UserAvatar({
  imageUrl,
  firstName,
  lastName,
  IsCenturion,
  isTopNav = false,
}) {
  const classes = useStyles();

  return imageUrl ? (
    imageUrl ===
    "https://s3-ap-southeast-1.amazonaws.com/spiking-img/spiking_mobile/placeholder_profile.png" ? (
      <Avatar
        sx={{ width: { xs: 50, sm: 90 }, height: { xs: 50, sm: 90 } }}
        {...stringAvatar(`${firstName} ${lastName}`)}
      />
    ) : isTopNav ? (
      <Avatar
        className={
          IsCenturion ? classes.profileAvatarDropdown : classes.profileAvatar
        }
        src={imageUrl}
      />
    ) : (
      <Avatar
        className={IsCenturion ? "centurionImage" : classes.profileAvatar}
        src={imageUrl}
      />
    )
  ) : (
    <Avatar
      sx={{ width: { xs: 50, sm: 90 }, height: { xs: 50, sm: 90 } }}
      {...stringAvatar(`${firstName} ${lastName}`)}
    />
  );
}

export default UserAvatar;
