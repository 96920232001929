import React from "react";
import Content from "../components/Content";

const Spike = () => {
  const content = {
    dayTitle: "Understand Spiking Up/Down",
    heading: "You're doing an excellent job by sticking with Spiking.",
    intro:
      "Each step you take brings you closer to mastering the strategies needed to capture significant market opportunities.",
    youtubeVideoId: "1dTVNyaMk_I",
    about: {
      sectionTitle: "ABOUT Spiking Up",
      description:
        "Today, we will explore the concept of the Blue Ocean, focusing on how to capture daily spikes between 5% to 100% by understanding the market dynamics before, during, and after spikes.",
    },
    topicsIntro:
      "Your Spiking journey will explore these essential topics in depth:",
    topics: [
      {
        title: "Before Spikes",
        description:
          "Gain insights into how early Wall Street insiders identify and invest in stocks before a spike occurs. Understand their strategies for building positions and deploying capital.",
      },
      {
        title: "During Spikes",
        description:
          "Learn how news announcements trigger retail traders to jump on the bandwagon. Discover the impact of these announcements on stock prices and trading volumes.",
      },
      {
        title: "After Spikes",
        description:
          "Explore how money managers and retail traders increase their positions post-spike due to heightened confidence in the stocks. Understand the strategies used to capitalize on these movements.",
      },
      {
        title: "Blue Ocean",
        description:
          "Identify the sweet spot for capturing daily spikes between 5% to 100%. Leverage this knowledge to optimize your trading strategies and maximize returns.",
      },
    ],
    nextSteps: {
      title: "NEXT STEPS",
      steps: [
        {
          title: "SPIKING UP & DOWN",
          description:
            "Monitor the most active stocks during premarket and postmarket trading.",
          actions: [
            {
              label: "Learn More",
              link: "https://spiking.com/spiking-up-down",
              type: "primary",
            },
          ],
        },
        {
          title: "Download App",
          description: "Stay updated on market movements with the Spiking App.",
          actions: [
            {
              label: "Download App",
              link: "https://spiking.com/app",
              type: "primary",
            },
          ],
        },
        {
          title: "IBKR Columns",
          description:
            "Set up your Interactive Brokers columns to track and analyze stock performance effectively.",
          actions: [
            {
              label: "Set Up IBKR Columns",
              link: "https://spiking.com/lesson/spiking-wealth-masterclass/content/3665",
              type: "primary",
            },
          ],
        },
      ],
    },
  };

  return <Content content={content} />;
};

export default Spike;
