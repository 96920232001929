import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Backdrop from "@material-ui/core/Backdrop"
import Badge from "@material-ui/core/Badge"
import Box from "@material-ui/core/Box"
import Popover from "@material-ui/core/Popover"
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined"
import NotificationPopup from "./notificationPopup"

const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: "#FC5A5A",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 3,
    color: "#fff",
  },
  notiIcon: {
    color: theme.palette.custom.lightGrey,
    cursor: "pointer"
  },
  popover: {
    marginTop: theme.spacing(1.5),
    position: "relative",
    // "&::before": {
    //   content: '""',
    //   position: "absolute",
    //   top: 27,
    //   right: 280,
    //   borderBottom: "10px solid #1B1F23",
    //   borderRight: "6px solid transparent",
    //   borderLeft: "6px solid transparent",
    //   borderTop: "13px solid transparent",
    //   zIndex: 10,
    //   backgroundColor: theme.palette.background.charcoal,
    // },
  },
  popoverPaper: {
    width: "24rem",
    backgroundColor: theme.palette.background.charcoal,
    borderRadius: "5px",
  },
}))

const NotificationAlerts = (props) => {
  const {
    id,
    handleNotificationsIconClick,
    handleNotiClick,
    handleViewAllNotiClick,
    handleMarkAllNotiAsRead,
    popperEl,
    isPopperOpen,
    isBackdropOpen,
    handlePopperClose,
    notiData,
    notiLoading,
    isPopup,
    user,
    manageNotificationSettings
  } = props
  const classes = useStyles()

  return (
    <React.Fragment>
      <Box aria-describedby={id} onClick={handleNotificationsIconClick}>
        <Badge
          badgeContent={
            user &&
            user.UnViewedNotificationCount &&
            user.UnViewedNotificationCount > 0
              ? user.UnViewedNotificationCount
              : null
          }
          classes={{ badge: classes.badge }}
        >
          <NotificationsOutlinedIcon className={classes.notiIcon} />
        </Badge>
      </Box>
      <Backdrop
        className={classes.backdrop}
        open={isBackdropOpen}
        onClick={handlePopperClose}
      >
        <Popover
          id={id}
          open={isPopperOpen}
          anchorEl={popperEl}
          onClose={handlePopperClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{ root: classes.popover, paper: classes.popoverPaper }}
        >
          <NotificationPopup
            notiData={notiData}
            handleNotificationClick={handleNotiClick}
            handleViewAll={handleViewAllNotiClick}
            notiLoading={notiLoading}
            isPopup={isPopup}
            handleMarkAllAsRead={handleMarkAllNotiAsRead}
            manageNotificationSettings={manageNotificationSettings}
          />
        </Popover>
      </Backdrop>
    </React.Fragment>
  )
}

export default NotificationAlerts
