import axios from "axios"

import * as actionTypes from "./actionTypes"
import { loading } from "./commonAc"
import { getUserDetails } from "./homeAc"
import { da } from "date-fns/locale"

let apiDomain = process.env.REACT_APP_API_URL
let domainUserDetails = process.env.REACT_APP_API2_URL

//api-dev.spiking.com/v3/webinar_videos_limit_offset?limit=10&offset=0&access_token=utAe01GWuhVN1IpUSYpjfG_n9wmfCQzB43ehyD4ursg.Bttrpg9XYpXYc5uc6GbdtO4rbIoqFgfCHttXIxwXtP0

export const getWebinarVideosDone = (data) => {
  return {
    type: actionTypes.GET_WEBINAR_VIDEOS_DONE,
    data: data,
  }
}

export const getStocksVideosDone = (data) => {
  return {
    type: actionTypes.GET_STOCKS_VIDEOS_DONE,
    data: data,
  }
}

export const getOptionsVideosDone = (data) => {
  return {
    type: actionTypes.GET_OPTIONS_VIDEOS_DONE,
    data: data,
  }
}

export const getCryptosVideosDone = (data) => {
  return {
    type: actionTypes.GET_CRYPTOS_VIDEOS_DONE,
    data: data,
  }
}

export const getCosyVideosDone = (data) => {
  return {
    type: actionTypes.GET_COSY_VIDEOS_DONE,
    data: data,
  }
}

export const getCoachingVideosDone = (data) => {
  return {
    type: actionTypes.GET_COACHING_VIDEOS_DONE,
    data: data,
  }
}

export const getMasterclassVideosDone = (data) => {
  return {
    type: actionTypes.GET_MASTERCLASS_VIDEOS_DONE,
    data: data,
  }
}

export const getVideoByIdDone = (data) => {
  return {
    type: actionTypes.GET_VIDEO_BY_ID_DONE,
    data: data,
  }
}

export const getZoomClassesDataDone = (data) => {
  return {
    type: actionTypes.GET_ZOOM_CLASSES_DATA_DONE,
    data: data,
  }
}

export const registerZoomClassDone = (data) => {
  return {
    type: actionTypes.REGISTER_FOR_ZOOM_CLASS_DONE,
    data: data
  }
}

export const getWebinarVideos = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))
    axios
      .get(
        domainUserDetails +
          "/v3/webinar_videos_limit_offset?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data
        if (!obj.onLoad) {
          let prevData = getState().public.webinarVideos.data
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getWebinarVideosDone(data))
        dispatch(loading(false))
      })
  }
}

export const getStocksOptionsCryptosVideos = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))
    axios
      .get(
        domainUserDetails +
          "/v3/class_videos_limit_offset?type=" +
          obj.type +
          "&limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data
        let prevData
        if (!obj.onLoad) {
          if (obj.type === "stocks")
            prevData = getState().public.stocksVideos.data
          else if (obj.type === "options")
            prevData = getState().public.optionsVideos.data
          else if (obj.type === "cryptos")
            prevData = getState().public.cryptosVideos.data
          else if (obj.type === "cosy")
            prevData = getState().public.cosyVideos.data

          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        switch (obj.type) {
          case "stocks":
            dispatch(getStocksVideosDone(data))
            break
          case "options":
            dispatch(getOptionsVideosDone(data))
            break
          case "cryptos":
            dispatch(getCryptosVideosDone(data))
            break
          case "cosy":
            dispatch(getCosyVideosDone(data))
            break
          default:
            dispatch(getStocksVideosDone(data))
        }
        dispatch(loading(false))
      })
  }
}

export const getCoachingVideos = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))
    axios
      .get(
        domainUserDetails +
          "/v3/coaching_videos_limit_offset?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data
        if (!obj.onLoad) {
          let prevData = getState().public.coachingVideos.data
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getCoachingVideosDone(data))
        dispatch(loading(false))
      })
  }
}

export const getMasterclassVideos = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))
    axios
      .get(
        domainUserDetails +
          "/v3/masterclass_videos_limit_offset?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data
        if (!obj.onLoad) {
          let prevData = getState().public.masterclassVideos.data
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getMasterclassVideosDone(data))
        dispatch(loading(false))
      })
  }
}

export const getVideoById = (id) => {
  return (dispatch) => {
    dispatch(loading(true))
    axios.get(domainUserDetails + "/v3/video_by_vid?id=" + id).then((res) => {
      let data = res.data
      dispatch(getVideoByIdDone(data))
      dispatch(loading(false))
    })
  }
}

export const getZoomClassesData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true))
    axios
      .get(
        domainUserDetails +
          "/v3/zoom_class_limit_offset?type=" +
          obj.type +
          "&limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data
        if (!obj.onLoad) {
          let prevData = getState().public.zoomClassesData.data
          let appendedData = prevData.concat(data.data)
          data.data = appendedData
        }
        dispatch(getZoomClassesDataDone(data))
        dispatch(loading(false))
      })
  }
}

export const postRegisterForZoomClass = (obj) => {
  return (dispatch) => {
    dispatch(loading(true))

    let body = new FormData()
    body.append("type", obj.type)
    body.append("zoom_meeting_id", obj.meetingId)

    axios.post(domainUserDetails + "/v3/zoom_class", body).then((res) => {
      let data = res.data
      // window.location.reload(true)
      // to refresh Orientation variable in me api
      if(obj.type === "stocks") {
        dispatch(getUserDetails())
      }
      dispatch(registerZoomClassDone(true))
      dispatch(loading(false))
    })
  }
}