import React from "react";
import ReactCountryFlag from "react-country-flag";

import localeInfo from "../data/locale-info.json";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  languageMain: {
    display: "flex",
  },
  flagBlock: {
    padding: "12px",
    cursor: "pointer",
  },
  languageBlock: {
    padding: "21px 0 12px 12px",
    width: "8rem",
    cursor: "pointer",
  },
}));

const LocaleControls = (props) => {
  const classes = useStyles();
  return (
    <Grid container>
      {localeInfo.map((locale, index) => (
        <Grid
          key={index}
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          // key={locale.langCode}
          className={classes.languageMain}
        >
          <div className={classes.flagBlock}>
            <ReactCountryFlag
              countryCode={locale.countryCode}
              svg
              style={{
                width: "3em",
                height: "3em",
              }}
              title={locale.countryCode}
              onClick={() => props.onLanguageChange(locale)}
            />
          </div>
          <div
            onClick={() => props.onLanguageChange(locale)}
            className={classes.languageBlock}
          >
            <span class="notranslate">{locale.language}</span>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default LocaleControls;
