import React from "react";
import Content from "../components/Content";

const Cosy = () => {
  const content = {
    dayTitle: "Start With COSY",
    heading: "Congratulations on continuing your journey with Spiking.",
    intro:
      "You're making strides towards a more empowered financial future with each step.",
    youtubeVideoId: "HxRb9n_r8WM",
    about: {
      sectionTitle: "ABOUT COSY Start",
      description:
        `Today, we’ll focus on the foundational concepts of investing with COSY: <strong>C</strong>ryptos, <strong>O</strong>ptions, <strong>S</strong>tocks, <strong>Y</strong>es. Understanding these basics is crucial for building a strong investment portfolio.`,
    },
    topicsIntro: "The Spiking journey will lead you through these essential topics:",
    topics: [
      {
        title: "Cryptos",
        description:
          "Learn about the top-performing cryptocurrencies, their market behavior, and how to invest in them wisely using Spiking’s tools. Focus on approved and upcoming spot ETFs to maximize your investment potential.",
      },
      {
        title: "Options",
        description:
          "Understand the basics of options trading, including calls, puts, and how to use them to hedge your investments. Dive into comparing the most powerful strategy against 56 other options strategies to find the best fit for your portfolio.",
      },
      {
        title: "Stocks",
        description:
          "Discover key strategies for stock investing using the FIAT methodology. This includes Fundamental analysis, Insider trading insights, Alerts for significant market movements, and Technical analysis for timing your trades.",
      },
      {
        title: "Yes",
        description:
          'Embrace the mindset to say "Yes" to smart investments. These three instruments of wealth – Cryptos, Options, and Stocks – provide liquid cash, enabling you to capitalize on market opportunities.',
      },
    ],
    nextSteps: {
      title: "NEXT STEPS",
      steps: [
        {
          title: "Know Thyself",
          description: "Visit 16 Personalities to unlock your strengths for smarter, confident investing.",
          actions: [
            {
              label: "Visit 16 Personalities",
              link: "https://www.16personalities.com",
              type: "primary",
            },
          ],
        },
        {
          title: "Complete Survey",
          description: "Understand your investor personality to tailor your strategies.",
          actions: [
            {
              label: "Take the Survey",
              link: "https://spiking.ai/wealth-masterclass-pre-survey/",
              type: "primary",
            },
          ],
        },
        {
          title: "Connect with our Angels",
          description: "For assistance or further guidance at Spiking Help.",
          actions: [
            {
              label: "Go to Spiking Help",
              link: "https://spi.ke/help",
              type: "secondary",
            },
          ],
        },
      ],
    },
  };

  return <Content content={content} />;
};

export default Cosy;
