import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: "rgba(27,31,35,0.8)",
  },
  tooltip: {
    backgroundColor: "rgba(27, 31, 35)",
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.subtitle1.fontSize,
  },
}))

const StyledTooltip = (props) => {
  const classes = useStyles()

  return <Tooltip arrow interactive classes={classes} {...props} />
}

export default StyledTooltip
