import axios from "axios";
import * as actionTypes from "./actionTypes";
import { loading } from "./commonAc";

let apiDomain = process.env.REACT_APP_API_URL;
let domainUserDetails = process.env.REACT_APP_API2_URL;

export const getSpikeCardsDataDone = (data) => {
  return {
    type: actionTypes.GET_SPIKE_CARDS_DATA_DONE,
    data: data,
  };
};

export const getSpikeOverviewDataDone = (data) => {
  return {
    type: actionTypes.GET_SPIKE_OVERVIEW_DONE,
    data: data,
  };
};

export const getMySpikesDataDone = (data) => {
  return {
    type: actionTypes.GET_WATCHLIST_DATA_DONE,
    data: data,
  };
};

export const postSpikeDone = (data) => {
  return {
    type: actionTypes.POST_SPIKE_SUCCESS,
    data: data,
  };
};

export const deleteSpikeDone = (data) => {
  return {
    type: actionTypes.DELETE_SPIKE_SUCCESS,
    data: data,
  };
};

export const maxSpikesReached = (data) => {
  return {
    type: actionTypes.MAX_SPIKES_REACHED_DATA,
    data: data,
  };
};

export const getSpikeCardsData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          "/v3/card-limit-offset?bids=" +
          obj.bids +
          "&cids=" +
          obj.cids +
          "&limit=" +
          obj.limit +
          "&offset=" +
          obj.offset +
          "&tags=" +
          obj.tags +
          "&action=" +
          obj.action
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().spikeUniverse.spikeCardsData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(loading(false));
        dispatch(getSpikeCardsDataDone(data));
      });
  };
};

export const getSpikeOverviewData = (obj) => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    axios
      .get(
        domainUserDetails + "/v3/cards-stats?bids=" + obj.bids + "&tags=" + obj.tags
      )
      .then((res) => {
        let data = res.data;
        // dispatch(loading(false));
        dispatch(getSpikeOverviewDataDone(data));
      });
  };
};

export const getMySpikesData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          "/v3/whale-watching-watchlist?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().spikeUniverse.mySpikesData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(getMySpikesDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const postSpike = (qString, obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .post(domainUserDetails + "/v3/whale_watching" + qString)
      .then((res) => {
        let data = res.data;
        if (data.message) dispatch(maxSpikesReached(data));
        else dispatch(postSpikeDone(true));
      });
  };
};

export const deleteMySpikes = (qString, obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .delete(domainUserDetails + "/v3/whale_watching" + qString)
      .then((res) => {
        // alert("success")
        // if (obj.isWatchlist) {
        if (obj) dispatch(getMySpikesData(obj));
        dispatch(deleteSpikeDone(true));
        // }
        // else {
        //   dispatch(getDailyTradesData(obj))
        // }
      });
  };
};
