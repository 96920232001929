
import axios from "axios";
import * as actionTypes from "./actionTypes";
import { loading, loadingEvent, searchLoading } from "./commonAc";
import { loadingRefreshSet } from "./optionsAc";

let domain = process.env.REACT_APP_API2_URL;

export const getTradeHistory = (data) => {
  return {
    type: actionTypes.GET_HISTORY_TRADE,
    data: data,
  };
};

export const getBreakdownRecords = (data) => {

  let breakdownData = {};
  let labels = data?.data.map((item) => item.name)
  let series = data?.data.map((item) => item.percentage)
  breakdownData = { ...data, labels, series }
  return {
    type: actionTypes.GET_BREAKDOWN_RECORDS,
    data: breakdownData,
  };
};

export const fetchTradeHistory = (obj) => {
  return (dispatch, getState) => {
    dispatch(loadingEvent(true));
    let userId = getState().home.user.Id
    axios
      .get(
        domain +
        `/v3/challenge/history?limit=${obj.limit}&offset=${obj.offset}&user_id=${userId}&challenge_id=${obj.event_id}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getTradeHistory(data));
      })
      .catch((error) => console.log('error', error))
      .finally(() =>
        dispatch(loadingEvent(false))
      );
  };
};

export const fetchBreakdownRecords = () => {
  return (dispatch, getState) => {
    let userId = getState().home.user.Id
    dispatch(loadingEvent(true));
    axios.get(domain + `/v3/challenge/portfolio/breakdown?user_id=${userId}`)
      .then((res) => {
        let data = res.data;
        dispatch(getBreakdownRecords(data))
      }).catch((error) => console.log('error', error))
      .finally(() =>
        dispatch(loadingEvent(false)))
  }
}