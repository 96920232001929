import * as actionTypes from "../actions/actionTypes";
const initialState = {};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_CHALLENGE_EVENT_SUCCESS: {
      return {
        ...state,
        [payload.event_id]: {
          ...payload,
        },
      };
    }
    case actionTypes.END_CHALLENGE_EVENT_SUCCESS: {
      return {
        ...state,
        [payload]: {
          ...state[payload],
          isEnd: true,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
