import React from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
// import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import interactionPlugin from "@fullcalendar/interaction";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import {
  addDays,
  addHours,
  addSeconds,
  differenceInCalendarDays,
  differenceInDays,
  endOfDay,
  format,
  getDate,
  startOfDay,
} from "date-fns";
import { useFormik } from "formik";
import useOutsideClickEffect from "./useOutsideClickEffect";
import { ChevronRight } from "@material-ui/icons";
import eventsList, { typeList } from "./eventsList";
import { IconClose } from "../../../components/Icons";
import AutoPositionedDiv from "./AutoPositionedDiv";
import DetailsComponent from "./DetailsComponent";
import DetailsIPO from "./DetailsIPO";
import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Hidden from "@material-ui/core/Hidden";
import DetailsMobile from "./DetailsMobile";
import dayjs from "dayjs";

let start = "";
let end = "";

function CalendarTable({
  handleAddToCalendar,
  rawData,
  setRawData,
  setSelectedMonth,
}) {
  const [groupDataDetails, setGroupDataDetails] = useState({});
  const [dataDetails, setDataDetails] = useState({});
  const [dateEventsDetails, setDateEventsDetails] = useState({});
  const calendarRef = useRef();
  const calendarMobileRef = useRef();
  const [events, setEvents] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [filterOn, setFilterOn] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const checkboxRef = useRef();
  useOutsideClickEffect(checkboxRef, () => setFilterOn(false));

  const fetchEvents = async (can_register = false) => {
    try {
      const result = await axios.get(
        `https://api.spiking.com/v3/public/events?start=${start}&end=${end}&type=&q=&can_register=${can_register}`
      );

      const { data, status } = result;

      const listEvent = data
        ?.filter((event) => typeList.includes(event.type))
        ?.reduce((acc, element) => {
          const eventStart = dayjs
            .utc(element.start_time)
            .format(
              element.all_day ? "YYYY-MM-DDT00:00:00" : "YYYY-MM-DDTHH:mm:ss[Z]"
            );
          const eventEnd = dayjs
            .utc(element.end_time)
            .format(
              element.all_day ? "YYYY-MM-DDT00:00:00" : "YYYY-MM-DDTHH:mm:ss[Z]"
            );
          if (
            element.type === "cpi" ||
            element.type === "ppi" ||
            element.type === "eci" ||
            element.type === "cosy" ||
            element.type === "tttn" ||
            element.type === "masterclass" ||
            element.type === "preview" ||
            element.type === "can_register_exclusive_network_event" ||
            element.type === "can_register_ipo_event" ||
            element.type === "special_event"
          ) {
            let dateStart = new Date(eventStart);
            let dateEnd = new Date(eventEnd);

            let Difference_In_Days = differenceInCalendarDays(
              dateEnd,
              dateStart
            );

            let a = [];
            let t = element.title;
            if (Difference_In_Days > 0) {
              let limit = Difference_In_Days;
              if (
                format(new Date(element.end_time), "HH:mm:ss") !== "00:00:00" &&
                !element.all_day
              ) {
                limit += 1;
              }
              for (let index = 0; index < limit; index++) {
                let tomorrow = new Date(eventStart);
                tomorrow.setDate(tomorrow.getDate() + index);
                tomorrow.toLocaleDateString();

                a.push({
                  id: element.id,
                  start: tomorrow,
                  type: element.type,
                  end: eventEnd,
                  title: t,
                  detailsTitle: element.title,
                  description: element.description,
                  allDay: eventsList.filter(
                    (event) => event.id === element.type
                  )?.[0]?.daily
                    ? element.all_day
                    : index === 0
                    ? element.all_day
                    : true,
                  group_events: element.group_events,
                  source: element.source,
                  priority: eventsList.filter(
                    (event) => event.id === element.type
                  )?.[0]?.priority,
                  display: "list-item",
                  color: eventsList.filter(
                    (event) => event.id === element.type
                  )?.[0]?.color,
                  eventDetailsUrl: element.url,
                });
              }
            } else {
              if (dateStart.getDate() !== dateEnd.getDate()) {
                a.push({
                  id: element.id,
                  start: startOfDay(dateEnd),
                  type: element.type,
                  end: eventEnd,
                  title: t,
                  detailsTitle: element.title,
                  description: element.description,
                  allDay: element.all_day,
                  group_events: element.group_events,
                  source: element.source,
                  priority: eventsList.filter(
                    (event) => event.id === element.type
                  )?.[0]?.priority,
                  display: "list-item",
                  color: eventsList.filter(
                    (event) => event.id === element.type
                  )?.[0]?.color,
                  eventDetailsUrl: element.url,
                });
              }
              a.push({
                id: element.id,
                start: eventStart,
                type: element.type,
                end: eventEnd,
                title: t,
                detailsTitle: element.title,
                description: element.description,
                allDay: element.all_day,
                group_events: element.group_events,
                source: element.source,
                priority: eventsList.filter(
                  (event) => event.id === element.type
                )?.[0]?.priority,
                display: "list-item",
                color: eventsList.filter(
                  (event) => event.id === element.type
                )?.[0]?.color,
                eventDetailsUrl: element.url,
              });
            }
            return [
              ...acc,
              {
                id: element.id,
                start: eventStart,
                type: element.type,
                end:
                  format(dateEnd, "HH:mm:ss") === "00:00:00"
                    ? eventEnd
                    : addDays(new Date(eventEnd), 1),
                title: element.title,
                detailsTitle: element.title,
                description: element.description,
                allDay: true,
                group_events: element.group_events,
                source: element.source,
                priority: eventsList.filter(
                  (event) => event.id === element.type
                )?.[0]?.priority,
                display: "block",
                color: eventsList.filter(
                  (event) => event.id === element.type
                )?.[0]?.color,
                eventDetailsUrl: element.url,
              },
              ...a,
            ];
          } else {
            let t =
              element?.group_events?.length > 0
                ? `${element?.group_events?.length} ${
                    element.type === "ipo" ? "IPO" : "Earnings"
                  }`
                : element.title;
            return [
              ...acc,
              {
                id: element.id,
                start: eventStart,
                type: element.type,
                end: eventEnd,
                title: t,
                detailsTitle:
                  element?.group_events?.length > 0
                    ? `${element?.group_events?.length} ${
                        element.type === "ipo" ? "IPO" : "Earnings"
                      }`
                    : element.title,
                description: element.description,
                allDay: element.all_day,
                group_events: element.group_events,
                source: element.source,
                priority: eventsList.filter(
                  (event) => event.id === element.type
                )?.[0]?.priority,
                display: eventsList.filter(
                  (event) => event.id === element.type
                )?.[0]?.display,
                color: eventsList.filter(
                  (event) => event.id === element.type
                )?.[0]?.color,
                eventDetailsUrl: element.url,
              },
            ];
          }
        }, []);
      if (status !== 200) return;
      setRawData(data);
      setSelectedMonth(
        format(
          calendarRef?.current?.calendar?.getDate() || new Date(),
          "MMM-yyyy"
        )
      );
      setEvents(listEvent.sort((a, b) => a.priority - b.priority));
      return setCalendarEvents(
        listEvent.sort((a, b) => a.priority - b.priority)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const setCalendarTime = (state) => {
    if (state === "prev") {
      calendarRef.current.calendar.prev();
      calendarMobileRef.current.calendar.prev();
    }
    if (state === "next") {
      calendarRef.current.calendar.next();
      calendarMobileRef.current.calendar.next();
    }
  };

  const getEventsDates = (event) => {
    start = event.start.toISOString();
    end = event.end.toISOString();
    fetchEvents(checked);
  };

  const filterHandler = (values) => {
    if (values.listFilter.length === 0) setCalendarEvents(events);
    else
      setCalendarEvents(
        events.filter((event) => values.listFilter.includes(event?.type))
      );
  };

  const checkboxForm = useFormik({
    initialValues: {
      listFilter: [],
    },
    onSubmit: (values) => filterHandler(values),
  });

  const openEvent = (info) => {
    // const date = {
    //   events: [details],
    //   dateTitle: format(details.start, 'dd MMM yyyy'),
    // };
    setDataDetails({
      id: info.event.id,
      start: info.event.start,
      end: info.event.end,
      allDay: info.event.allDay,
      description: info.event._def.extendedProps.description,
      title: info.event._def.extendedProps.detailsTitle,
      group_events: info.event._def.extendedProps.group_events,
      source: info.event._def.extendedProps.source,
      type: info.event._def.extendedProps.type,
      backgroundColor: info.event.backgroundColor,
      url: info.event._def.extendedProps.eventDetailsUrl,
      elePosition: info.el.getBoundingClientRect(),
    });
  };

  useEffect(() => {
    checkboxForm.handleSubmit();
  }, [checkboxForm.values.listFilter]);

  const openGroupDateDetails = (item) => {
    setGroupDataDetails(item);
  };

  const eventOrderCallback = (eventA, eventB) => {
    return eventA.extendedProps.priority - eventB.extendedProps.priority;
  };

  function getWeeksInMonth(year, month) {
    const lastDateOfMonth = new Date(year, month + 1, 0);
    const numDaysInMonth = lastDateOfMonth.getDate();
    const firstDayOfMonth = new Date(year, month, 1);
    const startingWeekday = firstDayOfMonth.getDay();
    return Math.ceil((numDaysInMonth + startingWeekday) / 7);
  }

  const handleRegisterEvents = (e) => {
    // if (e.target.checked === true) {
    //   fetchEvents(true);
    // }
    fetchEvents(e.target.checked);
    setChecked(e.target.checked);
  };

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  const numWeeks = getWeeksInMonth(currentYear, currentMonth);
  const calendarHeight = numWeeks * 100;

  const renderFilter = () => {
    return (
      <div ref={checkboxRef}>
        <button
          onClick={() => setFilterOn(!filterOn)}
          className="flex items-center justify-center gap-[8px] rounded-[6px] w-[86px] h-[32px] bg-primary"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.74659 11.8188H2.77794C2.45148 11.8188 2.18652 11.5934 2.18652 11.3157C2.18652 11.0379 2.45148 10.8125 2.77794 10.8125H7.74659C8.07305 10.8125 8.33801 11.0379 8.33801 11.3157C8.33801 11.5934 8.07305 11.8188 7.74659 11.8188"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.9643 5.31686H8.19389C7.8804 5.31686 7.62598 5.09145 7.62598 4.8137C7.62598 4.53596 7.8804 4.31055 8.19389 4.31055H12.9643C13.2778 4.31055 13.5322 4.53596 13.5322 4.8137C13.5322 5.09145 13.2778 5.31686 12.9643 5.31686"
              fill="white"
            />
            <mask
              id="mask0_5915_3499"
              maskUnits="userSpaceOnUse"
              x="2"
              y="2"
              width="5"
              height="5"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.16699 2.7002H6.34373V6.85399H2.16699V2.7002Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_5915_3499)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.25543 3.70602C3.65902 3.70602 3.17331 4.18637 3.17331 4.77741C3.17331 5.36778 3.65902 5.84746 4.25543 5.84746C4.85251 5.84746 5.33755 5.36778 5.33755 4.77741C5.33755 4.18637 4.85251 3.70602 4.25543 3.70602M4.25543 6.85377C3.10421 6.85377 2.16699 5.9226 2.16699 4.77741C2.16699 3.63223 3.10421 2.69971 4.25543 2.69971C5.40733 2.69971 6.34387 3.63223 6.34387 4.77741C6.34387 5.9226 5.40733 6.85377 4.25543 6.85377"
                fill="white"
              />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.8195 10.2192C11.2224 10.2192 10.7367 10.6996 10.7367 11.2899C10.7367 11.881 11.2224 12.3607 11.8195 12.3607C12.4159 12.3607 12.901 11.881 12.901 11.2899C12.901 10.6996 12.4159 10.2192 11.8195 10.2192M11.8196 13.367C10.6677 13.367 9.73047 12.4351 9.73047 11.2899C9.73047 10.1447 10.6677 9.21289 11.8196 9.21289C12.9708 9.21289 13.9073 10.1447 13.9073 11.2899C13.9073 12.4351 12.9708 13.367 11.8196 13.367"
              fill="white"
            />
          </svg>
          <div className="text-[12px] leading-[16px] -tracking-[0.01em] font-semibold text-white">
            Filter
          </div>
        </button>
        <form
          onSubmit={checkboxForm.handleSubmit}
          className={`${
            filterOn ? "block" : "hidden"
          } absolute right-0 top-full mt-2 bg-neutral-50 z-10 rounded-xl shadow-down-s border`}
        >
          {eventsList.map((item) => (
            <div
              key={item.id}
              className="flex items-center gap-x-[12px] p-[12px]"
            >
              <input
                className="cursor-pointer checked:bg-branding-500 checked:border-branding-500"
                type="checkbox"
                value={item.id}
                onChange={checkboxForm.handleChange}
                name="listFilter"
              />
              {item.event ? (
                <div
                  style={{ backgroundColor: `${item.color}` }}
                  className="form-check-label text-[#fff] text-[12px] leading-[14px] flex px-[8px] py-[6px] rounded-[4px] w-[190px]"
                >
                  {item.name}
                </div>
              ) : (
                <div className="flex items-center gap-x-[10px]">
                  <div
                    style={{ backgroundColor: `${item.color}` }}
                    className={`w-2 h-2 rounded-full`}
                  />
                  <div className="text-[12px] leading-[14px] text-[#272727]">
                    {item.name}
                  </div>
                </div>
              )}
            </div>
          ))}
        </form>
      </div>
    );
  };

  const openDateHandler = (details) => {
    const date = {
      events: [],
      dateTitle: format(details.date, "dd MMM yyyy"),
    };
    calendarEvents.forEach((event) => {
      const dayStart = new Date(details.date);
      const eventStartTime = new Date(event.start);
      const eventEndTime = new Date(event.end);
      const dayEnd = addSeconds(addHours(dayStart, 24), -1);

      if (dayStart <= eventStartTime && eventStartTime < dayEnd) {
        date.events = [...date.events, event];
      }
      if (eventStartTime < dayStart && eventEndTime > dayEnd) {
        date.events = [...date.events, event];
      }
    });
    if (date.events.length > 0) {
      setDateEventsDetails(date);
    }
    // openDate(true);
  };

  return (
    <>
      <div className="event-calendar overflow-hidden">
        <Hidden smDown>
          <div className="hidden md:flex w-full items-center justify-between mb-[12px] relative">
            <div className="flex gap-x-[12px] ">
              <button onClick={() => setCalendarTime("prev")}>
                <svg
                  width="15"
                  height="20"
                  viewBox="0 0 15 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2198 3.20051C11.6421 3.57903 11.6622 4.2119 11.2648 4.61408L5.94211 10.0001L11.2648 15.3862C11.6622 15.7884 11.6421 16.4213 11.2198 16.7998C10.7975 17.1783 10.133 17.1591 9.73559 16.7569L3.73559 10.6855C3.35507 10.3005 3.35507 9.69983 3.73559 9.31478L9.73559 3.24335C10.133 2.84118 10.7975 2.822 11.2198 3.20051Z"
                    fill="#99A2AD"
                  />
                </svg>
              </button>
              <button onClick={() => setCalendarTime("next")}>
                <svg
                  width="15"
                  height="20"
                  viewBox="0 0 15 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.78017 3.20051C3.35789 3.57903 3.33775 4.2119 3.7352 4.61408L9.05789 10.0001L3.7352 15.3862C3.33775 15.7884 3.35789 16.4213 3.78017 16.7998C4.20245 17.1783 4.86697 17.1591 5.26441 16.7569L11.2644 10.6855C11.6449 10.3005 11.6449 9.69983 11.2644 9.31478L5.26441 3.24335C4.86697 2.84118 4.20245 2.822 3.78017 3.20051Z"
                    fill="#99A2AD"
                  />
                </svg>
              </button>
              <div className="text-[16px] leading-[20px] md:text-[18px] md:leading-[24px] tracking-[0.01em] font-semibold text-[#5E5873]">
                {format(
                  calendarRef?.current?.calendar?.getDate() || new Date(),
                  "MMMM yyyy"
                )}
              </div>
            </div>
            <div>
              <input
                type="checkbox"
                id="can-register-checkbox"
                name="can-register-checkbox"
                // value={checked}
                checked={checked}
                onChange={(e) => handleRegisterEvents(e)}
              />
                <label for="html">Show me events that I can RSVP today!</label>
            </div>
            <div className="flex items-center gap-x-2">
              <span>
                <span
                  onClick={handleAddToCalendar}
                  className="text-sm text-primary underline font-semibold cursor-pointer"
                >
                  Add to Calendar
                </span>
                <Tooltip
                  title={
                    <span className="text-sm">
                      Click{" "}
                      <a
                        href="https://support.google.com/calendar/answer/37118?hl=en&co=GENIE.Platform%3DDesktop"
                        target="_blank"
                        className="text-sm underline"
                        rel="noreferrer"
                      >
                        here
                      </a>{" "}
                      to import events to Google Calendar
                    </span>
                  }
                  interactive
                >
                  <InfoOutlinedIcon
                    fontSize="small"
                    className="scale-[0.8] cursor-pointer"
                  />
                </Tooltip>
              </span>
              {renderFilter()}
            </div>
          </div>
        </Hidden>

        <Hidden mdUp>
          <div className="flex w-full items-center justify-between mb-[12px] relative">
            <div className="flex gap-x-[12px] ">
              <button onClick={() => setCalendarTime("prev")}>
                <svg
                  width="15"
                  height="20"
                  viewBox="0 0 15 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2198 3.20051C11.6421 3.57903 11.6622 4.2119 11.2648 4.61408L5.94211 10.0001L11.2648 15.3862C11.6622 15.7884 11.6421 16.4213 11.2198 16.7998C10.7975 17.1783 10.133 17.1591 9.73559 16.7569L3.73559 10.6855C3.35507 10.3005 3.35507 9.69983 3.73559 9.31478L9.73559 3.24335C10.133 2.84118 10.7975 2.822 11.2198 3.20051Z"
                    fill="#99A2AD"
                  />
                </svg>
              </button>
              <button onClick={() => setCalendarTime("next")}>
                <svg
                  width="15"
                  height="20"
                  viewBox="0 0 15 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.78017 3.20051C3.35789 3.57903 3.33775 4.2119 3.7352 4.61408L9.05789 10.0001L3.7352 15.3862C3.33775 15.7884 3.35789 16.4213 3.78017 16.7998C4.20245 17.1783 4.86697 17.1591 5.26441 16.7569L11.2644 10.6855C11.6449 10.3005 11.6449 9.69983 11.2644 9.31478L5.26441 3.24335C4.86697 2.84118 4.20245 2.822 3.78017 3.20051Z"
                    fill="#99A2AD"
                  />
                </svg>
              </button>
              <div className="text-[16px] leading-[20px] md:text-[18px] md:leading-[24px] tracking-[0.01em] font-semibold text-[#5E5873]">
                {format(
                  calendarRef?.current?.calendar?.getDate() || new Date(),
                  "MMMM yyyy"
                )}
              </div>
            </div>
            {renderFilter()}
          </div>
          <div className="flex w-full items-center justify-between mb-[16px] relative">
            <div>
              <input
                type="checkbox"
                id="can-register-checkbox"
                name="can-register-checkbox"
                // value={checked}
                checked={checked}
                onChange={(e) => handleRegisterEvents(e)}
              />
                <label for="html">Show me events that I can RSVP today!</label>
            </div>
            <span>
              <span
                onClick={handleAddToCalendar}
                className="text-sm text-primary underline font-semibold cursor-pointer"
              >
                Add to Calendar
              </span>
              <Tooltip
                title={
                  <span className="text-sm">
                    Click{" "}
                    <a
                      href="https://support.google.com/calendar/answer/37118?hl=en&co=GENIE.Platform%3DDesktop"
                      target="_blank"
                      className="text-sm underline"
                      rel="noreferrer"
                    >
                      here
                    </a>{" "}
                    to import events to Google Calendar
                  </span>
                }
                interactive
              >
                <InfoOutlinedIcon
                  fontSize="small"
                  className="scale-[0.8] cursor-pointer"
                />
              </Tooltip>
            </span>
          </div>
        </Hidden>
        <div className="min-w-[816px] bg-white sm:block hidden">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin]}
            aspectRatio={1.5}
            eventOrderStrict
            eventOrder={eventOrderCallback}
            events={calendarEvents}
            eventOverlap={false}
            eventClick={(info) => {
              info.jsEvent.preventDefault();
              info.jsEvent.stopPropagation();
              setGroupDataDetails(false);
              openEvent(info);
            }}
            eventTimeFormat={{
              hour: "numeric",
              minute: "2-digit",
              omitZeroMinute: true,
              meridiem: "short",
            }}
            dayCellContent={(args) => {
              const firstDayOfMonth = new Date(
                args.date.getFullYear(),
                args.date.getMonth(),
                1
              );

              if (args.date.getDate() === 1) {
                return `${firstDayOfMonth.toLocaleString("en-US", {
                  month: "short",
                })} 1`;
              } else {
                return args.date.getDate().toString();
              }
            }}
            headerToolbar={false}
            datesSet={(event) => getEventsDates(event)}
            fixedWeekCount={false}
          />
        </div>
        <div className="calendar-mobile block sm:hidden bg-white">
          <FullCalendar
            ref={calendarMobileRef}
            eventOrderStrict
            eventOrder={eventOrderCallback}
            events={calendarEvents}
            eventOverlap={false}
            plugins={[dayGridPlugin, interactionPlugin]}
            dayHeaderFormat={{
              weekday: "narrow",
            }}
            fixedWeekCount={false}
            dayMaxEvents
            dateClick={openDateHandler}
            displayEventTime={true}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              meridiem: true,
            }}
            headerToolbar={false}
            datesSet={(event) => getEventsDates(event)}
          />
        </div>
        <AutoPositionedDiv
          id="calendar-details-popup"
          closePopup={() => setDataDetails(false)}
          className={`${
            dataDetails?.title
              ? "md:opacity-100 md:z-[9999] md:visible "
              : "md:opacity-0 md:-z-50 invisible"
          } hidden md:block invisible absolute  duration-500 transition-all`}
          relativeElementRect={dataDetails?.elePosition}
        >
          <DetailsComponent
            setDataDetails={setDataDetails}
            dataDetails={dataDetails}
            openGroupDateDetails={openGroupDateDetails}
          >
            {groupDataDetails && (
              <DetailsIPO
                dataDetails={dataDetails}
                setGroupDataDetails={setGroupDataDetails}
                groupDataDetails={groupDataDetails}
              />
            )}
          </DetailsComponent>
        </AutoPositionedDiv>
      </div>
      <DetailsMobile
        openGroupDateDetails={openGroupDateDetails}
        groupDataDetails={groupDataDetails}
        dateEventsDetails={dateEventsDetails}
        setDateEventsDetails={setDateEventsDetails}
      />
    </>
  );
}
export default CalendarTable;
