import * as actionTypes from "../actions/actionTypes";

const initialState = {
  supportedBlockchains: [],
};

const setSupportedBlockchains = (state, action) => {
  return {
    ...state,
    supportedBlockchains: action.data,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SUPPORTED_BLOCKCHAINS:
      return setSupportedBlockchains(state, action);
    default:
      return state;
  }
};

export default reducer;
