import axios from "axios";

import * as actionTypes from "./actionTypes";
import { loading, searchLoading } from "./commonAc";

let domainUserDetails = process.env.REACT_APP_API2_URL;

export const loadingRefreshSet = (data) => {
  return {
    type: actionTypes.REFRESH_LOADING,
    data: data,
  };
};

export const getOptionsOpenInterestDataDone = (data) => {
  return {
    type: actionTypes.GET_OPTIONS_OPEN_INTEREST_DATA_DONE,
    data: data,
  };
};

export const getOptionsBlockQuantityDataDone = (data) => {
  return {
    type: actionTypes.GET_OPTIONS_BLOCK_QUANTITY_DATA_DONE,
    data: data,
  };
};

export const getOptionsBlockQualityDataDone = (data) => {
  return {
    type: actionTypes.GET_OPTIONS_BLOCK_QUALITY_DATA_DONE,
    data: data,
  };
};

export const getOptionsIvChangeDataDone = (data) => {
  return {
    type: actionTypes.GET_IV_CHANGE_DATA_DONE,
    data: data,
  };
};

export const getOptionsAverageVolDataDone = (data) => {
  return {
    type: actionTypes.GET_OPTIONS_AVERAGE_VOLUME_DATA_DONE,
    data: data,
  };
};

export const getTerritoryDataDone = (data) => {
  return {
    type: actionTypes.GET_TERRITORY_DATA_DONE,
    data: data,
  };
};

export const getInterestDataDone = (data) => {
  return {
    type: actionTypes.GET_OPTIONS_INTEREST_DATA_DONE,
    data: data,
  };
};

export const getMomentumDataDone = (data) => {
  return {
    type: actionTypes.GET_MOMENTUM_DATA_DONE,
    data: data,
  };
};

export const getExposureDataDone = (data) => {
  return {
    type: actionTypes.GET_EXPOSURE_DATA_DONE,
    data: data,
  };
};

export const getOptionsSearchDataDone = (data) => {
  return {
    type: actionTypes.GET_OPTIONS_SEARCH_DATA_DONE,
    data: data,
  };
};

export const setShowFaq = (value) => {
  return {
    type: actionTypes.SHOW_FAQ,
    data: value,
  };
};

export const getOptionsOpenInterestData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad && !obj.isInfiniteLoad) dispatch(loading(true));
    if (obj.isRefresh) dispatch(loadingRefreshSet(true));

    const params = {
      limit: obj.limit,
      offset: obj.offset,
      tags: obj.tags ? obj.tags : "",
      start: obj.start ? obj.start : "",
      end: obj.end ? obj.end : "",
      cids: obj.cids ? obj.cids : "",
      bids: "",
      action: "all",
      sortby: obj.sortby ? obj.sortby : "",
      sortorder: obj.sortorder ? obj.sortorder : "",
    };

    axios
      .get(`${domainUserDetails}/v3/options-open-interest`, { params })
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad && obj.isInfiniteLoad) {
          let prevData = getState().options.optionOpenInterestData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(getOptionsOpenInterestDataDone(data));
        dispatch(loading(false));
        dispatch(loadingRefreshSet(false));
      });
  };
};

export const getOptionsBlockQuantityData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad && !obj.isInfiniteLoad) dispatch(loading(true));
    if (obj.isRefresh) dispatch(loadingRefreshSet(true));

    const params = {
      limit: obj.limit,
      offset: obj.offset,
      tags: obj.tags ? obj.tags : "",
      start: obj.start ? obj.start : "",
      end: obj.end ? obj.end : "",
      cids: obj.cids ? obj.cids : "",
      bids: "",
      action: "all",
      sortby: obj.sortby ? obj.sortby : "",
      sortorder: obj.sortorder ? obj.sortorder : "",
    };

    axios
      .get(`${domainUserDetails}/v3/options-block-quantity`, { params })
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad && obj.isInfiniteLoad) {
          let prevData = getState().options.optionsBlockQuantityData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(getOptionsBlockQuantityDataDone(data));
        dispatch(loading(false));
        dispatch(loadingRefreshSet(false));
      });
  };
};

export const getOptionsBlockQualityData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad && !obj.isInfiniteLoad) dispatch(loading(true));
    if (obj.isRefresh) dispatch(loadingRefreshSet(true));

    const params = {
      limit: obj.limit,
      offset: obj.offset,
      tags: obj.tags ? obj.tags : "",
      start: obj.start ? obj.start : "",
      end: obj.end ? obj.end : "",
      cids: obj.cids ? obj.cids : "",
      bids: "",
      action: "all",
      sortby: obj.sortby ? obj.sortby : "",
      sortorder: obj.sortorder ? obj.sortorder : "",
    };

    axios
      .get(`${domainUserDetails}/v3/options-block-quality`, { params })
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad && obj.isInfiniteLoad) {
          let prevData = getState().options.optionsBlockQualityData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(getOptionsBlockQualityDataDone(data));
        dispatch(loading(false));
        dispatch(loadingRefreshSet(false));
      });
  };
};

export const getOptionsIvChangeData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad && !obj.isInfiniteLoad) dispatch(loading(true));
    if (obj.isRefresh) dispatch(loadingRefreshSet(true));

    const params = {
      limit: obj.limit,
      offset: obj.offset,
      tags: obj.tags ? obj.tags : "",
      start: obj.start ? obj.start : "",
      end: obj.end ? obj.end : "",
      cids: obj.cids ? obj.cids : "",
      bids: "",
      action: "all",
      sortby: obj.sortby ? obj.sortby : "",
      sortorder: obj.sortorder ? obj.sortorder : "",
    };

    axios
      .get(`${domainUserDetails}/v3/options-iv-change`, { params })
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad && obj.isInfiniteLoad) {
          let prevData = getState().options.optionsIvData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(getOptionsIvChangeDataDone(data));
        dispatch(loading(false));
        dispatch(loadingRefreshSet(false));
      });
  };
};

export const getOptionsAverageVolData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad && !obj.isInfiniteLoad) dispatch(loading(true));
    if (obj.isRefresh) dispatch(loadingRefreshSet(true));

    const params = {
      limit: obj.limit,
      offset: obj.offset,
      tags: obj.tags ? obj.tags : "",
      start: obj.start ? obj.start : "",
      end: obj.end ? obj.end : "",
      cids: obj.cids ? obj.cids : "",
      bids: "",
      action: "all",
      sortby: obj.sortby ? obj.sortby : "",
      sortorder: obj.sortorder ? obj.sortorder : "",
    };

    axios
      .get(`${domainUserDetails}/v3/options-quantity-average-volume`, {
        params,
      })
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad && obj.isInfiniteLoad) {
          let prevData = getState().options.optionsAverageVolData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(getOptionsAverageVolDataDone(data));
        dispatch(loading(false));
        dispatch(loadingRefreshSet(false));
      });
  };
};

export const getTerritoryData = (obj) => {
  return (dispatch) => {
    if (obj.onLoad) dispatch(loading(true));
    if (obj.isRefresh) dispatch(loadingRefreshSet(true));

    const params = {
      cid: obj.cid,
      date: obj.date,
      tags: obj.tags,
      start: obj.start,
      // sortby: obj.sortby ? obj.sortby : "",
      // sortorder: obj.sortorder ? obj.sortorder : "",
    };

    axios
      .get(`${domainUserDetails}/v3/options/time`, {
        params,
      })
      .then((res) => {
        let data = res.data;
        // if (!obj.onLoad) {
        //   let prevData = getState().options.territoryData.data;
        //   let appendedData = prevData.concat(data.data);
        //   data.data = appendedData;
        // }
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(getTerritoryDataDone(data));
        dispatch(loading(false));
        dispatch(loadingRefreshSet(false));
      });
  };
};

export const getOptionsInterestData = (obj) => {
  return (dispatch) => {
    if (obj.onLoad) dispatch(loading(true));
    if (obj.isRefresh) dispatch(loadingRefreshSet(true));

    const params = {
      cid: obj.cid,
      date: obj.date,
      start: obj.start,
      // sortby: obj.sortby ? obj.sortby : "",
      // sortorder: obj.sortorder ? obj.sortorder : "",
    };

    axios
      .get(`${domainUserDetails}/v3/options/interests`, {
        params,
      })
      .then((res) => {
        let data = res.data;
        // if (!obj.onLoad) {
        //   let prevData = getState().options.interestsData.data;
        //   let appendedData = prevData.concat(data.data);
        //   data.data = appendedData;
        // }
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(getInterestDataDone(data));
        dispatch(loading(false));
        dispatch(loadingRefreshSet(false));
      });
  };
};

export const getMomentumData = (obj) => {
  return (dispatch) => {
    if (obj.onLoad) dispatch(loading(true));
    if (obj.isRefresh) dispatch(loadingRefreshSet(true));

    const params = {
      limit: obj.limit,
      offset: obj.offset,
      cid: obj.cid,
      date: obj.date,
      start: obj.start,
      sortby: obj.sortby ? obj.sortby : "",
      sortorder: obj.sortorder ? obj.sortorder : "",
    };

    axios
      .get(`${domainUserDetails}/v3/options/momentum`, {
        params,
      })
      .then((res) => {
        let data = res.data;
        // if (!obj.onLoad) {
        //   let prevData = getState().options.momentumData.data;
        //   let appendedData = prevData.concat(data.data);
        //   data.data = appendedData;
        // }
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(getMomentumDataDone(data));
        dispatch(loading(false));
        dispatch(loadingRefreshSet(false));
      });
  };
};

export const getExposureData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    if (obj.isRefresh) dispatch(loadingRefreshSet(true));

    const params = {
      limit: obj.limit,
      offset: obj.offset,
      cid: obj.cid,
      expiration: obj.expiration,
      type: obj.type,
      strike: obj.strike,
      sortby: obj.sortby ? obj.sortby : "",
      sortorder: obj.sortorder ? obj.sortorder : "",
    };

    axios
      .get(`${domainUserDetails}/v3/options/exposure`, {
        params,
      })
      .then((res) => {
        let data = res.data;
        // if (!obj.onLoad) {
        //   let prevData = getState().options.exposureData.data;
        //   let appendedData = prevData.concat(data.data);
        //   data.data = appendedData;
        // }
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(getExposureDataDone(data));
        dispatch(loading(false));
        dispatch(loadingRefreshSet(false));
      });
  };
};

let cancelToken;

export const getOptionsSearchData = (obj) => {
  return (dispatch) => {
    dispatch(searchLoading(true));

    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();

    axios
      .get(
        domainUserDetails +
          `/v3/options/search?search=${obj.search}&tags=${obj.tags}`,
        {
          cancelToken: cancelToken.token,
        }
      )
      .then((res) => {
        let data = res.data;
        dispatch(getOptionsSearchDataDone(data?.company));
        dispatch(searchLoading(false));
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};
