import * as actionTypes from "../actions/actionTypes"
import { setChartRawData } from "../actions/homeAc"
import differenceInDays from "date-fns/differenceInDays"
import isCordova from "../../helpers/cordova-check"

const initialState = {
  user: null,
  isError: false,
  chartId: null,
  chartOptions: {
    chart: {
      styledMode: false,
      spacing: [24, 32, 24, 32],
      // zoomType: 'x'
      events: {
        render: () => {
          let divsToHide = document.getElementsByClassName("hide_flag")
          let shortFlagsToHide = document.getElementsByClassName(
            "hide_short_flag"
          )
          let isSmallScreen = localStorage.getItem("isSmallScreen")
          if (isSmallScreen === "true") {
            if (divsToHide) {
              for (var i = 0; i < divsToHide.length; i++) {
                // divsToHide[i].style.visibility = "hidden" // or
                divsToHide[i].style.display = "none" // depending on what you're doing
              }
            }
            if (shortFlagsToHide) {
              for (var i = 0; i < shortFlagsToHide.length; i++) {
                // divsToHide[i].style.visibility = "hidden" // or
                shortFlagsToHide[i].style.display = "block" // depending on what you're doing
              }
            }
          } else if (isSmallScreen === "false") {
            if (divsToHide) {
              for (var i = 0; i < divsToHide.length; i++) {
                // divsToHide[i].style.visibility = "hidden" // or
                divsToHide[i].style.display = "block" // depending on what you're doing
              }
            }
            if (shortFlagsToHide) {
              for (var i = 0; i < shortFlagsToHide.length; i++) {
                // divsToHide[i].style.visibility = "hidden" // or
                shortFlagsToHide[i].style.display = "none" // depending on what you're doing
              }
            }
          }
        },
        // load: () => {
        //   alert("load")
        // },
        // redraw: () => {
        //   alert("redraw")
        // }
        // afterPrint: () => {
        //   alert("afterPrint")
        // }
      },
    },
    credits: {
      enabled: false,
    },
    navigator: {
      enabled: true,
      xAxis: {
        alignTicks: true,
      },
    },
    scrollbar: {
      enabled: false,
    },
    mapNavigation: {
      // enableMouseWheelZoom: true,
      // enableButtons: true,
    },
    plotOptions: {
      candlestick: {
        color: "#ff3031",
        upColor: "#00b061",
        lineColor: "#ff3031",
        upLineColor: "#00b061",
      },
    },
    tooltip: {
      fontFamily: "Open Sans",
    },
    xAxis: [
      {
        plotBands: null,
        gridLineWidth: 1,
        gridLineColor: "#ffffff",
        alternateGridColor: "#f0f0f0",
        min: null,
        labels: {
          y: 30,
          padding: 25,
          format: "{value:%e %b}",
          style: {
            fontFamily: "Open Sans",
            fontSize: "14px",
          },
        },
        events: {
          afterSetExtremes: function () {
            var min = this.min,
              max = this.max
            let start = new Date(min)
            let end = new Date(max)

            const diffInDays = differenceInDays(new Date(end), new Date(start))
            localStorage.setItem("diffInDays", diffInDays)

            let divsToHide = document.getElementsByClassName("hide_flag")
            let shortFlagsToHide = document.getElementsByClassName(
              "hide_short_flag"
            )
            let isSmallScreen = localStorage.getItem("isSmallScreen")
            if (isSmallScreen === "false") {
              if (diffInDays > 730) {
                if (divsToHide) {
                  for (var i = 0; i < divsToHide.length; i++) {
                    divsToHide[i].style.display = "none" // depending on what you're doing
                  }
                }
                if (shortFlagsToHide) {
                  for (var i = 0; i < shortFlagsToHide.length; i++) {
                    shortFlagsToHide[i].style.display = "block" // depending on what you're doing
                  }
                }
              } else {
                if (divsToHide) {
                  for (var i = 0; i < divsToHide.length; i++) {
                    divsToHide[i].style.display = "block" // depending on what you're doing
                  }
                }
                if (shortFlagsToHide) {
                  for (var i = 0; i < shortFlagsToHide.length; i++) {
                    shortFlagsToHide[i].style.display = "none" // depending on what you're doing
                  }
                }
              }
            }

            if (isSmallScreen === "true") {
              // alert("small")
              if (divsToHide) {
                for (var i = 0; i < divsToHide.length; i++) {
                  divsToHide[i].style.display = "none" // depending on what you're doing
                }
              }
              if (diffInDays > 400) {
                if (shortFlagsToHide) {
                  for (var i = 0; i < shortFlagsToHide.length; i++) {
                    shortFlagsToHide[i].style.display = "none" // depending on what you're doing
                  }
                }
              } else {
                if (shortFlagsToHide) {
                  for (var i = 0; i < shortFlagsToHide.length; i++) {
                    shortFlagsToHide[i].style.display = "block" // depending on what you're doing
                  }
                }
              }
            }
          },
        },
      },
    ],
    yAxis: [
      {
        // allowDecimals: false,
        type: "logarithmic",
        maxPadding: 0.2,
        gridLineWidth: 1,
        minorGridLineWidth: 0,
        gridLineColor: "#e8e6e6",
        labels: {
          padding: 50,
          align: "right",
          // format: '{value:.2f}',
          x: -3,
          style: {
            fontFamily: "Open Sans",
            fontSize: "14px",
          },
        },
        title: {
          text: "OHLC",
          style: {
            fontFamily: "Open Sans",
            fontSize: "14px",
          },
        },
        height: "100%",
        lineWidth: 2,
        resize: {
          enabled: true,
        },
      },
      {
        gridLineWidth: 0,
        labels: {
          // format: '{value:.2f}',
          align: "right",
          x: -3,
        },
        title: {
          text: "Volume",
          style: {
            fontFamily: "Open Sans",
            fontSize: "14px",
          },
        },
        top: "85%",
        height: "15%",
        offset: 0,
        lineWidth: 2,
      },
    ],
    series: null,
    rangeSelector: {
      enabled: true,
      selected: isCordova() || window.screen.width < 400 ? 2 : 4,
      buttons: [
        {
          type: "month",
          count: 1,
          text: "1M",
          title: "View 1 month",
        },
        {
          type: "month",
          count: 3,
          text: "3M",
          title: "View 3 months",
        },
        {
          type: "month",
          count: 6,
          text: "6M",
          title: "View 6 months",
        },
        {
          type: "ytd",
          text: "YTD",
          title: "View year to date",
        },
        {
          type: "year",
          count: 1,
          text: "1Y",
          title: "View 1 year",
        },
        {
          type: "all",
          text: "All",
          title: "View all",
          events: {
            click: function (e) {
              let divsToHide = document.getElementsByClassName("hide_flag")
              if (divsToHide) {
                for (var i = 0; i < divsToHide.length; i++) {
                  // divsToHide[i].style.visibility = "hidden" // or
                  divsToHide[i].style.display = "none" // depending on what you're doing
                }
              }
            },
          },
        },
      ],
      buttonSpacing: 10,
      buttonTheme: {
        width: 42,
        style: {
          fontFamily: "Open Sans",
          fontSize: "14px",
        },
      },
      labelStyle: {
        fontFamily: "Open Sans",
        fontSize: "14px",
      },
      inputStyle: {
        fontFamily: "Open Sans",
        fontSize: "14px",
      },
      inputDateFormat: "%e %b %Y",
    },
  },
  companyId: null,
  companyDetails: "",
  pointValues: {},
  userDetailsLoading: false,
  chartRawData: null,
  topStoriesData: null,
  spikingUpData: null,
  spikingDownData: null,
}

const setUserDetails = (state, action) => {
  return {
    ...state,
    user: action.data,
  }
}

const setChartData = (state, action) => {
  if (action.data === null) {
    return {
      ...state,
      isError: true,
      chartId: null,
    }
  }

  let options = { ...state.chartOptions }

  options.xAxis[0].plotBands = action.data[0]
  options.xAxis[0].min = action.data[4]
  options.series = action.data[1]
  options.navigator.xAxis.min = action.data[5]
  options.is_locked = action.data[6]
  options.is_locked_url = action.data[7]
  if(action.data[8]) options.rangeSelector.selected = 5

  return {
    ...state,
    chartOptions: options,
    isError: false,
    chartId: action.data[2],
    companyDetails: action.data[3],
  }
}

const setSelectedCompany = (state, action) => {
  return {
    ...state,
    companyId: action.data,
  }
}

// const setCompanyDetails = (state, action) => {
//   alert("here");
//   return {
//     ...state,
//     companyDetails: action.data
//   }
// }

const setUserDetailsLoading = (state, action) => {
  return {
    ...state,
    userDetailsLoading: action.data,
  }
}

const setRawData = (state, action) => {
  return {
    ...state,
    chartRawData: action.data,
  }
}

const setTopStories = (state, action) => {
  return {
    ...state,
    topStoriesData: action.data,
  }
}

const setSpikingUp = (state, action) => {
  return {
    ...state,
    spikingUpData: action.data,
  }
}

const setSpikingDown = (state, action) => {
  return {
    ...state,
    spikingDownData: action.data,
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_DETAILS_DONE:
      return setUserDetails(state, action)
    case actionTypes.GET_CHART_DATA_DONE:
      return setChartData(state, action)
    case actionTypes.SELECT_COMPANY:
      return setSelectedCompany(state, action)
    // case actionTypes.GET_COMPANY_DETAILS:
    //   return setCompanyDetails(state, action);
    case actionTypes.USER_DETAILS_LOADING:
      return setUserDetailsLoading(state, action)
    case actionTypes.SET_CHART_RAW_DATA:
      return setRawData(state, action)
    case actionTypes.GET_TOP_STORIES_DONE:
      return setTopStories(state, action)
    case actionTypes.GET_SPIKING_UP_DONE:
      return setSpikingUp(state, action)
    case actionTypes.GET_SPIKING_DOWN_DONE:
      return setSpikingDown(state, action)
    default:
      return state
  }
}

export default reducer
