import React from "react";
import Content from "../components/Content";

import spikingImg from "../../../images/journey/spiking.png";

const Future = () => {
  const content = {
    dayTitle: "Your Future Starts Here",
    heading:
      "Congratulations on your decision to start your investment journey with Spiking.",
    intro:
      "You've taken a significant step towards empowering your financial future with data-driven insights and innovative strategies.",
    youtubeVideoId: "TDLnWycpamc",
    about: {
      sectionTitle: `ABOUT <img src="${spikingImg}" alt="Spiking" class="inline w-20 object-cover">`,
      description: `Investing in the stock market is a time-tested strategy for building wealth and achieving your financial goals. At Spiking, we’ll guide you through the investment process, help you navigate COSY assets (<strong>C</strong>ryptos, <strong>O</strong>ptions, <strong>S</strong>tocks, <strong>Y</strong>es), and introduce you to the game-changing concept of the Holy Grail Portfolio.`,
    },
    topicsIntro:
      "As part of your Spiking journey, we’ll take you step-by-step through these essential topics:",
    topics: [
      {
        title: "Foundational concepts",
        description:
          "Start with COSY, journey through SALT, and finish with MONEY",
      },
      {
        title: "Investment capital",
        description: "Join us to REST and grow your initial capital",
      },
      {
        title: "Blue ocean",
        description:
          "Achieve daily profits of 5% to 100% with insights from Wall Street insiders and TradeGPT",
      },
      {
        title: "Proprietary strategies",
        description:
          "Decode financial time frames with the Holy Grail portfolios",
      },
      {
        title: "Five strategies to build your investing legacy",
        description:
          "Cashflow tactics for employees, self-employed, business owners, investors, and family offices",
      },
    ],
    nextSteps: {
      title: "NEXT STEPS",
      steps: [
        {
          title: "Brokerage",
          description: "Set up your brokerage account and arrange deposit funds via bank wire.",
          actions: [
            {
              label: "Open Brokerage Account",
              link: "http://spi.ke/openIB",
              type: "primary",
            },
            {
              label: "Deposit Funds",
              link: "http://spi.ke/IBDeposit",
              type: "secondary",
            },
          ],
        },
        {
          title: "Budget",
          description: "Balanced your household budget and allocated monthly funds for investing.",
          actions: [],
        },
        {
          title: "Connect with our Angels",
          description: "For assistance or further guidance at Spiking Help.",
          actions: [
            {
              label: "Go to Spiking Help",
              link: "https://spi.ke/help",
              type: "secondary",
            },
          ],
        },
      ],
    },
  };

  return <Content content={content} />;
};

export default Future;
