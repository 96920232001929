import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

import isCordova from '../../../../../helpers/cordova-check';

import Drawer from "@material-ui/core/Drawer";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  drawerMain: {
    width: "58vw",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  weight600: {
    fontWeight: 600,
  },
  weight400: {
    fontWeight: 400,
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
  },
}));

const NotificationSwitch = ({ handlePost, enabled }) => {
  const [checked, setChecked] = React.useState(true);

  useEffect(() => {
    setChecked(enabled);
  }, [enabled]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    handlePost(event.target.checked);
  };

  return (
    <Switch
      color="primary"
      checked={checked}
      onChange={handleChange}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
};

const NotificationDrawer = ({ open, handleSettingsClose, user }) => {
  const classes = useStyles();
  const [notificationData, setNotificationData] = useState(null);

  const getData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API2_URL}/v3/user_notification_setting`
      );
      setNotificationData(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handlePost = useCallback(async (item, enabled) => {
    try {
      console.log(item?.id, enabled);
      let body = new FormData();
      body.append("id", item?.id);
      body.append("enabled", enabled);
      const response = await axios.patch(
        `${process.env.REACT_APP_API2_URL}/v3/user_notification_setting`,
        body
      );
      console.log(response);
      // setNotificationData(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [getData, open]);

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      classes={{ paper: classes.drawerMain }}
      onClose={handleSettingsClose}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        pb={2}
        pt={isCordova() ? 4 : 2}
        boxShadow="rgba(17, 17, 26, 0.1) 0px 1px 0px;"
      >
        <Box display="flex" alignItems="center">
          <Avatar src={user?.ImageUrl} className={classes.avatar} />
          <div>
            <Typography variant="body2" color="textSecondary">
              {user?.Firstname + " " + user?.Lastname}
            </Typography>
            <Typography variant="body1" className={classes.weight600}>
              Notification Settings
            </Typography>
          </div>
        </Box>
        <div>
          <Button variant="outlined" onClick={handleSettingsClose}>
            <Typography variant="subtitle1" className={classes.weight400}>
              Cancel
            </Typography>
          </Button>
        </div>
      </Box>
      <Box mt={6} px={4}>
        <Box maxWidth="700px" margin="0 auto">
          <Typography variant="h5" className={classes.weight600}>
            Notification Types
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Get updates when you have new...
          </Typography>
          {notificationData?.length > 0 &&
            notificationData.map((item) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={4}
                px={2.4}
                py={1.5}
                borderRadius={4}
                border={`1px solid lightgrey`}
                mb={3}
              >
                <div>
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {item.description}
                  </Typography>
                </div>
                {/* <Switch color="primary" /> */}
                <NotificationSwitch
                  enabled={item.enabled}
                  handlePost={(value) => handlePost(item, value)}
                />
              </Box>
            ))}
        </Box>
      </Box>
    </Drawer>
  );
};

export default NotificationDrawer;
