import * as actionTypes from "../actions/actionTypes";

const initialState = {
  earningsData: null,
  analystData: null,
  publicOfferingData: null,
  strategyData: null,
  buybackData: null,
  fdaApprovalData: null,
  notesOfferingData: null,
  bondData: null,
  delistingData: null,
  dividendData: null,
  ipoData: null,
  indexAdditionData: null,
  mediaData: null,
  resignationData: null,
  stockSplitData: null,
  acquisitionData: null,
  stripeSubData: null,
  popularInsiderData: null,
  popularInsiderFundsData: null,
  popularInsiderSovereignData: null,
  followingInsiderData: null,
  followingFundsData: null,
  followingCompaniesData: null,
  tdFundamentalsData: null,
  tdData: null,
};

const setEarningsData = (state, action) => {
  return {
    ...state,
    earningsData: action.data,
  };
};

const setAnalystData = (state, action) => {
  return {
    ...state,
    analystData: action.data,
  };
};

const setPublicOfferingData = (state, action) => {
  return {
    ...state,
    publicOfferingData: action.data,
  };
};

const setStrategyData = (state, action) => {
  return {
    ...state,
    strategyData: action.data,
  };
};

const setBuybackData = (state, action) => {
  return {
    ...state,
    buybackData: action.data,
  };
};

const setFdaApprovalData = (state, action) => {
  return {
    ...state,
    fdaApprovalData: action.data,
  };
};

const setNotesOfferingData = (state, action) => {
  return {
    ...state,
    notesOfferingData: action.data,
  };
};

const setBondData = (state, action) => {
  return {
    ...state,
    bondData: action.data,
  };
};

const setDelistingData = (state, action) => {
  return {
    ...state,
    delistingData: action.data,
  };
};

const setDividendData = (state, action) => {
  return {
    ...state,
    dividendData: action.data,
  };
};

const setIpoDataData = (state, action) => {
  return {
    ...state,
    ipoData: action.data,
  };
};

const setIndexAdditionData = (state, action) => {
  return {
    ...state,
    indexAdditionData: action.data,
  };
};

const setMediaData = (state, action) => {
  return {
    ...state,
    mediaData: action.data,
  };
};

const setResignationData = (state, action) => {
  return {
    ...state,
    resignationData: action.data,
  };
};

const setStockSplitData = (state, action) => {
  return {
    ...state,
    stockSplitData: action.data,
  };
};

const setAcquisitionData = (state, action) => {
  return {
    ...state,
    acquisitionData: action.data,
  };
};

const setStripeSubData = (state, action) => {
  return {
    ...state,
    stripeSubData: action.data,
  };
};

const setPopularInsiderData = (state, action) => {
  return {
    ...state,
    popularInsiderData: action.data,
  };
};

const setPopularInsiderFundsData = (state, action) => {
  return {
    ...state,
    popularInsiderFundsData: action.data,
  };
};

const setPopularInsiderSovereignData = (state, action) => {
  return {
    ...state,
    popularInsiderSovereignData: action.data,
  };
};

const setFollowingInsider = (state, action) => {
  return {
    ...state,
    followingInsiderData: action.data,
  };
};

const setFollowingFunds = (state, action) => {
  return {
    ...state,
    followingFundsData: action.data,
  };
};

const setFollowingCompaniesDone = (state, action) => {
  return {
    ...state,
    followingCompaniesData: action.data,
  };
};

const setTdFundamentalsData = (state, action) => {
  return {
    ...state,
    tdFundamentalsData: action.data,
  };
};

const setTdData = (state, action) => {
  return {
    ...state,
    tdData: action.data,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EARNINGS_DATA_DONE:
      return setEarningsData(state, action);
    case actionTypes.GET_ANALYST_DATA_DONE:
      return setAnalystData(state, action);
    case actionTypes.GET_PUBLIC_OFFERING_DATA_DONE:
      return setPublicOfferingData(state, action);
    case actionTypes.GET_STRATEGY_DATA_DONE:
      return setStrategyData(state, action);
    case actionTypes.GET_BUYBACK_DATA_DONE:
      return setBuybackData(state, action);
    case actionTypes.GET_FDA_APPROVAL_DATA_DONE:
      return setFdaApprovalData(state, action);
    case actionTypes.GET_NOTES_OFFERING_DATA_DONE:
      return setNotesOfferingData(state, action);
    case actionTypes.GET_BOND_DATA_DONE:
      return setBondData(state, action);
    case actionTypes.GET_DELISTING_DATA_DONE:
      return setDelistingData(state, action);
    case actionTypes.GET_DIVIDEND_DATA_DONE:
      return setDividendData(state, action);
    case actionTypes.GET_IPO_DATA_DONE:
      return setIpoDataData(state, action);
    case actionTypes.GET_INDEX_ADDITION_DATA_DONE:
      return setIndexAdditionData(state, action);
    case actionTypes.GET_MEDIA_DATA_DONE:
      return setMediaData(state, action);
    case actionTypes.GET_RESIGNATION_DATA_DONE:
      return setResignationData(state, action);
    case actionTypes.GET_STOCK_SPLIT_DATA_DONE:
      return setStockSplitData(state, action);
    case actionTypes.GET_ACQUISITION_DATA_DONE:
      return setAcquisitionData(state, action);
    case actionTypes.GET_STRIPE_SUBSCRIPTIONS_DONE:
      return setStripeSubData(state, action);
    case actionTypes.POPULAR_INSIDER_DATA_DONE:
      return setPopularInsiderData(state, action);
    case actionTypes.POPULAR_INSIDER_FUNDS_DATA_DONE:
      return setPopularInsiderFundsData(state, action);
    case actionTypes.POPULAR_INSIDER_SOVEREIGN_DATA_DONE:
      return setPopularInsiderSovereignData(state, action);
    case actionTypes.GET_FOLLOWING_INSIDER_DONE:
      return setFollowingInsider(state, action);
    case actionTypes.GET_FOLLOWING_FUNDS_DONE:
      return setFollowingFunds(state, action);
    case actionTypes.GET_FOLLOWING_COMPANIES_DONE:
      return setFollowingCompaniesDone(state, action);
    case actionTypes.GET_TD_FUNDAMENTALS_DONE:
      return setTdFundamentalsData(state, action);
    case actionTypes.GET_TD_DATA_DONE:
      return setTdData(state, action);
    case actionTypes.RESET_FOLLOWING_INSIDERS:
      return setFollowingInsider(state, action);
    default:
      return state;
  }
};

export default reducer;
