import React, { useEffect, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import futureIcon from "../../images/journey/icons/future.png";
import cosyIcon from "../../images/journey/icons/cosy.png";
import saltIcon from "../../images/journey/icons/salt.png";
import moneyIcon from "../../images/journey/icons/money.png";
import restIcon from "../../images/journey/icons/rest.png";
import spikeIcon from "../../images/journey/icons/spike.png";
import insiderIcon from "../../images/journey/icons/insider.png";
import timeFrameIcon from "../../images/journey/icons/timing.png";
import portfolioIcon from "../../images/journey/icons/portfolio.png";
import legacyIcon from "../../images/journey/icons/legacy.png";
import mentorIcon from "../../images/journey/icons/mentor.png";
import supportSystemIcon from "../../images/journey/icons/support.png";

import Layout from "../Layout/container";
import Future from "./Future";
import Cosy from "./Cosy";
import Salt from "./Salt";
import Money from "./Money";
import Rest from "./Rest";
import Spike from "./Spike";
import Insider from "./Insider";
import TimeFrame from "./TimeFrame";
import Portfolio from "./Portfolio";
import Strategy from "./Strategy";
import Mentor from "./Mentor";
import SupportSystem from "./SupportSystem";
import BlurredBackground from "./components/BlurredBackground";
import { styled } from "@mui/material/styles";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} placement="top" classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "#666666",
    borderRadius: "4px",
    fontSize: "13px",
    lineHeight: "20px",
    padding: "10px",
    boxShadow: "0px 7px 20px 5px rgba(0, 0, 0, 0.1)",
  },
}));

export const sidebarTop = [
  {
    id: "future",
    title: "Your Future Starts Here",
    description:
      "Investing is a Proven Way to Build Wealth and Achieve Financial Freedom.",
    icon: futureIcon,
    step: 1,
    active: true,
  },
  {
    id: "cosy",
    title: "COSY Start",
    description:
      "COSY: A smart investment journey through Cryptos, Options, Stocks, and saying Yes to opportunities",
    icon: cosyIcon,
    step: 2,
  },
  {
    id: "salt",
    title: "SALT Journey",
    description:
      "SALT: Share insights, leverage AI, keep Learning, and Trade with proprietary data.",
    icon: saltIcon,
    step: 3,
  },
  {
    id: "money",
    title: "MONEY Finish",
    description:
      "MONEY: Cultivate a Millionaire mindset, seize Opportunities, grow your Network, Empower loved ones, and You only live once.",
    icon: moneyIcon,
    step: 4,
  },
  {
    id: "rest",
    title: "REST Together",
    description:
      "REST: Refer friends, Earn rewards, Shop globally, and Track your investments.",
    icon: restIcon,
    step: 5,
  },
];

export const sidebarBottom = [
  {
    id: "spiking",
    title: "Spiking Up",
    description:
      "Identify market spikes before, during, and after they occur to capitalize on blue ocean opportunities.",
    icon: spikeIcon,
    step: 6,
  },
  {
    id: "insider",
    title: "Insider Investors",
    description:
      "Get exclusive access to real-time data on top investors and use their moves to build your portfolio.",
    icon: insiderIcon,
    step: 7,
  },
  {
    id: "time",
    title: "Market Timing",
    description:
      "Master the art of market timing by analyzing key financial periods, tracking real-time data, and identifying critical catalysts for the best stock opportunities.",
    icon: timeFrameIcon,
    step: 8,
  },
  {
    id: "holy-grail",
    title: "Holy Grail Portfolios",
    description:
      "Discover Spiking’s Holy Grail: a portfolio built on insider knowledge, economic models, and timeless investment strategies.",
    icon: portfolioIcon,
    step: 9,
  },
  {
    id: "legacy",
    title: "Legacy",
    description:
      "Build and secure your investing legacy with long-term, wealth-building strategies tailored to your goals.",
    icon: legacyIcon,
    step: 10,
  },
  {
    id: "mentor",
    title: "Meet Your Mentor",
    description:
      "Discover Dr. Clemen Chiang’s background, passions and vision to help you gain leverage and improve your investments",
    icon: mentorIcon,
    step: 11,
  },
  {
    id: "support",
    title: "Spiking Support System",
    description:
      "Spiking offers 5 levels of support to the entire spiking community. Reach out to us if you have any difficulties or enquiries",
    icon: supportSystemIcon,
    step: 12,
  },
];

const renderContent = (id) => {
  switch (id) {
    case sidebarTop[0].id:
      return <Future />;
    case sidebarTop[1].id:
      return <Cosy />;
    case sidebarTop[2].id:
      return <Salt />;
    case sidebarTop[3].id:
      return <Money />;
    case sidebarTop[4].id:
      return <Rest />;
    case sidebarBottom[0].id:
      return <Spike />;
    case sidebarBottom[1].id:
      return <Insider />;
    case sidebarBottom[2].id:
      return <TimeFrame />;
    case sidebarBottom[3].id:
      return <Portfolio />;
    case sidebarBottom[4].id:
      return <Strategy />;
    case sidebarBottom[5].id:
      return <Mentor />;
    case sidebarBottom[6].id:
      return <SupportSystem />;
    default:
      return <Future />;
  }
};

const defaultId = sidebarTop[0].id;
const defaultStep = sidebarTop[0].step;

const Journey = () => {
  const [selectedItem, setSelectedItem] = useState(defaultId);
  const [step, setStep] = useState(defaultStep);
  const [greeting, setGreeting] = useState("");

  const url = useLocation();

  const fullName = useSelector((state) => {
    return `${state?.home?.user?.Firstname} ${state?.home?.user?.Lastname}`;
  });

  const allSteps = [...sidebarTop, ...sidebarBottom];

  const handleSelect = (value) => {
    const s = [...sidebarTop, ...sidebarBottom].find((element) => {
      return element.id === value;
    }).step;

    setStep(s);
  };

  const handlePrevious = () => {
    // If step is already at the first item, do nothing or wrap around
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleNext = () => {
    // If step is already at the last item, do nothing or wrap around
    if (step < allSteps.length) {
      setStep(step + 1);
    }
  };

  useEffect(() => {
    // Whenever 'step' changes, find the sidebar item that matches
    const item = [...sidebarTop, ...sidebarBottom].find(
      (element) => element.step === step
    );

    if (item) {
      setSelectedItem(item.id);
    }
  }, [step]);

  useEffect(() => {
    // Automatically detect step from URL query parameter
    const searchParams = new URLSearchParams(url.search);
    const stepFromUrl = searchParams.get("step");

    if (stepFromUrl) {
      const matchingItem = [...sidebarTop, ...sidebarBottom].find(
        (item) => item.id === stepFromUrl
      );

      if (matchingItem) {
        setStep(matchingItem.step);
        setSelectedItem(matchingItem.id);
      }
    }
  }, [url]);

  useEffect(() => {
    if (url === "/support") {
      setStep(sidebarBottom[6].step);
    }
  }, []);

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      setGreeting("Good morning");
    } else if (currentHour < 18) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }
  }, []);

  return (
    <Layout>
      <BlurredBackground>
        <div className="container font-open-sans flex flex-row gap-4 sm:gap-10 h-full w-full sm:pt-20 pb-2 px-0 sm:px-4 dark:text-black">
          {/* Desktop Sidebar */}
          <aside className="border border-[#E1E1E1] bg-white px-2 sm:px-5 py-6 sm:py-8 rounded md:rounded-2xl w-fit h-fit">
            <nav>
              <div className="hidden sm:flex mb-8 items-center justify-between">
                <h2 className="text-[#AAAAAA] font-bold">Quick Guide</h2>
                <div className="flex gap-4">
                  <ArrowBackIosIcon
                    className="cursor-pointer text-[#AAAAAA] hover:text-black"
                    onClick={handlePrevious}
                  />
                  <ArrowForwardIosIcon
                    className="cursor-pointer text-[#AAAAAA] hover:text-black"
                    onClick={handleNext}
                  />
                </div>
              </div>
              <ul className="flex flex-col gap-2">
                {sidebarTop.map((item, index) => (
                  <SideBarItem
                    key={item.title + index + item.id}
                    id={item.id}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                    selectedItem={selectedItem}
                    handleSelect={handleSelect}
                  />
                ))}
                <hr className="text-[#DFE1E6] my-4 sm:my-6"></hr>
                {sidebarBottom.map((item, index) => (
                  <SideBarItem
                    key={item.title + index}
                    id={item.id}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                    selectedItem={selectedItem}
                    handleSelect={handleSelect}
                  />
                ))}
              </ul>
            </nav>
          </aside>

          <main className="h-fit rounded-2xl w-full">
            {/* Hello user */}
            <div>
              <h2 className="text-4xl md:text-[40px] font-semibold mb-10">
                <span className="text-[#AAAAAA]">{greeting}, </span>
                <span className="inline-flex items-center gap-4">
                  <span className="">{fullName}</span>
                  <picture className="inline-block">
                    <source
                      srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f44b/512.webp"
                      type="image/webp"
                    />
                    <img
                      src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f44b/512.gif"
                      alt="👋"
                      width="40"
                      height="40"
                    />
                  </picture>
                </span>
              </h2>
            </div>

            {/* Content */}
            {renderContent(selectedItem)}
          </main>
        </div>
      </BlurredBackground>
    </Layout>
  );
};

const SideBarItem = ({
  icon,
  title,
  description,
  handleSelect,
  selectedItem,
  id,
}) => {
  const isActive = selectedItem === id;

  return (
    <CustomTooltip
      title={description}
    >
      <li
        onClick={() => handleSelect?.(id)}
        className={`${
          isActive && "bg-[#F6F6F9]"
        } flex flex-row gap-6 p-2 sm:py-3 sm:px-6 items-center whitespace-nowrap cursor-pointer rounded-lg`}
      >
        <div className="h-8 w-8">
          <img src={icon} alt={title} />
        </div>
        <h3 className="hidden sm:block">{title}</h3>
      </li>
    </CustomTooltip>
  );
};

export default Journey;
