import axios from "axios";
import { useEffect, useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import StyledTooltip from "../../../components/StyledTooltip/StyledTooltip";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const colors = {
  cosy: "#FEDD00",
  tttn: "#EE5921",
  masterclass: "#9747FF",
  special_event: "#2065F6",
};

const EventsView = ({ handleAddToCalendar }) => {
  const [events, setEvents] = useState([]);
  let history = useHistory();

  const fetchEvents = async (can_register = false) => {
    try {
      var currentDate = new Date();
      var theFirst = new Date(currentDate.getFullYear(), 0, 1).toISOString();
      var theLast = new Date(currentDate.getFullYear(), 11, 31).toISOString();

      const result = await axios.get(
        `https://api.spiking.com/v3/public/events?start=${theFirst}&end=${theLast}&type=&q=&can_register=${can_register}`
      );
      const { data, status } = result;
      if (status === 200) {
        const groups = _.groupBy(data, ({ start_time, type }) => {
          return new Date(start_time).getMonth();
        });
        setEvents(groups);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEventClick = (type) => {
    switch (type) {
      case "cosy":
        history.push("/courses?recommend=cosy");
        break;
      case "tttn":
        history.push("/courses?recommend=tttn");
        break;
      case "masterclass":
        history.push("/courses?recommend=masterclass");
        break;
      default:
        return false;
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);
  
  return (
    <div className="space-y-6">
      <div className="flex items-center gap-x-4">
        <Typography className="!text-[24px] !font-semibold">
          {new Date().getFullYear()} Events
        </Typography>
        <span>
          <span
            onClick={handleAddToCalendar}
            className="text-sm text-primary underline font-semibold cursor-pointer"
          >
            Add to Calendar
          </span>
          <Tooltip
            title={
              <span className="text-sm">
                Click{" "}
                <a
                  href="https://support.google.com/calendar/answer/37118?hl=en&co=GENIE.Platform%3DDesktop"
                  target="_blank"
                  className="text-sm underline"
                  rel="noreferrer"
                >
                  here
                </a>{" "}
                to import events to Google Calendar
              </span>
            }
            interactive
          >
            <InfoOutlinedIcon
              fontSize="small"
              className="scale-[0.8] cursor-pointer"
            />
          </Tooltip>
        </span>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-5 gap-x-8">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((index) => (
          <div className="border border-gray-200 rounded-xl p-2">
            <Typography variant="body1" className="!font-semibold !mb-4">
              {months[index]}
            </Typography>
            {events[index]?.length > 0 &&
              events[index].map((event) => (
                <>
                  {(event.type === "cosy" ||
                    event.type === "tttn" ||
                    event.type === "masterclass" ||
                    event.type === "special_event") && (
                    <div className="flex items-start gap-x-3 mb-6">
                      <div
                        className="p-4 rounded-lg font-bold"
                        style={{ backgroundColor: colors[event.type] }}
                      >
                        {event.start_time.slice(8, 10)}
                      </div>
                      <div>
                        <Typography className="!font-bold !text-[15px]">
                          {event.title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          {dayjs(event.start_time).format(
                            "MMMM D, YYYY - hh:mm A"
                          )}
                        </Typography>
                        <StyledTooltip title={event.description}>
                          <Typography
                            variant="subtitle1"
                            className="line-clamp-2"
                          >
                            {event.description}
                          </Typography>
                        </StyledTooltip>
                        {(event.type === "cosy" ||
                          event.type === "tttn" ||
                          event.type === "masterclass") && (
                          <Typography
                            className="!font-medium !text-[15px] cursor-pointer"
                            color="primary"
                            onClick={() => handleEventClick(event.type)}
                          >
                            Register Now
                          </Typography>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventsView;
