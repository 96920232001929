import * as actionTypes from "../actions/actionTypes";

const initialState = {
  lessonShowBanner: false,
  preRequisiteChapter: null
};

const setPrerequisite = (state, action) => {
  return {
    ...state,
    lessonShowBanner: action.data,
  };
};

const setPrerequisiteLesson = (state, action) => {
  return {
    ...state,
    preRequisiteChapter: action.data,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_LESSON_PREREQUISITE_BANNER:
      return setPrerequisite(state, action);
    case actionTypes.STORE_PREREQUISITE_LESSON:
      return setPrerequisiteLesson(state, action);
    default:
      return state;
  }
};

export default reducer;
