import { makeStyles, fade } from "@material-ui/core/styles";

import MarsBackground from "../../images/mars.jpg";

const smallDrawerWidth = 80;
const drawerWidth = 326;

const useStyles = makeStyles((theme) => ({
  root: {},
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: (props) =>
      props.openBackdrop ? theme.zIndex.drawer : theme.zIndex.drawer + 1,
  },
  backgrounColor: {
    // backgroundColor: props => props.isLightMode ? "#282f48" : theme.palette.background.dark
    backgroundColor: theme.palette.custom.sideNavBackground,
  },
  drawerOpen: {
    width: drawerWidth,
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      // background: "#f1f1f1"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.custom.dividerColor,
      borderRadius: 20,
    },
  },
  fixedDrawer: {
    width: smallDrawerWidth,
  },
  drawerClose: {
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: 0,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down("sm")]: {
      ...theme.mixins.toolbar,
    },
  },
  mainContent: {
    flexGrow: 1,
    padding: `5rem 16px`,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: `4rem 8px`,
    },
    // position: 'relative',
  },
  content: {
    flexGrow: 1,
    // christmas banner changes
    // padding: `5rem 16px 0px 16px`,
    padding: `1rem 16px 0px 16px`,
    // christmas banner changes
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: `4rem 8px 0px 8px`,
    },
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginLeft: 0,
  },
  grow: {
    flexGrow: 1,
  },
  searchBlock: {
    [theme.breakpoints.up("sm")]: {
      width: "40ch",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: "100%",
  },
  searchIcon: {
    padding: "0 10px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: (props) => (props.type === "small" ? "1.2rem" : "1.5rem"),
    },
  },
  inputRoot: {
    color: "inherit !important",
    width: "100%",
  },
  inputElem: {
    padding: "8px 7px 9px 45px !important",
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  iconButton: {
    padding: "10px 12px",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  navbarLogo: {
    width: "95px",
    height: "35px",
    cursor: "pointer",
    // paddingLeft: 10,
    [theme.breakpoints.down("md")]: {
      width: "70px",
      height: "25px",
      paddingLeft: 0,
      paddingRight: 10,
    },
  },
  languageDropdown: {
    color: "#4F4F5A",
    marginLeft: "-6px",
    marginTop: "6px",
  },
  languageButton: {
    textTransform: "capitalize",
  },
  notificationDiv: {
    marginRight: "34px",
    marginTop: "8px",
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
      marginTop: "0px",
    },
  },
  badge: {
    backgroundColor: "#FC5A5A",
  },
  notiIcon: {
    color: theme.palette.custom.lightGrey,
    cursor: "pointer",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 3,
    color: "#fff",
  },
  popoverPaper: {
    width: "24rem",
    backgroundColor: "#1B1F23",
    borderRadius: "5px",
    // border: "0.3px solid rgba(255, 255, 255, 0.23)",
    // position: "relative",
  },
  popoverClass: {
    marginTop: "12px",
    // border: "1px solid black",
    position: "relative",
    // zIndex: `${theme.zIndex.drawer} + 3 !important`,
    "&::before": {
      content: '""',
      position: "absolute",
      top: "27px",
      right: "175px",
      borderBottom: "10px solid #1B1F23",
      borderRight: "6px solid transparent",
      borderLeft: "6px solid transparent",
      borderTop: "13px solid transparent",
      zIndex: 10,
    },
  },
  notiRoot: {
    padding: 15,
  },
  notiDataRoot: {
    padding: "15px 0 !important",
  },
  dividerPopup: {
    margin: "13px 0 15px 0",
  },
  timeDiv: {
    paddingTop: 6,
  },
  viewAll: {
    textAlign: "center",
    color: "#757575",
    fontSize: theme.typography.body2.fontSize,
    cursor: "pointer",
    paddingTop: 10,
  },
  drawerRoot: {
    borderRight: "1px solid rgba(255, 255, 255, 0.12)",
  },
  permDrawerRoot: {
    textAlign: "center",
    fontSize: 10,
    marginTop: 30,
  },
  drawerListItem: {
    marginBottom: "25px",
    display: "block",
    cursor: "pointer",
  },
  tempDrawerRoot: {
    marginTop: 16,
    // padding: "0 10px",
  },
  tempDrawerList: {
    display: "flex",
    marginBottom: "25px",
  },
  tempIcon: {
    marginRight: 20,
  },
  profileBlock: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  profilePic: {
    marginLeft: 12,
  },
  nameBlock: {
    marginLeft: "12px",
    fontWeight: "bold",
  },
  largeAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  articlesBlock: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // whiteSpace: 'normal',
    // padding: `0 ${theme.spacing(2)}px`,
    // margin: '26px 0',
  },
  articlesItem: {
    cursor: "pointer",
    // paddingBottom: 5,
    // paddingRight: 8,
    padding: "16px 16px",
  },
  socialLinksBlock: {
    display: "flex",
    flexWrap: "wrap",
    margin: "26px 0",
    justifyContent: "center",
  },
  socialLinksItem: {
    padding: "0 8px",
    cursor: "pointer",
  },
  drawerLogoBlock: {
    // padding: theme.spacing(0, 2),
    // paddingTop: 'constant(safe-area-inset-top)',
    // paddingTop: 'env(safe-area-inset-top)',
    position: "sticky !important",
    paddingTop: theme.spacing(1.5),
    top: 0,
    // backgroundColor: theme.palette.background.dark,
    zIndex: 2,
    marginBottom: theme.spacing(1.5),
    // marginTop: "20px",
    // borderRight: (props) =>
    //   !props.isHome ? '1px solid rgba(255, 255, 255, 0.12)' : 'none',
  },
  drawerStyles: {
    borderRight: "none !important",
  },
  groupTitle: {
    padding: "0 14px",
    // color: '#92929D',
  },
  listItemIconRoot: {
    minWidth: "30px !important",
    "& div": {
      display: "flex",
      alignItems: "center",
    },
  },
  listItemGutters: {
    // paddingRight: 0,
    paddingLeft: "52px !important",
  },
  optionSelected: {
    // background:
    //   "linear-gradient(1.8deg, rgba(58, 105, 142, 0.01) -18.23%, rgba(27, 61, 88, 0.5) 98.47%)",
    // borderLeft: "3px solid #008DF4 !important",

    // backgroundColor: "#E6F7FF !important",
    color: theme.palette.primary.main,
    // borderRight: `3px solid ${theme.palette.primary.main}`,
  },
  transparentBorderLeft: {
    borderLeft: "3px solid transparent",
  },
  svgRoot: {
    width: "0.8em !important",
    height: "0.8em !important",
  },
  languangeChangeMobile: {
    padding: "0 14px",
    display: "flex",
  },
  cursor: {
    cursor: "pointer",
  },
  hoverEffect: {
    "&:hover": {
      // backgroundColor: "#263238"
    },
  },
  iosTopPadding: {
    // flexGrow: 1,
    "& header": {
      // paddingTop: 'constant(safe-area-inset-top)',
      // paddingTop: 'env(safe-area-inset-top)',
    },
  },
  iosToolbar: {
    minHeight: "45px !important",
  },
  weight600: {
    fontWeight: 600,
  },
  accList: {
    width: "100%",
    padding: "0px !important",
  },
  textThemeColor: {
    color: theme.palette.primary.main,
  },
  dayNightBlock: {
    padding: theme.spacing(2),
  },
  viewProfile: {
    cursor: "pointer",
    marginTop: theme.spacing(1),
  },
  profileBlock: {
    display: "flex",
    // alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(1.5, 2.5),
    // backgroundColor: "#F5F7FA",
    backgroundColor: theme.palette.custom.sideNavProfileBlock,
  },
  profileBlockCenturion: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.5, 2.5),
    background:
      "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)",
    cursor: "pointer",
  },
  profileBlockMars: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.5, 2.5),
    cursor: "pointer",
    backgroundImage: `url(${MarsBackground})`,
    backgroundSize: "cover",
  },
  activeTillTypo: {
    fontSize: 12,
    marginTop: theme.spacing(0.5),
  },
  acSummaryContent: {
    display: "flex",
    alignItems: "center",
  },
  acSummaryRoot: {
    padding: "0 20px !important",
    // backgroundColor:  "transparent !important",
    borderBottom: "none !important",
    color: theme.palette.text.secondary,
    fontSize: theme.typography.subtitle1.fontSize,
    fontFamily: theme.typography.subtitle1.fontFamily,
    paddingLeft: (props) => props.isSecondLevel && "56px !important",
  },
  iconRoot: {
    margin: "0",
  },
  marginLeftClass: {
    marginLeft: 32,
    borderLeft: `1px solid ${theme.palette.custom.dividerColor}`,
    width: "80%",
  },
  fixedSettingsBlock: {
    // position: "fixed",
    // bottom: 0,
    // width: drawerWidth,
    padding: "0 20px",
    zIndex: 10,
    backgroundColor: theme.palette.custom.sideNavBackground,
  },
  fixedDiv: {
    display: "flex",
    alignItems: "center",
    minHeight: 56,
    cursor: "pointer",
  },
  settingsBorderTop: {
    borderTop: `1px solid ${theme.palette.custom.dividerColor}`,
  },
  srollableDivSideNav: {
    // height: 700,
    // overflowY: "scroll",
    // overflowX: "hidden",
    // "&::-webkit-scrollbar": {
    //   width: 5,
    // },
    // "&::-webkit-scrollbar-track": {
    //   // background: "#f1f1f1"
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: theme.palette.custom.dividerColor,
    //   borderRadius: 20,
    // },
  },
  scrollableMain: {
    // height: "calc(100% - 168px)",
    // height: "calc(100% - 240px)",
    height: "calc(100% - 280px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100% - 72px)",
    },
  },
  svgImage: {
    marginRight: 8,
    // "& path":{
    //   stroke: "green"
    // }
  },
  accexpanded: {
    color: theme.palette.primary.main,
    backgroundColor: (props) => !props.isSecondLevel && "lightblue",
    fontWeight: 600,
    // padding: 0,
    "& .side-nav-svgmain": {
      "& path": {
        stroke: theme.palette.primary.main,
      },
      "& circle": {
        stroke: theme.palette.primary.main,
      },
      "& rect": {
        stroke: theme.palette.primary.main,
      },
      "& ellipse": {
        stroke: theme.palette.primary.main,
      },
    },
  },
  accExpandIcon: {
    padding: 0,
  },
  subtitle1Variant: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.subtitle1.fontFamily,
  },
  selectedSubtitle1Variant: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.primary.main,
    fontFamily: theme.typography.subtitle1.fontFamily,
  },
  selectedSvg: {
    "& .side-nav-svgmain": {
      "& path": {
        fill: theme.palette.primary.main,
      },
      "& circle": {
        stroke: theme.palette.primary.main,
      },
      "& rect": {
        stroke: theme.palette.primary.main,
      },
      "& ellipse": {
        stroke: theme.palette.primary.main,
      },
    },
  },
  borderRight: {
    borderRight: `3px solid ${theme.palette.primary.main}`,
  },
  toolbarMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  upgradeButtonBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(2, 0),
    fontSize: 12,
    fontWeight: 600,
  },
  drawerPaper: {
    overflowX: "hidden",
    zIndex: "1 !important",
  },
  singleOptions: {
    display: "flex",
    alignItems: "center",
    minHeight: 56,
    cursor: "pointer",
    padding: "0 20px",
    // zIndex: 10,
    // backgroundColor: theme.palette.custom.sideNavBackground,
  },
  notificationItem: {
    padding: theme.spacing(2),
    cursor: 'pointer',
    borderBottom: '1px solid #eee',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
  },
  activeNotification: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  notificationIcon: {
    color: theme.palette.primary.main,
    fontSize: '1.5rem'
  },
  notificationIconContainer: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.primary.main, 0.1)
  },
  notificationBadge: {
    '& .MuiBadge-dot': {
      backgroundColor: theme.palette.primary.main,
      transform: 'scale(1.2)'
    }
  },
  notificationHeader: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  notificationList: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    maxHeight: 400,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.divider,
      borderRadius: 20,
    }
  },
  notificationAction: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      opacity: 0.8
    }
  },
  notificationSecondaryAction: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      opacity: 0.8
    }
  },
  notificationDescription: {
    fontWeight: props => props.active ? 600 : 400,
    color: theme.palette.text.primary
  },
  notificationTime: {
    display: 'block',
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary
  },
  notificationLoader: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  '@keyframes fadeIn': {
    from: { 
      opacity: 0,
      transform: 'translateY(-10px)'
    },
    to: { 
      opacity: 1,
      transform: 'translateY(0)'
    }
  },
  container: {
    width: props => props.isPopup ? '360px' : '100%',
    backgroundColor: '#fff',
    borderRadius: 8,
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  },
  header: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  title: {
    fontSize: '1.1rem',
    fontWeight: 500,
  },
  markAllRead: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  dotIndicator: {
    width: 8,
    height: 8,
    marginTop: 6,
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    display: 'block',
  },
  notificationText: {
    fontSize: '0.95rem',
    color: theme.palette.text.primary,
    lineHeight: 1.4,
  },
  timeText: {
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
  chevronIcon: {
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
  },
  footer: {
    padding: theme.spacing(1.5),
    borderTop: '1px solid #eee',
    textAlign: 'center',
  },
  viewAll: {
    color: theme.palette.text.secondary,
    fontSize: '0.9rem',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}));

export { useStyles };
