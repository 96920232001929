import React from "react";
import Content from "../components/Content";

const Portfolio = () => {
  const content = {
    dayTitle: "Focus On Holy Grail Portfolio",
    heading: "You're nearing the pinnacle of your journey with Spiking.",
    intro:
      "Your dedication is paying off as you acquire advanced strategies to refine and optimize your investment approach.",
    youtubeVideoId: "ij4b-uOTd8A",
    about: {
      sectionTitle: "ABOUT Holy Grail Portfolios",
      description:
        "Today, we will focus on the Holy Grail Portfolios, a nuanced and targeted approach to equity investment inspired by the foundational principles of Ray Dalio and Warren Buffett.",
    },
    topicsIntro: "Your Spiking journey provides a deep dive into these key topics:",
    topics: [
      {
        title: "The Spiking Holy Grail",
        description:
          "Combines the foundational principles of Ray Dalio’s Holy Grail and Warren Buffett's investment approach to create a nuanced and targeted strategy for equity investment. By employing the Spiking Holy Grail, we eliminate 80% of risks effectively.",
      },
      {
        title: "Economic Machine Model",
        description:
          "Aligns the 16 stocks in the Holy Grail Portfolio with macroeconomic factors, categorized into Booming, Goldilocks, Stagflationary, and Recessionary environments.",
      },
      {
        title: "Warren Buffett’s Match",
        description:
          "Demonstrates that over 90% of Warren Buffett's stocks are concentrated into the 16 stocks of the Holy Grail Portfolio, highlighting the efficacy of this strategy.",
      },
      {
        title: "Holy Grail Portfolios",
        description:
          "Offers specialized portfolios for cryptos, options, and stocks, each designed to maximize returns within their respective markets.",
      },
    ],
    nextSteps: {
      title: "NEXT STEPS",
      steps: [
        {
          title: "Spiking Holy Grail",
          description:
            "This strategy diversifies with 11 top stocks across sectors and 5 buffer stocks, creating a balanced 16-stock portfolio.",
          actions: [
            {
              label: "Learn More",
              link: "https://spiking.com/holygrail",
              type: "primary",
            },
          ],
        },
        {
          title: "Inspired by Ray Dalio",
          description: "Explore key insights from Dalio's investing principles.",
          actions: [
            {
              label: "View Dalio's Principles",
              link: "https://spiking.com/lesson/spiking-wealth-masterclass/content/3676",
              type: "primary",
            },
          ],
        },
        {
          title: "Study Warren Buffett's Holy Grail Portfolio",
          description: "Explore the composition and strategies of Warren Buffett's Holy Grail Portfolio.",
          actions: [
            {
              label: "Learn More",
              link: "https://spiking.com/holygrail?bid=67782",
              type: "primary",
            },
          ],
        },
      ],
    },
  };

  return <Content content={content} />;
};

export default Portfolio;
