import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import clsx from "clsx";

import RocketIcon from "../../images/rocket.png";
import * as actions from "../../store/actions/index";
import isCordova from "../../helpers/cordova-check";
import { isAndroid, isIos } from "../../helpers/device-check";
import Layout from "../../features/Layout/container";
import { upgradeStaticData } from "./constants";
import UpgradeRestoreDialog from "./upgradeRestoreDialog";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

let productId = "spikingstockspro";

const useStyles = makeStyles((theme) => ({
  titleBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  subWrapper: {
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(3),
    minHeight: "85vh",
    // backgroundColor: "#040d14",
    // opacity: 0.7
    borderRadius: 10,
  },
  mainImageBlock: {
    height: 80,
    width: 80,
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
    },
  },
  weight600: {
    fontWeight: 600,
  },
  dividerMain: {
    backgroundColor: "rgba(158, 158, 158, 0.4)",
    margin: "20px 0",
  },
  contentTitle: {
    maxWidth: "76rem",
    margin: "0 auto",
  },
  gridItem: {
    padding: "10px 35px",
    display: "flex",
  },
  gridContent: {
    borderBottom: "0.5px solid rgba(158, 158, 158, 0.4)",
    textAlign: "center",
    paddingBottom: 21,
  },
  gridImageBlock: {
    width: 28,
    height: 28,
    margin: "0 auto",
  },
  buttonLabel: {
    display: "block",
  },
  buyButton: {
    // padding: "6px 100px",
    width: "20rem",
    [theme.breakpoints.down("xs")]: {
      width: "16rem",
    },
  },
  privacyBlock: {
    display: "flex",
    justifyContent: "center",
  },
  linkStyles: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  restorePurchase: {
    textTransform: "uppercase",
    textDecoration: "underline",
    cursor: "pointer",
  },
  haveAccessText: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      // textAlign: "left",
    },
  },
  cursor: {
    cursor: "pointer",
  },
  mainWrapper: {
    padding: theme.spacing(4.75),
    backgroundColor: theme.palette.background.dark,
    minHeight: "85vh",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  subTitleBlock: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  upgradeText: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

const UpgradePopup = (props) => {
  const classes = useStyles();
  const {
    acPostInAppPurchaseReceiptLogs,
    upgradeRestoreSuccess,
    history,
    acLoading,
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [upgradeData, setUpgradeData] = useState(null);
  const [productPrice, setProductPrice] = useState("");

  useEffect(() => {
    if (upgradeRestoreSuccess) {
      setShowDialog(true);
      setUpgradeData(upgradeRestoreSuccess);
    }
  }, [upgradeRestoreSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0)
    if (isCordova()) {
      window.inAppPurchase.getProducts([productId]).then((products) => {
        console.log(products);
        if (products.length > 0) {
          let price = products[0].price;
          setProductPrice(price);
        }
      });
    }
  }, []);

  const handleClose = () => {
    // setShowDialog(false)
    handleDialogActionClick();
  };

  const handleSubscribeUpgradeClick = () => {
    if (isCordova()) {
      window.inAppPurchase
        .getProducts([productId])
        .then((products) => {
          // $scope.products = products;
          console.log("from getProducts");
          console.log(products);
          // this.setState({ products: products })
          acLoading(true);
          window.inAppPurchase
            .subscribe(productId)
            .then((data) => {
              // alert("success");
              console.log("in app succes");
              console.log(JSON.stringify(data));
              let obj = {};
              if (isIos()) {
                obj.platform = "ios";
              } else if (isAndroid()) {
                obj.platform = "android";
              }
              obj.receipt = data;
              acPostInAppPurchaseReceiptLogs(obj);
            })
            .catch(function (err) {
              console.log(err);
              // alert("err" + err)
              // eslint-disable-next-line eqeqeq
              if (err.code == "-3") {
                let obj = {
                  success: false,
                  title: "Sorry, something went wrong",
                  message: "Please try again or contact us for support.",
                  button: "",
                  deeplink: "",
                };
                setUpgradeData(obj);
                setShowDialog(true);
                acLoading(false);
              }
            });
        })
        .catch(function (err) {
          console.log(err);
          acLoading(false);
        });
    }
  };

  const handleConsumeUpgradeClick = () => {
    if (isCordova()) {
      acLoading(true);
      window.inAppPurchase
        .buy(productId)
        .then((data) => {
          // alert("buy success -> setting purchase details")
          localStorage.setItem("purchaseData", JSON.stringify(data));
          return window.inAppPurchase.consume(
            data.productType,
            data.receipt,
            data.signature
          );
        })
        .then(() => {
          let purchaseDetails = localStorage.getItem("purchaseData");
          console.log(purchaseDetails);

          let obj = {};
          if (isIos()) {
            obj.platform = "ios";
          } else if (isAndroid()) {
            obj.platform = "android";
          }
          obj.receipt = purchaseDetails;
          acPostInAppPurchaseReceiptLogs(obj);
          localStorage.removeItem("purchaseData");
        })
        .catch(function (err) {
          console.log(err);
          acLoading(false);
        });
    }
  };

  const handleRestorePurchase = () => {
    // alert("restore")
    // setShowDialog(true)
    acLoading(true);
    window.inAppPurchase
      .restorePurchases()
      .then(function (data) {
        // alert("restore success")
        console.log(data);
        if (data.length > 0) {
          let obj1 = {};
          obj1.signature = data[0].signature;
          obj1.productId = data[0].productId;
          obj1.transactionId = data[0].transactionId;
          obj1.type = data[0].type;
          obj1.productType = data[0].productType;

          if (isAndroid()) {
            obj1.receipt = data[0].receipt;

            let obj = {};
            obj.platform = "android";
            obj.receipt = { ...obj1 };
            obj.action = "restore";

            acPostInAppPurchaseReceiptLogs(obj);
          }

          if (isIos()) {
            window.inAppPurchase
              .getReceipt()
              .then((receipt) => {
                console.log("from get receipt");
                console.log(receipt);
                // alert("receipt success")
                // alert(receipt)
                obj1.receipt = receipt;

                let obj = {};
                obj.platform = "ios";
                obj.receipt = { ...obj1 };
                obj.action = "restore";

                acPostInAppPurchaseReceiptLogs(obj);
              })
              .catch(function (err) {
                console.log(err);
                acLoading(false);
              });
          }

          console.log("obj1");
          console.log(obj1);
        }
        if (data.length <= 0) {
          let obj = {
            success: false,
            title: "No Purchase Restored",
            message: "Please upgrade your membership",
            button: "",
            deeplink: "",
          };
          setUpgradeData(obj);
          setShowDialog(true);
          acLoading(false);
        }
      })
      .catch(function (err) {
        console.log(err);
        let obj = {
          success: false,
          title: "Purchase Unsuccessful!",
          message:
            "Something went wrong. Please try again or contact us for support.",
          button: "",
          deeplink: "",
        };
        setUpgradeData(obj);
        setShowDialog(true);
        acLoading(false);
      });
  };

  const handleDialogActionClick = () => {
    if (upgradeData && upgradeData.success) {
      window.location.reload();
    } else if (upgradeData && !upgradeData.success) {
      setShowDialog(false);
    }
  };

  const handlePrivacyClick = () => {
    window.open("https://spiking.com/privacy", "_blank");
  };

  const handleTermsClick = () => {
    window.open("https://spiking.com/terms", "_blank");
  };

  const handleUpgradeContentClose = () => {
    history.push("/dailytrades/bought?tags=&start=&end=&bids=&cids=");
  };

  const handleUpgrade = () => {
    if (isCordova() && isAndroid()) handleConsumeUpgradeClick();
    else if (isCordova() && isIos()) handleSubscribeUpgradeClick();
    else if (!isCordova()) window.open("https://spiking.com/yes", "_blank");
  };

  return (
    // <Dialog fullScreen={false} open={true} onClose={handleClose}>
    <Layout>
      <Box className={classes.mainWrapper}>
        <div className={classes.subWrapper}>
          {/* close icon */}
          <div>
            <CloseIcon
              onClick={handleUpgradeContentClose}
              className={classes.cursor}
            />
          </div>
          {/* title + content block */}
          <div className={classes.contentTitle}>
            {/* title */}
            <div className={classes.titleBlock}>
              <Box className={classes.mainImageBlock}>
                <img src={RocketIcon} alt="Rocket" width="100%" height="100%" />
              </Box>
              <Box className={classes.subTitleBlock}>
                <Typography
                  variant="h6"
                  className={clsx(classes.weight600, classes.upgradeText)}
                >
                  Upgrade your membership
                </Typography>
                <Typography variant="body2" align="center">
                  You will have access to:
                </Typography>
              </Box>
            </div>
            <Divider className={classes.dividerMain} />

            {/* grids */}
            <Grid container>
              {upgradeStaticData.map((item, index) => (
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  className={classes.gridItem}
                >
                  <div className={classes.gridContent}>
                    <div className={classes.gridImageBlock}>
                      <img
                        src={item.image}
                        width="100%"
                        height="100%"
                        alt="lockimage"
                      />
                    </div>
                    <Box my={1}>
                      <Typography variant="h5">{item.title}</Typography>
                    </Box>
                    <Typography variant="caption">{item.desc}</Typography>
                  </div>
                </Grid>
              ))}
            </Grid>

            {/* {isIos() && (
              <Box mt={4.75} textAlign="center">
                <Typography color="textSecondary" variant="caption">
                  Your subscription will be charged to your iTunes account and
                  will automatically renew through iTunes unless auto-renew is
                  turned off at least 24-hours before the end of your current
                  period. You can manage your subscription through your iTunes
                  account. Any unused portion of a free trial period, if
                  offered, will be forfeited when you purchase this
                  subscription. For further information, go to
                  https://support.apple.com/en-us/HT202039
                </Typography>
              </Box>
            )} */}

            {/* button */}
            <Box textAlign="center" mt={4} mb={2.5}>
              <Button
                color="primary"
                variant="contained"
                classes={{ label: classes.buttonLabel }}
                className={classes.buyButton}
                onClick={handleUpgrade}
              >
                <Typography variant="body2" className={classes.weight600}>
                  Upgrade Now
                </Typography>
                {isCordova() && (
                  <Typography variant="caption">
                    {productPrice} for 14 days access
                  </Typography>
                )}
              </Button>
            </Box>

            {isCordova() && (
              <Box textAlign="center" mt={2} mb={1.5}>
                <Typography
                  variant="caption"
                  color="primary"
                  className={classes.restorePurchase}
                  onClick={handleRestorePurchase}
                >
                  Restore Purchases
                </Typography>
              </Box>
            )}

            <div className={classes.privacyBlock}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.linkStyles}
                style={{ paddingRight: 32 }}
                onClick={handlePrivacyClick}
              >
                Privacy Policy
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.linkStyles}
                color="textSecondary"
                onClick={handleTermsClick}
              >
                Terms & Conditions
              </Typography>
            </div>
          </div>
        </div>
      </Box>
      {/* dialog */}
      <UpgradeRestoreDialog
        showDialog={showDialog}
        handleClose={handleClose}
        upgradeData={upgradeData}
        handleDialogActionClick={handleDialogActionClick}
      />
    </Layout>
    // </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    upgradeRestoreSuccess: state.login.upgradeRestoreSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acPostInAppPurchaseReceiptLogs: (obj) =>
      dispatch(actions.postInAppPurchaseReceiptLogs(obj)),
    acLoading: (data) => dispatch(actions.loading(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpgradePopup));
