import React, { useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

import isToken from "../../../helpers/token-check";
import * as actions from "../../../store/actions/index";
import localInfo from "../../../data/locale-info.json";
import LazyLoader from "../../../components/LazyLoader";

const cookies = new Cookies();

const PublicRoute = (props) => {
  const {
    path,
    component,
    exact,
    key,
    acGetUserDetails,
    acChangeLanguage,
    location,
  } = props;

  useEffect(() => {
    if (isToken()) acGetUserDetails();
    if (
      !isToken() &&
      !location.pathname.includes("login") &&
      !location.pathname.includes("create-account") &&
      !location.pathname.includes("email-login") &&
      !location.pathname.includes("forgot-password") &&
      !location.pathname.includes("reset-password") &&
      !location.pathname.includes("race-to-100")
    ) {
      let lang = cookies.get("googtrans");
      let localedata = "";
      if (lang) {
        for (const value of localInfo) {
          if (value.googleTrans === lang) {
            localedata = value;
          }
        }
      }
      if (localedata) acChangeLanguage(localedata);
    }
  }, []);

  return (
    <React.Suspense fallback={<LazyLoader />}>
      <Route path={path} component={component} exact={exact} key={key} />
    </React.Suspense>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    acGetUserDetails: () => dispatch(actions.getUserDetails()),
    acChangeLanguage: (localedata) =>
      dispatch(actions.changeLanguage(localedata)),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(PublicRoute));
