import Typography from "@material-ui/core/Typography";
import downloadjs from "downloadjs";
import { createEvents } from "ics";
import { useState } from "react";
import { EventViewIcon, MonthViewIcon } from "../../components/Icons";
import "../Courses/styles.css";
import CalendarTable from "./components/CalendarTable";
import DetailsMobile from "./components/DetailsMobile";
import EventsView from "./components/EventsView";

function EventCalendarContainer(props) {
  const [view, setView] = useState("month");
  const [rawData, setRawData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const handleAddToCalendar = () => {
    let events = [];

    rawData.forEach((element) => {
      let s = new Date(element?.start_time);
      let e = new Date(element?.end_time);
      events.push({
        title: element.title,
        start: [
          s.getUTCFullYear(),
          s.getUTCMonth() + 1,
          s.getUTCDate(),
          s.getHours(),
          s.getMinutes(),
        ],
        end: [
          e.getUTCFullYear(),
          e.getUTCMonth() + 1,
          e.getUTCDate(),
          e.getHours(),
          e.getMinutes(),
        ],
      });
    });

    createEvents(events, (error, value) => {
      const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
      downloadjs(blob, `spiking-calendar-${selectedMonth}.ics`);
    });
  };

  return (
    <div className="h-full w-full bg-neutral-50 dark:bg-dark-neutral-25 dark:text-dark-neutral-800 text-neutral-800 pt-5 duration-200 px-5">
      <div className="2xl:max-w-[1250px] mx-auto bg-neutral-25 dark:bg-dark-neutral-50 border border-neutral-300 dark:border-dark-neutral-300 rounded-2xl p-5">
        <div className="duration-300 relative mb-5 text-center">
          <Typography
            component="span"
            className="cursor-pointer text-transparent !text-[18px] px-3 leading-[24px] !font-semibold text-center background-gradient-running-text bg-clip-text"
            onClick={() =>
              window.open(
                "https://spiking.ai/masterclass?affiliate=SpikingCalendar"
              )
            }
          >
            <div className="h-px"></div>
          </Typography>
          <span className="flex items-center gap-x-5 mb-5 md:absolute top-[30px]">
            <div className="flex items-center gap-x-1">
              <MonthViewIcon isSelected={view === "month"} />
              <Typography
                variant="subtitl1"
                className="cursor-pointer !font-medium"
                onClick={() => setView("month")}
                color={view === "month" ? "primary" : "textPrimary"}
              >
                Month View
              </Typography>
            </div>
            <div className="flex items-center gap-x-1">
              <EventViewIcon isSelected={view === "event"} />
              <Typography
                variant="subtitl1"
                className="cursor-pointer !font-medium"
                onClick={() => setView("event")}
                color={view === "event" ? "primary" : "textPrimary"}
              >
                Event View
              </Typography>
            </div>
          </span>
        </div>

        {view === "month" && (
          <div className="event-calendar mt-1">
            <CalendarTable
              setSelectedMonth={setSelectedMonth}
              handleAddToCalendar={handleAddToCalendar}
              setRawData={setRawData}
              rawData={rawData}
            />
          </div>
        )}
        {view === "event" && (
          <div>
            <EventsView
              handleAddToCalendar={handleAddToCalendar}
              setRawData={setRawData}
              rawData={rawData}
            />
          </div>
        )}
        <Typography
          color="textSecondary"
          className="!text-xs !mt-2"
          align="right"
        >
          *All dates and times displayed are in local time.
        </Typography>
      </div>
    </div>
  );
}

export default EventCalendarContainer;
