import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogueRoot: {
    zIndex: `${theme.zIndex.drawer} + 5`,
  },
  loaderRoot: {
    overflow: "hidden",
    backgroundColor: "transparent !important",
    boxShadow: "none !important"
  },
  iconBlock: {
    width: "23px",
    height: "23px",
    "& img": {
      width: "100%",
      height: "100%",
    },
    left: "34%",
    bottom: "35%",
    position: "absolute",
  },
  loader: {
    borderRadius: "50%",
    width: "5em",
    height: "5em",
    position: "relative",
    margin: "10px",
    fontSize: "10px",
    textIndent: "-9999em",
    borderTop: "0.2em solid rgba(72,119,229, 0.2)",
    borderRight: "0.2em solid rgba(72,119,229, 0.2)",
    borderBottom: "0.2em solid rgba(72,119,229, 0.2)",
    borderLeft: "0.2em solid #4877e5",
    "-webkit-transform": "translateZ(0)",
    "-ms-transform": "translateZ(0)",
    transform: "translateZ(0)",
    "-webkit-animation": "$load8 0.6s infinite linear",
    animation: "$load8 0.6s infinite linear",
    "&::after": {
      borderRadius: "50%",
      width: "5em",
      height: "5em",
      position: "relative",
    },
  },
  "@-webkit-keyframes load8": {
    "0%": {
      "-webkit-transform": "rotate(0deg)",
      transform: "rotate(0deg)",
    },
    "100%": {
      "-webkit-transform": "rotate(360deg)",
      transform: "rotate(360deg)",
    },
  },
  "@keyframes load8": {
    "0%": {
      "-webkit-transform": "rotate(0deg)",
      transform: "rotate(0deg)",
    },
    "100%": {
      "-webkit-transform": "rotate(360deg)",
      transform: "rotate(360deg)",
    },
  },
}));

export { useStyles };
