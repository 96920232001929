import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Rocket from "../../images/upgradeRocket.png"
import ErrorIcon from "../../images/upgradeError.png"

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  dialogMain: {
    width: 328,
    height: 445,
    padding: theme.spacing(3),
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 0,
    borderRadius: 10,
    backgroundImage:
      "linear-gradient(to top, rgba(80, 166, 234, 0.39), #040d14), linear-gradient(to bottom, #1b1f23, #1b1f23)",
    textAlign: "center",
    // [theme.breakpoints.down("xs")]: {
    //   minWidth: 300,
    //   minHeight: 200,
    //   padding: 10,
    // },
  },
  dialogClose: {
    textAlign: "left",
    color: "rgba(255, 255, 255, 0.87)",
    marginBottom: theme.spacing(2),
  },
  dialogImage: {
    width: 253,
    height: 182,
    marginLeft: "-21px",
  },
  dialogErrorImage: {
    width: 70,
    height: 70,
    margin: "0 auto",
  },
  weight600: {
    fontWeight: 600,
  },
  dialogueButton: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
}))

const UpgradeRestoreDialog = (props) => {
  const classes = useStyles()
  const {
    showDialog,
    handleClose,
    upgradeData,
    handleDialogActionClick,
  } = props

  const handleContactSupport = () => {
    window.open("https://love.spiking.com/hc/en-us/requests/new", "_blank");
  }

  return (
    <Dialog open={showDialog} onClose={handleClose}>
      <div className={classes.dialogMain}>
        <div className={classes.dialogClose}>
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>

        {/* main image */}
        {upgradeData && upgradeData.success ? (
          <div className={classes.dialogImage}>
            <img src={Rocket} width="100%" height="100%" />
          </div>
        ) : (
          <div className={classes.dialogErrorImage}>
            <img src={ErrorIcon} width="100%" height="100%" />
          </div>
        )}
        {/* title */}
        <Box mb={1.5} mt={2}>
          <Typography variant="h6" className={classes.weight600}>
            {upgradeData && upgradeData.title}
          </Typography>
        </Box>
        {/* desc */}
        <Typography variant="body2">
          {upgradeData && upgradeData.message}
        </Typography>
        {/* button */}
        <Button
          variant="contained"
          color="primary"
          className={classes.dialogueButton}
          onClick={handleDialogActionClick}
        >
          {upgradeData && upgradeData.success ? "Back to App" : "Try Again"}
        </Button>

        {upgradeData && !upgradeData.success && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.dialogueButton}
            onClick={handleContactSupport}
          >
            Contact Support
          </Button>
        )}
      </div>
    </Dialog>
  )
}

UpgradeRestoreDialog.propTypes = {
  showDialog: PropTypes.bool,
  handleClose: PropTypes.func,
  upgradeData: PropTypes.object,
  handleDialogActionClick: PropTypes.func,
}

export default UpgradeRestoreDialog
