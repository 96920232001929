import React, { useEffect, Component } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";

const emails = ["username@gmail.com", "user02@gmail.com"];
const styles = {
  paperwidth: {
    maxWidth: "100%",
  },
};

class SimpleDialog extends Component {
  componentDidUpdate() {
    window.setTimeout(() => {
      this.createScript();
    }, 5000);
  }

  createScript = () => {
    if (document.getElementById("tutorialDemo")) {
      var script = document.createElement("script");
      script.textContent =
        'function nextSlide(num) {document.getElementById("hiddenButton").click();document.getElementById("main-image").src="https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/dailybread/dailybread_image_2.PNG";document.getElementById("next-button").style.display = "none";document.getElementById("next-button-image").style.display = "none";play(2);}function mouse() {var audio = new Audio("https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/mouse.mp3");audio.play();}function play(num) {mouse();if(num == 0){document.getElementById("onload-button").style.display = "none";document.getElementById("main-video").style.display = "block";var vid = document.getElementById("intro-video");vid.play();vid.addEventListener("ended",vidEnded,false);function vidEnded(e) {play(1);}}else if(num == 1){document.getElementById("main-video").style.display = "none";document.getElementById("onload-button").style.display = "none";document.getElementById("main-image").style.display = "block";var audio = new Audio("https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/dailybread/dailybread_audio_1.mp3");audio.play();audio.addEventListener("ended",audioEnded,false);function audioEnded(e) {document.getElementById("next-button").style.display = "block";document.getElementById("next-button-image").style.display = "block";}}else if(num == 2){var audio = new Audio("https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/dailybread/dailybread_audio_2.mp3");audio.play();audio.addEventListener("ended",audioEnded,false);function audioEnded(e) {document.getElementById("next-button").style.left = "45px";document.getElementById("next-button").style.top = "565px";document.getElementById("next-button").style.width = "1800px";document.getElementById("next-button").style.height = "70px";document.getElementById("next-button").style.display = "block";}}}';
      document.body.appendChild(script);

      let div = document.createElement("div");
      div.innerHTML =
        '<img onclick="play(0);" id="onload-button" style="cursor:pointer;" src="https://storage.googleapis.com/academy-prod/courses/course05/unit3/lesson2/html5/assets/htmlimages/Play_icon.png"><div id="main-video" style="display:none; width:1123px; height:794px; margin:0 auto; position:relative;"><video id ="intro-video"><source src="https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/dailybread/tutorial_intro.webm" type="video/webm"></div><div style="margin:0 auto; position:relative;"><img id="main-image" style="display:none;" src="https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/dailybread/dailybread_image_1.PNG"><div id="next-button" onclick="nextSlide(2)" style="display:none;border: 2px solid red;position: absolute;left: 95px;top: 165px;cursor: pointer;font-size: 25px;max-width: 100%;height: 50px;width: 180px;font-weight: bold;color: transparent;">CLICK HERE</div><img id="next-button-image" style="display:none;position: absolute;left: 85px;top: 104px;" src="https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/click_here.png"></div>';
      // document.body.appendChild(div);
      document.getElementById("tutorialDemo").appendChild(div);
    }
  };

  render() {
    const { open, handleClose } = this.props;
    const { classes } = this.props;
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        // style={{maxWidth: 0}}
        classes={{ paperWidthSm: classes.paperwidth }}
        fullScreen
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Demo tutorial Loading</div>
          <div onClick={handleClose}>Close Video</div>
        </div>
        <div id="tutorialDemo" style={{ width: "75rem", height: "80vh" }}></div>
      </Dialog>
    );
  }
}
// (props) {
//   const classes = useStyles();
//   const { onClose, selectedValue, open } = props;

//   const handleClose = () => {
//     onClose(selectedValue);
//   };

//   const handleListItemClick = (value) => {
//     onClose(value);
//   };

//   useEffect(() => {
//     if (document.getElementById("tutorialDemo")) {
//       alert("called");
//       var script = document.createElement("script");
//       script.textContent =
//         'function nextSlide(num) {document.getElementById("main-image").src="https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/dailybread/dailybread_image_2.PNG";document.getElementById("next-button").style.display = "none";document.getElementById("next-button-image").style.display = "none";play(2);}function mouse() {var audio = new Audio("https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/mouse.mp3");audio.play();}function play(num) {mouse();if(num == 0){document.getElementById("onload-button").style.display = "none";document.getElementById("main-video").style.display = "block";var vid = document.getElementById("intro-video");vid.play();vid.addEventListener("ended",vidEnded,false);function vidEnded(e) {play(1);}}else if(num == 1){document.getElementById("main-video").style.display = "none";document.getElementById("onload-button").style.display = "none";document.getElementById("main-image").style.display = "block";var audio = new Audio("https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/dailybread/dailybread_audio_1.mp3");audio.play();audio.addEventListener("ended",audioEnded,false);function audioEnded(e) {document.getElementById("next-button").style.display = "block";document.getElementById("next-button-image").style.display = "block";}}else if(num == 2){var audio = new Audio("https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/dailybread/dailybread_audio_2.mp3");audio.play();audio.addEventListener("ended",audioEnded,false);function audioEnded(e) {document.getElementById("next-button").style.left = "45px";document.getElementById("next-button").style.top = "565px";document.getElementById("next-button").style.width = "1800px";document.getElementById("next-button").style.height = "70px";document.getElementById("next-button").style.display = "block";}}}';
//       document.body.appendChild(script);

//       let div = document.createElement("div");
//       div.innerHTML =
//         '<img onclick="play(0);" id="onload-button" style="cursor:pointer;" src="https://storage.googleapis.com/academy-prod/courses/course05/unit3/lesson2/html5/assets/htmlimages/Play_icon.png"><div id="main-video" style="display:none; width:1123px; height:794px; margin:0 auto; position:relative;"><video id ="intro-video"><source src="https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/dailybread/tutorial_intro.webm" type="video/webm"></div><div style="margin:0 auto; position:relative;"><img id="main-image" style="display:none;" src="https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/dailybread/dailybread_image_1.PNG"><div id="next-button" onclick="nextSlide(2)" style="display:none;border: 2px solid red;position: absolute;left: 95px;top: 165px;cursor: pointer;font-size: 25px;max-width: 100%;height: 50px;width: 180px;font-weight: bold;color: transparent;">CLICK HERE</div><img id="next-button-image" style="display:none;position: absolute;left: 85px;top: 104px;" src="https://spiking-img.s3.ap-southeast-1.amazonaws.com/tutorial/click_here.png"></div>';
//       // document.body.appendChild(div);
//       if (document.getElementById("tutorialDemo"))
//         document.getElementById("tutorialDemo").appendChild(div);
//     }
//   }, [open]);

//   return (

//   );
// }

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
};

export default withStyles(styles)(SimpleDialog);
