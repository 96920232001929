import isCordova from "../../../helpers/cordova-check";
import { isAndroid, isIos } from "../../../helpers/device-check";

//max time to wait for rating dialog to display on iOS
// var MAX_DIALOG_WAIT_TIME_IOS = 5 * 1000;

//max time to wait for rating dialog to display on Android and be submitted by user
// var MAX_DIALOG_WAIT_TIME_ANDROID = 60 * 1000;

// var ratingTimerId;

// function ratingDialogNotShown() {
//   var msg;
//   if (isAndroid()) {
//     msg =
//       "Rating dialog outcome not received (after " +
//       MAX_DIALOG_WAIT_TIME_ANDROID +
//       "ms)";
//   } else if (isIos()) {
//     msg =
//       "Rating dialog was not shown (after " + MAX_DIALOG_WAIT_TIME_IOS + "ms)";
//   }
//   console.warn(msg);
// }

const Rating = () => {
//   if (isAndroid()) {
//     ratingTimerId = setTimeout(
//       ratingDialogNotShown,
//       MAX_DIALOG_WAIT_TIME_ANDROID
//     );
//   }

  window.LaunchReview.rating(
    function (status) {
      if (status === "requested") {
        if (isAndroid()) {
          console.log("Displayed rating dialog");
        //   clearTimeout(ratingTimerId);
        } else if (isIos()) {
          console.log("Requested rating dialog");
        //   ratingTimerId = setTimeout(
        //     ratingDialogNotShown,
        //     MAX_DIALOG_WAIT_TIME_IOS
        //   );
        }
      } else if (status === "shown") {
        console.log("Rating dialog displayed");
        // clearTimeout(ratingTimerId);
      } else if (status === "dismissed") {
        console.log("Rating dialog dismissed");
        // clearTimeout(ratingTimerId);
      }
    },
    function (err) {
      console.error("Error launching rating dialog: " + err);
    //   clearTimeout(ratingTimerId);
    }
  );
};

export default Rating
