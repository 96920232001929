import React, { Component } from "react"
import { connect } from "react-redux"
import { Route, Redirect, withRouter } from "react-router-dom"
import PropTypes from "prop-types"

import * as actions from "../../store/actions/index"

class RedirectComp extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // alert(this.props.location.pathname);
      // alert(prevProps.location.pathname);
      if (
        prevProps.location.pathname === "/login" &&
        this.props.location.pathname !== "/login"
      ) {
        // alert("redirect comp")
        this.props.acRedirectUser({ isRedirect: false })
        this.props.acIsLoggedIn(false)
        // this.props.acAutoSigninUser(false);
      }
    }
  }
  render() {
    // debugger;
    const { redirectUser } = this.props
    if (redirectUser) {
      return <Redirect to="/login" />
      // window.location.href = "/login"
    }
    return <React.Fragment></React.Fragment>
  }
}

RedirectComp.propTypes = {
  redirectUser: PropTypes.bool,
  acLoading: PropTypes.func,
  acRedirectUser: PropTypes.func,
  acIsLoggedIn: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    redirectUser: state.login.redirectUser,
    acAutoSigninUser: PropTypes.func,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    acRedirectUser: (data) => dispatch(actions.redirectUser(data)),
    acLoading: (data) => dispatch(actions.loading(data)),
    acIsLoggedIn: (data) => dispatch(actions.isLoggedIn(data)),
    acAutoSigninUser: (data) => dispatch(actions.autoSigninUser(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RedirectComp))
