import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
// import Cookies from "universal-cookie"
import queryString from "query-string";
import LazyLoader from "../../../components/LazyLoader";

// import isToken from "../../helpers/token-check"

// const cookies = new Cookies()
let checkoutFacebookPixelOnPageLoad = false;
const isWebDev = process.env.REACT_APP_IS_WEB_DEV;

class AffiliateRoute extends Component {
  componentDidMount() {
    if (this.props.location.search !== "") {
      this.handleMountUpdate();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleMountUpdate();
    }
  }

  handleMountUpdate = () => {
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.affiliate_id) {
      // if (!cookies.get("affiliate_id")) {
      var date = new Date();
      var addDays = 30;
      date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);

      let obj = {
        affiliate_id: parsed.affiliate_id,
        expiresIn: date,
      };
      window.localStorage.setItem("affiliate_id", JSON.stringify(obj));

      // let obj2 = localStorage.getItem("affiliate_id")
      // let dataa = JSON.parse(obj2)

      // console.log("retirveddd")
      // console.log(dataa)

      // cookies.set("affiliate_id", parsed.affiliate_id, {
      //   path: "/",
      //   expires: new Date(date),
      //   domain: ".localhost",
      //   httpOnly: false
      // })
      // }
    }
    if (
      isWebDev === "false" &&
      this.props.location.pathname.includes("checkout") &&
      !checkoutFacebookPixelOnPageLoad
    ) {
      this.setCheckoutFacebookPixel();
      checkoutFacebookPixelOnPageLoad = true;
    }
  };

  setCheckoutFacebookPixel = () => {
    const script = document.createElement("script");
    script.innerHTML = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '698264110625800');
    fbq('track', 'PageView');
    fbq('track', 'InitiateCheckout');`;
    document.body.appendChild(script);

    const noscript = document.createElement("noscript");
    noscript.innerHTML = `<img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=698264110625800&ev=PageView&noscript=1"/>`;
    document.body.appendChild(noscript);
  };

  render() {
    const { path, component, exact, location } = this.props;
    // alert(path)
    return (
      <React.Suspense fallback={<LazyLoader />}>
        <Route
          path={this.props.path}
          component={this.props.component}
          exact={this.props.exact}
        />
      </React.Suspense>
    );
  }
}

export default withRouter(AffiliateRoute);
