import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import CryptoIcon from "../../../../../images/bitcoin.png";
import OptionsIcon from "../../../../../images/options-icon.png";
import StocksIcon from "../../../../../images/stocks-icon.png";
import CourseIcon from "../../../../../images/course-icon.png";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    "&:hover": {
      opacity: 0.8,
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  weight600: {
    fontWeight: 600,
  },
  dropdownPaper: {
    minWidth: 140,
  },
  menuIconButton: {
    cursor: "pointer",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

const DashboardSelect = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectValue, setSelectedValue] = useState("Stocks AI");
  const [selectImage, setSelectedImage] = useState(StocksIcon);
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const { handleDashboardSelect, location } = props;

  useEffect(() => {
    if (location.pathname.includes("/options")) {
      setSelectedValue("Options AI");
      setSelectedImage(OptionsIcon);
    }
    if (location.pathname.includes("/cryptos")) {
      setSelectedValue("Cryptos AI");
      setSelectedImage(OptionsIcon);
    }
  }, [location]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e, value, image) => {
    if (value.includes("AI")) {
      setSelectedValue(value);
      setSelectedImage(image);
    }
    handleDashboardSelect(value);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={classes.menuButton}
      >
        {selectValue}
      </Button>
      <img
        src={selectImage}
        alt="Cryptos icon"
        width="25px"
        height="25px"
        onClick={handleClick}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={classes.menuIconButton}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        classes={{ paper: classes.dropdownPaper }}
      >
        <MenuItem onClick={(e) => handleClose(e, "Cryptos AI", CryptoIcon)}>
          <img
            src={CryptoIcon}
            alt="Cryptos icon"
            width="25px"
            height="25px"
            style={{ marginRight: 2 }}
          />
          <Typography
            variant="caption"
            color={selectValue === "Cryptos AI" ? "primary" : "textSecondary"}
            className={classes.weight600}
          >
            <span className="!text-red-500 !font-bold">C</span>ryptos AI
          </Typography>
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, "Options AI", OptionsIcon)}>
          <img
            src={OptionsIcon}
            alt="Options icon"
            width="16px"
            height="16px"
            style={{ marginRight: 8 }}
          />
          <Typography
            variant="caption"
            color={selectValue === "Options AI" ? "primary" : "textSecondary"}
            className={classes.weight600}
          >
            <span className="!text-red-500 !font-bold">O</span>ptions AI
          </Typography>
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, "Stocks AI", StocksIcon)}>
          <img
            src={StocksIcon}
            alt="Stocks icon"
            width="20px"
            height="20px"
            style={{ marginRight: 6 }}
          />
          <Typography
            variant="caption"
            color={selectValue === "Stocks AI" ? "primary" : "textSecondary"}
            className={classes.weight600}
          >
            <span className="!text-red-500 !font-bold">S</span>tocks AI
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setAnchorEl(null);
            window.open("https://spiking.com/courses", "_blank");
          }}
        >
          <img
            src={CourseIcon}
            alt="Yes icon"
            width="16px"
            height="16px"
            style={{ marginRight: 10 }}
          />
          <Typography
            variant="caption"
            color={selectValue === "Cryptos AI" ? "primary" : "textSecondary"}
            className={classes.weight600}
          >
            <span className="!text-red-500 !font-bold">Y</span>es!
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withRouter(DashboardSelect);
