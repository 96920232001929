import React from "react";
import { injectIntl } from "react-intl";
import clsx from "clsx";
import { withRouter } from "react-router-dom";

import NotificationAlerts from "./components/NotificationAlerts/NotificationAlerts";
import LanguageChange from "./components/LanguageChange/";
import ReferralPopup from "../../ReferralPopup/ReferralPopup";
import DashboardSelect from "./components/DashboardSelect/DashboardSelect";
import Logo from "../../../images/logo-blue.png";

import ContactUsPopup from "./components/ContactUsPopup";

// import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Toolbar from "@material-ui/core/Toolbar";
import {
  isAndroid,
  isIos,
  isIosBrowser,
  isAndroidBrowser,
} from "../../../helpers/device-check";
import ProfileDropdown from "./components/ProfileDropdown";
import UpgradeButton from "./components/UpgradeButton";

const drawerWidth = 295;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.dark,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.05)",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  navbarLogo: {
    height: 36,
    [theme.breakpoints.down("xs")]: {
      height: 25,
    },
  },
  searchField: {
    height: 40,
    width: "100%",
    backgroundColor: theme.palette.background.charcoal,
    padding: "6px 18px",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      border: `0.1px solid #404040`,
    },
  },
  inputBase: {
    padding: theme.spacing(0.5, 0.75),
    flex: 1,
  },
  icon: {
    color: theme.palette.custom.lightGrey,
  },
  disabled: {
    width: "100%",
  },
  navbarRoot: {
    // flexGrow: 1,
    "& header": {
      // paddingTop: "constant(safe-area-inset-top)",
      paddingTop: "env(safe-area-inset-top)",
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  dayLightIcon: {
    marginRight: 16,
    color: theme.palette.custom.lightGrey,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginRight: 14,
    },
  },
  searchMargin: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginRight: 14,
    },
  },
  referalButtons: {
    borderRadius: 30,
    lineHeight: "16px",
    fontWeight: 600,
    // padding: "6px 28px",
    marginRight: 32,
    fontSize: theme.typography.caption.fontSize,
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
    color: theme.palette.text.secondary,
  },
  community: {
    borderRadius: 40,
    marginRight: 25,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 600,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
    color: theme.palette.text.secondary,
  },
  communityIcon: {
    width: 32,
    height: 32,
    color: theme.palette.custom.lightGrey,
    marginRight: 20,
  },
  moneyIcon: {
    color: theme.palette.custom.lightGrey,
    marginRight: 20,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginRight: 14,
    },
  },
  supText: {
    fontSize: 10,
    color: theme.palette.primary.main,
  },
  dashboardSelectDiv: {
    marginRight: 14,
  },
  dashboardSelectDivMob: {
    marginRight: 20,
  },
  communityButton: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginRight: 20,
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

const NavBar = (props) => {
  const {
    isSideDrawerOpen,
    setSideDrawerOpen,
    isDrawerPermanent,
    handleSearchClick,
    intl,
    notificationProps,
    languageProps,
    isLightMode,
    handleLightModeClick,
    handleCommunityClick,
    handleNavigateReferral,
    handleReferalClick,
    handleDashboardSelect,
    user,
    history,
  } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const hamburgerIconClick = () => {
    if (isAndroid() || isIos() || isAndroidBrowser() || isIosBrowser())
      setSideDrawerOpen();
    else return;
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]:
          isSideDrawerOpen && isDrawerPermanent && !isMobile,
      })}
      //  className={classes.appBar}
    >
      {/* <Banner /> */}
      <Toolbar>
        <Grid container alignItems="center">
          {isDrawerPermanent && isSideDrawerOpen && !isMobile ? (
            ""
          ) : (
            <Grid
              item
              lg={2}
              md={3}
              sm={4}
              xs={4}
              container
              alignItems="center"
            >
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={hamburgerIconClick}
                onMouseEnter={setSideDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              <Box
                ml={{ xs: 0, md: 4.5 }}
                display="flex"
                alignItems="center"
                onClick={() => history.push("/spiking-up-down")}
              >
                <img
                  className={classes.navbarLogo}
                  style={{ cursor: "pointer" }}
                  src={Logo}
                  alt="Spiking"
                />
              </Box>
            </Grid>
          )}
          {/* Search */}
          <Hidden smDown>
            <Grid item md={4} lg={4} sm={4} xs={4}>
              <Box className={classes.searchField}>
                <InputBase
                  onClick={handleSearchClick}
                  className={classes.inputBase}
                  classes={{ disabled: classes.disabled }}
                  placeholder={intl.formatMessage({
                    id: "filter.searchCompanyOrInvestor",
                  })}
                  inputProps={{
                    readOnly: true,
                  }}
                  // disabled
                />
                <SearchIcon className={classes.icon} />
              </Box>
            </Grid>
          </Hidden>
          <Hidden sm>
            <div className={classes.flexGrow}></div>
          </Hidden>
          {/* Notification, Stock Market, Language, day/night */}
          <Grid
            item
            xs={8}
            sm={6}
            md={5}
            lg={6}
            container
            alignItems="center"
            justify="flex-end"
          >
            <Hidden mdUp>
              <Box
                className={classes.searchMargin}
                display="flex"
                alignItems="center"
              >
                <SearchIcon
                  className={classes.icon}
                  onClick={handleSearchClick}
                />
              </Box>
            </Hidden>

            {/* Upgrade Button */}
            <Hidden mdDown>
              <div className="mr-2">
                <UpgradeButton />
              </div>
            </Hidden>

            {/* Dashboard Select */}
            <Hidden mdDown>
              <div className={classes.dashboardSelectDiv}>
                <DashboardSelect
                  handleDashboardSelect={handleDashboardSelect}
                />
              </div>
              {/* community */}
              {/* <div
                title="Community"
                className={classes.community}
                onClick={handleCommunityClick}
              >
                <FormattedMessage id="nav.community" />
              </div> */}
              {/* <Button
                id="basic-button"
                aria-haspopup="true"
                onClick={handleCommunityClick}
                className={classes.communityButton}
              >
                <FormattedMessage id="nav.community" />
              </Button> */}
            </Hidden>
            {/* referral */}

            {/* Dashboard Select Mobile */}
            <Hidden lgDown>
              <div className={classes.dashboardSelectDivMob}>
                <DashboardSelect
                  handleDashboardSelect={handleDashboardSelect}
                />
              </div>
            </Hidden>
            {/* day night icon */}

            <ContactUsPopup />
            <>
              {isLightMode && (
                <Brightness4Icon
                  titleAccess="Change Dark mode"
                  className={clsx(classes.dayLightIcon)}
                  onClick={() => handleLightModeClick(false)}
                />
              )}
              {!isLightMode && (
                <Brightness7Icon
                  titleAccess="Change light mode"
                  className={clsx(classes.dayLightIcon)}
                  onClick={() => handleLightModeClick(true)}
                />
              )}
            </>
            <NotificationAlerts {...notificationProps} />
            <ReferralPopup user={user} />
            <Hidden smDown>
              <LanguageChange
                ml={2.5}
                {...languageProps}
                isSideDrawer={false}
              />
            </Hidden>
            <ProfileDropdown user={user} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(injectIntl(NavBar));
