import axios from "axios";
import dayjs from "dayjs";
import * as actionTypes from "./actionTypes";
import { loading, changeLanguage } from "./commonAc";
import getLocaleData from "../../helpers/getLocaleData";
import isCordova from "../../helpers/cordova-check";

let daysList = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
let monthsList = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Aug",
  "Oct",
  "Nov",
  "Dec",
];

// let oauthUrl = process.env.REACT_APP_API_URL;
let domainUserDetails = process.env.REACT_APP_API2_URL;
let apiDomain = process.env.REACT_APP_API_URL;

export const getUserDetailsDone = (userData) => {
  return {
    type: actionTypes.GET_USER_DETAILS_DONE,
    data: userData,
  };
};

export const getChartDataDone = (chartData) => {
  return {
    type: actionTypes.GET_CHART_DATA_DONE,
    data: chartData,
  };
};

export const userDetailsLoading = (value) => {
  return {
    type: actionTypes.USER_DETAILS_LOADING,
    data: value,
  };
};

export const setChartRawData = (data) => {
  return {
    type: actionTypes.SET_CHART_RAW_DATA,
    data: data,
  };
};

export const getTopStoriesDone = (data) => {
  return {
    type: actionTypes.GET_TOP_STORIES_DONE,
    data: data,
  };
};

export const getSpikingUpDone = (data) => {
  return {
    type: actionTypes.GET_SPIKING_UP_DONE,
    data: data,
  };
};

export const getSpikingDownDone = (data) => {
  return {
    type: actionTypes.GET_SPIKING_DOWN_DONE,
    data: data,
  };
};

export const handleOnGetUserDetailsSuccess = (language, response) => {
  return (dispatch) => {
    if (language !== "") {
      let localeData = getLocaleData(language);
      if (localeData !== null) dispatch(changeLanguage(localeData));
      else {
        dispatch(
          changeLanguage({
            countryCode: "US",
            langCode: "en",
            language: "English",
            apiCode: "english",
            googleTrans: "/en/en",
          })
        );
      }
    } else {
      dispatch(
        changeLanguage({
          countryCode: "US",
          langCode: "en",
          language: "English",
          apiCode: "english",
          googleTrans: "/en/en",
        })
      );
    }
    if (response) {
      dispatch(getUserDetailsDone(response.data));
      dispatch(userDetailsLoading(false));
    }
  };
};

export const getUserDetails = () => {
  // alert("entered user details api")
  return (dispatch) => {
    dispatch(userDetailsLoading(true));
    getUserDetailsApi().then((response) => {
      // alert("success from user api")
      dispatch(handleOnGetUserDetailsSuccess(response.data.Language, response));
      if (isCordova()) {
        if (response.data.UnViewedNotificationCount > 0) {
          window.cordova.plugins.notification.badge.set(
            response.data.UnViewedNotificationCount
          );
        }
      }
    });
  };
};

export const getUserDetailsApi = () => {
  let url = isCordova() ? "/v3/me?device=app" : "/v3/me";
  return axios.get(domainUserDetails + url);
};

// export const getCompanyDetails = (companyId) => {
//   return {
//     type: actionTypes.GET_COMPANY_DETAILS,
//     data: companyId,
//   };

// }

const selectCompany = (companyId) => {
  return {
    type: actionTypes.SELECT_COMPANY,
    data: companyId,
  };
};

const volumeBarColor = (open, close) => {
  if (open < close) return "#00b061";
  if (open > close) return "#ff3031";
  return "#00b061";
};

const getMinDate = () => {
  let today = new Date();
  let previous_year = today.getFullYear() - 1;
  let current_quarter = Math.floor(today.getMonth() / 3);
  let last_day_of_quarter = new Date();
  switch (current_quarter) {
    case 0:
      last_day_of_quarter = new Date(previous_year + "-03-31");
      break;
    case 1:
      last_day_of_quarter = new Date(previous_year + "-06-30");
      break;
    case 2:
      last_day_of_quarter = new Date(previous_year + "-09-30");
      break;
    case 3:
      last_day_of_quarter = new Date(previous_year + "-12-30");
      break;
  }
  let min_unix = last_day_of_quarter.getTime();
  return min_unix;
};

export const getChartData = (companyId, isFS, bid, isCrypto, gap) => {
  return (dispatch) => {
    dispatch(loading(true));
    let url =
      domainUserDetails + "/v3/company?cid=" + companyId + "&bid=" + bid;
    if (isCrypto)
      url =
        domainUserDetails +
        "/v3/cryptos/chart?cid=" +
        companyId +
        "&gap=" +
        gap;
    axios.get(url).then((res) => {
      let data = res.data;
      if (!data.t || data.t.length <= 0) {
        dispatch(loading(false));
        dispatch(getChartDataDone(null));
        return false;
      } else {
        dispatch(setChartRawData(data));
        dispatch(chartSetup(companyId, data, isFS, isCrypto));
      }
    });
  };
};

export const chartSetup = (companyId, data, isFS, isCrypto) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    let ohlc = [];
    let volume = [];
    let dataLength = data.t.length;
    let plotBandsQuarter = [];
    let series = [];

    let companyDetails = {
      name: data.name,
      market: data.Market,
      ticker: data.ticker,
    };

    // push quarterly plotbands from endpoint
    for (let i = 0; i < data.plotbands.length; i += 1) {
      plotBandsQuarter.push(data.plotbands[i]);
    }

    for (let i = 0; i < dataLength; i += 1) {
      ohlc.push([
        parseInt(data.t[i] + "000"), // the date
        data.o[i], // open
        data.h[i], // high
        data.l[i], // low
        data.c[i], // close
      ]);
      volume.push({
        x: parseInt(data.t[i] + "000"), // the date
        y: data.v[i], // the volume
        color: volumeBarColor(data.o[i], data.c[i]), // the color
      });
    }

    series.push({
      type: "candlestick",
      name: "AAPL",
      data: ohlc,
      id: "dataseries",
      tooltip: {
        valueDecimals: 2,
        pointFormatter: function () {
          let d = new Date(this.x);
          const formattedDate = dayjs(d).format("DD MMM YYYY");

          document.getElementById("pointsX").innerHTML = formattedDate;
          document.getElementById("pointsOpen").innerHTML =
            this.open.toLocaleString() <= 0
              ? this.open
              : this.open.toLocaleString();
          // parseFloat(
          //   this.open
          // ).toFixed(2);
          document.getElementById("pointsHigh").innerHTML =
            this.high.toLocaleString() <= 0
              ? this.high
              : this.high.toLocaleString();
          document.getElementById("pointsLow").innerHTML =
            this.low.toLocaleString() <= 0
              ? this.low
              : this.low.toLocaleString();
          document.getElementById("pointsClose").innerHTML =
            this.close.toLocaleString() <= 0
              ? this.close
              : this.close.toLocaleString();

          if (isFS) {
            document.getElementById("pointsXx").innerHTML = formattedDate;
            document.getElementById("pointsOpenn").innerHTML =
              this.open.toLocaleString() <= 0
                ? this.open
                : this.open.toLocaleString();
            document.getElementById("pointsHighh").innerHTML =
              this.high.toLocaleString() <= 0
                ? this.high
                : this.high.toLocaleString();
            document.getElementById("pointsLoww").innerHTML =
              this.low.toLocaleString() <= 0
                ? this.low
                : this.low.toLocaleString();
            document.getElementById("pointsClosee").innerHTML =
              this.close.toLocaleString() <= 0
                ? this.close
                : this.close.toLocaleString();
          }

          return false;
        },
      },
      states: {
        inactive: {
          opacity: 1,
        },
      },
    });
    series.push({
      type: "column",
      name: "Volume",
      data: volume,
      turboThreshold: 0,
      yAxis: 1,
      valueDecimals: 2,
      tooltip: {
        pointFormatter: function () {
          document.getElementById("pointsVolume").innerHTML =
            this.y.toLocaleString();
          if (isFS) {
            document.getElementById("pointsVolumee").innerHTML =
              this.y.toLocaleString();
          }
          return false;
        },
      },
      states: {
        inactive: {
          opacity: 1,
        },
      },
    });

    // for (i = 0; i < data["series"].length; i += 1) {
    //   var s = data["series"][i];
    //   var id = data["series"][i].id;
    //   s.events = {
    //     click: function () {
    //       $(id).modal("show");
    //     },
    //   };
    //   series.push(s);
    //   console.log(data["series"][i].id);
    // }

    // push series from endpoint
    for (let i = 0; i < data.series.length; i += 1) {
      let s = data.series[i];
      let id = data.series[i].id;
      let className = data.series[i].className;

      // s.events = {
      //   click: (e) => {
      //     dispatch(selectCompany(id))
      //   },
      // }

      // if(s.type === "flags") {
      //   s.fillColor = "#68d7ab"
      // }

      if (s.type === "polygon") {
        // let s = data.series[i]
        // s.color = "#FFEEED"
        s.lineColor = "#FFA1A1";
        // s.allowPointSelect = true
        s.enableMouseTracking = true;
        s.events = {
          click: (e) => {
            let isSmallScreen = localStorage.getItem("isSmallScreen");
            let shortFlagsToHide = document.getElementsByClassName(
              "hide_short_flag" + " " + className
            );
            if (isSmallScreen === "true") {
              if (shortFlagsToHide) {
                for (var i = 0; i < shortFlagsToHide.length; i++) {
                  shortFlagsToHide[i].style.display = "block"; // depending on what you're doing
                }
              }
            }
          },
          mouseOut: (e) => {
            let isSmallScreen = localStorage.getItem("isSmallScreen");
            let diffInDays = localStorage.getItem("diffInDays");
            let shortFlagsToHide = document.getElementsByClassName(
              "hide_short_flag" + " " + className
            );
            if (isSmallScreen === "true" && diffInDays > 400) {
              if (shortFlagsToHide) {
                for (var i = 0; i < shortFlagsToHide.length; i++) {
                  shortFlagsToHide[i].style.display = "none"; // depending on what you're doing
                }
              }
            }
          },
          // mouseOver: (e) => {

          //   let divsToHide = document.getElementsByClassName(
          //     "hide_flag" + " " + className
          //   )
          //   let diffInDays = localStorage.getItem("diffInDays")
          //   if (diffInDays && diffInDays > 450) {
          //     if (divsToHide) {
          //       for (var i = 0; i < divsToHide.length; i++) {
          //         divsToHide[i].style.display = "block" // depending on what you're doing
          //       }
          //     }
          //   }

          //   let isSmallScreen = localStorage.getItem("isSmallScreen")
          //   if (isSmallScreen === "true") {
          //     if (divsToHide) {
          //       for (var i = 0; i < divsToHide.length; i++) {
          //         divsToHide[i].style.display = "block" // depending on what you're doing
          //       }
          //     }
          //   }
          // },
          // mouseOut: (e) => {
          //   // alert(id)
          //   let divsToHide = document.getElementsByClassName(
          //     "hide_flag" + " " + className
          //   )
          //   let diffInDays = localStorage.getItem("diffInDays")
          //   if (diffInDays && diffInDays > 450) {
          //     if (divsToHide) {
          //       for (var i = 0; i < divsToHide.length; i++) {
          //         divsToHide[i].style.display = "none" // depending on what you're doing
          //       }
          //     }
          //   }

          //   let isSmallScreen = localStorage.getItem("isSmallScreen")
          //   if (isSmallScreen === "true") {
          //     if (divsToHide) {
          //       for (var i = 0; i < divsToHide.length; i++) {
          //         divsToHide[i].style.display = "none" // depending on what you're doing
          //       }
          //     }
          //   }
          // },
        };
      }

      if (s.type === "flags" && isCrypto) {
        // s.shape = "squarepin";
        s.y = -70 - i * 20;
        s.lineColor = "#696969";
        s.onSeries = "dataseries";
        s.lineWidth = 1;
        // s.data[0].shape = "sqaurepin"
      }

      // series.push(data.series[i]);
      // series.push(s);
      // let id = data.series[i].id;

      if (data.series[i].type === "candlestick") {
        let extendedohlc = [];
        for (let j = 0; j < data.series[i].data.length; j += 1) {
          extendedohlc.push([
            parseInt(data.series[i].data[j][0]), // the date
            data.series[i].data[j][1], // open
            data.series[i].data[j][2], // high
            data.series[i].data[j][3], // low
            data.series[i].data[j][4], // close
          ]);
        }
        series.push({
          type: "candlestick",
          data: extendedohlc,
          id: "dataseries",
          tooltip: {
            valueDecimals: 2,
          },
          lineWidth: 0,
          enableMouseTracking: false,
        });
      } else {
        series.push(s);
      }
    }

    let minDate = getMinDate();
    if (isCrypto) minDate = null;
    if (isFS) {
      document.getElementById("fullScreenChartsBlock").style.display = "block";
    }

    let navigatorMinDate = parseInt(data.t[0] + "000");
    let is_locked = data.is_locked;
    let is_locked_url = data.is_locked_url;

    dispatch(
      getChartDataDone([
        plotBandsQuarter,
        series,
        companyId,
        companyDetails,
        minDate,
        navigatorMinDate,
        is_locked,
        is_locked_url,
        isCrypto,
      ])
    );
    dispatch(loading(false));
  };
};

export const getTopStories = () => {
  return (dispatch) => {
    dispatch(loading(true));

    axios.get(domainUserDetails + "/v3/top-stories").then((res) => {
      let data = res.data;
      dispatch(getTopStoriesDone(data));
      dispatch(loading(false));
    });
  };
};

export const getSpikingUp = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));

    axios
      .get(
        domainUserDetails +
          "/v3/latest-spiking-up-briefing-spike?start=" +
          obj.start +
          "&end=" +
          obj.end +
          "&limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().home.spikingUpData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(getSpikingUpDone(data));
        if (!obj.onLoad) dispatch(loading(false));
      });
  };
};

export const getSpikingDown = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));

    axios
      .get(
        domainUserDetails +
          "/v3/latest-spiking-down-briefing-spike?start=" +
          obj.start +
          "&end=" +
          obj.end +
          "&limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().home.spikingDownData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(getSpikingDownDone(data));
        if (!obj.onLoad) dispatch(loading(false));
      });
  };
};
