import messages_en from "../translations/en.json";
import messages_zh_cn from "../translations/zh_cn.json";
import messages_zh_tw from "../translations/zh_tw.json";
import messages_vn from "../translations/vn.json";

class LocaleMessages {
  static getMessages(lang) {
    switch (lang) {
      case "en":
        return messages_en;
      case "zh":
        return messages_zh_cn;
      case "zh-tw":
        return messages_zh_tw;
      case "vn":
        return messages_vn;
      default:
        return messages_en;
    }
  }
}

export default LocaleMessages;
