import axios from "axios";

import * as actionTypes from "./actionTypes";
import { loading } from "./commonAc";

const domain = process.env.REACT_APP_API2_URL;

export const getSupportedBlockchains = (data) => {
  return {
    type: actionTypes.GET_SUPPORTED_BLOCKCHAINS,
    data: data,
  };
};

export const fetchSupportedBlockchains = () => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    axios
      .get(domain + `/v3/cryptos-ai/blockchain-supported/list`)
      .then((res) => {
        const data = res.data;
        dispatch(getSupportedBlockchains(null));
        dispatch(getSupportedBlockchains(data));
        dispatch(loading(false));
      });
  };
};
