import * as actionTypes from "../actions/actionTypes";

const initialState = {
  arkCompanyChartData: null,
  arkTrendChartData: null,
  arkFundTableData: null,
  arkFundMetadata: null,
  arkFundBreakdownData: null,
  arkFundSearchData: null,
  arkInvestorInsiderTableData: null,
  arkInvestorInsiderTableQuaterData: null,
  arkCompanyFundData: null,
  arkCompanyInsiderData: null,
  arkNewsData: null,
  arkWeightChartData: null,
  arkPerformanceChartData: null,
  arkOwnershipChartData: null,
  arkMovingTickerData: null,
  trillionDollar: null,
};

const setArkCompanyChart = (state, action) => {
  return {
    ...state,
    arkCompanyChartData: action.data,
  };
};

const setTrendChart = (state, action) => {
  return {
    ...state,
    arkTrendChartData: action.data,
  };
};

const setFundamentalsTableData = (state, action) => {
  return {
    ...state,
    arkFundTableData: action.data,
  };
};

const setArkFundamentalMetadata = (state, action) => {
  return {
    ...state,
    arkFundMetadata: action.data,
  };
};

const setArkFundBreakdownData = (state, action) => {
  return {
    ...state,
    arkFundBreakdownData: action.data,
  };
};

const setArkFundSearchData = (state, action) => {
  return {
    ...state,
    arkFundSearchData: action.data,
  };
};

const setArkInsidersTableData = (state, action) => {
  return {
    ...state,
    arkInvestorInsiderTableData: action.data,
  };
};

const setArkInsidersTableQuaterData = (state, action) => {
  return {
    ...state,
    arkInvestorInsiderTableQuaterData: action.data,
  };
};

const setArkCompanyFundamentalsData = (state, action) => {
  return {
    ...state,
    arkCompanyFundData: action.data,
  };
};

const setCompanyArkInsiderData = (state, action) => {
  return {
    ...state,
    arkCompanyInsiderData: action.data,
  };
};

const setArkNewsData = (state, action) => {
  return {
    ...state,
    arkNewsData: action.data,
  };
};

const setArkWeightChartData = (state, action) => {
  return {
    ...state,
    arkWeightChartData: action.data,
  };
};

const setArkPerformanceChartData = (state, action) => {
  return {
    ...state,
    arkPerformanceChartData: action.data,
  };
};

const setArkOwnershipChartData = (state, action) => {
  return {
    ...state,
    arkOwnershipChartData: action.data,
  };
};

const setMovingTickerData = (state, action) => {
  return {
    ...state,
    arkMovingTickerData: action.data,
  };
};

const setTrillionDollarData = (state, action) => {
  return {
    ...state,
    trillionDollar: action.data,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ARK_COMPANY_CHART_DATA_DONE:
      return setArkCompanyChart(state, action);
    case actionTypes.GET_ARK_TREND_CHART_DATA_DONE:
      return setTrendChart(state, action);
    case actionTypes.GET_ARK_FUNDAMENTALS_TABLE_DATA_DONE:
      return setFundamentalsTableData(state, action);
    case actionTypes.GET_ARK_FUNDAMENTALS_METADATA_DONE:
      return setArkFundamentalMetadata(state, action);
    case actionTypes.GET_ARK_FUNDAMENTAL_BREAKDOWN_DATA_DONE:
      return setArkFundBreakdownData(state, action);
    case actionTypes.GET_ARK_FUNDS_HOLDINGS_SEARCH_DONE:
      return setArkFundSearchData(state, action);
    case actionTypes.GET_ARK_INSIDERS_TABLE_DATA_DONE:
      return setArkInsidersTableData(state, action);
    case actionTypes.GET_ARK_INVESTOR_INSIDERS_TABLE_QUATER_DATA_DONE:
      return setArkInsidersTableQuaterData(state, action);
    case actionTypes.GET_ARK_COMPANY_FUNDAMENTALS_DATA_DONE:
      return setArkCompanyFundamentalsData(state, action);
    case actionTypes.GET_ARK_COMPANY_INSIDER_DATA_DONE:
      return setCompanyArkInsiderData(state, action);
    case actionTypes.GET_ARK_NEWS_DATA_DONE:
      return setArkNewsData(state, action);
    case actionTypes.GET_ARK_WEIGHT_CHART_DATA_DONE:
      return setArkWeightChartData(state, action);
    case actionTypes.GET_ARK_PERFORMANCE_CHART_DATA_DONE:
      return setArkPerformanceChartData(state, action);
    case actionTypes.GET_OWNERSHIP_CHART_DATA_DONE:
      return setArkOwnershipChartData(state, action);
    case actionTypes.GET_MOVING_TICKER_DATA_DONE:
      return setMovingTickerData(state, action);
    case actionTypes.GET_TRILLION_DOLLAR_DATA_DONE:
      return setTrillionDollarData(state, action);
    default:
      return state;
  }
};

export default reducer;
