import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import { useStyles } from "../css";
import Logo from "../../../images/logo-blue.png";
import isCordova from "../../../helpers/cordova-check";
import { ReactComponent as LogoutIcon } from "../../../images/log-out.svg";
import { ReactComponent as RateUsIcon } from "../../../images/rate-us.svg";
import { ReactComponent as SettingsIcon } from "../../../images/settings.svg";
import { ReactComponent as SupportIcon } from "../../../images/support.svg";
import { isAndroid, isIos } from "../../../helpers/device-check";
import { menuItems } from "../data/drawerList3";
import SideDrawerList from "./SideDrawerList";

import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Typography } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import UserAvatar from "../../UserAvatar";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const SideDrawerNew = (props) => {
  const classes = useStyles();
  const { isSideDrawerOpen, setSideDrawerOpen, user, isDrawerPermanent } =
    props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let history = useHistory();
  const { pathname } = useLocation();

  const handleUpgradeRenewClick = () => {
    if (isCordova()) {
      history.push("/payment");
    } else window.open(user?.UpgradeOrRenewButtonTextURL, "_blank");
  };

  const handleListItemClick = useCallback(
    (item) => {
      if (item?.to === "#") {
        return;
      }

      setSideDrawerOpen(false);
      if (item?.to?.includes("https")) {
        window.open(item.to, "_blank");
      } else if (item.to !== "") history.push(item.to);
      else return;
    },
    [history, setSideDrawerOpen]
  );

  const handleReview = () => {
    if (isCordova()) {
      let appId = "";
      if (isAndroid()) appId = "com.spiking.spike.android";
      if (isIos()) appId = "1081721327";

      window.LaunchReview.launch(
        () => {
          console.log("Successfully launched store app");
        },
        (error) => {
          console.log("Error launching store app: " + error);
        },
        appId
      );
    } else {
      // window.open("https://www.feefo.com/en-GB/reviews/spiking", "_blank");
      window.open("https://spiking.com/app", "_blank");
    }
  };

  const handleLogout = () => {
    if (isCordova()) {
      window.cordova.plugins.GoogleSignInPlugin.signOut(
        function (success) {
          console.log(success);
          history.push("/logout");
        },
        function (error) {
          console.error(error);
        }
      );
    } else history.push("/logout");
  };

  const renderSideList = useMemo(
    () => (
      <>
        {menuItems?.map((list) => (
          <>
            {list.isDivider ? (
              <div className="flex-grow border-t border-[#ECEFF2]"></div>
            ) : (
              <SideDrawerList list={list}>
                {list?.children &&
                  list.children?.map((item1) => (
                    <>
                      {item1.children ? (
                        <SideDrawerList list={item1} className="pl-14">
                          {item1?.children?.map((item2) => (
                            <>
                              {item2.children ? (
                                <SideDrawerList
                                  list={item2}
                                  className="pl-[4.5rem]"
                                >
                                  {item2.children?.map((item3) => (
                                    <div
                                      onClick={() => handleListItemClick(item3)}
                                      className={`px-5 py-4 pl-[74px] hover:bg-slate-100 dark:hover:bg-slate-800 cursor-pointer ${
                                        item3?.isSelected &&
                                        "bg-slate-100 dark:bg-dark-neutral-50"
                                      }`}
                                    >
                                      <Typography
                                        color={
                                          item3?.isSelected
                                            ? "primary"
                                            : "textPrimary"
                                        }
                                        className="!text-base"
                                      >
                                        {item3.label}
                                        {item3.subLabel && (
                                          <sup
                                            style={{
                                              fontSize: "12px",
                                              color: "rgb(24, 143, 255)",
                                              fontWeight: 400,
                                              marginLeft: 2,
                                            }}
                                          >
                                            {item3.subLabel}
                                          </sup>
                                        )}
                                      </Typography>
                                    </div>
                                  ))}
                                </SideDrawerList>
                              ) : (
                                <div
                                  onClick={() => handleListItemClick(item2)}
                                  className={`px-5 py-4 pl-[74px] hover:bg-slate-100 dark:hover:bg-slate-800 cursor-pointer ${
                                    item2?.isSelected &&
                                    "bg-slate-100 dark:bg-dark-neutral-50"
                                  }`}
                                >
                                  <Typography
                                    color={
                                      item2?.isSelected
                                        ? "primary"
                                        : "textPrimary"
                                    }
                                    className="!text-base"
                                  >
                                    {item2.label}
                                    {item2.subLabel && (
                                      <sup
                                        style={{
                                          fontSize: "12px",
                                          color: "rgb(24, 143, 255)",
                                          fontWeight: 400,
                                          marginLeft: 2,
                                        }}
                                      >
                                        {item2.subLabel}
                                      </sup>
                                    )}
                                  </Typography>
                                </div>
                              )}
                            </>
                          ))}
                        </SideDrawerList>
                      ) : (
                        <div
                          onClick={() => handleListItemClick(item1)}
                          className={`px-5 py-4 pl-[60px] hover:bg-slate-100 dark:hover:bg-slate-800 cursor-pointer ${
                            item1?.to?.includes(pathname) &&
                            "bg-slate-100 dark:bg-dark-neutral-50"
                          } ${item1.highlight && "bg-[#a8e4a0]"}`}
                        >
                          <Typography
                            color={
                              item1?.to?.includes(pathname)
                                ? "primary"
                                : "textPrimary"
                            }
                            className="!text-base"
                          >
                            {item1.label}
                            {item1.subLabel && (
                              <sup
                                style={{
                                  fontSize: "12px",
                                  color: "rgb(24, 143, 255)",
                                  fontWeight: 400,
                                  marginLeft: 2,
                                }}
                              >
                                {item1.subLabel}
                              </sup>
                            )}
                          </Typography>
                        </div>
                      )}
                    </>
                  ))}
              </SideDrawerList>
            )}
          </>
        ))}
      </>
    ),
    [handleListItemClick, pathname]
  );

  return (
    <Drawer
      // variant={isMobile ? "temporary" : "permanent"}
      variant={!isDrawerPermanent || isMobile ? "temporary" : "permanent"}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isSideDrawerOpen,
        [classes.drawerClose]: !isSideDrawerOpen,
      })}
      classes={{
        paper: clsx(
          {
            [classes.drawerOpen]: isSideDrawerOpen,
            [classes.drawerClose]: !isSideDrawerOpen,
          },
          classes.backgrounColor,
          classes.drawerPaper
        ),
        paperAnchorDockedLeft: classes.drawerStyles,
      }}
      open={isSideDrawerOpen}
      onClose={() => setSideDrawerOpen(false)}
    >
      <div>
        <Toolbar
          className={clsx(
            classes.drawerLogoBlock,
            classes.backgrounColor,
            classes.toolbarMobile
          )}
        >
          <Grid container alignItems="center" justify="space-between">
            <div className={classes.navbarLogo}>
              <img
                src={Logo}
                alt="Spiking"
                className="w-full cursor-pointer"
                onClick={() => {
                  props.history.push("/spiking-up-down");
                  setSideDrawerOpen(false);
                }}
              />
            </div>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={() => setSideDrawerOpen(!isSideDrawerOpen)}
              className="!p-0"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Toolbar>
        <div>
          <div
            className={
              user?.IsMars
                ? classes.profileBlockMars
                : user?.IsCenturion
                ? classes.profileBlockCenturion
                : classes.profileBlock
            }
          >
            <div className="flex items-center">
              <div className={user?.IsCenturion ? "centurionBorder" : ""}>
                <UserAvatar
                  imageUrl={user && user.ImageUrl}
                  firstName={user && user.Firstname}
                  lastName={user && user.Lastname}
                  IsCenturion={user?.IsCenturion}
                />
              </div>
              {user?.IsCenturion ? (
                <div className="ml-2">
                  <Typography
                    variant="subtitle1"
                    className="!font-bold !text-white"
                  >
                    {user && user.Firstname} {user && user.Lastname}
                    {!user && "Free User"}
                  </Typography>
                  <Typography className="!font-semibold !text-white !text-xs !mt-1">
                    {user && user.MembershipText}
                  </Typography>
                </div>
              ) : (
                <Box ml={1}>
                  <Typography variant="subtitle1" className={classes.weight600}>
                    {user && user.Firstname} {user && user.Lastname}
                    {!user && "Free User"}
                  </Typography>
                  <Typography className={classes.activeTillTypo}>
                    {user && user.MembershipText}
                  </Typography>
                </Box>
              )}
            </div>
            {user?.UpgradeOrRenewButtonText && !user?.IsCenturion && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className="!rounded-md !text-[12px] !shadow-none !font-semibold !mt-3 !py-2"
                  onClick={handleUpgradeRenewClick}
                >
                  {!isCordova() && user?.UpgradeOrRenewButtonText}
                  {isCordova() && "Upgrade Now"}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classes.scrollableMain}>
        <div className={classes.srollableDivSideNav}>{renderSideList}</div>
        {/* fixed block */}
        <div>
          {/* settings */}
          <div
            className={clsx(
              "flex items-center cursor-pointer px-5 py-4",
              classes.settingsBorderTop
            )}
            onClick={() => history.push("/profile/personal-information")}
          >
            <span class="side-nav-svgmain">
              <SvgIcon>
                <SettingsIcon />
              </SvgIcon>
            </span>
            <Typography
              className="!text-base"
              // color={selectedOption === "settings" ? "primary" : "textPrimary"}
            >
              <FormattedMessage id="nav.settings" />
            </Typography>
          </div>
          {/* support */}
          <div
            className="flex items-center cursor-pointer px-5 py-4"
            onClick={() =>
              handleListItemClick({
                to: "https://spiking.com/help",
                isHref: true,
                id: "support",
              })
            }
          >
            <span className={classes.svgImage}>
              <SvgIcon style={{ transform: "scale(0.95)" }}>
                <SupportIcon />
              </SvgIcon>
            </span>
            <Typography
              className="!text-base"
              // color={selectedOption === "support" ? "primary" : "textPrimary"}
            >
              <FormattedMessage id="nav.support" />
            </Typography>
          </div>
          {/* rate us */}
          <div
            className="flex items-center cursor-pointer px-5 py-4"
            onClick={handleReview}
          >
            <span class="side-nav-svgmain">
              <SvgIcon style={{ transform: "scale(0.8)" }}>
                <RateUsIcon />
              </SvgIcon>
            </span>
            <Typography
              className="!text-base"
              // color={selectedOption === "support" ? "primary" : "textPrimary"}
            >
              Rate us
            </Typography>
          </div>
          {/* logout */}
          <div
            className="flex items-center cursor-pointer px-5 py-4"
            onClick={handleLogout}
          >
            <span class="side-nav-svgmain">
              <SvgIcon>
                <LogoutIcon />
              </SvgIcon>
            </span>
            <Typography
              className="!text-base"
              // color={selectedOption === "logout" ? "primary" : "textPrimary"}
            >
              <FormattedMessage id="login.signout" />
            </Typography>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default SideDrawerNew;
