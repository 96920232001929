import * as actionTypes from "../actions/actionTypes";

const initialState = {
  historyTradeList: [],
  isLast: false,
  portfolioBreakdownData: {}
};

const setHistoryTrade = (state, action) => {
  return {
    ...state,
    historyTradeList: [...state.historyTradeList, ...action.data.data],
    isLast: action.data.is_last
  };
};

const setBreakdownRecords = (state, action) => {
  return {
    ...state,
    portfolioBreakdownData: action.data,
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_HISTORY_TRADE:
      return setHistoryTrade(state, action);
    case actionTypes.GET_BREAKDOWN_RECORDS:
      return setBreakdownRecords(state, action)
    default:
      return state;
  }
};

export default reducer;
