import Axios from "axios";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from "react-redux";
import { styled } from '@mui/material/styles';

const AnimatedSuccessIcon = styled('div')`
  @keyframes scaleIn {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  animation: scaleIn 0.5s ease-out forwards;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: 0 auto 16px;
  border-radius: 50%;
  background: linear-gradient(45deg, #4CAF50 30%, #81C784 90%);
  box-shadow: 0 3px 5px 2px rgba(76, 175, 80, .3);
`;

const NPSSurvey = ({ onSubmit }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.home.user);

  const features = [
    "Daily Bread",
    "Holy Grail Portfolio",
    "TradeGPT",
    "Events / Calendar",
    "Video Learning Library / Spiking Courses",
    "Other",
  ];

  const validationSchema = Yup.object().shape({
    npsScore: Yup.number().required("Please select a score"),
    features: Yup.array()
      .min(1, "Please select at least one feature")
      .required("Please select at least one feature"),
    otherFeature: Yup.string().when("features", {
      is: (features) => features?.includes("Other"),
      then: Yup.string().required("Please specify the other feature"),
    }),
    improvements: Yup.string().required(
      "Please provide improvement suggestions"
    ),
    suggestions: Yup.string().required("Please provide feature suggestions"),
  });

  const initialValues = {
    npsScore: null,
    features: [],
    improvements: "",
    suggestions: "",
    otherFeature: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    try {
      const formData = {
        ...values,
        email: user.Email,
      };

      await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/submitNPS`,
        formData
      );
      if (onSubmit) onSubmit(values);
      setCurrentStep(5);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const renderStep = (formik) => {
    switch (currentStep) {
      case 1:
        return (
          <div className="mb-8">
            <h2 className="text-md font-medium text-center mb-8">
              How likely are you to recommend Spiking to a friend or colleague?
            </h2>

            <div className="flex justify-between text-sm mb-2">
              <span className="text-gray-500">Not likely at all</span>
              <span className="text-gray-500">Extremely likely</span>
            </div>

            <div className="flex justify-between mb-8">
              {[...Array(11)].map((_, index) => (
                <button
                  type="button"
                  key={index}
                  onClick={() => formik.setFieldValue("npsScore", index)}
                  className={`w-8 h-8 rounded-full border ${
                    formik.values.npsScore === index
                      ? "bg-blue-100 border-blue-400 text-blue-600"
                      : "border-gray-300 hover:border-blue-400 hover:bg-blue-50"
                  }`}
                >
                  {index}
                </button>
              ))}
            </div>
          </div>
        );

      case 2:
        return (
          <div className="mb-3">
            <h2 className="text-sm sm:text-base font-semibold mb-1.5">
              Which features of Spiking do you use most often?
            </h2>
            <div className="space-y-1.5">
              {features.map((feature) => (
                <div key={feature} className="flex items-center">
                  <button
                    type="button"
                    onClick={() => {
                      const newFeatures = formik.values.features.includes(
                        feature
                      )
                        ? formik.values.features.filter((f) => f !== feature)
                        : [...formik.values.features, feature];
                      formik.setFieldValue("features", newFeatures);
                    }}
                    className={`p-1.5 w-full text-left rounded-md border ${
                      formik.values.features.includes(feature)
                        ? "bg-[#0068FF] text-white border-[#0068FF]"
                        : "border-neutral-300 hover:border-[#0068FF] hover:text-[#0068FF]"
                    }`}
                  >
                    {feature}
                  </button>
                </div>
              ))}
              {formik.values.features.includes("Other") && (
                <Field
                  name="otherFeature"
                  type="text"
                  placeholder="Please specify other features..."
                  className="w-full p-1.5 border border-neutral-300 rounded-md mt-0.5"
                />
              )}
            </div>
            {formik.touched.features && formik.errors.features && (
              <div className="text-red-500 text-sm mt-2">
                {formik.errors.features}
              </div>
            )}
            {formik.touched.otherFeature && formik.errors.otherFeature && (
              <div className="text-red-500 text-sm mt-2">
                {formik.errors.otherFeature}
              </div>
            )}
          </div>
        );

      case 3:
        return (
          <div className="mb-3">
            <h2 className="text-sm sm:text-base font-semibold mb-1.5">
              What can we improve to enhance your experience?
            </h2>
            <Field
              as="textarea"
              name="improvements"
              className="w-full p-1.5 border border-neutral-300 rounded-md h-12"
              placeholder="Share your thoughts..."
            />
            {formik.touched.improvements && formik.errors.improvements && (
              <div className="text-red-500 text-sm mt-2">
                {formik.errors.improvements}
              </div>
            )}
          </div>
        );

      case 4:
        return (
          <div className="mb-3">
            <h2 className="text-sm sm:text-base font-semibold mb-1.5">
              What additional features or services would you like to see in
              Spiking?
            </h2>
            <Field
              as="textarea"
              name="suggestions"
              className="w-full p-1.5 border border-neutral-300 rounded-md h-12"
              placeholder="Share your suggestions..."
            />
            {formik.touched.suggestions && formik.errors.suggestions && (
              <div className="text-red-500 text-sm mt-2">
                {formik.errors.suggestions}
              </div>
            )}
          </div>
        );

      case 5:
        return (
          <div className="mb-3 text-center">
            <AnimatedSuccessIcon>
              <CheckIcon sx={{ fontSize: 28, color: 'white' }} />
            </AnimatedSuccessIcon>
            <h2 className="text-xl font-bold mb-2">Thank You!</h2>
            <p className="text-gray-600 text-sm leading-relaxed max-w-md mx-auto">
              Your feedback has been successfully submitted. We appreciate your
              time and will use your input to improve our services.
            </p>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form className="w-full max-w-xl mx-auto px-8 py-6 bg-white rounded-xl">
          {/* Emoji Icon */}
          <div className="flex justify-center mb-4">
            <div className="w-12 h-12 bg-blue-50 rounded-full flex items-center justify-center">
              <span className="text-xl">☺️</span>
            </div>
          </div>

          {/* Title and Subtitle */}
          <h1 className="text-2xl font-bold text-center mb-1">
            Your input is valuable to us
          </h1>
          <p className="text-gray-500 text-sm text-center mb-4">
            Help us improve Spiking by sharing your feedback
          </p>

          {/* NPS Question */}
          {renderStep(formik)}

          {/* Action Buttons */}
          <div className="flex justify-between">
            <button
              type="button"
              onClick={() => currentStep > 1 && setCurrentStep(currentStep - 1)}
              className={`px-6 py-2 text-gray-500 hover:text-gray-700 ${
                currentStep === 1 ? "invisible" : ""
              }`}
            >
              Back
            </button>
            <button
              type="button"
              onClick={() => {
                if (currentStep < 4) {
                  formik.validateForm().then((errors) => {
                    const currentStepFields = {
                      1: ["npsScore"],
                      2: ["features", "otherFeature"],
                      3: ["improvements"],
                      4: ["suggestions"],
                    }[currentStep];

                    const hasErrors = currentStepFields.some(
                      (field) => errors[field]
                    );

                    if (!hasErrors) {
                      setCurrentStep((prev) => prev + 1);
                    } else {
                      currentStepFields.forEach((field) => {
                        formik.setFieldTouched(field, true);
                      });
                    }
                  });
                } else {
                  formik.handleSubmit();
                }
              }}
              className="px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-500 transition-colors disabled:opacity-50"
              disabled={(() => {
                const currentStepFields = {
                  1: ["npsScore"],
                  2: ["features", "otherFeature"],
                  3: ["improvements"],
                  4: ["suggestions"],
                }[currentStep];

                if (currentStep === 5) {
                  return true;
                }

                const allFieldsEmpty = currentStepFields?.every(
                  (field) => !formik.values[field]
                );

                return (
                  currentStepFields.some((field) => formik.errors[field]) ||
                  isLoading ||
                  allFieldsEmpty
                );
              })()}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <CircularProgress size={16} className="mr-2 text-white" />
                  Submitting...
                </div>
              ) : currentStep === 4 ? (
                "Submit Feedback"
              ) : (
                "Next"
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NPSSurvey;
