import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Icon from "../../images/icon.png";
import { useStyles } from "./css";

import Dialog from "@material-ui/core/Dialog";

const Loader = (props) => {
  const { isLoading } = props;
  const classes = useStyles();

  return (
    <Dialog
    //   onClose={handleClose}
      open={isLoading}
      classes={{ paper: classes.loaderRoot, root: classes.dialogueRoot }}
    >
      <div className={classes.loader} />
      <div className={classes.iconBlock}>
        <img src={Icon} alt="Loading" />
      </div>
    </Dialog>
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.common.loading,
  };
};

export default connect(mapStateToProps)(Loader);
