import React from "react";
import EventCalendarContainer from "./calendar";
import Layout from "../Layout/container";

function index(props) {
  return (
    <>
      <Layout>
        <EventCalendarContainer />
      </Layout>
    </>
  );
}

export default index;
