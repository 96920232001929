import localInfo from '../data/locale-info.json';

const getLocaleData = (code) => {
    let localeData = null;
    for (let i = 0; i < localInfo.length; i++) {
        if (localInfo[i].apiCode === code) {
            localeData = localInfo[i];
        }
    }
    return localeData;
}



export default getLocaleData;