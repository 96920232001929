import axios from "axios";

import * as actionTypes from "./actionTypes";
import { loading, searchLoading } from "./commonAc";
import { loadingRefreshSet } from "./optionsAc";

let domain = process.env.REACT_APP_API2_URL;

export const getCryptosOwnersDataDone = (data) => {
  return {
    type: actionTypes.GET_CRYPTO_OWNERS_DATA_DONE,
    data: data,
  };
};

export const getCryptosOwnersTableDataDone = (data) => {
  return {
    type: actionTypes.GET_CRYPTO_OWNERS_TABLE_DATA_DONE,
    data: data,
  };
};

export const getCryptosNewsDataDone = (data) => {
  return {
    type: actionTypes.GET_CRYPTOS_NEWS_DATA_DONE,
    data: data,
  };
};

export const getCryptosNewsAllData = (data) => {
  return {
    type: actionTypes.GET_CRYTPOS_NEWS_ALL_DATA_DONE,
    data: data,
  };
};

export const getCryptoHeaderDataDone = (data) => {
  return {
    type: actionTypes.GET_CRYPTO_HEADER_DATA_DONE,
    data: data,
  };
};

export const getCryptosDailyCoinsDataDone = (data) => {
  return {
    type: actionTypes.GET_CRYPTOS_DAILY_COINS_DATA_DONE,
    data: data,
  };
};

export const getCryptosSearchDataDone = (data) => {
  return {
    type: actionTypes.GET_CRYPTOS_SEARCH_DATA_DONE,
    data: data,
  };
};

export const getCryptosOwnersData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));
    if (obj.isRefresh) dispatch(loadingRefreshSet(true));

    let type = obj.type;
    let cid = obj.cid;
    const nextPageToken = obj.nextPageToken;

    // const url =  `${domain}/v3/cryptos/owner${nextPageToken}`
    let url = "";
    if (obj.pageMode === "owners") {
      url = nextPageToken
        ? `${domain}/v3/cryptos/100-owner/list${nextPageToken}`
        : `${domain}/v3/cryptos/100-owner/list?cid=${cid}&type=${type}`;
    } else if (obj.pageMode === "insiders") {
      url = nextPageToken
        ? `${domain}/v3/cryptos/1000-owner/list${nextPageToken}`
        : `${domain}/v3/cryptos/1000-owner/list?cid=${cid}&type=${type}`;
    }

    axios.get(url).then((res) => {
      let data = res.data;

      if (!obj.onLoad && nextPageToken) {
        let prevData = getState().cryptos.ownersData.data;
        let appendedData = prevData.concat(data.data);
        data.data = appendedData;
      }

      dispatch(getCryptosOwnersDataDone(data));
      dispatch(loading(false));
      dispatch(loadingRefreshSet(false));
      //   dispatch(infiniteScrollLoading(false));
    });
  };
};
export const getCryptosOwnersTableData = (obj) => {
  return (dispatch) => {
    if (obj.isRefresh) dispatch(loadingRefreshSet(true));
    if (obj.onLoad) dispatch(loading(true));

    let url = "";
    if (obj.pageMode === "owners") {
      url =
        domain +
        `/v3/cryptos/100-owner/table?interval=${obj.interval}&bid=${obj.bid}&limit=${obj.limit}&offset=${obj.offset}&sortby=${obj.sortby}&sortorder=${obj.sortorder}`;
    } else if (obj.pageMode === "insiders") {
      url =
        domain +
        `/v3/cryptos/1000-owner/table?interval=${obj.interval}&bid=${obj.bid}&limit=${obj.limit}&offset=${obj.offset}&sortby=${obj.sortby}&sortorder=${obj.sortorder}`;
    }

    axios.get(url).then((res) => {
      let data = res.data;
      dispatch(getCryptosOwnersTableDataDone(data));
      let element = document.getElementById("tableScroll");
      if (element) element.scrollTop = 0;
      dispatch(loading(false));
      dispatch(loadingRefreshSet(false));
    });
  };
};

export const getCryptosNewsData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));

    // const params = {
    //   limit: obj.limit,
    //   offset: obj.offset,
    //   tags: obj.tags ? obj.tags : "",
    //   start: obj.start ? obj.start : "",
    //   end: obj.end ? obj.end : "",
    //   bids: obj.bids ? obj.bids : "",
    //   cids: obj.cids ? obj.cids : "",
    //   action: obj.action,
    // };

    let start = "";
    let end = "";
    let bids = "";
    let tags = "";
    let cids = "";
    if (obj.start !== undefined) start = obj.start;
    if (obj.end !== undefined) end = obj.end;
    if (obj.bids !== undefined) bids = obj.bids;
    if (obj.tags !== undefined) tags = obj.tags;
    if (obj.cids !== undefined) cids = obj.cids;

    let apiUrl = null;

    if (obj.action === "all" && !obj.onLoad)
      apiUrl = domain + "/v3/cryptos/news" + obj.queryString;
    else
      apiUrl =
        domain +
        "/v3/cryptos/news?limit=" +
        obj.limit +
        "&offset=" +
        obj.offset +
        "&tags=" +
        tags +
        "&start=" +
        // "2020-10-05" +
        start +
        "&end=" +
        // "2020-12-31"
        end +
        "&bids=" +
        bids +
        "&cids=" +
        cids +
        "&action=" +
        obj.action;

    axios.get(apiUrl).then((res) => {
      let data = res.data;
      // alert("success");
      if (!obj.onLoad) {
        if (obj.action !== "all") {
          let prevData = getState().cryptos.cryptosNewsData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        } else {
          let prevData = getState().cryptos.cryptosNewsAllData;
          let appendedData = prevData.data.concat(data.data);
          prevData.next_page_token = data.next_page_token;
          data.data = appendedData;
        }
      }
      // dispatch(setBidsTags(data.bids));
      // dispatch(setCidsTags(data.cids));
      // dispatch(setCommonTags(data.tags));
      if (obj.action === "all") dispatch(getCryptosNewsAllData(data));
      else dispatch(getCryptosNewsDataDone(data));
      dispatch(loading(false));
      // dispatch(infiniteScrollLoading(false));
    });
  };
};

export const getCryptoHeaderData = (id) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios.get(domain + `/v3/crypto/${id}`).then((res) => {
      let data = res.data;
      dispatch(getCryptoHeaderDataDone(data));
      // dispatch(loading(false));
    });
  };
};

export const getCryptosDailyCoinsData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad && !obj.isInfiniteLoad) dispatch(loading(true));
    if (obj.isRefresh) dispatch(loadingRefreshSet(true));

    axios
      .get(
        domain +
          `/v3/cryptos/coins?bid=${obj.bid}&limit=${obj.limit}&offset=${obj.offset}&sortby=${obj.sortby}&sortorder=${obj.sortorder}&cids=${obj.cids}&tags=${obj.tags}`
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad && obj.isInfiniteLoad) {
          let prevData = getState().cryptos.cryptosCoinData.data;
          let appendTableData = prevData.table_data.concat(
            data.data.table_data
          );
          data.data.table_data = appendTableData;
        }
        dispatch(getCryptosDailyCoinsDataDone(data));
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(loading(false));
        dispatch(loadingRefreshSet(false));
      });
  };
};

let cancelToken;

export const getCryptosSearchData = (obj) => {
  return (dispatch) => {
    dispatch(searchLoading(true));

    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();

    axios
      .get(domain + `/v3/cryptos/search?search=${obj.search}&bid=${obj.bid}`, {
        cancelToken: cancelToken.token,
      })
      .then((res) => {
        let data = res.data;
        dispatch(getCryptosSearchDataDone(data?.coin));
        dispatch(searchLoading(false));
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};
