import React from "react";
import clemen from "../../../images/journey/clemen-bonus.jpg";
import Content from "../components/Content";

const Mentor = () => {
  const content = {
    dayTitle: "Meet Your Investment Mentor",
    heading: "Welcome to the final part of your Spiking journey.",
    youtubeVideoId: "",
    introImage: clemen,
    intro:
      "You've acquired invaluable insights and strategies, and now it's time to meet the visionary behind Spiking.",
    about: {
      sectionTitle: "ABOUT Dr. Clemen Chiang",
      description: `Meet Dr. Clemen Chiang, your investment mentor. Discover his background, passion, and vision to gain valuable insights and inspiration for your own investment journey.`,
    },
    topicsIntro:
      "Join Spiking and Dr. Clemen Chiang to take the next step in your financial growth:",
    topics: [
      {
        title: "Who is Dr. Clemen Chiang?",
        description:
          "Learn about the founder and CEO of Spiking, his educational background, and his achievements in the investment world. <a class='font-bold text-blue-500' href='https://www.spiking.com/our-story' target='_blank' rel='noopener noreferrer'>Learn more</a>",
      },
      {
        title: "What drives his passion?",
        description:
          "Dr. Chiang believes that your destiny is to become a millionaire. It takes preparation, perseverance, and passion. He aims to groom 1,000,000 millionaires in the Spiking Community.",
      },
      {
        title: "Where does he live?",
        description:
          "Dr. Chiang was born and raised in Singapore. Presently, he lives in Malaysia where he conducts live streaming from his home studio and also spends time with his family in Singapore.",
      },
      {
        title: "When did he become a Millionaire?",
        description:
          "Dr. Chiang became a millionaire in 2004 at the age of 30 years old when there were 31,000 millionaires in Singapore. By 2024, there are 333,204 millionaires in Singapore, marking a more than 10X growth in millionaires in his country.",
      },
      {
        title: "Why did he launch Spiking?",
        description:
          "Dr. Chiang wants to solve the problem of information asymmetry. The market is likened to David versus Goliath, where retail investors often lose out to institutional money managers. By providing proprietary data, Spiking aims to close this gap, allowing retail investors to invest alongside the giants of Wall Street.",
      },
      {
        title: "How does Spiking compare to the rest out there?",
        description:
          "Spiking's TradeGPT is the only product in the world that has attained validation from both the best of the East, Huawei, and the best of the West, IBM.",
      },
    ],
    nextSteps: {
      title: "NEXT STEPS",
      steps: [
        {
          title: "World Championship",
          description:
            "Showcase your skills in the Stocks and Options Trading Competition and compete for top honours!",
          actions: [
            {
              label: "Learn More",
              link: "https://worldchampionship.com/",
              type: "primary",
            },
          ],
        },
        {
          title: "Mars Mastermind Coaching Program",
          description:
            "Join the Mars Mastermind Coaching Program, led by Dr. Clemen Chiang and tailored for committed investors.",
          actions: [
            {
              label: "Join the Program",
              link: "https://spi.ke/membership",
              type: "primary",
            },
          ],
        },
        {
          title: "Connect with our Angels",
          description: "For assistance or further guidance at Spiking Help.",
          actions: [
            {
              label: "Go to Spiking Help",
              link: "https://spi.ke/help",
              type: "secondary",
            },
          ],
        },
      ],
    },
  };

  return <Content content={content} />;
};

export default Mentor;
