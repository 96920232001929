import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import ReactGA from "react-ga4";
// import { Router } from 'react-router';
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";

import App from "./features/App/App";
import store from "./store/store";
import authService from "./helpers/axios";
import dayjs from "dayjs";

// import { createBrowserHistory, createHashHistory } from 'history';
const utc = require("dayjs/plugin/utc");
const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);
dayjs.extend(utc);

const history = createBrowserHistory();

const isWebDev = process.env.REACT_APP_IS_WEB_DEV;

authService.setInterceptors(store, history);
if (isWebDev === "false") ReactGA.initialize("G-1299QF7WXY");
// ReactGA.initialize("UA-82887468-1")

let RootRouter;

const renderReactDom = () => {
  ReactDOM.render(
    <ReduxProvider store={store}>
      <React.StrictMode>
        <RootRouter>
          <App />
          <ToastContainer />
        </RootRouter>
      </React.StrictMode>
    </ReduxProvider>,

    document.getElementById("root")
  );
};

if (window.cordova) {
  RootRouter = HashRouter;
  document.addEventListener(
    "deviceready",
    () => {
      renderReactDom();
    },
    false
  );
} else {
  RootRouter = BrowserRouter;
  renderReactDom();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
