import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';

function DetailsIPO({ dataDetails, groupDataDetails, setGroupDataDetails }) {
  const scrollElementRef = useRef(null);
  const divRef = useRef(null);
  const [scrolling, setScrolling] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const divRect = divRef.current.getBoundingClientRect();
    let newPosition = {
      top: 0,
      left: 0,
    };
    const parentDiv = document
      .getElementById('calendar-event-details-custom')
      .getBoundingClientRect();

    if (parentDiv.left + 2 * divRect.width + 10 <= windowWidth) {
      newPosition.left = divRect.width + 10;
    } else if (parentDiv.left - divRect.width < 0) {
      newPosition.left = 0;
      newPosition.top = parentDiv.height + 10;
    } else {
      // If it can't fit to the right, position it to the left
      newPosition.left = 0 - divRect.width - 10;
    }
    setPosition(newPosition);
  }, [groupDataDetails]);

  useEffect(() => {
    const scrollElement = scrollElementRef.current;

    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  function handleScroll() {
    // Your scroll event handling logic here
    scrollElementRef.current.scrollTop > 0 ? setScrolling(true) : setScrolling(false);
  }
  return (
    <div
      style={{
        position: 'absolute',
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
      ref={divRef}
      className="pb-2 bg-white shadow-down-xxl rounded-md md:w-[448px] max-w-[448px] border-neutral-600 "
    >
      <div
        className={`pt-2 px-[6px] flex flex-row-reverse items-center ${
          scrolling ? 'shadow-down-l' : ''
        } transition-all duration-200`}
      >
        <button
          className="h-10 w-10 hover:bg-[#f9f9f9] rounded-full bg-transparent flex items-center justify-center"
          onClick={() => setGroupDataDetails(false)}
        >
          <svg width="20" height="20" viewBox="0 0 24 24" className=" NMm5M">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
          </svg>
        </button>
      </div>
      <div ref={scrollElementRef} className="max-h-[300px] overflow-y-auto px-[28px]">
        <>
          <div className="flex items-start gap-x-3 mb-[24px]">
            <div className="w-[24px] h-[24px] flex-shrink-0">
              <div
                style={{
                  backgroundColor: dataDetails?.backgroundColor,
                }}
                className="w-[14px] h-[14px] rounded-[4px] flex-shrink-0 mt-2"
              />
            </div>
            <div>
              <div className="text-[#3c4043] text-[22px] leading-[28px] max-w-[348px]">
                {groupDataDetails?.title}
              </div>
              <div className="text-[14px] mt-[3px] leading-[18px] text-[#3c4043] max-w-[348px]">
                {groupDataDetails?.start_time && (
                  <>
                    <span>{format(new Date(groupDataDetails?.start_time), 'iiii, MMMM d')}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          {groupDataDetails?.url && (
            <div className="flex items-start gap-x-3 mb-[14px] max-w-[348px] ">
              <div className="w-[24px] h-[24px] flex-shrink-0  text-[#5f6368]">
                <svg
                  focusable="false"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="text-[#5f6368] -ml-1"
                  fill="#5f6368"
                >
                  <path
                    fill="#5f6368"
                    d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"
                  ></path>
                  <circle fill="#5f6368" cx="12" cy="9" r="2.5"></circle>
                </svg>
              </div>
              <a
                target="_blank"
                href={groupDataDetails?.url}
                className="inline-block text-[14px] truncate leading-[18px] text-[#3c4043] hover:underline"
                rel="noreferrer"
              >
                {groupDataDetails?.url}
              </a>
            </div>
          )}
          {groupDataDetails?.source && (
            <div className="flex items-start gap-x-3 mb-[14px] max-w-[348px] ">
              <div className="w-[24px] h-[24px] flex-shrink-0  text-[#5f6368]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 -960 960 960"
                  width="20"
                  className="-ml-[2px]"
                  fill="#5f6368"
                >
                  <path d="M580-240q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z" />
                </svg>
              </div>
              <div className="text-[14px] leading-[18px] text-[#3c4043]">
                {groupDataDetails?.source}
              </div>
            </div>
          )}
          {groupDataDetails?.description && (
            <div className="flex items-start gap-x-3  mb-[14px] max-w-[348px]">
              <div className="w-[24px] h-[24px] mt-2 flex-shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="var(--ci-primary-color, #5f6368)"
                    d="M334.627,16H48V496H472V153.373ZM440,166.627V168H320V48h1.373ZM80,464V48H288V200H440V464Z"
                    className="ci-primary"
                  />
                  <rect
                    width="224"
                    height="32"
                    x="136"
                    y="296"
                    fill="var(--ci-primary-color, #5f6368)"
                    className="ci-primary"
                  />
                  <rect
                    width="224"
                    height="32"
                    x="136"
                    y="376"
                    fill="var(--ci-primary-color, #5f6368)"
                    className="ci-primary"
                  />
                </svg>
              </div>
              <div className="text-[14px] leading-[18px] text-[#3c4043]">
                <span rel="noreferrer" style={{ whiteSpace: 'pre-line' }}>
                  {groupDataDetails?.description}
                </span>
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default DetailsIPO;
