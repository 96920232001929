import React from "react";
import Content from "../components/Content";

const Salt = () => {
  const content = {
    dayTitle: "Journey Through SALT",
    heading: "Congratulations on progressing further with Spiking.",
    intro:
      "With each step, you’re moving closer to achieving your financial goals and building a robust investment strategy.",
    youtubeVideoId: "ghA50hT8r6U",
    about: {
      sectionTitle: "ABOUT SALT Journey",
      description: `Today, we will explore the SALT framework: <strong>S</strong>hare, <strong>A</strong>I, <strong>L</strong>earn, <strong>T</strong>rade. These elements are essential for engaging with the investment community and leveraging advanced tools and strategies for successful trading.`,
    },
    topicsIntro: "The Spiking journey will take you through these key topics:",
    topics: [
      {
        title: "Share",
        description:
          "Share your knowledge and experiences with the Spiking community. Engaging with other investors can provide new perspectives and insights, enhancing your investment strategies.",
      },
      {
        title: "AI",
        description:
          "Utilize TradeGPT, an AI tool validated by the IBM Hyper Protect Accelerator and winner of the Huawei AI & Generative AI Award. This tool offers enhanced insights and analytics to help you make informed investment decisions.",
      },
      {
        title: "Learn",
        description:
          "Stay updated and continuously learn through bi-monthly COSY Live Trading sessions, quarterly Trade Through The Night events, IPO insights, and updates on Spot ETFs. These resources will keep you informed about market trends and opportunities.",
      },
      {
        title: "Trade",
        description:
          "Participate in live trading sessions to apply the strategies you’ve learned and conduct in-depth analyses. These sessions provide a practical environment to hone your trading skills.",
      },
    ],
    nextSteps: {
      title: "NEXT STEPS",
      steps: [
        {
          title: "Join Facebook Community",
          description:
            "Connect with other investors at Spiking Facebook Community.",
          actions: [
            {
              label: "Join Facebook Community",
              link: "https://www.facebook.com/groups/spiking",
              type: "primary",
            },
          ],
        },
        {
          title: "Join Global Community",
          description:
            "Be updated on the Spiking Events in the Spiking Global Community.",
          actions: [
            {
              label: "Join Global Community",
              link: "https://spiking.com/join",
              type: "primary",
            },
          ],
        },
        {
          title: "Follow Dr. Clemen Chiang",
          description:
            "Stay updated with insights from Dr. Clemen Chiang by following him on Twitter.",
          actions: [
            {
              label: "Follow on Twitter",
              link: "https://x.com/clemenchiang",
              type: "primary",
            },
          ],
        },
      ],
    },
  };

  return <Content content={content} />;
};

export default Salt;
