import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

import { useStyles } from "./css"

import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import SearchIcon from "@material-ui/icons/Search"
import Slide from "@material-ui/core/Slide"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import InputBase from "@material-ui/core/InputBase"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { Avatar, Toolbar } from "@material-ui/core"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

const SearchDialogue = (props) => {
  const {
    toggleSearch,
    handleToggle,
    handleSearch,
    searchResults,
    handleCompanySelect,
    handleBluewhaleSelect,
    loading,
  } = props
  const classes = useStyles()
  const [open] = React.useState(false)
  const [inputValue, setInputValue] = React.useState(null)

  // let view = null;

  const handleInputChange = (event) => {
    handleSearch(event.target.value)
  }

  const handleCompanyClick = (id) => {
    handleToggle()
    handleCompanySelect(id)
  }

  const handleBluewhaleClick = (id) => {
    handleToggle()
    handleBluewhaleSelect(id)
  }

  // if (searchResults && searchResults.bluewhale.length === 0) {
  //   view = <div>No search results</div>;
  // }

  // if (searchResults && searchResults.company.length === 0) {
  //   view = <div>No search results</div>;
  // }

  // console.log("search");
  // console.log(searchResults);

  return (
    <Dialog
      fullScreen
      open={toggleSearch || open}
      onClose={handleToggle}
      TransitionComponent={Transition}
      classes={{ paper: classes.searchDialogueRoot }}
      style={{ zIndex: 3000 }}
    >
      <Toolbar className={classes.searchMain}>
        <IconButton color="inherit" onClick={handleToggle} aria-label="close">
          <ArrowBackIcon className={classes.arrowColor} />
        </IconButton>
        <div style={{ width: "95%" }}>
          <FormattedMessage id="filter.search">
            {(placeholder) => (
              <InputBase
                placeholder={"Search"}
                classes={{
                  root: classes.rootInput,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                autoFocus={true}
                onChange={handleInputChange}
                // onKeyDown={handleKeyPress}
              />
            )}
          </FormattedMessage>
        </div>
        <IconButton color="inherit" onClick={handleToggle} aria-label="close">
          <CloseIcon className={classes.arrowColor} />
        </IconButton>
        {/* <IconButton
          color="inherit"
          onClick={() => handleSearch(inputValue)}
          aria-label="close"
        >
          <SearchIcon />
        </IconButton> */}
      </Toolbar>

      {!loading ? (
        <div>
          {/* company */}
          <div className={classes.dialogueMain} container>
            {searchResults && searchResults.company.length > 0 ? (
              <Typography
                color="textPrimary"
                variant="h5"
                className={classes.displayName}
              >
                <FormattedMessage id="table.companies" />
              </Typography>
            ) : null}
            {searchResults && searchResults.company.length > 0
              ? searchResults.company.map((item) => (
                  <div
                    key={item.company.id}
                    elevation={3}
                    className={classes.paperRoot}
                    onClick={() => handleCompanyClick(item.company.id)}
                  >
                    <Grid container>
                      {/* <Grid item lg={1} md={1} sm={1} xs={1} style={{marginRight: "12px"}}>
                      <Avatar
                        alt={item.company.name}
                        src={item.company.image_url}
                      />
                    </Grid> */}
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.imageDiv}
                      >
                        <Avatar
                          alt={item.company.name}
                          src={item.company.image_url}
                        />
                        <div>
                          <Typography variant="h6" color="textPrimary">
                            {item.company.name}
                          </Typography>
                          <Typography
                            variant="overline"
                            color="textSecondary"
                            style={{ fontSize: "0.9rem" }}
                          >
                            ({item.company.ticker + " : " + item.company.market}
                            )
                          </Typography>
                        </div>
                        {/* <div>{}</div> */}
                      </Grid>
                    </Grid>
                  </div>
                ))
              : searchResults && searchResults.company.length <= 0
              ? null
              : // <div>
                //   <FormattedMessage id="filter.noSearchResults" />
                // </div>
                null}
          </div>

          {/* bluewhale */}
          <div className={classes.dialogueMain}>
            {searchResults && searchResults.bluewhale.length > 0 ? (
              <Typography
                color="textPrimary"
                variant="h5"
                className={classes.displayName}
              >
                <FormattedMessage id="table.insiders" />
              </Typography>
            ) : null}
            {searchResults && searchResults.bluewhale.length > 0 ? (
              searchResults.bluewhale.map((item) => (
                <div
                  key={item.bluewhale.id}
                  elevation={3}
                  className={classes.paperRoot}
                  onClick={() => handleBluewhaleClick(item.bluewhale.id)}
                >
                  <Grid container>
                    {/* <Grid item lg={1} md={1} sm={1} xs={1}>
                      <Avatar
                        alt={item.bluewhale.name}
                        src={item.bluewhale.image_url}
                      />
                    </Grid> */}
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.imageDiv}
                    >
                      <Avatar
                        alt={item.bluewhale.name}
                        src={item.bluewhale.image_url}
                      />
                      <div>
                        <Typography variant="h6" color="textPrimary">
                          {item.bluewhale.name}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ))
            ) : searchResults &&
              searchResults.bluewhale.length <= 0 &&
              searchResults.company.length <= 0 ? (
              <div>
                <FormattedMessage id="table.noData" />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div>{/* <FormattedMessage id="table.loading" /> */}Loading</div>
      )}
    </Dialog>
  )
}

SearchDialogue.propTypes = {
  toggleSearch: PropTypes.bool,
  handleToggle: PropTypes.func,
  handleSearch: PropTypes.func,
  searchResults: PropTypes.object,
  handleCompanySelect: PropTypes.func,
  handleBluewhaleSelect: PropTypes.func,
  loading: PropTypes.bool,
}

export default SearchDialogue
