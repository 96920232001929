import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import queryString from "query-string";

import * as actions from "../../store/actions/index";
import CommonTemplate from "../../components/templates/common";
import isToken from "../../helpers/token-check";
import isCordova from "../../helpers/cordova-check";
import { referralPopupInteract } from "../../store/actions/commonAc";

class Layout extends Component {
  state = {
    // isHome: false,
    selOption: null,
    selAccordian: null,
    openSpin: false,
  };
  componentDidMount() {
    let option = this.props.location.pathname.split("/")[1];
    if (option === "popularspikes") {
      const values = queryString.parse(this.props.location.search);
      option = values.category;
    } else if (option === "cryptos") {
      const values = queryString.parse(this.props.location.search);
      option = values.tags;
    }
    this.setState({ selOption: option });
    this.handleOnMountUpdate();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.handleOnMountUpdate();
    }
  }

  handleOnMountUpdate = () => {
    let pathname = this.props.location.pathname;
    if (pathname.includes("home")) {
      // this.setState({ isHome: true });
      this.setState({ selOption: "home", selAccordian: "dashboard" });
    }
    if (pathname.includes("dailybread")) {
      this.setState({ selOption: "dailybread", selAccordian: "stocks_ai" });
    } else if (pathname.includes("resurrection")) {
      this.setState({ selOption: "resurrection", selAccordian: "stocks_ai" });
    } else if (pathname.includes("triangulation")) {
      this.setState({ selOption: "triangulation", selAccordian: "stocks_ai" });
    } else if (pathname.includes("goat")) {
      this.setState({ selOption: "goat", selAccordian: "stocks_ai" });
    } else if (pathname.includes("yolo")) {
      this.setState({ selOption: "yolo", selAccordian: "stocks_ai" });
    } else if (pathname.includes("grit")) {
      this.setState({ selOption: "grit", selAccordian: "stocks_ai" });
    } else if (pathname.includes("trillion")) {
      this.setState({ selOption: "trillion", selAccordian: "stocks_ai" });
    } else if (pathname.includes("buyback")) {
      this.setState({ selOption: "buyback", selAccordian: "stocks_ai" });
    } else if (pathname.includes("/options")) {
      this.setState({
        selOption: this.props.location.pathname.split("/")[2],
        selAccordian: "options_ai",
      });
    } else if (pathname.includes("/cryptos")) {
      this.setState({
        selAccordian: "cryptos_ai",
      });
    } else if (
      pathname.includes("baiting") ||
      pathname.includes("explorespikes") ||
      pathname.includes("myspikes") ||
      pathname.includes("spikealerts") ||
      pathname.includes("/add")
    ) {
      this.setState({ selOption: "explorespikes", selAccordian: "dashboard" });
    } else if (pathname.includes("top100managers")) {
      this.setState({ selOption: "top100managers", selAccordian: "investors" });
    } else if (pathname.includes("universeoffunds")) {
      this.setState({ selOption: "universeFunds", selAccordian: "investors" });
    } else if (pathname.includes("universeofFunds")) {
      this.setState({ selOption: "following", selAccordian: "dashboard" });
    } else if (pathname.includes("courses")) {
      this.setState({ selOption: "bookcourses", selAccordian: "dashboard" });
    } else if (pathname.includes("settings")) {
      this.setState({ selOption: "settings" });
    } else if (
      pathname.includes("insiders") ||
      pathname.includes("ark") ||
      pathname.includes("investor")
    ) {
      if (pathname.includes("following")) {
        this.setState({ selOption: "following", selAccordian: "dashboard" });
      } else {
        this.setState({ selOption: "insiders", selAccordian: "dashboard" });
      }
    } else if (pathname.includes("funds")) {
      this.setState({
        selOption: this.props.match.params.bid,
        selAccordian: "investors",
      });
    } else if (pathname.includes("tradingdiary")) {
      this.setState({ selOption: "tradingdiary", selAccordian: "dashboard" });
    }
  };

  handleNotiBadgeClick = () => {
    let obj = {
      limit: 3,
      offset: 0,
      isPopup: true,
    };
    if (isCordova()) window.cordova.plugins.notification.badge.clear();
    this.props.acGetNotifications(obj);
  };

  handleReferralPopupClick = () => {
    this.props.referralInteract(true);
  };

  handleNotificationClick = (noti) => {
    // this.props.history.push("/baiting" + noti.querystring + "&prev=spikealerts")
    if (noti.querystring.toLowerCase().includes("baiting"))
      this.props.history.push(noti.querystring + "&prev=spikealerts");
    else this.props.history.push(noti.querystring);
    // window.location.reload();
  };

  handleViewAll = () => {
    this.props.history.push("/profile/notification");
  };

  handleMarkAllAsRead = () => {
    let obj = {
      limit: 3,
      offset: 0,
      isPopup: true,
    };
    this.props.acMarkNotificationAsRead(obj);
  };

  render() {
    const {
      notiLoading,
      notificationsData,
      acToggleSearch,
      acToggleLanguageDialogue,
      acPatchLanguageChange,
      localeData,
      openLanguageDialogue,
      user,
      fullWidth,
      testDeviceToken,
      acSetLightDarkMode,
    } = this.props;

    let isPublic = false;

    if (!isToken()) {
      isPublic = true;
    }

    return (
      <CommonTemplate
        fullWidth={fullWidth}
        handleNotiBadgeClick={this.handleNotiBadgeClick}
        notiData={!notiLoading && notificationsData ? notificationsData : null}
        notiLoading={notiLoading}
        toggleSearch={acToggleSearch}
        toggleLanguageDialogue={acToggleLanguageDialogue}
        patchLanguageChange={acPatchLanguageChange}
        localeData={localeData}
        isLanguageDialogueOpen={openLanguageDialogue}
        handleNotificationClick={this.handleNotificationClick}
        handleViewAll={this.handleViewAll}
        handleMarkAllAsRead={this.handleMarkAllAsRead}
        referralInteract={this.handleReferralPopupClick}
        user={user}
        isPopup={true}
        defaultSelectedOption={this.state.selOption}
        isPublic={isPublic}
        testDeviceToken={testDeviceToken}
        handleLightMode={acSetLightDarkMode}
        selAccordian={this.state.selAccordian}
      >
        {this.props.children}
      </CommonTemplate>
    );
  }
}

Layout.propTypes = {
  loading: PropTypes.bool,
  acGetNotifications: PropTypes.func,
  notificationsData: PropTypes.object,
  acMarkNotificationAsRead: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    notiLoading: state.common.notificationLoading,
    notificationsData: state.common.popupNotificationsData,
    localeData: state.login.localeData,
    openLanguageDialogue: state.login.toggleLanguageDialogue,
    user: state.home.user,
    testDeviceToken: state.login.testDeviceToken,
    isLightMode: state.common.isLightMode,
    drawerOpen: state.common.drawerOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acGetNotifications: (obj) => dispatch(actions.getNotifications(obj)),
    acToggleSearch: () => dispatch(actions.toggleSearch()),
    acToggleLanguageDialogue: () => dispatch(actions.toggleLanguageDialogue()),
    acPatchLanguageChange: (localeData) =>
      dispatch(actions.patchLanguageChange(localeData)),
    acMarkNotificationAsRead: (obj) =>
      dispatch(actions.markNotificationAsRead(obj)),
    referralInteract: (state) => dispatch(referralPopupInteract(state)),
    acDeleteDeviceToken: (obj) => dispatch(actions.deleteDeviceToken(obj)),
    acSetLightDarkMode: (data) => dispatch(actions.setLightDarkMode(data)),
    acToggleSideDrawer: (value) => dispatch(actions.toggleSideDrawer(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
