import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactCountryFlag from "react-country-flag";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  languageButton: {
    textTransform: "capitalize",
    fontFamily: "Poppins",
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
    color: (props) =>
      props.isSideDrawer
        ? theme.palette.common.white
        : theme.palette.text.secondary,
  },
}));

const LanguageChange = (props) => {
  const { localeData, toggleLanguageDialogue, isSideDrawer, ...rest } = props;
  const classes = useStyles({ isSideDrawer: isSideDrawer });

  return (
    <Box display="flex" alignItems="center" {...rest}>
      <ReactCountryFlag
        onClick={toggleLanguageDialogue}
        countryCode={localeData.countryCode}
        svg
        style={{
          width: "1.5em",
          height: "1.5em",
          cursor: "pointer",
        }}
      />
      {isSideDrawer && (
        <Button
          onClick={toggleLanguageDialogue}
          className={classes.languageButton}
        >
          {localeData.language}
        </Button>
      )}
    </Box>
  );
};

export default LanguageChange;
