import { combineReducers } from "redux";

import loginReducer from "./loginReducer";
import homeReducer from "./homeReducer";
import commonReducer from "./commonReducer";
import leaderboardReducer from "./leaderboardReducer";
import dashboardReducer from "./dashboardReducer";
import publicReducer from "./publicReducer";
import navbarReducer from "./navbarReducer";
import spikeUniverseReducer from "./spikeUniverseReducer";
import learnReducer from "./learnReducer";
import arkReducer from "./arkReducer";
import fundsReducer from "./fundsReducer";
import optionsReducer from "./optionsReducer";
import coursesReducer from "./coursesReducer";
import cryptosReducer from "./cryptosReducer";
import eventReducer from "./eventReducer";
import challengeReducer from "./challengeReducer";
import profileReducer from "./profileReducer";
import blockchainReducer from "./blockchainReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  home: homeReducer,
  common: commonReducer,
  leaderboard: leaderboardReducer,
  dashboard: dashboardReducer,
  public: publicReducer,
  navbar: navbarReducer,
  spikeUniverse: spikeUniverseReducer,
  event: eventReducer,
  learn: learnReducer,
  ark: arkReducer,
  funds: fundsReducer,
  options: optionsReducer,
  courses: coursesReducer,
  cryptos: cryptosReducer,
  challenge: challengeReducer,
  profile: profileReducer,
  blockchain: blockchainReducer,
});

export default rootReducer;
