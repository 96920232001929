import axios from "axios";

import * as actionTypes from "./actionTypes";
import { loading } from "./commonAc";
import isCordova from "../../helpers/cordova-check";
import { isAndroid, isIos } from "../../helpers/device-check";
import clearStorage from "../../helpers/clear_localStorage";
import { getUserDetails } from "./homeAc";

let id = process.env.REACT_APP_WEB_CLIENT_ID;

const options = {
  scopes: "profile email",
  webClientId: id,
  offline: true,
};

let apiDomain = process.env.REACT_APP_API_URL;
let userDetailsDomain = process.env.REACT_APP_API2_URL;
let idpDomain = process.env.REACT_APP_IDP_URL;

// export const loading = (value) => {
//   return {
//     type: actionTypes.LOADING,
//     data: value
//   };
// };

export const toggleLanguageDialogue = () => {
  return {
    type: actionTypes.TOGGLE_LANGUAGE_DILOGUE,
  };
};

export const isLoggedIn = (value) => {
  return {
    type: actionTypes.IS_LOGGED_IN,
    data: value,
  };
};

export const urlToRedirect = (url) => {
  return {
    type: actionTypes.SET_URL_TO_REDIRECT,
    data: url,
  };
};

export const setRedirectUser = (data) => {
  return {
    type: actionTypes.REDIRECT,
    data: data,
  };
};

export const autoSigninUser = (data) => {
  return {
    type: actionTypes.AUTO_SIGNIN_USER,
    data: data,
  };
};

export const redirectUser = (data) => {
  return (dispatch) => {
    dispatch(loading(false));
    // dispatch(urlToRedirect(url));
    dispatch(setRedirectUser(data.isRedirect));
  };
};

export const postWebPurchaseReceiptDone = (data) => {
  return {
    type: actionTypes.POST_WEB_PURCHASE_RECEIPT_DONE,
    data: data,
  };
};

export const postDeviceTokenDone = (data) => {
  return {
    type: actionTypes.POST_DEVICE_TOKEN_SUCCESS,
    data: data,
  };
};

export const postTermsAndConditionsDone = (data) => {
  return {
    type: actionTypes.AGREE_TO_TERMS_DONE,
    data: data,
  };
};

export const test_storeDeviceToken = (data) => {
  return {
    type: actionTypes.TEST_STORE_DEVICE_TOKEN,
    data: data,
  };
};

export const upgradeRestoreDone = (data) => {
  return {
    type: actionTypes.UPGRADE_RESTORE_DONE,
    data: data,
  };
};

export const verifyEmailDone = (data) => {
  return {
    type: actionTypes.VERIFY_EMAIL_DONE,
    data: data,
  };
};

export const checkEmailDone = (data) => {
  return {
    type: actionTypes.CHECK_EMAIL_DONE,
    data: data,
  };
};

export const setLogoutMobile = (data) => {
  return {
    type: actionTypes.LOGOUT_MOBILE,
    data: data,
  };
};

// export const clearLocalStorage = () => {
//   localStorage.clear();
//   localStorage.setItem("isCredentialsUpdate", true);
// };

// export const redirectUser = (data) => {
//   return {
//     type: actionTypes.REDIRECT,
//     data: data,
//   };
// };

export const getAccessToken = (fromAxios) => {
  return (dispatch) => {
    dispatch(loading(true));
    let loginToken = localStorage.getItem("login_token");
    let mode = localStorage.getItem("login_mode");

    let body = new FormData();
    body.append(`${mode}`, loginToken);

    axios
      .post(idpDomain + "/oauth", body)
      .then((response) => {
        // alert("get access token success")
        let token = response.data.access_token;
        let refresh = response.data.refresh_token;
        localStorage.setItem("token", token);
        localStorage.setItem("refresh", refresh);
        dispatch(loading(false));
        dispatch(isLoggedIn(true));
        if (fromAxios) window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        // alert("error")
        console.log(error);
        dispatch(loading(false));
      });
  };
};

export const postDeviceToken = (obj) => {
  return (dispatch) => {
    axios
      .post(
        userDetailsDomain +
          "/v3/device_token?platform=" +
          obj.platform +
          "&device=" +
          obj.deviceId
      )
      .then((res) => {
        dispatch(postDeviceTokenDone(false));
        dispatch(test_storeDeviceToken(obj.deviceId));
        // alert("device token post success");
      });
  };
};

export const deleteDeviceToken = (obj) => {
  return (dispatch) => {
    axios
      .delete(
        userDetailsDomain +
          "/v3/device_token?platform=" +
          obj.platform +
          "&device=" +
          obj.deviceId
      )
      .then((res) => {
        if (isCordova()) {
          let loginMode = localStorage.getItem("login_mode");

          // facebook logout
          if (loginMode === "facebook") {
            window.facebookConnectPlugin.logout(
              (res) => {
                // clearStorage(true)
                // window.location.reload(true)
                dispatch(setLogoutMobile(true));
                // alert("success fb logout");
              },
              (err) => {
                // clearStorage(true)
                // window.location.reload(true)
                dispatch(setLogoutMobile(true));
                // alert("failure fb logout");
              }
            );
          } else if (loginMode === "google" || loginMode === "firebasetoken") {
            dispatch(setLogoutMobile(true));
            // google logout
            // window.plugins.googleplus.logout(
            //   (msg) => {
            //     alert("inside logout")
            //     clearStorage(true)
            //     window.location.reload(true)
            //   },
            //   (err) => {
            //     // alert(err);
            //   }
            // )
            // window.plugins.googleplus.trySilentLogin(
            //   options,
            //   (response) => {
            //     window.plugins.googleplus.logout(
            //       (msg) => {
            //         // alert("inside logout")
            //         // clearStorage(true)
            //         // window.location.reload(true)
            //         dispatch(setLogoutMobile(true));
            //       },
            //       (err) => {
            //         // alert(err);
            //       }
            //     );
            //   },
            //   (error) => {
            //     // alert(error)
            //     window.plugins.googleplus.disconnect(
            //       (msg) => {
            //         // clearStorage(true)
            //         // window.location.reload(true)
            //         dispatch(setLogoutMobile(true));
            //       },
            //       (err) => {
            //         // alert("silent login fail, disconnect fail");
            //         // alert(err);
            //         // clearStorage(true)
            //         // window.location.reload(true)
            //         dispatch(setLogoutMobile(true));
            //       }
            //     );
            //   }
            // );
          } else if (loginMode === "apple") {
            // clearStorage(true)
            // window.location.reload(true)
            dispatch(setLogoutMobile(true));
          }
        }
        // dispatch(postDeviceTokenDone(false))
        // dispatch(test_storeDeviceToken(obj.deviceId))
        // alert("device token post success");
      });
  };
};

export const postInAppPurchaseReceiptLogs = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    let body = new FormData();
    body.append("receipt", JSON.stringify(obj.receipt));
    body.append("platform", obj.platform);
    if (obj.action) body.append("action", obj.action);
    axios
      .post(userDetailsDomain + "/v3/in_app_purchase_receipt", body)
      .then((res) => {
        let data = res.data;
        // alert("in app post success")
        dispatch(loading(false));
        dispatch(upgradeRestoreDone(data));
        // window.location.reload();
      });
  };
};

export const postWebPurchaseReceipt = (receipt) => {
  return (dispatch) => {
    let body = new FormData();
    body.append("receipt", receipt);
    axios
      .post(userDetailsDomain + "/v3/stripe_purchase_receipt", body)
      .then((res) => {
        // localStorage.removeItem("webPurchaseReceipt");
        dispatch(postWebPurchaseReceiptDone(true));
        localStorage.setItem("isWebPurchaseInProgress", false);
        localStorage.removeItem("sign_up_affiliate_id");
        // this is a fix for crypto, after purchase land on crypto dashboard directly
        dispatch(getUserDetails());
      });
  };
};

export const postTermsAndConditions = (ipAdd, pathname) => {
  return (dispatch, getState) => {
    let body = new FormData();
    body.append("ip_address", ipAdd);
    axios.patch(userDetailsDomain + "/v3/agree_to_terms", body).then((res) => {
      // alert("post receipt success");
      if (!isCordova()) {
        let receipt = localStorage.getItem("webPurchaseReceipt");
        if (receipt) {
          window.location.href = "/receipt?i=" + receipt;
        } else if (
          pathname.includes("welcome") ||
          pathname.includes("members-area")
        ) {
          window.location.reload();
        } else {
          if (getState().home.user.IsProBrilliant)
            window.location.href =
              "/dailybread-new?tags=net_positive_purchase&load=1";
          else if (getState().home.user.IsOptionsMembership)
            window.location.href = "/options/open-interest";
          else
            window.location.href =
              "/dailybread-new?tags=net_positive_purchase&load=1";
        }
      } else {
        dispatch(postTermsAndConditionsDone(true));
      }
    });
  };
};

export const storeAppSoftHardUpdateData = (appUpdateCode) => {
  return {
    type: actionTypes.APP_HARD_SOFT_UPDATE,
    data: appUpdateCode,
  };
};

export const storeSystemUpdateData = (data) => {
  return {
    type: actionTypes.SYSTEM_UPDATE,
    data: data,
  };
};

export const patchUserDetails = (obj) => {
  return (dispatch) => {
    // dispatch(loading(true))
    let body = new FormData();
    body.append("firstname", obj.firstName);
    body.append("lastname", obj.lastName);
    body.append("mobile_number", obj.mobile_number);
    body.append("mobile_extension", obj.mobile_extension);
    // body.append("zoom_email", obj.email)

    axios
      .patch(userDetailsDomain + "/v3/me", body)
      .then((res) => {
        if (obj.reloadPage) {
          window.location.reload();
        }
        // alert("patch success")
        // dispatch(changeLanguage(localeData));
        // dispatch(loading(false));
        // window.location.reload()
      })
      .catch((err) => {
        // alert("error")
      });
  };
};

export const postCheckEmail = (email) => {
  return (dispatch) => {
    dispatch(loading(true));

    let body = new FormData();
    body.append("email", email);

    axios
      .post(idpDomain + "/check-email", body)
      .then((res) => {
        dispatch(checkEmailDone(res.data));
        // dispatch(changeLanguage(localeData));
        dispatch(loading(false));
        // window.location.reload()
      })
      .catch((err) => {
        // alert("error")
      });
  };
};

export const postVerifyEmail = (code) => {
  return (dispatch) => {
    dispatch(loading(true));

    let body = new FormData();
    body.append("code", code);

    axios
      .post(idpDomain + "/verify-email", body)
      .then((res) => {
        dispatch(verifyEmailDone(res.data));
        dispatch(loading(false));
        // dispatch(changeLanguage(localeData));
        // dispatch(loading(false));
        // window.location.reload()
      })
      .catch((err) => {
        // alert("error")
      });
  };
};

export const postSignupAffiliateid = (obj) => {
  return (dispatch) => {
    let body = new FormData();
    body.append("sign_up_affiliate_id", obj.sign_up_affiliate_id);
    body.append("return_to", obj.return_to);

    axios.post(userDetailsDomain + "/v3/track_affiliate", body).then((res) => {
      let data = res.data;
      dispatch(loading(false));
      localStorage.removeItem("sign_up_affiliate_id");
      localStorage.removeItem("template_id");
      localStorage.removeItem("sign_up_return_to");
      localStorage.removeItem("login_banner");
    });
  };
};

export const postUpdateUserReferrer = (obj) => {
  return axios.post(userDetailsDomain + "/v3/update-referrer", obj);
};

export const postUpdateUserSpinToken = (obj) => {
  return axios.post(userDetailsDomain + "/v3/spin-wheel/claim-reward", obj);
};
