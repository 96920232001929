import PageNotFoundIcon from "../../images/pagenotfound_icon.png"
import NoInternetIcon from "../../images/nointernet_icon.png"
import ServerErrorIcon from "../../images/servererror_icon.png"

export const PAGE_LINKS = [
  {
    title: "Daily Bread",
    href: "/dailybread-new",
  },
  {
    title: "Resurrection",
    href: "/resurrection-new",
  },
  {
    title: "Triangulation",
    href: "/triangulation-new",
  },
  // TODO link for GOAT and YOLO
  {
    title: "GOAT",
    href: "/",
  },
  {
    title: "YOLO",
    href: "/",
  },
]

export const PAGE_NOT_FOUND = {
  overline: "Error 404",
  header: "Oops, no Spikes here!",
  body: [
    "We couldn’t find what you were looking for.",
    "Fortunately, you can still find other Spiking-related content here:",
  ],
  image: PageNotFoundIcon,
  showLinkBar: true,
  showRefresh: true
}

export const NO_INTERNET_CONNECTION = {
  overline: "Internet Connection Lost",
  header: "No connection here",
  body: [
    "We’re experiencing connectivity issues.",
    " Hit refresh or try again later.",
  ],
  image: NoInternetIcon,
  showLinkBar: false,
  showRefresh: true
}

export const SERVER_ERROR = {
  overline: "Server Error | 505",
  header: `It's not you, it's us`,
  body: [
    "We’ve got our wires crossed – do give us a moment to resolve the issue. Meanwhile, you can still find other Spiking-related content here: ",
  ],
  image: ServerErrorIcon,
  showLinkBar: true,
  showRefresh: true
}

export const SYSTEM_UPDATE = {
  overline: "System Update",
  header: `It's not you, it's us`,
  body: [
    "We are currently updating our Website. Check back soon!",
  ],
  image: ServerErrorIcon,
  showLinkBar: false,
  showRefresh: false
}
