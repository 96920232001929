import * as actionTypes from "../actions/actionTypes";
const initialState = {
  data: null,
  error: null,
  isLoading: true,
  isEdit: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_PROFILE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        data: payload,
      };
    }
    case actionTypes.GET_PROFILE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case actionTypes.TOGGLE_EDIT_PROFILE_REQUEST: {
      return {
        ...state,
        isEdit: payload,
      };
    }
    case actionTypes.TOGGLE_EDIT_PROFILE_SUCCESS: {
      return {
        ...state,
        isEdit: false,
      };
    }
    case actionTypes.TOGGLE_EDIT_PROFILE_FAILURE: {
      return {
        ...state,
        isEdit: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
