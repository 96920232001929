import React, { useCallback, useEffect, useState } from 'react';
import { useStyles } from './css';
import { ReactComponent as GuidedTourIcon } from "../../images/guide.svg";
import { ReactComponent as ExtendIcon } from "../../images/spread.svg";
import { ReactComponent as ShrinkIcon } from "../../images/srink.svg";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Box, Button, debounce, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import Step from './Step';
import './style.css'
import { getListGuideTour } from '../../store/actions/commonAc';

function GuideTour(props) {
  const [guideOpen, setGuideOpen] = useState(false);
  const [guideList, setGuideList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [textContent, setTextContent] = useState('');
  const [textQuery, setTextQuery] = useState('');
  const [headerTopic, setHeaderTopic] = useState('');
  const [guideListId, setGuideListId] = useState([])
  const [guideTitle, setGuideTitle] = useState('')
  const [percentage, setProgressPercentage] = useState(0);
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles();
  const [activeGuidedContent, setActiveContent] = useState(false);

  const getListGuider = async () => {
    try {
      const response = await getListGuideTour();
      if (response.status === 200) {
        setGuideTitle(response.data?.title);
        setGuideList(response.data?.items);
        setSearchList(response.data?.items);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getGuideListID = async () => {
    const guideListId = JSON.parse(await localStorage.getItem('guideList'));
    if (guideListId !== null || guideListId?.length > 0) {
      setGuideListId(guideListId)
    }
  }

  const clickContent = async (id, type, checked) => {
    const selectedItem = guideList.filter(guide => guide.id === id)
    if (type === 'text') {
      setActiveContent(true)
      setTextContent(selectedItem[0].content)
      setHeaderTopic(selectedItem[0].title)
    }
    if (type === 'video') {
      if (!checked) {
        const newGuideList = [...guideListId, id]
        setGuideListId(newGuideList)
        localStorage.setItem('guideList', JSON.stringify(newGuideList))
      }
      setVideoDialogOpen(true)
      setHeaderTopic(selectedItem[0].title)
      setTextContent(selectedItem[0].embed_video)
    }
    if (type === 'url') {
      if (!checked) {
        const newGuideList = [...guideListId, id]
        setGuideListId(newGuideList)
        localStorage.setItem('guideList', JSON.stringify(newGuideList))
      }
      window.open(selectedItem[0].url, '_blank')
    }
  }

  const searchItem = (value) => {
    let queriedList = guideList.filter(guide => guide.title.toLowerCase().includes(value));
    setSearchList(queriedList);
  }


  const handleInputOnChange = (event) => {
    setTextQuery(event.target.value)
  }

  useEffect(() => {
    searchItem(textQuery)
  }, [textQuery])

  useEffect(() => {
    getGuideListID();
    getListGuider();
  }, [])

  useEffect(() => {
    let matchNumber = 0;
    const guideListSum = guideList.filter(guide => guide.type !== 'text').length;
    guideList.forEach(guide => {
      if (guideListId.includes(guide.id)) {
        matchNumber++;
      }
    });
    setProgressPercentage(Math.round((matchNumber / guideListSum) * 100))
  }, [guideListId, guideList])

  return (
    <div style={{ maxWidth: expanded ? 800 : 400, width: guideOpen ? '80%' : 'auto', height: guideOpen ? '70%' : 'auto' }} className={classes.tourContainer}>
      {guideOpen &&
        <Paper elevation={1} style={{ position: 'relative', overflow: 'hidden' }}>
          <Box width={'100%'} height={'100%'} overflow="scroll" minWidth={300}>
            <Grid container alignItems='center'>
              <Box py={1} width={'100%'} height={500}>
                <Box px={2} mb={2}>
                  <Grid alignItems='center' container justify='space-between'>
                    {expanded ? <SvgIcon onClick={() => setExpanded(!expanded)}>
                      <ShrinkIcon />
                    </SvgIcon> :
                      <SvgIcon onClick={() => setExpanded(!expanded)}>
                        <ExtendIcon />
                      </SvgIcon>
                    }
                    <Typography>
                      <span role='img'>🎓 </span>Spiking 101
                    </Typography>
                  </Grid>
                </Box>
                <Box mb={2} px={2}>
                  <TextField
                    value={textQuery}
                    onChange={handleInputOnChange}
                    variant="outlined"
                    size='small'
                    fullWidth='100%'
                    label="Search"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                <Box px={2} fontWeight={600} fontSize={16}>
                  {guideTitle}
                </Box>
                {(!isNaN(percentage) || percentage !== null || percentage !== undefined) && <Box px={2}>
                  <Grid container justify='space-between' alignItems="center">
                    <Grid items className={classes.percentageBarBackground}>
                      <div className={classes.percentageBarValue} style={{ width: `${percentage}%` }} />
                    </Grid>
                    <Box fontWeight={600} fontSize={14} color={'#0D8BFF'} mx={1}>
                      {percentage} %
                    </Box>
                  </Grid>
                </Box>}
                {searchList.map(guide => {
                  return (
                    <Step clickContent={clickContent} id={guide.id} stepTitle={guide.title} type={guide.type} checked={guideListId.includes(guide.id)} />
                  )
                })}
              </Box>
              <Box className={activeGuidedContent ? classes.contentTopicContainerActive : classes.contentTopicContainerInactive}>
                <Box py={1} width={'100%'} height={500}>
                  <Box px={2} pb={2}>
                    <Grid onClick={() => setActiveContent(false)} container alignItems="center">
                      <IconButton size='small'>
                        <ArrowBackIos />
                      </IconButton>
                      <Box ml={2} fontWeight={600}>
                        {headerTopic}
                      </Box>
                    </Grid>
                  </Box>
                  <Divider light />
                  <Box px={2} mt={2}>
                    {textContent}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Paper>
      }
      <Dialog
        open={videoDialogOpen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">{headerTopic}</DialogTitle>
        <DialogContent >
          <Divider />
          <div style={{ height: '100%', padding: '10px 10px' }}>
            <div className="video-container-guided">
              <div dangerouslySetInnerHTML={{ __html: textContent }}>
              </div>
            </div>
          </div>
          <Divider />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVideoDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      {guideList && guideList.length > 0 && <Grid container justify='flex-end'>
        <div onClick={() => setGuideOpen(!guideOpen)} className={classes.guideButtonBackground}>
          <SvgIcon>
            <GuidedTourIcon />
          </SvgIcon>
        </div>
      </Grid>}
    </div>
  );
}

export default GuideTour;