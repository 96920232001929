import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
// import { Offline, Online } from "react-detect-offline";
import ReactGA from "react-ga4";
// import ReactGA from "react-ga";
import Cookies from "universal-cookie";

import "./App.css";
import DarkTheme from "./css";
import * as actions from "../../store/actions/index";
import isToken from "../../helpers/token-check";
import { isAndroid, isIos } from "../../helpers/device-check";
import LocaleMessages from "../../helpers/locale-messages";
import Loader from "../../components/loader/loader";
import ReturnToSpinner from "../../components/loader/returnToLoader";
import RedirectComp from "../Redirect/container";
import isCordova from "../../helpers/cordova-check";
import HardUpdateModal from "./components/hardUpdateModal";
import SoftUpdateModal from "./components/softUpdateModal";
import AllRoutes from "./components/routes";
import Tutorial from "./components/tutorialTestModal";
import AnnouncementDialog from "./components/announcementDialog";
import SystemUpdate from "../Errors/SystemUpdate";
import getLocaleData from "../../helpers/getLocaleData";
import Rating from "./components/ReviewRating";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { ThemeProvider } from "@material-ui/core/styles";
import { QueryClient, QueryClientProvider } from "react-query";
// import ReferralPopup from "../../components/ReferralPopup/ReferralPopup";

let GACalledOnPageLoad = false;
let claritySetOnPageLoad = false;
let facebookPixelOnPageLoad = false;
let hardAppUpdateCalledOnPageLoad = false;
let isSystemUpdateCalled = false;
let receiptFacebookPixelOnPageLoad = false;
let languagePatchCalled = false;
let GTCalledOnPageLoad = false;
const isWebDev = process.env.REACT_APP_IS_WEB_DEV;

const cookies = new Cookies();
const queryClient = new QueryClient();

class App extends Component {
  state = {
    giveAppAccess: true,
    showHardUpdateModal: false,
    showSoftUpdateModal: false,
    showTutorial: false,
  };

  componentDidMount() {
    if (isToken() && localStorage.getItem("login_mode") === "facebook") {
      this.props.acAutoSigninUser(true);
    }
    if (isToken() && this.props.isPostDeviceToken) {
      this.handleAppDeviceIdSetup();
    }
    if (isCordova()) {
      document.addEventListener("resume", this.onAppResume, false);
      window.screen.orientation.lock("portrait");
    }
    // google translate init
    this.initGoogleTransalte(true);

    // call ga for public routes
    if (!isToken() && isWebDev === "false") {
      this.setGoogleAnalyticsPageView();
      this.setGoogleTag();
      this.setClarity();
    }
  }

  onAppResume = () => {
    if (this.props.user && this.props.user.UnViewedNotificationCount <= 0) {
      window.cordova.plugins.notification.badge.clear();
    }
    if (isToken()) {
      if (this.props.location.pathname.includes("notifications")) {
        let obj = {
          limit: this.props.notificationLimit,
          offset: 0,
          isPopup: false,
          onLoad: true,
          isResumeApp: true,
        };
        this.props.acSetNotificationOffset(0);
        this.props.acGetNotifications(obj);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      this.props.acGetUserDetails(true);
    }
  };

  componentDidUpdate(prevProps) {
    // this code stores the previously visited path in case of session timeout to redirect the user back to the page after login
    if (prevProps.location !== this.props.location) {
      if (!isToken()) {
        if (
          prevProps.location.pathname !== "/login" &&
          prevProps.location.pathname !== "/login-old" &&
          prevProps.location.pathname !== "/email-login" &&
          prevProps.location.pathname !== "/create-account" &&
          prevProps.location.pathname !== "/forgot-password" &&
          prevProps.location.pathname !== "/reset-password" &&
          prevProps.location.pathname !== "/logout" &&
          prevProps.location.pathname !== "/react" &&
          !prevProps.location.pathname.includes("dailytrades")
        ) {
          let url = prevProps.location.pathname;
          if (prevProps.location.search !== "") {
            this.props.acSetUrlToRedirect(url + prevProps.location.search);
          } else {
            this.props.acSetUrlToRedirect(url);
          }
          // this is to solve the issue on directing to referral page on mobile browser
          if (url.includes("settings/referral")) {
            localStorage.setItem("mustRedirectUrl", "/settings/referral");
          } else if (url.includes("checkout")) {
            localStorage.setItem(
              "mustRedirectUrl",
              "/checkout" + prevProps.location.search
            );
          }
        }
        // else {
        //   if (
        //     this.props.location.pathname !== "/login" &&
        //     this.props.location.pathname !== "/email-login" &&
        //     this.props.location.pathname !== "/create-account" &&
        //     this.props.location.pathname !== "/forgot-password" &&
        //     this.props.location.pathname !== "/reset-password"
        //   ) {
        //     console.log("im heree")
        //     this.props.acSetUrlToRedirect("/home/spikingup");
        //   }

        // }
      }

      if (isCordova()) {
        // in app review
        if (this.props.user) {
          let count = this.props.user.AppScreenCount;
          let limit = this.props.user.AppScreenLimit;
          if (
            count === limit ||
            count === limit * 2 ||
            count === limit * 3 ||
            count === limit * 4
          ) {
            Rating();
          }
        }
      }

      if (isWebDev === "false") {
        this.setGoogleAnalyticsPageView();
      }
      this.handleAppDeviceIdSetup();
      this.handleAppSoftHardUpdate();
      this.handleSystemUpdate();
    }

    if (isCordova()) {
      // clear badge
      if (this.props.user && this.props.user.UnViewedNotificationCount <= 0) {
        window.cordova.plugins.notification.badge.clear();
      }
    }
    if (isToken()) {
      if (this.props.user && !GACalledOnPageLoad && isWebDev === "false") {
        this.setGoogleAnalyticsPageView();
        GACalledOnPageLoad = true;
      }
    } else {
      if (!GACalledOnPageLoad && isWebDev === "false") {
        this.setGoogleAnalyticsPageView();
      }
    }

    if (isWebDev === "false" && !claritySetOnPageLoad) {
      this.setClarity();
      claritySetOnPageLoad = true;
    }
    if (isWebDev === "false" && !GTCalledOnPageLoad) {
      this.setGoogleTag();
      GTCalledOnPageLoad = true;
    }
    if (isWebDev === "false" && !facebookPixelOnPageLoad) {
      this.setFacebookPixel();
      facebookPixelOnPageLoad = true;
    }
    if (this.props.appUpdateCode && !hardAppUpdateCalledOnPageLoad) {
      this.handleAppSoftHardUpdate();
      hardAppUpdateCalledOnPageLoad = true;
    }
    if (this.props.isSystemUpdate && !isSystemUpdateCalled) {
      this.handleSystemUpdate();
      isSystemUpdateCalled = true;
    }
    if (
      isWebDev === "false" &&
      this.props.location.pathname.includes("receipt") &&
      !receiptFacebookPixelOnPageLoad
    ) {
      this.setReceiptFacebookPixel();
      receiptFacebookPixelOnPageLoad = true;
    }
    if (this.props.location.pathname.includes("login")) {
      let receipt = localStorage.getItem("webPurchaseReceipt");
      if (receipt && isWebDev === "false") {
        this.setReceiptFacebookPixel();
      }
    }
    if (isToken() && localStorage.getItem("login_mode") === "facebook") {
      this.props.acAutoSigninUser(true);
    }

    // google translate init
    this.initGoogleTransalte(false);

    let storedLang = localStorage.getItem("lang");
    if (
      isToken() &&
      storedLang &&
      !storedLang.includes("english") &&
      !languagePatchCalled
    ) {
      let localedata = getLocaleData(storedLang);
      languagePatchCalled = true;
      this.props.acPatchLanguageChange(localedata);
    }
  }

  setGoogleAnalyticsPageView = () => {
    if (this.props.user) {
      ReactGA.set({ userId: this.props.user.Id });
      window.gtag("set", "user_properties", {
        customeruserid: this.props.user.Id,
      });
      if (isCordova()) {
        window.FirebasePlugin.logEvent("select_content", {
          content_type: "page_view",
          item_id: window.location.pathname + window.location.search,
        });
        window.FirebasePlugin.setScreenName(
          window.location.pathname + window.location.search
        );
        window.FirebasePlugin.setUserId(this.props.user.Id);
      }
    }
    // ReactGA.pageview(window.location.pathname + window.location.search);
  };

  initGoogleTransalte = (isLoad) => {
    let isGT = document.getElementById("google_translate");
    if (isToken() && this.props.user && (!isGT || isLoad)) {
      if (this.props.user.Language === "chinese_simp") {
        this.createGoogleTranslateScript("zh-CN");
      } else if (this.props.user.Language === "chinese_trad") {
        this.createGoogleTranslateScript("zh-TW");
      } else if (this.props.user.Language === "vietnamese") {
        this.createGoogleTranslateScript("vi");
      }
    } else if (!isToken() && (!isGT || isLoad)) {
      let lan = cookies.get("googtrans");
      if (lan) {
        if (lan.includes("CN")) this.createGoogleTranslateScript("zh-CN");
        else if (lan.includes("TW")) this.createGoogleTranslateScript("zh-TW");
        else if (lan.includes("vi")) this.createGoogleTranslateScript("vi");
      }
    }
  };

  createGoogleTranslateScript = (lang) => {
    try {
      const div = document.createElement("div");
      div.id = "google_translate_element";
      document.body.appendChild(div);
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "google_translate";
      script.innerHTML =
        "function googleTranslateElementInit() {\n" +
        "new window.google.translate.TranslateElement( \n" +
        `{ pageLanguage: "en" , includedLanguages: "zh-CN,zh-TW,en,fr,de,es,it,ru,ja,vi", autoDisplay: false},\n` +
        '"google_translate_element");\n' +
        'var a = document.querySelector("#google_translate_element select"); \n' +
        "a.selectedIndex=1; \n" +
        'a.dispatchEvent(new Event("change")); \n' +
        "}";

      document.body.appendChild(script);

      const script2 = document.createElement("script");
      script2.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script2.async = true;
      script2.id = "google_translate2";

      document.body.appendChild(script2);
    } catch (error) {
      console.log("Google translate error", error);
    }
  };

  setClarity = () => {
    try {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "df2aemt0cj");`;
      document.body.appendChild(script);
    } catch (error) {
      console.log("Hotjar error", error);
    }
  };

  setGoogleTag = () => {
    try {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NNCXQHB');
      `;
      document.body.appendChild(script);
    } catch (error) {
      console.log("Google Tag error", error);
    }
  };

  setFacebookPixel = () => {
    try {
      const script = document.createElement("script");
      script.innerHTML = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '698264110625800');
      fbq('track', 'PageView');`;
      document.body.appendChild(script);

      const noscript = document.createElement("noscript");
      noscript.innerHTML = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=698264110625800&ev=PageView&noscript=1"/>`;
      document.body.appendChild(noscript);
    } catch (error) {
      console.log("Facebook Pixel error", error);
    }
  };

  setReceiptFacebookPixel = () => {
    try {
      const script = document.createElement("script");
      script.innerHTML = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '698264110625800');
      fbq('track', 'PageView');
      fbq('track', 'Purchase', {value: 97.00, currency: 'USD'});`;
      document.body.appendChild(script);

      const noscript = document.createElement("noscript");
      noscript.innerHTML = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=698264110625800&ev=PageView&noscript=1"/>`;
      document.body.appendChild(noscript);
    } catch (error) {
      console.log("Facebook Pixel Receipt error", error);
    }
  };

  handleAppDeviceIdSetup = () => {
    if (isCordova()) {
      if (isToken() && this.props.isPostDeviceToken) {
        this.handleGrantPermissionIosAndroid();
      }
      this.handleNotificationReceived();
      this.handleUniversalLinks();
    }
  };

  handleAppSoftHardUpdate = () => {
    if (isCordova()) {
      // hard and soft app update
      if (this.props.appUpdateCode === "hard") {
        this.setState({
          giveAppAccess: false,
          showHardUpdateModal: true,
          showSoftUpdateModal: false,
        });
      } else if (this.props.appUpdateCode === "soft") {
        let showSoftModal = false;
        let softUpdateTimeStamp = localStorage.getItem("softUpdateTimeStamp");
        if (softUpdateTimeStamp !== "" || softUpdateTimeStamp !== undefined) {
          let now = new Date();
          let next = new Date(softUpdateTimeStamp);
          if (now >= next) {
            showSoftModal = true;
          } else {
            showSoftModal = false;
          }
        } else {
          showSoftModal = true;
        }
        if (showSoftModal)
          this.setState({
            giveAppAccess: false,
            showSoftUpdateModal: true,
            showHardUpdateModal: false,
          });
      }
    }
  };

  handleSystemUpdate = () => {
    if (this.props.isSystemUpdate) {
      this.setState({ giveAppAccess: false });
    }
  };

  firebaseSetup = (platform) => {
    try {
      window.FirebasePlugin.getToken((token) => {
        let obj = {
          platform: platform,
          deviceId: token,
        };
        this.props.acPostDeviceToken(obj);
      });
    } catch (e) {
      console.log(e);
    }
  };

  handleGrantPermissionIosAndroid = () => {
    if (isIos()) {
      try {
        window.FirebasePlugin.grantPermission((hasPermission) => {});
        this.firebaseSetup("ios");
      } catch (e) {
        console.error("FirebasePlugin grantPermission error", e);
      }
    } else if (isAndroid()) {
      this.firebaseSetup("android");
    }
  };

  handleUniversalLinks = () => {
    window.universalLinks.subscribe(
      "handleUniversalLinks",
      this.onNewsListPageRequested
    );
  };

  onNewsListPageRequested = (eventData) => {
    let url = eventData.path + "?i=" + eventData.params.i;
    this.pushLinks(url);
  };

  handleNotificationReceived = () => {
    window.FirebasePlugin.onMessageReceived(
      (message) => {
        if (message.messageType === "notification") {
          // handles notification only if the app is in the background
          if (message.tap) {
            if (message.link) {
              this.pushLinks(message.link);
            }
          } else {
            // when app is in foreground, call me api to get the red dot on noti icon
            this.props.acGetUserDetails();
            if (this.props.location.pathname.includes("notifications")) {
              let obj = {
                limit: this.props.notificationLimit,
                offset: 0,
                isPopup: false,
                onLoad: true,
                isResumeApp: true,
              };
              this.props.acSetNotificationOffset(0);
              this.props.acGetNotifications(obj);
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }
          }
        }
      },
      function (error) {
        console.error(error);
      }
    );
  };

  pushLinks = (url) => {
    window.cordova.plugins.notification.badge.clear();
    if (url.includes("https")) window.open(url, "_blank");
    else this.props.history.push(url);
  };

  handleUpdateApp = () => {
    if (isAndroid())
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.spiking.spike.android";
    else if (isIos()) {
      window.cordova.InAppBrowser.open(
        "https://apps.apple.com/sg/app/spiking-verified-social-trading/id1081721327",
        "_self",
        "location=no"
      );
    }
  };

  handleSoftUpdateClose = () => {
    this.setState({ showSoftUpdateModal: false, giveAppAccess: true });
    let nextTimestamp = new Date(new Date().getTime() + 60 * 60 * 24 * 1000);
    localStorage.setItem("softUpdateTimeStamp", nextTimestamp);
  };

  openDialogue = () => {
    this.setState({ showTutorial: true });
  };

  handleClose = () => {
    this.setState({ showTutorial: false });
  };

  render() {
    const { localeData, isLightMode } = this.props;
    const theme = DarkTheme(isLightMode, this.props.location.pathname);

    if (
      localStorage.isLightMode === "true" ||
      !("isLightMode" in localStorage)
    ) {
      document.documentElement.classList.remove("dark");
    } else {
      document.documentElement.classList.add("dark");
    }

    return (
      <React.Fragment>
        {/* <Online> */}
        <div
        // style={{
        //   backgroundColor: theme.palette.background.default,
        //   minHeight: "100vh",
        // }}
        >
          {this.state.giveAppAccess && (
            <React.Fragment>
              <QueryClientProvider client={queryClient}>
                <GoogleOAuthProvider clientId="842871485257-rco1dbshuuqjo5c23pntdra1v7ipd4fg.apps.googleusercontent.com">
                  <ThemeProvider theme={theme}>
                    <IntlProvider
                      key={localeData.langCode}
                      locale={localeData.langCode}
                      messages={LocaleMessages.getMessages(localeData.langCode)}
                    >
                      <AllRoutes />
                      <Loader />
                      <ReturnToSpinner />
                      <RedirectComp />
                    </IntlProvider>
                  </ThemeProvider>
                </GoogleOAuthProvider>
              </QueryClientProvider>
              {/* <button onClick={this.openDialogue}>CLick me</button> */}
            </React.Fragment>
          )}
          {!this.state.giveAppAccess && this.props.isSystemUpdate && (
            <SystemUpdate />
          )}
          <Tutorial
            open={this.state.showTutorial}
            handleClose={this.handleClose}
          />
          <HardUpdateModal
            show={this.state.showHardUpdateModal}
            handleUpdateApp={this.handleUpdateApp}
          />
          <SoftUpdateModal
            show={this.state.showSoftUpdateModal}
            handleUpdateApp={this.handleUpdateApp}
            handleSoftUpdateClose={this.handleSoftUpdateClose}
          />
        </div>
        <AnnouncementDialog />
        {/* </Online> */}
        {/* <Offline>You are offline, please check your network</Offline> */}
      </React.Fragment>
    );
  }
}

App.propTypes = {
  localeData: PropTypes.object.isRequired,
  acSetUrlToRedirect: PropTypes.func,
  acAutoSigninUser: PropTypes.func,
  acPostDeviceToken: PropTypes.func,
  isPostDeviceToken: PropTypes.bool,
  user: PropTypes.object,
  appUpdateCode: PropTypes.string,
  acGetUserDetails: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    localeData: state.login.localeData,
    isPostDeviceToken: state.login.isPostDeviceToken,
    user: state.home.user,
    appUpdateCode: state.login.appUpdateCode,
    isLightMode: state.common.isLightMode,
    notificationOffset: state.common.notificationOffset,
    notificationLimit: state.common.notificationLimit,
    isSystemUpdate: state.login.isSystemUpdate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acSetUrlToRedirect: (url) => dispatch(actions.urlToRedirect(url)),
    acAutoSigninUser: (data) => dispatch(actions.autoSigninUser(data)),
    acPostDeviceToken: (obj) => dispatch(actions.postDeviceToken(obj)),
    acGetUserDetails: () => dispatch(actions.getUserDetails()),
    acGetNotifications: (obj) => dispatch(actions.getNotifications(obj)),
    acSetNotificationOffset: (data) =>
      dispatch(actions.setNotificationOffset(data)),
    acStoreSystemUpdateData: (data) =>
      dispatch(actions.storeSystemUpdateData(data)),
    acPatchLanguageChange: (localedata) =>
      dispatch(actions.patchLanguageChange(localedata)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
