import FiatIcon from "../../images/fiatIcon.png"
import WISIcon from "../../images/willItSpikeIcon.png"
import GoatIcon from "../../images/goat.png"
import TriangulationIcon from "../../images/triangulation.png"
import YoloIcon from "../../images/yolo.png"
import DailybreadIcon from "../../images/dailybread.png"
import ResurrectionIcon from "../../images/resurrection.png"
import GritIcon from "../../images/grit_icon.png"
import Top100Logo from "../../images/top100logo.png"

export const upgradeStaticData = [
  {
    title: "F.I.A.T",
    desc:
      "Fundamentals, Insiders, Alerts and Technical Details of a Company or Stock",
    image: FiatIcon,
  },
  {
    title: "Will it Spike",
    desc:
      "Be The First To Know All About The Latest Spikes Updates On Insider Trading",
    image: WISIcon,
  },
  {
    title: "Top 100",
    desc:
      "Tracks the 100 Blue Whales. Allows you to see all of the trades that TOP Money Managers",
    image: Top100Logo,
  },
  {
    title: "Daily Bread",
    desc:
      "Monitor Daily Actionable Stocks, Sorted Based On The Net Positive Purchase",
    image: DailybreadIcon,
  },
  {
    title: "Resurrection",
    desc:
      "Get The Latest Drug Insights And Pipeline Awaiting FDA Announcements And Approvals",
    image: ResurrectionIcon,
  },
  {
    title: "Triangulation",
    desc:
      "Stay Ahead Of The Market With The Latest Insider Movements From Top Managers To Funds",
    image: TriangulationIcon,
  },
  {
    title: "Greatest-Of-All-Time",
    desc:
      "Invest in the future with carefully curated stocks that are at the verge to spike to the moon!",
    image: GoatIcon,
  },
  {
    title: "You-Only-Live-Once",
    desc: "Become A Part Of The Bitcoin Movement And Revolution",
    image: YoloIcon,
  },
  {
    title: "GRIT",
    desc: "It's A Matter Of Preparation, Perseverance, And Passion!.",
    image: GritIcon,
  },
]
