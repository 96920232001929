import * as actionTypes from "../actions/actionTypes";
import { clearServerError } from "../actions/commonAc";

const initialState = {
  loading: false,
  loadingEvent: false,
  referralPopup: false,
  searchLoading: false,
  affiliateLink: "",
  infiniteScrollLoading: false,
  toggleSearch: false,
  searchData: null,
  bluewhaleSearchData: null,
  companySearchData: null,
  filteredBluewhales: [],
  filteredCompanies: [],
  filteredQuarter: null,
  isDrawerOpen: false,
  companyTags: null,
  bluewhaleTags: null,
  selFilterValues: {
    start: "",
    end: "",
    tags: "",
    qid: "",
    bids: "",
    cids: "",
    sortby: "",
    sortorder: "",
  },
  filteredSearchTerm: {
    searchTerm: null,
    results: 0,
  },
  isFilterOpen: false,
  isClearFilter: false,
  isServerError: false,
  notificationsData: null,
  popupNotificationsData: null,
  notificationLoading: false,
  notificationDataById: null,
  isLightMode: null,
  isSettingsPatchDone: false,
  wisdomData: null,
  IsReturnToSpinning: false,
  notificationOffset: 0,
  notificationLimit: 10,
  showAnnouncement: false,
  drawerOpen: false,
  isDeleteAccountSuccess: false,
};

const setLightDarkModee = (state, action) => {
  return {
    ...state,
    isLightMode: action.data,
  };
};

const setLoading = (state, action) => {
  return {
    ...state,
    loading: action.data,
  };
};

const setLoadingEvent = (state, action) => {
  return {
    ...state,
    loadingEvent: action.data
  }
}

const setReferralPopup = (state, action) => {
  return {
    ...state,
    referralPopup: action.data,
  };
};

const setSearchLoading = (state, action) => {
  return {
    ...state,
    searchLoading: action.data,
  };
};

const setInfiniteScrollLoading = (state, action) => {
  return {
    ...state,
    infiniteScrollLoading: action.data,
  };
};

const handleToggleSearch = (state, action) => {
  return {
    ...state,
    toggleSearch: !state.toggleSearch,
  };
};

const handleGetSearchDataDone = (state, action) => {
  return {
    ...state,
    searchData: action.data,
  };
};

const setBluewhaleSearchData = (state, action) => {
  return {
    ...state,
    bluewhaleSearchData: action.data,
  };
};

const saveFilteredBluewhales = (state, action) => {
  return {
    ...state,
    filteredBluewhales: action.data,
  };
};

const saveFilteredCompanies = (state, action) => {
  return {
    ...state,
    filteredCompanies: action.data,
  };
};

const saveFilteredQuarter = (state, action) => {
  return {
    ...state,
    filteredQuarter: action.data,
  };
};

const setCompanySearchData = (state, action) => {
  return {
    ...state,
    companySearchData: action.data,
  };
};

const handleClearCompanySeachData = (state, action) => {
  return {
    ...state,
    companySearchData: null,
  };
};

const handleClearBluewhaleSeachData = (state, action) => {
  return {
    ...state,
    bluewhaleSearchData: null,
  };
};

const setToggleFilterDrawer = (state, action) => {
  return {
    ...state,
    isDrawerOpen: !state.isDrawerOpen,
  };
};

const setCompanyTags = (state, action) => {
  return {
    ...state,
    companyTags: action.data,
  };
};

const setBluewhaleTags = (state, action) => {
  return {
    ...state,
    bluewhaleTags: action.data,
  };
};

const sClearFilterTags = (state, action) => {
  return {
    ...state,
    companyTags: null,
    bluewhaleTags: null,
  };
};

const setFilterValues = (state, action) => {
  return {
    ...state,
    selFilterValues: action.data,
    isClearFilter: false,
  };
};

const sClearFilters = (state, action) => {
  return {
    ...state,
    selFilterValues: {
      start: "",
      end: "",
      tags: "",
      qid: "",
      cids: "",
      bids: "",
    },
    isClearFilter: true,
  };
};

const setServerError = (state, action) => {
  return {
    ...state,
    isServerError: true,
  };
};

const clearServerErrorr = (state, action) => {
  return {
    ...state,
    isServerError: false,
  };
};

const setNotifications = (state, action) => {
  return {
    ...state,
    notificationsData: action.data,
  };
};

const setPopupNotifications = (state, action) => {
  return {
    ...state,
    popupNotificationsData: action.data,
  };
};

const setNotificationLoading = (state, action) => {
  return {
    ...state,
    notificationLoading: action.data,
  };
};

const setFilterOpen = (state, action) => {
  return {
    ...state,
    isFilterOpen: action.data,
  };
};

const setFilterSearchTerm = (state, action) => {
  return {
    ...state,
    filteredSearchTerm: {
      ...state.filteredSearchTerm,
      searchTerm: action.data,
    },
  };
};

const setFilterSearchResults = (state, action) => {
  return {
    ...state,
    filteredSearchTerm: {
      ...state.filteredSearchTerm,
      results: action.data,
    },
  };
};

const setNotificationDataById = (state, action) => {
  return {
    ...state,
    notificationDataById: action.data,
  };
};

const setSettingsPatch = (state, action) => {
  return {
    ...state,
    isSettingsPatchDone: action.data,
  };
};

const setWisdomLink = (state, action) => {
  return {
    ...state,
    wisdomData: action.data,
  };
};

const setReturnToSpinnerr = (state, action) => {
  return {
    ...state,
    IsReturnToSpinning: action.data,
  };
};

const setNotificationOffsett = (state, action) => {
  return {
    ...state,
    notificationOffset: action.data,
  };
};

const setShowAnnouncement = (state, action) => {
  return {
    ...state,
    showAnnouncement: action.data,
  };
};

const setAffiliateLink = (state, action) => {
  return {
    ...state,
    affiliateLink: action.data,
  };
};

const setSideDrawer = (state, action) => {
  return {
    ...state,
    drawerOpen: action.data,
  };
};

const setDeleteAccount = (state, action) => {
  return {
    ...state,
    isDeleteAccountSuccess: action.data,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LIGHT_DARK_MODE:
      return setLightDarkModee(state, action);
    case actionTypes.LOADING:
      return setLoading(state, action)
    case actionTypes.LOADING_EVENT:
      return setLoadingEvent(state, action)
    case actionTypes.REFERRAL_POPUP:
      return setReferralPopup(state, action);
    case actionTypes.SEARCH_LOADING:
      return setSearchLoading(state, action);
    case actionTypes.INFINITE_SCROLL_LOADING:
      return setInfiniteScrollLoading(state, action);
    case actionTypes.TOGGLE_SEARCH:
      return handleToggleSearch(state, action);
    case actionTypes.GET_SEARCH_DATA_DONE:
      return handleGetSearchDataDone(state, action);
    case actionTypes.GET_BLUEWHALE_SEARCH_DATA_DONE:
      return setBluewhaleSearchData(state, action);
    case actionTypes.SAVE_FILTERED_BLUEWHALES:
      return saveFilteredBluewhales(state, action);
    case actionTypes.SAVE_FILTERED_COMPANIES:
      return saveFilteredCompanies(state, action);
    case actionTypes.SAVE_FILTERED_QUARTER:
      return saveFilteredQuarter(state, action);
    case actionTypes.GET_COMPANY_SEARCH_DATA_DONE:
      return setCompanySearchData(state, action);
    case actionTypes.CLEAR_COMPANY_SEARCH_DATA:
      return handleClearCompanySeachData(state, action);
    case actionTypes.CLEAR_BLUEWHALE_SEARCH_DATA:
      return handleClearBluewhaleSeachData(state, action);
    case actionTypes.TOGGLE_FILTER_DRAWER:
      return setToggleFilterDrawer(state, action);
    case actionTypes.GET_COMPANY_TAGS_DONE:
      return setCompanyTags(state, action);
    case actionTypes.GET_BLUEWHALE_TAGS_DONE:
      return setBluewhaleTags(state, action);
    case actionTypes.CLEAR_FILTER_TAGS:
      return sClearFilterTags(state, action);
    case actionTypes.GET_AFFILIATE_LINK:
      return setAffiliateLink(state, action);
    case actionTypes.SET_SELECTED_FILTER_VALUES:
      return setFilterValues(state, action);
    case actionTypes.CLEAR_FILTERS:
      return sClearFilters(state, action);
    case actionTypes.SERVER_ERROR_DONE:
      return setServerError(state, action);
    case actionTypes.CLEAR_SERVER_ERROR:
      return clearServerErrorr(state, action);
    case actionTypes.GET_NOTIFICATIONS_DONE:
      return setNotifications(state, action);
    case actionTypes.GET_POPUP_NOTIFICATIONS_DONE:
      return setPopupNotifications(state, action);
    case actionTypes.NOTIFICATION_LOADING:
      return setNotificationLoading(state, action);
    case actionTypes.SET_FILTER_OPEN:
      return setFilterOpen(state, action);
    case actionTypes.SET_FILTER_SEARCH_TERM:
      return setFilterSearchTerm(state, action);
    case actionTypes.SET_FILTER_SEARCH_RESULTS:
      return setFilterSearchResults(state, action);
    case actionTypes.GET_NOTIFICATION_DATA_BY_ID:
      return setNotificationDataById(state, action);
    case actionTypes.SETTINGS_PATCH_DONE:
      return setSettingsPatch(state, action);
    case actionTypes.GET_WISDOM_LINK_DONE:
      return setWisdomLink(state, action);
    case actionTypes.RETURN_TO_SPINNER:
      return setReturnToSpinnerr(state, action);
    case actionTypes.SET_NOTIFICATION_OFFSET:
      return setNotificationOffsett(state, action);
    case actionTypes.SHOW_ANNOUNCEMENT_POPUP:
      return setShowAnnouncement(state, action);
    case actionTypes.TOGGLE_SIDE_DRAWER:
      return setSideDrawer(state, action);
    case actionTypes.PATCH_DELETE_ACCOUNT_DONE:
      return setDeleteAccount(state, action);
    default:
      return state;
  }
};

export default reducer;
