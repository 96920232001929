import React from "react"
import clsx from "clsx"

import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"

import CloseIcon from "@material-ui/icons/Close"
import UpdateIcon from "../../../images/update_icon.svg"
import { useStyles } from "./SystemUpdateModal.styles"

const SystemUpdateModal = (props) => {
  const { show, handleUpdateApp, handleSoftUpdateClose } = props
  const classes = useStyles()

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {handleSoftUpdateClose && (
        <Box className={classes.closeDialogIcon}>
          <IconButton color="inherit" onClick={handleSoftUpdateClose}>
            <CloseIcon color="inherit" />
          </IconButton>
        </Box>
      )}
      <Box
        // width={560}
        bgcolor="background.charcoal"
        pt={8}
        pb={6.5}
        borderRadius={20}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <img src={UpdateIcon} alt="update gear icon" />
        <Box mt={3} mb={4}>
          <Typography className={classes.baseFontStyles} variant="h5">
            Update Available
          </Typography>
        </Box>
        {/* TODO Get the current version of the app */}
        {/* <Typography className={clsx(classes.baseFontStyles, classes.bold)}>
          Spiking Wealth Management System
        </Typography> */}
        {/* <Box display="flex" alignItems="center" mb={2.75}> */}
          {/* <Typography className={classes.baseFontStyles} variant="body2">
            Update Required
          </Typography> */}
          {/* <div className={classes.circle}></div> */}
          {/* TODO Get the update size */}
          {/* <Typography className={classes.baseFontStyles} variant="body2">
            904.4MB
          </Typography> */}
        {/* </Box> */}

        <Box maxWidth={438}>
          <Typography
            align="center"
            className={classes.baseFontStyles}
            variant="body2"
          >
            A new version of Spiking application is available, please click below to
            update to the latest version.
          </Typography>
        </Box>

        <Box mt={5}>
          {handleSoftUpdateClose && (
            <Button
              onClick={handleSoftUpdateClose}
              variant="contained"
              color="primary"
              className={clsx(classes.button, classes.closeButton)}
            >
              Close
            </Button>
          )}
          <Button
            onClick={handleUpdateApp}
            variant="contained"
            color="primary"
            className={clsx(classes.button, classes.updateButton)}
          >
            Update Now
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default SystemUpdateModal
