import React from 'react';

const BlurredBackground = ({ children }) => {
  return (
    <div className="relative w-full h-full bg-[#f6f6f9]">
      {/* Random blurred circles */}
      <div className="absolute inset-0">
        <div className="absolute top-1/2 -left-[100px] w-96 h-96 bg-[#0D8BFF] opacity-50 blur-[200px] rounded-full"></div>
        <div className="absolute bottom-[100px] right-0 w-96 h-96 bg-[#0D8BFF] opacity-50 blur-[200px] rounded-full"></div>
      </div>

      {/* Content */}
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};

export default BlurredBackground;
