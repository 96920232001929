import React from "react";
import Content from "../components/Content";

const Money = () => {
  const content = {
    dayTitle: "Finish With MONEY",
    heading: "Well done on your continued commitment to Spiking.",
    intro:
      "You're gaining the knowledge and tools needed to make informed investment decisions and secure your financial future.",
    youtubeVideoId: "gcgQR0aEMyU",
    about: {
      sectionTitle: "ABOUT MONEY Finish",
      description:
        "Today, we will finish with MONEY, focusing on cultivating a millionaire mindset, seizing opportunities, building a network, empowering loved ones, and personalizing your investment strategy.",
    },
    topicsIntro: "Your Spiking journey will explore these important topics:",
    topics: [
      {
        title: "Millionaire",
        description:
          "Cultivate a mindset for successful investing. Learn the strategies and habits that can help you become a millionaire and join the top 1% of the wealth pyramid.",
      },
      {
        title: "Opportunity",
        description:
          "Identify and seize investment opportunities. Learn how to own a million-dollar asset with less than a third of US$1 million, maximizing your investment potential.",
      },
      {
        title: "Network",
        description:
          "Build a strong network of like-minded investors. Remember, your net worth is your network; you are the average of the five people you spend the most time with.",
      },
      {
        title: "Empower Your Loved Ones",
        description:
          "Achieve financial freedom and plan a lasting legacy for your loved ones. Empower them with the knowledge and resources to continue building wealth.",
      },
      {
        title: "You",
        description:
          "Personalize your investment strategy to match your unique goals and risk tolerance. Remember, You Only Live Once (YOLO), so make your investment journey uniquely yours.",
      },
    ],
    nextSteps: {
      title: "NEXT STEPS",
      steps: [
        {
          title: "Read UBS Global Wealth Report",
          description: "Gain insights from the latest wealth trends and data.",
          actions: [
            {
              label: "Read Report",
              link: "https://spiking.com/lesson/spiking-wealth-masterclass/content/3670",
              type: "primary",
            },
          ],
        },
        {
          title: "Assess Your Position",
          description: "Determine where you stand in the Wealth Pyramid.",
          actions: [
            {
              label: "View Wealth Pyramid",
              link: "https://spiking.com/lesson/spiking-wealth-masterclass/content/3671",
              type: "primary",
            },
          ],
        },
        {
          title: "Engage with the Community",
          description:
            "Participate in the poll on our Facebook Community about becoming a millionaire.",
          actions: [
            {
              label: "Join the Poll",
              link: "https://facebook.com/groups/spiking",
              type: "primary",
            },
          ],
        },
      ],
    },
  };

  return <Content content={content} />;
};

export default Money;
