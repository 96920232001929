const clearLocalStorage = (isSetCredUpdate) => {
    let lightModeValue = localStorage.getItem("isLightMode")
    let affiliate_id = localStorage.getItem("affiliate_id")
    window.localStorage.clear();
    localStorage.setItem("isLightMode", lightModeValue)
    if(affiliate_id) localStorage.setItem("affiliate_id", affiliate_id)
    if(isSetCredUpdate === true) localStorage.setItem("isCredentialsUpdate", true);
    return;
}

export default clearLocalStorage;