import axios from "axios";

import * as actionTypes from "./actionTypes";
import { loading } from "./commonAc";

let apiDomain = process.env.REACT_APP_API_URL;
let userDetailsDomain = process.env.REACT_APP_API2_URL;

export const getEarningsDataDone = (data) => {
  return {
    type: actionTypes.GET_EARNINGS_DATA_DONE,
    data: data,
  };
};

export const getAnalystDataDone = (data) => {
  return {
    type: actionTypes.GET_ANALYST_DATA_DONE,
    data: data,
  };
};

export const getPublicOfferingDataDone = (data) => {
  return {
    type: actionTypes.GET_PUBLIC_OFFERING_DATA_DONE,
    data: data,
  };
};

export const getStrategyDataDone = (data) => {
  return {
    type: actionTypes.GET_STRATEGY_DATA_DONE,
    data: data,
  };
};

export const getBuybackDataDone = (data) => {
  return {
    type: actionTypes.GET_BUYBACK_DATA_DONE,
    data: data,
  };
};

export const getFdaApprovalDataDone = (data) => {
  return {
    type: actionTypes.GET_FDA_APPROVAL_DATA_DONE,
    data: data,
  };
};

export const getNotesOfferingDataDone = (data) => {
  return {
    type: actionTypes.GET_NOTES_OFFERING_DATA_DONE,
    data: data,
  };
};

export const getBondDataDone = (data) => {
  return {
    type: actionTypes.GET_BOND_DATA_DONE,
    data: data,
  };
};

export const getDelistingDataDone = (data) => {
  return {
    type: actionTypes.GET_DELISTING_DATA_DONE,
    data: data,
  };
};

export const getDividendDataDone = (data) => {
  return {
    type: actionTypes.GET_DIVIDEND_DATA_DONE,
    data: data,
  };
};

export const getIpoDone = (data) => {
  return {
    type: actionTypes.GET_IPO_DATA_DONE,
    data: data,
  };
};

export const getIndexAdditionDataDone = (data) => {
  return {
    type: actionTypes.GET_INDEX_ADDITION_DATA_DONE,
    data: data,
  };
};

export const getMediaDataDone = (data) => {
  return {
    type: actionTypes.GET_MEDIA_DATA_DONE,
    data: data,
  };
};

export const getResignationDataDone = (data) => {
  return {
    type: actionTypes.GET_RESIGNATION_DATA_DONE,
    data: data,
  };
};

export const getStockSplitDataDone = (data) => {
  return {
    type: actionTypes.GET_STOCK_SPLIT_DATA_DONE,
    data: data,
  };
};

export const getAcquisitionDataDone = (data) => {
  return {
    type: actionTypes.GET_STOCK_SPLIT_DATA_DONE,
    data: data,
  };
};

export const getStripeSubscriptionsDone = (data) => {
  return {
    type: actionTypes.GET_STRIPE_SUBSCRIPTIONS_DONE,
    data: data,
  };
};

export const getPopularInsidersDataDone = (data) => {
  return {
    type: actionTypes.POPULAR_INSIDER_DATA_DONE,
    data: data,
  };
};

export const getPopularInsidersFundsDataDone = (data) => {
  return {
    type: actionTypes.POPULAR_INSIDER_FUNDS_DATA_DONE,
    data: data,
  };
};

export const getPopularInsidersSovereignDataDone = (data) => {
  return {
    type: actionTypes.POPULAR_INSIDER_SOVEREIGN_DATA_DONE,
    data: data,
  };
};

const getAppendedData = (prevData, data) => {
  let appendedData = prevData.concat(data.data);
  return appendedData;
};

const getFollowingInsidersDataDone = (data) => {
  return {
    type: actionTypes.GET_FOLLOWING_INSIDER_DONE,
    data: data,
  };
};

const getFollowingFundsDataDone = (data) => {
  return {
    type: actionTypes.GET_FOLLOWING_FUNDS_DONE,
    data: data,
  };
};

const getFollowingCompaniesDataDone = (data) => {
  return {
    type: actionTypes.GET_FOLLOWING_COMPANIES_DONE,
    data: data,
  };
};

const getTdFundamentalsDataDone = (data) => {
  return {
    type: actionTypes.GET_TD_FUNDAMENTALS_DONE,
    data: data,
  };
};

const getTdDataDone = (data) => {
  return {
    type: actionTypes.GET_TD_DATA_DONE,
    data: data,
  };
};

export const getPopularSpikesData = (obj) => {
  return (dispatch, getState) => {
    // dispatch(loading(true));
    if (obj.onLoad) dispatch(loading(true));
    let mode = "";
    if (obj.spiking === "spikingup") mode = "up";
    else mode = "down";
    axios
      .get(
        userDetailsDomain +
          "/v3/briefing-spike-by-category?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset +
          "&category=" +
          obj.category +
          "&spiking=" +
          mode
      )
      .then((res) => {
        let data = res.data;
        switch (obj.category) {
          case "earnings":
            if (!obj.onLoad) {
              let prevData = getState().navbar.earningsData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getEarningsDataDone(data));
            break;
          case "analyst":
            if (!obj.onLoad) {
              let prevData = getState().navbar.analystData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getAnalystDataDone(data));
            break;
          case "public_offering":
            if (!obj.onLoad) {
              let prevData = getState().navbar.publicOfferingData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getPublicOfferingDataDone(data));
            break;
          case "strategy":
            if (!obj.onLoad) {
              let prevData = getState().navbar.strategyData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getStrategyDataDone(data));
            break;
          case "buyback":
            if (!obj.onLoad) {
              let prevData = getState().navbar.buybackData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getBuybackDataDone(data));
            break;
          case "fda_approval":
            if (!obj.onLoad) {
              let prevData = getState().navbar.fdaApprovalData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getFdaApprovalDataDone(data));
            break;
          case "notes_offering":
            if (!obj.onLoad) {
              let prevData = getState().navbar.notesOfferingData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getNotesOfferingDataDone(data));
            break;
          case "bond":
            if (!obj.onLoad) {
              let prevData = getState().navbar.bondData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getBondDataDone(data));
            break;
          case "delisting":
            if (!obj.onLoad) {
              let prevData = getState().navbar.delistingData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getDelistingDataDone(data));
            break;
          case "dividend":
            if (!obj.onLoad) {
              let prevData = getState().navbar.dividendData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getDividendDataDone(data));
            break;
          case "ipo":
            if (!obj.onLoad) {
              let prevData = getState().navbar.ipoData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getIpoDone(data));
            break;
          case "index_addition":
            if (!obj.onLoad) {
              let prevData = getState().navbar.indexAdditionData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getIndexAdditionDataDone(data));
            break;
          case "media":
            if (!obj.onLoad) {
              let prevData = getState().navbar.mediaData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getMediaDataDone(data));
            break;
          case "resignation":
            if (!obj.onLoad) {
              let prevData = getState().navbar.resignationData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getResignationDataDone(data));
            break;
          case "stock_split":
            if (!obj.onLoad) {
              let prevData = getState().navbar.stockSplitData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getStockSplitDataDone(data));
            break;
          case "acquisition":
            if (!obj.onLoad) {
              let prevData = getState().navbar.acquisitionData.data;
              data.data = getAppendedData(prevData, data);
            }
            dispatch(getAcquisitionDataDone(data));
          default:
            dispatch(loading(false));
        }
        dispatch(loading(false));
      })
      .catch((error) => {
        console.log(error);
        // dispatch(loading(false));
        dispatch(loading(false));
      });
  };
};

export const getStripeSubscriptions = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));

    axios
      .get(
        userDetailsDomain +
          "/v3/stripe_subscriptions_limit_offset?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data;
        dispatch(getStripeSubscriptionsDone(data));
        dispatch(loading(false));
      });
  };
};

export const deleteStripeSubscription = (body) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .delete(userDetailsDomain + "/v3/stripe_subscription", {
        params: body,
      })
      .then((res) => {
        let data = res.data;
        window.location.reload();
      });
  };
};

export const getPopularInsidersData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));

    axios
      .get(
        userDetailsDomain +
          "/v3/popular-insiders?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset +
          "&category=" +
          obj.category +
          "&sortby=" +
          obj.sortBy
      )
      .then((res) => {
        let data = res.data;
        if (obj.category === "insiders") {
          if (!obj.onLoad) {
            let prevData = getState().navbar.popularInsiderData.data;
            let appendedData = prevData.concat(data.data);
            data.data = appendedData;
          }
          dispatch(getPopularInsidersDataDone(data));
        }
        if (obj.category === "universe_of_funds") {
          if (!obj.onLoad) {
            let prevData = getState().navbar.popularInsiderFundsData.data;
            let appendedData = prevData.concat(data.data);
            data.data = appendedData;
          }
          dispatch(getPopularInsidersFundsDataDone(data));
        }
        if (obj.category === "sovereign_wealth_fund") {
          if (!obj.onLoad) {
            let prevData = getState().navbar.popularInsiderSovereignData.data;
            let appendedData = prevData.concat(data.data);
            data.data = appendedData;
          }
          dispatch(getPopularInsidersSovereignDataDone(data));
        }
        dispatch(loading(false));
      });
  };
};

export const getFollowingInsidersData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));

    if (obj.category.includes("universe")) obj.category = "universe_of_funds";

    axios
      .get(
        userDetailsDomain +
          "/v3/following-insiders?category=" +
          obj.category +
          "&limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data;
        if (obj.category === "insiders") {
          if (!obj.onLoad) {
            let prevData = getState().navbar.followingInsiderData.data;
            let appendedData = prevData.concat(data.data);
            data.data = appendedData;
          }
          dispatch(getFollowingInsidersDataDone(data));
        } else {
          if (!obj.onLoad) {
            let prevData = getState().navbar.followingFundsData.data;
            let appendedData = prevData.concat(data.data);
            data.data = appendedData;
          }
          dispatch(getFollowingFundsDataDone(data));
        }

        // dispatch(getFollowingInsidersDataDone(data))
        // else dispatch(getFollowingFundsDataDone(data))
        dispatch(loading(false));
      });
  };
};

export const getFollowingCompaniesData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));

    axios
      .get(
        userDetailsDomain +
          "/v3/following-companies" +
          "?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().navbar.followingCompaniesData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(getFollowingCompaniesDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const getTdFundamentalsData = (obj) => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    axios.get(userDetailsDomain + "/v3/user-fundamentals").then((res) => {
      let data = res.data;

      dispatch(getTdFundamentalsDataDone(data));
      dispatch(loading(false));
    });
  };
};

export const getTdData = (obj) => {
  return (dispatch, getState) => {
    if (obj.onLoad) dispatch(loading(true));

    axios
      .get(
        userDetailsDomain +
          "/v3/trading_diary?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().navbar.tdData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }
        dispatch(getTdDataDone(data));
        dispatch(loading(false));
      });
  };
};

export const postTdDetails = (obj, getObj) => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    let body = new FormData();
    body.append("cids", obj.cids);
    body.append("price", obj.price);
    body.append("action", obj.action);
    body.append("numberofshares", obj.shares);
    body.append("dateoftransaction", obj.date);

    axios.post(userDetailsDomain + "/v3/trading_diary", body).then((res) => {
      let data = res.data;
      dispatch(getTdData(getObj));
      dispatch(getTdFundamentalsData());
      dispatch(loading(false));
    });
  };
};

export const deleteTrade = (id, getObj) => {
  return (dispatch) => {
    dispatch(loading(true));

    // let body = new FormData()
    // body.append("id", +id)

    axios
      .delete(userDetailsDomain + "/v3/trading_diary", {
        params: { id: id },
      })
      .then((res) => {
        let data = res.data;
        dispatch(getTdData(getObj));
        dispatch(getTdFundamentalsData());
        dispatch(loading(false));
      });
  };
};

export const resetFollowingInsidersData = () => {
  return {
    type: actionTypes.RESET_FOLLOWING_INSIDERS,
    data: null,
  };
};
