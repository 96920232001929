import axios from "axios";

import * as actionTypes from "./actionTypes";
import { loading, searchLoading } from "./commonAc";

let apiDomain = process.env.REACT_APP_API_URL;
let domainUserDetails = process.env.REACT_APP_API2_URL;
let randomNum = 1;

export const getFundsHoldingsDataDone = (data) => {
  return {
    type: actionTypes.GET_FUNDS_HOLDINGS_DATA_DONE,
    data: data,
  };
};

export const getFundsMetadataDone = (data) => {
  return {
    type: actionTypes.GET_FUNDS_METADATA_DONE,
    data: data,
  };
};

export const getFundsSearchDataDone = (data) => {
  return {
    type: actionTypes.GET_FUNDS_SEARCH_DATA_DONE,
    data: data,
  };
};

export const getFundsInsidersDataDone = (data) => {
  return {
    type: actionTypes.GET_FUNDS_INSIDERS_DATA_DONE,
    data: data,
  };
};

export const getFundsInsidersMoreDataDone = (data) => {
  return {
    type: actionTypes.GET_FUNDS_INSIDERS_MORE_DATA_DONE,
    data: data,
  };
};

export const getFundsTechnicalDataDone = (data) => {
  return {
    type: actionTypes.GET_FUNDS_TECHNICAL_DATA_DONE,
    data: data,
  };
};

export const getFundsCostAverageDataDone = (data) => {
  return {
    type: actionTypes.GET_FUNDS_COST_AVERAGE_DATA_DONE,
    data: data,
  };
};

export const getFundsCostAverageSearchDataDone = (data) => {
  return {
    type: actionTypes.GET_FUNDS_COST_AVERAGE_SEARCH_DATA_DONE,
    data: data,
  };
};

export const getJimCramerCompaniesDataDone = (data) => {
  return {
    type: actionTypes.GET_JIM_CRAMER_COMPANIES_DONE,
    data: data,
  };
};

export const getPoliticiansDataDone = (data) => {
  return {
    type: actionTypes.GET_POLITICIANS_DATA_DONE,
    data: data,
  };
};

export const getPoliticiansSearchDataDone = (data) => {
  return {
    type: actionTypes.GET_POLTITCIANS_SEARCH_DATA_DONE,
    data: data,
  };
};

export const getCnbcDataDone = (data) => {
  return {
    type: actionTypes.GET_CNBC_DATA_DONE,
    data: data,
  };
};

export const getFundsHoldingsData = (obj) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/fund/holdings?bid=${obj.bid}&limit=${obj.limit}&offset=${obj.offset}&sortby=${obj.sortby}&sortorder=${obj.sortorder}&bids=${obj.bids}&tags=${obj.tags}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getFundsHoldingsDataDone(data));
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(loading(false));
      });
  };
};

export const getFundsMetadata = (bid) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios.get(domainUserDetails + `/v3/funds/${bid}`).then((res) => {
      let data = res.data;
      dispatch(getFundsMetadataDone(data));
      // dispatch(loading(false));
    });
  };
};

export const getFundsSearchData = (obj) => {
  return (dispatch) => {
    dispatch(searchLoading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/fund/holdings/search?search=${obj.search}&bid=${obj.bid}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getFundsSearchDataDone(data));
        dispatch(searchLoading(false));
      });
  };
};

export const getFundsInsidersData = (obj) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/fund/trades?bid=${obj.bid}&limit=${obj.limit}&offset=${obj.offset}&sortby=${obj.sortby}&sortorder=${obj.sortorder}&bids=${obj.bids}&tags=${obj.tags}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getFundsInsidersDataDone(data));
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(loading(false));
      });
  };
};

export const getFundsInsidersMoreData = (obj) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/fund/trades/more?bid=${obj.bid}&limit=20&offset=0&cid=${obj.cid}&date=${obj.date}`
      )
      .then((res) => {
        let data = res.data;
        // alert("success")
        let insiderTableData =
          getState().funds.fundsInsidersData.data.table_data;
        // console.log(insiderTableData)
        for (const value of insiderTableData) {
          if (value.id === obj.id) {
            value.more_data = data;
          }
        }

        // let randomNum = Math.floor(Math.random() * 3)
        // console.log(insiderTableData);
        randomNum += 1;
        dispatch(getFundsInsidersMoreDataDone(randomNum));
        dispatch(loading(false));
        // dispatch(getArkInsidersTableDataDone(getState().ark.arkInvestorInsiderTableData))
        // console.log(data)
      });
  };
};

export const getFundsTechnicalData = (bid) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(domainUserDetails + `/v3/fund/net_exposure_chart?bid=${bid}`)
      .then((res) => {
        let data = res.data;
        dispatch(getFundsTechnicalDataDone(data));
        dispatch(loading(false));
      });
  };
};
// https://api-dev.spiking.com/v3/fund/fundamentals?bid=67782&limit=20&offset=0&sortby=&sortorder=&cids=&tags=
export const getFundsCostAverageData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/fund/fundamentals?bid=${obj.bid}&limit=${obj.limit}&offset=${obj.offset}&sortby=${obj.sortby}&sortorder=${obj.sortorder}&cids=${obj.cids}&tags=${obj.tags}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getFundsCostAverageDataDone(data));
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(loading(false));
      });
  };
};
// https://api-dev.spiking.com/v3/fund/costaverage/search?search=apple&bid=9230
export const getFundsCostAverageSearchData = (obj) => {
  return (dispatch) => {
    dispatch(searchLoading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/fund/costaverage/search?search=${obj.search}&bid=${obj.bid}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getFundsCostAverageSearchDataDone(data));
        dispatch(searchLoading(false));
      });
  };
};

// https://api-dev.spiking.com/v3/jim-cramer-companies?limit=10&offset=0
export const getJimCramerCompanies = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/jim-cramer-companies?limit=${obj.limit}&offset=${obj.offset}&sortby=${obj.sortby}&sortorder=${obj.sortorder}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getJimCramerCompaniesDataDone(data));
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(loading(false));
      });
  };
};
// https://api-dev.spiking.com/v3/politician/trades?limit=20&offset=0
export const getPoliticiansData = (obj) => {
  return (dispatch, getState) => {
    if(obj.onLoad) dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/politician/trades?limit=${obj.limit}&offset=${obj.offset}&sortby=${obj.sortby}&sortorder=${obj.sortorder}&bids=${obj.bids}&tags=${obj.tags}`
      )
      .then((res) => {
        let data = res.data;
        if (!obj.onLoad) {
          let prevData = getState().funds.politiciansData.data.table_data;          ;
          let appendedData = prevData.concat(data.data.table_data);
          data.data.table_data = appendedData;
        }
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
 
        dispatch(getPoliticiansDataDone(data));

        // let element = document.getElementById("tableScroll");
        // if (element) element.scrollTop = 0;
        dispatch(loading(false));
      });
  };
};

let cancelToken;
export const getPoliticiansSearchData = (obj) => {
  return (dispatch) => {
    dispatch(searchLoading(true));

    //Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    axios
      .get(domainUserDetails + `/v3/politician/search?search=${obj.search}`, {
        cancelToken: cancelToken.token,
      })
      .then((res) => {
        let data = res.data;
        dispatch(getPoliticiansSearchDataDone(data));
        dispatch(searchLoading(false));
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};
// https://api-dev.spiking.com/v3/cnbc/jim-lebenthal?limit=10&offset=0
export const getCnbcData = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(
        domainUserDetails +
          `/v3/cnbc/${obj.id}?limit=${obj.limit}&offset=${obj.offset}&sortby=${obj.sortby}&sortorder=${obj.sortorder}`
      )
      .then((res) => {
        let data = res.data;
        dispatch(getCnbcDataDone(data));
        let element = document.getElementById("tableScroll");
        if (element) element.scrollTop = 0;
        dispatch(loading(false));
      });
  };
};
