import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import SearchLayout from "./components/search";
import * as actions from "../../store/actions/index";

class Search extends Component {
  handleSearch = (searchValue) => {
    if (searchValue === "") {
      return;
    }
    this.props.acGetSearchData(searchValue);
  };
  handleCompanySelect = (id) => {
    if (this.props.location.pathname.includes("/options")) {
      this.props.history.push(`/options/company/territory/${id}`);
    } else this.props.history.push(`/company/fundamentals/${id}/calls?date=`);
  };

  handleBluewhaleSelect = (id) => {
    this.props.history.push("/bluewhale/" + id + "/fundamentals");
  };

  render() {
    const { toggleSearch, acToggleSearch, searchResults, searchLoading } =
      this.props;
    return (
      <SearchLayout
        toggleSearch={toggleSearch}
        handleToggle={acToggleSearch}
        handleSearch={this.handleSearch}
        searchResults={searchResults}
        handleCompanySelect={this.handleCompanySelect}
        handleBluewhaleSelect={this.handleBluewhaleSelect}
        loading={searchLoading}
      />
    );
  }
}

Search.propTypes = {
  searchLoading: PropTypes.bool,
  toggleSearch: PropTypes.bool,
  acToggleSearch: PropTypes.func,
  acGetSearchData: PropTypes.func,
  searchResults: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    searchLoading: state.common.searchLoading,
    toggleSearch: state.common.toggleSearch,
    searchResults: state.common.searchData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acToggleSearch: () => dispatch(actions.toggleSearch()),
    acGetSearchData: (value) => dispatch(actions.getSearchData(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
