import React from "react";
import Content from "../components/Content";

const Rest = () => {
  const content = {
    dayTitle: "Join Us to REST",
    heading: "Great job on advancing with Spiking.",
    intro:
      "You're actively developing the skills and insights required to enhance your investment portfolio and achieve success.",
    youtubeVideoId: "DlLLoJ0i3QI",
    about: {
      sectionTitle: "ABOUT REST Together",
      description:
        "Today, we will focus on REST: <strong>R</strong>efer, <strong>E</strong>arn, <strong>S</strong>hop, <strong>T</strong>rack. These components are crucial for maximizing your engagement with the Spiking ecosystem and enhancing your investment experience.",
    },
    topicsIntro: "Your Spiking journey will navigate these key topics:",
    topics: [
      {
        title: "Refer",
        description:
          "Learn how you can benefit from referring friends and family to Spiking. Our referral program offers rewards and incentives for expanding our community.",
      },
      {
        title: "Earn",
        description:
          "Discover the various earning opportunities within the Spiking ecosystem. Whether through referrals, investments, or participation in our programs, there are numerous ways to enhance your earnings.",
      },
      {
        title: "Shop",
        description:
          "Utilize the resources and tools available through Spiking to shop globally with your investment returns. Our platform provides access to a wide range of products and services that can be purchased using your investment gains.",
      },
      {
        title: "Track",
        description:
          "Monitor and track your investment performance with our advanced tracking tools. Stay informed about your portfolio’s progress and make data-driven decisions to optimize your returns.",
      },
    ],
    nextSteps: {
      title: "NEXT STEPS",
      steps: [
        {
          title: "Spiking Referral Program",
          description:
            "Share the value of Spiking with your network and inspire others to join the journey.",
          actions: [
            {
              label: "Join Referral Program",
              link: "https://spiking.com/refer",
              type: "primary",
            },
          ],
        },
        {
          title: "Spin To Win",
          description:
            "Participate in our Spin To Win contest for exciting rewards.",
          actions: [
            {
              label: "Enter Contest",
              link: "https://spiking.com/spin",
              type: "primary",
            },
          ],
        },
        {
          title: "Pre-Order Crypto Debit Card",
          description:
            "Pre-order your Crypto Debit Card to start using your investment returns globally.",
          actions: [
            {
              label: "Pre-Order Now",
              link: "https://spiking.com/shop",
              type: "primary",
            },
          ],
        },
      ],
    },
  };

  return <Content content={content} />;
};

export default Rest;
