import * as actionTypes from "../actions/actionTypes";

const initialState = {
  spikeCardsData: null,
  spikeOverviewData: {},
  mySpikesData: null,
  isPostSpikeSuccess: false,
  isDeleteSpikeSuccess: false,
  maxSpikesReachedData: null,
};

const setSpikeCardsData = (state, action) => {
  return {
    ...state,
    spikeCardsData: action.data,
  };
};

const setSpikeOverviewData = (state, action) => {
  return {
    ...state,
    spikeOverviewData: action.data,
  };
};

const setMySpikesData = (state, action) => {
  return {
    ...state,
    mySpikesData: action.data,
  };
};

const setPostSpikeSuccess = (state, action) => {
  return {
    ...state,
    isPostSpikeSuccess: action.data,
  };
};

const setDeleteSpikeSuccess = (state, action) => {
  return {
    ...state,
    isDeleteSpikeSuccess: action.data,
  };
};

const setMaxSpikesData = (state, action) => {
  return {
    ...state,
    maxSpikesReachedData: action.data,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SPIKE_CARDS_DATA_DONE:
      return setSpikeCardsData(state, action);
    case actionTypes.GET_SPIKE_OVERVIEW_DONE:
      return setSpikeOverviewData(state, action);
    case actionTypes.GET_WATCHLIST_DATA_DONE:
      return setMySpikesData(state, action);
    case actionTypes.POST_SPIKE_SUCCESS:
      return setPostSpikeSuccess(state, action);
    case actionTypes.DELETE_SPIKE_SUCCESS:
      return setDeleteSpikeSuccess(state, action);
    case actionTypes.MAX_SPIKES_REACHED_DATA:
      return setMaxSpikesData(state, action);
    default:
      return state;
  }
};

export default reducer;
