import * as actionTypes from "../actions/actionTypes";
import { storeSystemUpdateData } from "../actions/loginAc";
// import { updateObject } from "../utility";

const initialState = {
  loading: false,
  localeData: {
    countryCode: "US",
    langCode: "en",
    language: "English",
  },
  toggleLanguageDialogue: false,
  isLoggedIn: false,
  urlToRedirect: null,
  redirectUser: false,
  autoSignin: false,
  isWebPurchaseSuccess: false,
  isPostDeviceToken: true,
  isAgreeToTermsSuccess: null,
  appUpdateCode: null,
  testDeviceToken: "",
  upgradeRestoreSuccess: null,
  verifyEmailData: null,
  isLogoutMobile: false,
  isSystemUpdate: null,
  checkEmailData: null
};

const changeLanguage = (state, action) => {
  //   const updatedLocaleData = action.localeData;
  //   return updateObject(state, updatedLocaleData);
  return {
    ...state,
    localeData: action.data,
  };
};

const toggleLanguageDialogue = (state, action) => {
  return {
    ...state,
    toggleLanguageDialogue: !state.toggleLanguageDialogue,
  };
};

const setIsLoggedIn = (state, action) => {
  return {
    ...state,
    isLoggedIn: action.data,
  };
};

const setUrlToRedirect = (state, action) => {
  return {
    ...state,
    urlToRedirect: action.data,
  };
};

const setRedirectUser = (state, action) => {
  return {
    ...state,
    redirectUser: action.data,
  };
};

const setAutoSigninUser = (state, action) => {
  return {
    ...state,
    autoSignin: action.data,
  };
};

const setWebPurchaseReceipt = (state, action) => {
  return {
    ...state,
    isWebPurchaseSuccess: action.data,
  };
};

const setDeviceTokenSuccessResult = (state, action) => {
  return {
    ...state,
    isPostDeviceToken: false,
  };
};

const setAgreeToTerms = (state, action) => {
  return {
    ...state,
    isAgreeToTermsSuccess: action.data
  }
}

const setAppHardSoftUpdate = (state, action) => {
  return {
    ...state,
    appUpdateCode: action.data
  }
}

const testStoreDeviceID = (state, action) => {
  return {
    ...state,
    testDeviceToken: action.data
  }
}

const setUpgradeRestore = (state, action) => {
  return {
    ...state,
    upgradeRestoreSuccess: action.data
  }
}

const setVerifyEmail = (state, action) => {
  return {
    ...state,
    verifyEmailData: action.data
  }
}

const setLogoutMobilee = (state, action) => {
  return {
    ...state,
    isLogoutMobile: action.data
  }
}

const setSystemUpdate = (state, action) => {
  return {
    ...state,
    isSystemUpdate: action.data
  }
}

const setCheckEmail = (state, action) => {
  return {
    ...state,
    checkEmailData: action.data
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_LANGUAGE:
      return changeLanguage(state, action);
    case actionTypes.TOGGLE_LANGUAGE_DILOGUE:
      return toggleLanguageDialogue(state, action);
    case actionTypes.IS_LOGGED_IN:
      return setIsLoggedIn(state, action);
    case actionTypes.SET_URL_TO_REDIRECT:
      return setUrlToRedirect(state, action);
    case actionTypes.REDIRECT:
      return setRedirectUser(state, action);
    case actionTypes.AUTO_SIGNIN_USER:
      return setAutoSigninUser(state, action);
    case actionTypes.POST_WEB_PURCHASE_RECEIPT_DONE:
      return setWebPurchaseReceipt(state, action);
    case actionTypes.POST_DEVICE_TOKEN_SUCCESS:
      return setDeviceTokenSuccessResult(state, action);
    case actionTypes.AGREE_TO_TERMS_DONE:
      return setAgreeToTerms(state, action);
    case actionTypes.APP_HARD_SOFT_UPDATE:
      return setAppHardSoftUpdate(state, action);
    case actionTypes.TEST_STORE_DEVICE_TOKEN:
      return testStoreDeviceID(state, action)
    case actionTypes.UPGRADE_RESTORE_DONE:
      return setUpgradeRestore(state, action)
    case actionTypes.VERIFY_EMAIL_DONE:
      return setVerifyEmail(state, action)
    case actionTypes.LOGOUT_MOBILE:
      return setLogoutMobilee(state, action)
    case actionTypes.SYSTEM_UPDATE:
      return setSystemUpdate(state, action)
    case actionTypes.CHECK_EMAIL_DONE:
      return setCheckEmail(state, action)
    default:
      return state;
  }
};

export default reducer;
