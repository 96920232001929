import React, { useState, useEffect } from "react";
import NPSSurvey from "./NPSSurvey";
import { useSelector } from "react-redux";
import axios from "axios";

// One day in milliseconds: 1000ms * 60s * 60min * 24hr
const MS_PER_DAY = 1000 * 60 * 60 * 24;
const API_URL = process.env.REACT_APP_API_URL;

const fetchLastFeedbackTime = async (email) => {
  try {
    if (!email) {
      throw new Error("Email is required");
    }

    const response = await axios.get(`${API_URL}/api/getNpsTimeTracker`, {
      params: { email }
    });
    return response.data.lastShown;
  } catch (error) {
    console.error("Error fetching feedback time:", error);
    return null;
  }
};

const updateFeedbackTime = async (timestamp, email) => {
  try {
    await axios.post(`${API_URL}/api/saveNpsTimeTracker`, {
      timestamp,
      email
    });
    return true;
  } catch (error) {
    console.error("Error updating feedback time:", error);
    return false;
  }
};

const NPSFeedback = ({
  storageKey = "lastNPSFeedback",
  delayInMinutes = 5,
  daysInterval = 60,
  onSubmit,
  className = "",
}) => {
  const [showNPS, setShowNPS] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  const user = useSelector((state) => state.home?.user);
  const email = user?.Email;

  const isAccountOldEnough = () => {
    if (!user?.CreatedAt) return false;
    const oneMonthInMs = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
    const accountAge = Date.now() - new Date(user.CreatedAt).getTime();
    return accountAge > oneMonthInMs;
  };

  useEffect(() => {
    const checkAndShowNPS = async () => {
      if (!email || !isAccountOldEnough()) {
        setShowNPS(false);
        return;
      }

      try {
        // Try to get time from API first
        const apiLastFeedback = await fetchLastFeedbackTime(email);

        if (apiLastFeedback === null) {
          // If no API data, check localStorage for migration
          const localLastFeedback = localStorage.getItem(storageKey);

          if (localLastFeedback) {
            // Migrate data from localStorage to API
            const success = await updateFeedbackTime(
              parseInt(localLastFeedback),
              email
            );

            if (success) {
              // Clear localStorage after migration
              localStorage.removeItem(storageKey);
            }

            // Show survey if the local storage data is older than 60 days
            const daysSinceLastFeedback =
              (Date.now() - parseInt(localLastFeedback)) / MS_PER_DAY;
            if (daysSinceLastFeedback > daysInterval) {
              return setShowNPS(true);
            } else {
              return setShowNPS(false);
            }
          }

          // If no data in localStorage either, show survey
          return setShowNPS(true);
        }

        // Calculate days since last feedback using API time
        const daysSinceLastFeedback =
          (Date.now() - apiLastFeedback) / MS_PER_DAY;

        // Only show if we've passed the interval
        if (daysSinceLastFeedback > daysInterval) {
          setShowNPS(true);
        } else {
          setShowNPS(false);
        }
      } catch (error) {
        console.error("Error checking NPS feedback time:", error);
        // On error, check localStorage as fallback
        const localLastFeedback = localStorage.getItem(storageKey);

        if (!localLastFeedback) {
          // If no data in localStorage either, don't show
          setShowNPS(false);
          return;
        }

        // Only calculate and check interval if we have localStorage data
        const daysSinceLastFeedback =
          (Date.now() - parseInt(localLastFeedback)) / MS_PER_DAY;

        if (daysSinceLastFeedback > daysInterval) {
          setShowNPS(true);
        } else {
          setShowNPS(false);
        }
      }
    };

    // Check immediately
    checkAndShowNPS();

    // Then set up timer for periodic checks
    const timer = setInterval(checkAndShowNPS, delayInMinutes * 60 * 1000);

    return () => clearInterval(timer);
  }, [delayInMinutes, daysInterval, storageKey, email, user?.CreatedAt]);

  const handleClose = async () => {
    setShowNPS(false);
    const currentTime = Date.now();

    try {
      // Update API first
      const success = await updateFeedbackTime(currentTime, email);
      if (!success) {
        // Fallback to localStorage if API update fails
        localStorage.setItem(storageKey, currentTime.toString());
      }
    } catch (error) {
      // Fallback to localStorage if API fails
      localStorage.setItem(storageKey, currentTime.toString());
    }

    if (onSubmit) onSubmit();
  };

  if (!showNPS) return null;

  if (isMinimized) {
    return (
      <div className={`fixed bottom-4 left-4 ${className}`}>
        <button
          onClick={() => setIsMinimized(false)}
          className="bg-[#0068FF] text-white px-4 py-3 rounded-full shadow-lg hover:opacity-90 flex items-center gap-2"
        >
          <span role="img" aria-label="feedback">
            📝
          </span>
          Give Feedback
        </button>
      </div>
    );
  }

  return (
    <div className={`fixed bottom-4 left-4 w-full max-w-md ${className}`}>
      <div className="bg-white rounded-2xl shadow-xl">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-neutral-200">
          <h2 className="font-semibold">We Value Your Feedback!</h2>
          <div className="flex gap-2">
            <button
              onClick={() => setIsMinimized(true)}
              className="p-1 hover:bg-neutral-100 rounded"
            >
              <span role="img" aria-label="minimize">
                ➖
              </span>
            </button>
            <button
              onClick={handleClose}
              className="p-1 hover:bg-neutral-100 rounded"
            >
              <span role="img" aria-label="close">
                ✖️
              </span>
            </button>
          </div>
        </div>

        {/* Survey Content */}
        <NPSSurvey onClose={handleClose} />
      </div>
    </div>
  );
};

export default NPSFeedback;
