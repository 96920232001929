import React from "react"
import PropTypes from "prop-types"
import SystemUpdateModal from "./SystemUpdateModal"

const SoftUpdateModal = (props) => {
  const { show, handleUpdateApp, handleSoftUpdateClose } = props

  return (
    <SystemUpdateModal
      show={show}
      handleUpdateApp={handleUpdateApp}
      handleSoftUpdateClose={handleSoftUpdateClose}
    />
  )
}

SoftUpdateModal.propTypes = {
  handleUpdateApp: PropTypes.func,
  handleSoftUpdateClose: PropTypes.func,
  show: PropTypes.bool,
}

export default SoftUpdateModal
