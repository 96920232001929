import * as actionTypes from "../actions/actionTypes";

const initialState = {
  ownersData: null,
  cryptosNewsData: null,
  cryptosNewsAllData: null,
  cryptoHeaderData: null,
  cryptosCoinData: null,
  cryptosSearchData: null,
  ownersTableData: null,
};

const setOwnersData = (state, action) => {
  return {
    ...state,
    ownersData: action.data,
  };
};

const setNewsData = (state, action) => {
  return {
    ...state,
    cryptosNewsData: action.data,
  };
};

const setAllNewsData = (state, action) => {
  return {
    ...state,
    cryptosNewsAllData: action.data,
  };
};

const setHeaderData = (state, action) => {
  return {
    ...state,
    cryptoHeaderData: action.data,
  };
};

const setCoinData = (state, action) => {
  return {
    ...state,
    cryptosCoinData: action.data,
  };
};

const setCryptosSearch = (state, action) => {
  return {
    ...state,
    cryptosSearchData: action.data,
  };
};

const setOwnersTableData = (state, action) => {
  return {
    ...state,
    ownersTableData: action.data,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CRYPTO_OWNERS_DATA_DONE:
      return setOwnersData(state, action);
    case actionTypes.GET_CRYPTOS_NEWS_DATA_DONE:
      return setNewsData(state, action);
    case actionTypes.GET_CRYTPOS_NEWS_ALL_DATA_DONE:
      return setAllNewsData(state, action);
    case actionTypes.GET_CRYPTO_HEADER_DATA_DONE:
      return setHeaderData(state, action);
    case actionTypes.GET_CRYPTOS_DAILY_COINS_DATA_DONE:
      return setCoinData(state, action);
    case actionTypes.GET_CRYPTOS_SEARCH_DATA_DONE:
      return setCryptosSearch(state, action);
    case actionTypes.GET_CRYPTO_OWNERS_TABLE_DATA_DONE:
      return setOwnersTableData(state, action);
    default:
      return state;
  }
};

export default reducer;
