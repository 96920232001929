import * as actionTypes from "../actions/actionTypes"
import { setFundamentalsOptionsScroll } from "../actions/leaderboardAc"

const initialState = {
  whoBoughtData: null,
  whoSoldData: null,
  leaderboardTableData: null,
  bluewhaleWhoBoughtData: null,
  bluewhaleWhoSoldData: null,
  qid: "",
  bqid: null,
  bids: "",
  cids: "",
  sortorder: undefined,
  shareholdersData: null,
  bluewhaleHoldingsData: null,
  b100WhoBoughtData: null,
  b100WhoSoldData: null,
  upcomingEventsData: null,
  companyConnectSpikesData: null,
  bluewhaleConnectSpikesData: null,
  queryParams: null,
  companyMetadata: null,
  bluewhaleMetadata: null,
  quarterFilterOptions: null,
  company100BluewhaleData: null,
  companyBluewhaleDescription: null,
  optionsData: null,
  optionsSortData: { apiName: "", mode: "" },
  companyFundamentalsData: null,
  companyInsidersData: null,
  bluewhaleFundamentalsData: null,
  bluewhaleProfileData: null,
  bluewhaleNetExposureData: null,
  isFollowingInvestor: null,
  isFollowingCompany: null,
  bluewhaleManagedFundsData: null,
  isOptionsScroll: false,
  companyInsidersCallsData: null,
  companyInsidersPutsData: null,
  bluewhaleInsidersCallsData: null,
  bluewhaleInsidersPutsData: null,
  bluewhalePortfolioBreakdown: null,
  defaultQidObj: null
}

const setWhoBoughtData = (state, action) => {
  return {
    ...state,
    whoBoughtData: action.data,
  }
}

const setWhoSoldData = (state, action) => {
  return {
    ...state,
    whoSoldData: action.data,
  }
}

const setLeaderboardTableData = (state, action) => {
  return {
    ...state,
    leaderboardTableData: action.data,
  }
}

const setBluehwhaleWhoBoughtData = (state, action) => {
  return {
    ...state,
    bluewhaleWhoBoughtData: action.data,
  }
}

const setBluehwhaleWhoSoldData = (state, action) => {
  return {
    ...state,
    bluewhaleWhoSoldData: action.data,
  }
}

const setStoreQid = (state, action) => {
  return {
    ...state,
    qid: action.data,
  }
}

const setStoreSortOrder = (state, action) => {
  return {
    ...state,
    sortorder: action.data,
  }
}

const setStoreBluewhaleQid = (state, action) => {
  return {
    ...state,
    bqid: action.data,
  }
}

const setStoreBluewhaleIds = (state, action) => {
  return {
    ...state,
    bids: action.data,
  }
}

const setStoreCompanyIds = (state, action) => {
  return {
    ...state,
    cids: action.data,
  }
}

const setShareholdersData = (state, action) => {
  return {
    ...state,
    shareholdersData: action.data,
  }
}

const setBluewhaleHoldingsData = (state, action) => {
  return {
    ...state,
    bluewhaleHoldingsData: action.data,
  }
}

const set100WhoBoughtData = (state, action) => {
  return {
    ...state,
    b100WhoBoughtData: action.data,
  }
}

const set100WhoSoldData = (state, action) => {
  return {
    ...state,
    b100WhoSoldData: action.data,
  }
}

const setUpcomingEventsData = (state, action) => {
  return {
    ...state,
    upcomingEventsData: action.data,
  }
}

const setCompanyConnectSpikesData = (state, action) => {
  return {
    ...state,
    companyConnectSpikesData: action.data,
  }
}

const setBluewhaleConnectSpikesData = (state, action) => {
  return {
    ...state,
    bluewhaleConnectSpikesData: action.data,
  }
}

const setParamss = (state, action) => {
  return {
    ...state,
    queryParams: action.data,
  }
}

const setCompanyMetadata = (state, action) => {
  return {
    ...state,
    companyMetadata: action.data,
  }
}

const setBluewhaleMetadata = (state, action) => {
  return {
    ...state,
    bluewhaleMetadata: action.data,
  }
}

const setQuarterFilterOptions = (state, action) => {
  return {
    ...state,
    quarterFilterOptions: action.data,
  }
}

const setCompany100BluewhaleData = (state, action) => {
  return {
    ...state,
    company100BluewhaleData: action.data,
  }
}

const setCompanyBluewhaleDescription = (state, action) => {
  return {
    ...state,
    companyBluewhaleDescription: action.data,
  }
}

const setOptionsData = (state, action) => {
  return {
    ...state,
    optionsData: action.data,
  }
}

const setSelectedSortData = (state, action) => {
  return {
    ...state,
    optionsSortData: action.data,
  }
}

const clearCompMetadata = (state, action) => {
  return {
    ...state,
    companyMetadata: null,
  }
}

const setCompanyFundamentalsData = (state, action) => {
  return {
    ...state,
    companyFundamentalsData: action.data,
  }
}

const setIsFollowingInvestor = (state, action) => {
  return {
    ...state,
    isFollowingInvestor: action.data,
  }
}

const setIsFollowingCompany = (state, action) => {
  return {
    ...state,
    isFollowingCompany: action.data,
  }
}

const setCompanyInsidersData = (state, action) => {
  return {
    ...state,
    companyInsidersData: action.data,
  }
}

const setBluewhaleFundamentalsData = (state, action) => {
  return {
    ...state,
    bluewhaleFundamentalsData: action.data,
  }
}

const setBluewhaleProfileData = (state, action) => {
  return {
    ...state,
    bluewhaleProfileData: action.data,
  }
}

const setBluewhaleNetExposureData = (state, action) => {
  return {
    ...state,
    bluewhaleNetExposureData: action.data,
  }
}

const setBluewhaleManagedFundsData = (state, action) => {
  return {
    ...state,
    bluewhaleManagedFundsData: action.data,
  }
}

const setFundsOptionsScroll = (state, action) => {
  return {
    ...state,
    isOptionsScroll: action.data,
  }
}

const setCompanyInsidersCallsData = (state, action) => {
  return {
    ...state,
    companyInsidersCallsData: action.data
  }
}

const setCompanyInsidersPutsData = (state, action) => {
  return {
    ...state,
    companyInsidersPutsData: action.data
  }
}

const setBluewhaleCallsData = (state, action) => {
  return {
    ...state,
    bluewhaleInsidersCallsData: action.data
  }
}

const setBluewhalePutsData = (state, action) => {
  return {
    ...state,
    bluewhaleInsidersPutsData: action.data
  }
}

const setBluewhalePortfolioBreakdown = (state, action) => {
  return {
    ...state,
    bluewhalePortfolioBreakdown: action.data
  }
}

const setDefaultQidObj = (state, action) => {
  return {
    ...state, 
    defaultQidObj: action.data
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WHO_BOUGHT_DATA_DONE:
      return setWhoBoughtData(state, action)
    case actionTypes.GET_WHO_SOLD_DATA_DONE:
      return setWhoSoldData(state, action)
    case actionTypes.GET_LEADERBOARD_TABLE_DATA_DONE:
      return setLeaderboardTableData(state, action)
    case actionTypes.GET_BLUEWHALE_WHO_BOUGHT_DATA_DONE:
      return setBluehwhaleWhoBoughtData(state, action)
    case actionTypes.GET_BLUEWHALE_WHO_SOLD_DATA_DONE:
      return setBluehwhaleWhoSoldData(state, action)
    case actionTypes.STORE_QID:
      return setStoreQid(state, action)
    case actionTypes.STORE_SORT_ORDER:
      return setStoreSortOrder(state, action)
    case actionTypes.STORE_BLUEWHALE_QID:
      return setStoreBluewhaleQid(state, action)
    case actionTypes.STORE_BLUEWHALE_IDS:
      return setStoreBluewhaleIds(state, action)
    case actionTypes.STORE_COMPANY_IDS:
      return setStoreCompanyIds(state, action)
    case actionTypes.GET_SHAREHOLDERS_DATA_DONE:
      return setShareholdersData(state, action)
    case actionTypes.GET_BLUEWHALE_HOLDINGS_DATA_DONE:
      return setBluewhaleHoldingsData(state, action)
    case actionTypes.GET_100_WHO_BOUGHT_DATA_DONE:
      return set100WhoBoughtData(state, action)
    case actionTypes.GET_100_WHO_SOLD_DATA_DONE:
      return set100WhoSoldData(state, action)
    case actionTypes.GET_UPCOMING_EVENTS_DATA_DONE:
      return setUpcomingEventsData(state, action)
    case actionTypes.GET_COMPANY_CONNECT_SPIKES_DATA_DONE:
      return setCompanyConnectSpikesData(state, action)
    case actionTypes.GET_BLUEWHALE_CONNECT_SPIKES_DATA_DONE:
      return setBluewhaleConnectSpikesData(state, action)
    case actionTypes.SET_QUERY_PARAMS:
      return setParamss(state, action)
    case actionTypes.GET_COMPANY_METADATA_DONE:
      return setCompanyMetadata(state, action)
    case actionTypes.GET_BLUEWHALE_METADATA_DONE:
      return setBluewhaleMetadata(state, action)
    case actionTypes.GET_QUARTER_FILTER_OPTIONS_DONE:
      return setQuarterFilterOptions(state, action)
    case actionTypes.GET_COMPANY_100BLUEWHALE_DATA_DONE:
      return setCompany100BluewhaleData(state, action)
    case actionTypes.GET_COMPANY_BLUEWHALE_DESCRIPTION_DONE:
      return setCompanyBluewhaleDescription(state, action)
    case actionTypes.GET_OPTIONS_DATA_DONE:
      return setOptionsData(state, action)
    case actionTypes.SET_OPTIONS_SORT_DATA:
      return setSelectedSortData(state, action)
    case actionTypes.CLEAR_COMPANY_METADATA:
      return clearCompMetadata(state, action)
    case actionTypes.GET_COMPANY_FUNDAMENTALS_DATA_DONE:
      return setCompanyFundamentalsData(state, action)
    case actionTypes.GET_COMPANY_INSIDERS_DATA_DONE:
      return setCompanyInsidersData(state, action)
    case actionTypes.GET_BLUEWHALE_FUNDAMENTALS_DATA_DONE:
      return setBluewhaleFundamentalsData(state, action)
    case actionTypes.GET_BLUEWHALE_PROFILE_DATA_DONE:
      return setBluewhaleProfileData(state, action)
    case actionTypes.GET_BLUEWHALE_NET_EXPOSURE_DATA_DONE:
      return setBluewhaleNetExposureData(state, action)
    case actionTypes.GET_IS_FOLLOWING_INVESTOR_DONE:
      return setIsFollowingInvestor(state, action)
    case actionTypes.GET_IS_FOLLOWING_COMPANY_DONE:
      return setIsFollowingCompany(state, action)
    case actionTypes.GET_BLUEWHALE_MANAGED_FUNDS_DONE:
      return setBluewhaleManagedFundsData(state, action)
    case actionTypes.SET_FUNDAMENTALS_OPTIONS_SCROLL:
      return setFundsOptionsScroll(state, action);
    case actionTypes.GET_COMPANY_INSIDERS_CALLS_DATA_DONE:
      return setCompanyInsidersCallsData(state, action);
    case actionTypes.GET_COMPANY_INSIDERS_PUTS_DATA_DONE:
      return setCompanyInsidersPutsData(state, action);
    case actionTypes.GET_BLUEWHALE_CALLS_DATA_DONE:
      return setBluewhaleCallsData(state, action);
    case actionTypes.GET_BLUEWHALE_PUTS_DATA_DONE:
      return setBluewhalePutsData(state, action);
    case actionTypes.BLUEWHALE_PORTFOLIO_BREAKDOWN_DONE:
      return setBluewhalePortfolioBreakdown(state, action)
    case actionTypes.STORE_DEFAULT_QID_OBJ:
      return setDefaultQidObj(state, action)
    default:
      return state
  }
}

export default reducer
