import axios from "axios";
import Cookies from "universal-cookie";

import * as actionTypes from "./actionTypes";
import { getArray } from "../../helpers/filterSearchArray";
import { getUserDetails } from "./homeAc";
import isToken from "../../helpers/token-check";

let apiDomain = process.env.REACT_APP_API_URL;
let domainUserDetails = process.env.REACT_APP_API2_URL;

const cookies = new Cookies();

export const setLightDarkMode = (data) => {
  return {
    type: actionTypes.SET_LIGHT_DARK_MODE,
    data: data,
  };
};

export const getLocalization = () => {
  return axios.get("https://extreme-ip-lookup.com/json/");
};

export const getProfileInfo = () => {
  return axios.get(domainUserDetails + "/v3/me/profile");
};

export const getCertificateInfo = () => {
  return axios.get(domainUserDetails + "/v3/me/certificates?limit=20");
};

export const patchProfileInfo = (values) => {
  return axios.patch(domainUserDetails + "/v3/me/profile", values);
};

export const getAffiliateLinks = () => {
  let token = localStorage.getItem("token");
  return axios.get(domainUserDetails + "/v3/affiliate_links");
};

export const getSuccessfulReferralApi = () => {
  let token = localStorage.getItem("token");
  return axios.get(domainUserDetails + "/v3/referred_details");
};

export const saveNewMail = (email) => {
  let body = new FormData();
  body.append("paypal_email", email);

  return axios.patch(
    domainUserDetails + `/v3/affiliate_update_paypal_email`,
    body,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const toggleSearch = () => {
  return {
    type: actionTypes.TOGGLE_SEARCH,
  };
};

export const loading = (value) => {
  return {
    type: actionTypes.LOADING,
    data: value,
  };
};

export const loadingEvent = (value) => {
  return {
    type: actionTypes.LOADING_EVENT,
    data: value,
  };
};

export const referralPopup = (value) => {
  return {
    type: actionTypes.REFERRAL_POPUP,
    data: value,
  };
};

export const searchLoading = (value) => {
  return {
    type: actionTypes.SEARCH_LOADING,
    data: value,
  };
};

export const infiniteScrollLoading = (value) => {
  return {
    type: actionTypes.INFINITE_SCROLL_LOADING,
    data: value,
  };
};

export const clearBluewhaleSearchData = () => {
  return {
    type: actionTypes.CLEAR_BLUEWHALE_SEARCH_DATA,
  };
};

export const clearCompanySearchData = () => {
  return {
    type: actionTypes.CLEAR_COMPANY_SEARCH_DATA,
  };
};

export const getSearchDataDone = (data) => {
  return {
    type: actionTypes.GET_SEARCH_DATA_DONE,
    data: data,
  };
};

export const getBluewhaleSearchDataDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_SEARCH_DATA_DONE,
    data: data,
  };
};

export const saveFilteredBluewhales = (data) => {
  return {
    type: actionTypes.SAVE_FILTERED_BLUEWHALES,
    data: data,
  };
};

export const saveFilteredCompanies = (data) => {
  return {
    type: actionTypes.SAVE_FILTERED_COMPANIES,
    data: data,
  };
};

export const saveFilteredQuarter = (data) => {
  return {
    type: actionTypes.SAVE_FILTERED_QUARTER,
    data: data,
  };
};

export const getCompanySearchDataDone = (data) => {
  return {
    type: actionTypes.GET_COMPANY_SEARCH_DATA_DONE,
    data: data,
  };
};

export const toggleFilterDrawer = () => {
  return {
    type: actionTypes.TOGGLE_FILTER_DRAWER,
  };
};

export const getCompanyTagsDone = (data) => {
  return {
    type: actionTypes.GET_COMPANY_TAGS_DONE,
    data: data,
  };
};

export const getBluewhaleTagsDone = (data) => {
  return {
    type: actionTypes.GET_BLUEWHALE_TAGS_DONE,
    data: data,
  };
};

export const receivedAffiliateLink = (data) => {
  return {
    type: actionTypes.GET_AFFILIATE_LINK,
    data: data,
  };
};

export const clearFilterTags = () => {
  return {
    type: actionTypes.CLEAR_FILTER_TAGS,
  };
};

export const setSelectedFilterValues = (data) => {
  return {
    type: actionTypes.SET_SELECTED_FILTER_VALUES,
    data: data,
  };
};

export const getCheckoutProductInsider = () => {
  return axios.get(domainUserDetails + "/v3/insider-plans");
};

export const clearFilters = () => {
  return {
    type: actionTypes.CLEAR_FILTERS,
  };
};

export const serverErrorDone = () => {
  return {
    type: actionTypes.SERVER_ERROR_DONE,
  };
};

export const getNotificationsDone = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_DONE,
    data: data,
  };
};

export const getPopupNotificationsDone = (data) => {
  return {
    type: actionTypes.GET_POPUP_NOTIFICATIONS_DONE,
    data: data,
  };
};

export const clearServerError = () => {
  return {
    type: actionTypes.CLEAR_SERVER_ERROR,
  };
};

export const notificationLoading = (data) => {
  return {
    type: actionTypes.NOTIFICATION_LOADING,
    data: data,
  };
};

export const getNotificationDataByIdDone = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATION_DATA_BY_ID,
    data: data,
  };
};

export const serverError = () => {
  return (dispatch) => {
    dispatch(loading(false));
    dispatch(serverErrorDone());
  };
};

export const setFilterOpen = (value) => {
  return {
    type: actionTypes.SET_FILTER_OPEN,
    data: value,
  };
};

export const setFilterSearchTerm = (value) => {
  return {
    type: actionTypes.SET_FILTER_SEARCH_TERM,
    data: value,
  };
};

export const setFilterSearchResults = (value) => {
  return {
    type: actionTypes.SET_FILTER_SEARCH_RESULTS,
    data: value,
  };
};

export const settingsPatchDone = (data) => {
  return {
    type: actionTypes.SETTINGS_PATCH_DONE,
    data: data,
  };
};

export const getWisdomDataDone = (data) => {
  return {
    type: actionTypes.GET_WISDOM_LINK_DONE,
    data: data,
  };
};

export const patchDeleteAccountDone = (data) => {
  return {
    type: actionTypes.PATCH_DELETE_ACCOUNT_DONE,
    data: data,
  };
};

let cancelToken;

export const getSearchData = (value, mode, obj) => {
  return (dispatch) => {
    dispatch(searchLoading(true));
    console.log(obj, value, mode);
    let bid = "";
    let cid = "";
    let type = "";
    let sector = "";

    if (obj && obj.bid) bid = obj.bid;
    if (obj && obj.cid) cid = obj.cid;
    if (obj && obj.type) type = obj.type;
    if (obj && obj.sector) sector = obj.sector;

    //Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    axios
      .get(
        domainUserDetails +
          "/v3/search?search=" +
          value +
          "&offset=0&bid=" +
          bid +
          "&cid=" +
          cid +
          "&type=" +
          type +
          "&sector=" +
          sector,
        {
          cancelToken: cancelToken.token,
        }
      )
      .then((res) => {
        let data = res.data;
        if (mode === "company" || mode === "both") {
          let array;
          if (data.company.length > 0) {
            array = getArray(data.company, "company");
          }
          dispatch(getCompanySearchDataDone(array));
        }
        if (mode === "bluewhale" || mode === "both") {
          let array;
          if (data.bluewhale.length > 0) {
            array = getArray(data.bluewhale, "bluewhale");
          }
          dispatch(getBluewhaleSearchDataDone(array));
        } else {
          dispatch(getSearchDataDone(data));
        }
        dispatch(searchLoading(false));
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

export const getCheckoutProduct = (params) => {
  return axios.get(domainUserDetails + "/v3/stripe/get_product_details_by_id", {
    params: params,
  });
};

export const getCheckoutDetailsV2 = (params) => {
  return axios.get(domainUserDetails + "/v3/product-details", {
    params: params,
  });
};

export const postCheckoutProduct = (body) => {
  var form_data = new FormData();

  for (var key in body) {
    form_data.append(key, body[key]);
  }

  return axios.post(
    process.env.REACT_APP_PAYMENT_URL + "/v3/stripe/create-subscription",
    form_data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const chargeCheckoutProduct = (body) => {
  var form_data = new FormData();

  for (var key in body) {
    form_data.append(key, body[key]);
  }

  return axios.post(
    process.env.REACT_APP_PAYMENT_URL + "/v3/stripe/create-charge",
    form_data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const referralPopupInteract = (state) => {
  return async (dispatch) => {
    const response = await getAffiliateLinks();
    if (response.status === 200) {
      dispatch(receivedAffiliateLink(response?.data?.url));
    }
    dispatch(referralPopup(state));
  };
};

export const getListGuideTour = () => {
  return axios.get(domainUserDetails + "/v3/guide-list");
};

export const getTagsByBidCid = (tags, mode, cids) => {
  return (dispatch) => {
    dispatch(loading(true));
    let a = null;
    if (mode === "company") a = "cids";
    else a = "bids";
    axios
      .get(
        domainUserDetails +
          "/v3/tags-by-bid-cid?" +
          "bids" +
          "=" +
          tags +
          "&cids=" +
          cids
        // +
        // "&cids=" +
        // 21451
      )
      .then((res) => {
        let data = res.data;
        if (mode === "bluewhale" || mode === "both") {
          if (data.bluewhale.length > 0) {
            let array = getArray(data.bluewhale, "bluewhale");
            dispatch(getBluewhaleTagsDone(array));
          }
        }
        if (mode === "company" || mode === "both") {
          if (data.company.length > 0) {
            let array = getArray(data.company, "company");
            dispatch(getCompanyTagsDone(array));
          }
        }
        // dispatch(getSearchDataDone(res.data));
        // dispatch(loading(false));
      });
  };
};

// return {
//   type: actionTypes.CHANGE_LANGUAGE,
//   data: localeData,
// };

export const changeLanguage = (localeData) => {
  return {
    type: actionTypes.CHANGE_LANGUAGE,
    data: localeData,
  };
};

export const patchLanguageChange = (localeData) => {
  console.log(localeData);
  // return
  return (dispatch) => {
    dispatch(loading(true));
    // let lang = null
    // let googleTrans = null
    // switch (localeData.langCode) {
    //   case "en":
    //     lang = "english"
    //     googleTrans = "/en/en"
    //     break
    //   case "zh":
    //     lang = "chinese_simp"
    //     googleTrans = "/en/zh-CN"
    //     break
    //   case "zh-tw":
    //     lang = "chinese_trad"
    //     googleTrans = "/en/zh-TW"
    //     break
    //   case "vn":
    //     lang = "vietnamese"
    //     googleTrans = "/en/vi"
    //     break
    //   default:
    //     lang = "english"
    //     googleTrans = "/en/en"
    // }

    if (!isToken()) {
      cookies.set("googtrans", localeData.googleTrans, { path: "/" });
      window.location.reload();
      return;
    }
    let body = new FormData();
    body.append("language", localeData.apiCode);

    axios
      .patch(domainUserDetails + "/v3/me", body)
      .then((res) => {
        // dispatch(changeLanguage(localeData));
        // dispatch(loading(false));
        // alert(cookies.get("googtrans"))
        // alert("cookie change")
        cookies.set("googtrans", localeData.googleTrans, { path: "/" });
        localStorage.removeItem("lang");
        localStorage.setItem("isPatchCalled", true);
        // localStorage.setItem("patchCalled", true)
        // alert(lang)
        // alert(googleTrans)
        // alert(cookies.get("googtrans"))
        window.location.reload();
      })
      .catch((err) => {
        // alert("error")
      });
  };
};

export const getNotifications = (obj) => {
  return (dispatch, getState) => {
    if (!obj.isResumeApp) {
      if (obj.isPopup) dispatch(notificationLoading(true));
      else if (!obj.isPopup && obj.onLoad) dispatch(loading(true));
    }
    let category = "";
    if (obj.category) category = obj.category;

    axios
      .get(
        domainUserDetails +
          "/v3/notifications?limit=" +
          obj.limit +
          "&offset=" +
          obj.offset +
          "&category=" +
          category
      )
      .then((res) => {
        let data = res.data;

        if (!obj.onLoad && !obj.isPopup) {
          let prevData = getState().common.notificationsData.data;
          let appendedData = prevData.concat(data.data);
          data.data = appendedData;
        }

        if (!obj.isResumeApp) {
          if (!obj.isPopup) dispatch(getNotificationsDone(data));
          else dispatch(getPopupNotificationsDone(data));
          if (obj.isPopup) dispatch(notificationLoading(false));
          else dispatch(loading(false));

          if (obj.isPopup) dispatch(getUserDetails());
        } else {
          dispatch(getNotificationsDone(data));
        }
      });
  };
};

export const markNotificationAsRead = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));
    //api-dev.spiking.com/v3/unread_notifications?access_token=
    axios.patch(domainUserDetails + "/v3/unread_notifications").then((res) => {
      if (!obj.isPopup) dispatch(getNotifications(obj));
      if (!obj.isPopup) dispatch(getUserDetails());
      dispatch(loading(false));
    });
  };
};

// https://api-dev.spiking.com/v3/notification_by_id?id=1

export const getNotificationDataById = (id) => {
  return (dispatch) => {
    dispatch(loading(true));
    axios
      .get(domainUserDetails + "/v3/notification_by_id?id=" + id)
      .then((res) => {
        let data = res.data;
        dispatch(getNotificationDataByIdDone(data));
        dispatch(loading(false));
      });
  };
};

export const patchBinanceDetails = (obj) => {
  return (dispatch) => {
    // dispatch(loading(true))

    let body = new FormData();
    body.append("btc_address", obj.binanceWalletAddress);
    body.append("binance_user_id", obj.binanceUserId);
    body.append("binance_bep2_memo", obj.binanceMemo);
    // body.append("zoom_email", obj.email)

    axios
      .patch(domainUserDetails + "/v3/me", body)
      .then((res) => {
        // alert("binane updated")
        dispatch(getUserDetails());
        dispatch(settingsPatchDone(true));
        // if (obj.reloadPage) {
        //   window.location.reload()
        // }
        // alert("patch success")
        // dispatch(changeLanguage(localeData));
        // dispatch(loading(false));
        // window.location.reload()
      })
      .catch((err) => {
        // alert("error")
      });
  };
};

export const patchFacebookDetails = (obj) => {
  return (dispatch) => {
    // dispatch(loading(true))

    let body = new FormData();
    body.append("facebook_email", obj.email);
    // body.append("zoom_email", obj.email)

    axios
      .patch(domainUserDetails + "/v3/me", body)
      .then((res) => {
        // alert("fb updated")
        dispatch(getUserDetails());
        dispatch(settingsPatchDone(true));
        // if (obj.reloadPage) {
        //   window.location.reload()
        // }
        // alert("patch success")
        // dispatch(changeLanguage(localeData));
        // dispatch(loading(false));
        // window.location.reload()
      })
      .catch((err) => {
        // alert("error")
      });
  };
};

export const patchCertificateDetails = (obj) => {
  return (dispatch) => {
    // dispatch(loading(true))

    let body = new FormData();
    body.append("cst_certificate_name", obj.cstCertificate);
    // body.append("zoom_email", obj.email)

    axios
      .patch(domainUserDetails + "/v3/me", body)
      .then((res) => {
        // alert("cert updated")
        dispatch(getUserDetails());
        dispatch(settingsPatchDone(true));
        // if (obj.reloadPage) {
        //   window.location.reload()
        // }
        // alert("patch success")
        // dispatch(changeLanguage(localeData));
        // dispatch(loading(false));
        // window.location.reload()
      })
      .catch((err) => {
        // alert("error")
      });
  };
};

export const patchPersonalityDetails = (obj) => {
  return (dispatch) => {
    // dispatch(loading(true))

    let body = new FormData();
    body.append("personality_type", obj.mbtiOption);
    // body.append("zoom_email", obj.email)

    axios
      .patch(domainUserDetails + "/v3/me", body)
      .then((res) => {
        // alert("personality updated")
        dispatch(getUserDetails());
        dispatch(settingsPatchDone(true));
        // if (obj.reloadPage) {
        //   window.location.reload()
        // }
        // alert("patch success")
        // dispatch(changeLanguage(localeData));
        // dispatch(loading(false));
        // window.location.reload()
      })
      .catch((err) => {
        // alert("error")
      });
  };
};

export const patchWhatsappDetails = (obj) => {
  return (dispatch) => {
    // dispatch(loading(true))

    let body = new FormData();
    body.append("mobile_number", obj.whatsappNumber);
    body.append("mobile_extension", obj.whatsappNumberPrefix);
    // body.append("zoom_email", obj.email)

    axios
      .patch(domainUserDetails + "/v3/me", body)
      .then((res) => {
        // alert("whatsapp updated")
        dispatch(getUserDetails());
        dispatch(settingsPatchDone(true));
        // if (obj.reloadPage) {
        //   window.location.reload()
        // }
        // alert("patch success")
        // dispatch(changeLanguage(localeData));
        // dispatch(loading(false));
        // window.location.reload()
      })
      .catch((err) => {
        // alert("error")
      });
  };
};

export const getWisdomLink = (obj) => {
  return (dispatch) => {
    dispatch(loading(true));

    axios
      .get(domainUserDetails + "/v3/wisdom")
      .then((res) => {
        let data = res.data;
        dispatch(getWisdomDataDone(data));
        dispatch(loading(false));
      })
      .catch((err) => {
        // alert("error")
      });
  };
};

export const patchDeleteAccount = () => {
  return (dispatch) => {
    dispatch(loading(true));

    axios
      .patch(domainUserDetails + "/v3/delete-me")
      .then((res) => {
        let data = res.data;
        dispatch(patchDeleteAccountDone(true));
        dispatch(loading(false));
      })
      .catch((err) => {
        // alert("error")
      });
  };
};

export const setReturnToSpinner = (data) => {
  return {
    type: actionTypes.RETURN_TO_SPINNER,
    data: data,
  };
};

export const setNotificationOffset = (data) => {
  return {
    type: actionTypes.SET_NOTIFICATION_OFFSET,
    data: data,
  };
};

export const showAnnouncementDialog = (data) => {
  return {
    type: actionTypes.SHOW_ANNOUNCEMENT_POPUP,
    data: data,
  };
};

export const toggleSideDrawer = (value) => {
  // alert(value)
  return {
    type: actionTypes.TOGGLE_SIDE_DRAWER,
    data: value,
  };
};

export const patchProfileAvatar = (avatar) => {
  var formData = new FormData();
  formData.append("file", avatar);

  return axios.patch(domainUserDetails + "/v3/me/avatar", formData);
};
