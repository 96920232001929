export const LOADING = "LOADING";
export const SEARCH_LOADING = "SEARCH_LOADING";
export const INFINITE_SCROLL_LOADING = "INFINITE_SCROLL_LOADING";
export const USER_DETAILS_LOADING = "USER_DETAILS_LOADING";
export const SET_LIGHT_DARK_MODE = "SET_LIGHT_DARK_MODE";
export const REFERRAL_POPUP = "REFERRAL_POPUP";
export const GET_AFFILIATE_LINK = "GET_AFFILIATE_LINK";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const TOGGLE_LANGUAGE_DILOGUE = "TOGGLE_LANGUAGE_DILOGUE";
export const GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN";
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const SET_URL_TO_REDIRECT = "SET_URL_TO_REDIRECT";
export const TOGGLE_FILTER_DRAWER = "TOGGLE_FILTER_DRAWER";
export const GET_NOTIFICATIONS_DONE = "GET_NOTIFICATIONS_DONE";
export const GET_POPUP_NOTIFICATIONS_DONE = "GET_POPUP_NOTIFICATIONS_DONE";
export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const MARK_NOTI_AS_READ = "MARK_NOTI_AS_READ";
export const POST_TERMS_AND_CONDITIONS_SUCCESS =
  "POST_TERMS_AND_CONDITIONS_SUCCESS";
export const TOGGLE_SIDE_DRAWER = "TOGGLE_SIDE_DRAWER";

export const GET_USER_DETAILS_DONE = "GET_USER_DETAILS_DONE";

export const GET_HISTORY_TRADE = "GET_HISTORY_TRADE";
export const LOADING_EVENT = "LOADING_EVENT";
export const GET_BREAKDOWN_RECORDS = "GET_BREAKDOWN_RECORDS";

export const TOGGLE_SEARCH = "TOGGLE_SEARCH";
export const GET_SEARCH_DATA = "GET_SEARCH_DATA";
export const GET_SEARCH_DATA_DONE = "GET_SEARCH_DATA_DONE";
export const GET_BLUEWHALE_SEARCH_DATA_DONE = "GET_BLUEWHALE_SEARCH_DATA_DONE";
export const GET_COMPANY_SEARCH_DATA_DONE = "GET_COMPANY_SEARCH_DATA_DONE";
export const CLEAR_COMPANY_SEARCH_DATA = "CLEAR_COMPANY_SEARCH_DATA";
export const CLEAR_BLUEWHALE_SEARCH_DATA = "CLEAR_BLUEWHALE_SEARCH_DATA";
export const SAVE_FILTERED_BLUEWHALES = "SAVE_FILTERED_BLUEWHALES";
export const SAVE_FILTERED_COMPANIES = "SAVE_FILTERED_COMPANIES";
export const SAVE_FILTERED_QUARTER = "SAVE_FILTERED_QUARTER";
export const GET_COMPANY_TAGS_DONE = "GET_COMPANY_TAGS_DONE";
export const GET_BLUEWHALE_TAGS_DONE = "GET_BLUEWHALE_TAGS_DONE";
export const CLEAR_FILTER_TAGS = "CLEAR_FILTER_TAGS";
export const SET_SELECTED_FILTER_VALUES = "SET_SELECTED_FILTER_VALUES";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const SET_FILTER_OPEN = "SET_FILTER_OPEN";
export const SET_FILTER_SEARCH_TERM = "SET_FILTER_SEARCH_TERM";
export const SET_FILTER_SEARCH_RESULTS = "SET_FILTER_SEARCH_RESULTS";

export const GET_CHART_DATA_DONE = "GET_CHART_DATA_DONE";
export const SET_CHART_RAW_DATA = "SET_CHART_RAW_DATA";
export const SELECT_COMPANY = "SELECT_COMPANY";
export const GET_TOP_STORIES_DONE = "GET_TOP_STORIES_DONE";
export const GET_SPIKING_UP_DONE = "GET_SPIKING_UP_DONE";
export const GET_SPIKING_DOWN_DONE = "GET_SPIKING_DOWN_DONE";
export const GET_EARNINGS_DATA_DONE = "GET_EARNINGS_DATA_DONE";
export const GET_ANALYST_DATA_DONE = "GET_ANALYST_DATA_DONE";
export const GET_PUBLIC_OFFERING_DATA_DONE = "GET_PUBLIC_OFFERING_DATA_DONE";
export const GET_STRATEGY_DATA_DONE = "GET_STRATEGY_DATA_DONE";
export const GET_BUYBACK_DATA_DONE = "GET_BUYBACK_DATA_DONE";
export const GET_FDA_APPROVAL_DATA_DONE = "GET_FDA_APPROVAL_DATA_DONE";
export const GET_NOTES_OFFERING_DATA_DONE = "GET_NOTES_OFFERING_DATA_DONE";
export const GET_BOND_DATA_DONE = "GET_BOND_DATA_DONE";
export const GET_DELISTING_DATA_DONE = "GET_DELISTING_DATA_DONE";
export const GET_DIVIDEND_DATA_DONE = "GET_DIVIDEND_DATA_DONE";
export const GET_IPO_DATA_DONE = "GET_IPO_DATA_DONE";
export const GET_INDEX_ADDITION_DATA_DONE = "GET_INDEX_ADDITION_DATA_DONE";
export const GET_MEDIA_DATA_DONE = "GET_MEDIA_DATA_DONE";
export const GET_RESIGNATION_DATA_DONE = "GET_RESIGNATION_DATA_DONE";
export const GET_STOCK_SPLIT_DATA_DONE = "GET_STOCK_SPLIT_DATA_DONE";
export const GET_ACQUISITION_DATA_DONE = "GET_ACQUISITION_DATA_DONE";

export const GET_WHO_BOUGHT_DATA_DONE = "GET_WHO_BOUGHT_DATA_DONE";
export const GET_WHO_SOLD_DATA_DONE = "GET_WHO_SOLD_DATA_DONE";
export const GET_COMPANY_INSIDERS_CALLS_DATA_DONE =
  "GET_COMPANY_INSIDERS_CALLS_DATA_DONE";
export const GET_COMPANY_INSIDERS_PUTS_DATA_DONE =
  "GET_COMPANY_INSIDERS_PUTS_DATA_DONE";
export const GET_COMPANY_INSIDERS_DATA_DONE = "GET_COMPANY_INSIDERS_DATA_DONE";
export const GET_LEADERBOARD_TABLE_DATA_DONE =
  "GET_LEADERBOARD_TABLE_DATA_DONE";
export const GET_BLUEWHALE_WHO_BOUGHT_DATA_DONE =
  "GET_BLUEWHALE_WHO_BOUGHT_DATA_DONE";
export const GET_BLUEWHALE_WHO_SOLD_DATA_DONE =
  "GET_BLUEWHALE_WHO_SOLD_DATA_DONE";
export const GET_BLUEWHALE_CALLS_DATA_DONE = "GET_BLUEWHALE_CALLS_DATA_DONE";
export const GET_BLUEWHALE_PUTS_DATA_DONE = "GET_BLUEWHALE_PUTS_DATA_DONE";
export const STORE_QID = "STORE_QID";
export const STORE_DEFAULT_QID_OBJ = "STORE_DEFAULT_QID_OBJ";
export const STORE_SORT_ORDER = "STORE_SORT_ORDER";
export const STORE_BLUEWHALE_QID = "STORE_BLUEWHALE_QID";
export const STORE_BLUEWHALE_IDS = "STORE_BLUEWHALE_IDS";
export const STORE_COMPANY_IDS = "STORE_COMPANY_IDS";
export const GET_SHAREHOLDERS_DATA_DONE = "GET_SHAREHOLDERS_DATA_DONE";
export const GET_BLUEWHALE_HOLDINGS_DATA_DONE =
  "GET_BLUEWHALE_HOLDINGS_DATA_DONE";
export const GET_TAGS_BY_BID_CID_DONE = "GET_TAGS_BY_BID_CID_DONE";
export const GET_100_WHO_BOUGHT_DATA_DONE = "GET_100_WHO_BOUGHT_DATA_DONE";
export const GET_100_WHO_SOLD_DATA_DONE = "GET_100_WHO_SOLD_DATA_DONE";
export const GET_COMPANY_100BLUEWHALE_DATA_DONE =
  "GET_COMPANY_100BLUEWHALE_DATA_DONE";
export const GET_UPCOMING_EVENTS_DATA_DONE = "GET_UPCOMING_EVENTS_DATA_DONE";
export const GET_COMPANY_CONNECT_SPIKES_DATA_DONE =
  "GET_COMPANY_CONNECT_SPIKES_DATA_DONE";
export const GET_BLUEWHALE_CONNECT_SPIKES_DATA_DONE =
  "GET_BLUEWHALE_CONNECT_SPIKES_DATA_DONE";
export const SET_QUERY_PARAMS = "SET_QUERY_PARAMS";
export const GET_COMPANY_METADATA_DONE = "GET_COMPANY_METADATA_DONE";
export const GET_BLUEWHALE_METADATA_DONE = "GET_BLUEWHALE_METADATA_DONE";
export const GET_QUARTER_FILTER_OPTIONS_DONE =
  "GET_QUARTER_FILTER_OPTIONS_DONE";
export const GET_COMPANY_BLUEWHALE_DESCRIPTION_DONE =
  "GET_COMPANY_BLUEWHALE_DESCRIPTION_DONE";
export const GET_OPTIONS_DATA_DONE = "GET_OPTIONS_DATA_DONE";
export const SET_OPTIONS_SORT_DATA = "SET_OPTIONS_SORT_DATA";
export const CLEAR_COMPANY_METADATA = "CLEAR_COMPANY_METADATA";
export const GET_COMPANY_FUNDAMENTALS_DATA_DONE =
  "GET_COMPANY_FUNDAMENTALS_DATA_DONE";
export const GET_BLUEWHALE_FUNDAMENTALS_DATA_DONE =
  "GET_BLUEWHALE_FUNDAMENTALS_DATA_DONE";
export const GET_BLUEWHALE_PROFILE_DATA_DONE =
  "GET_BLUEWHALE_PROFILE_DATA_DONE";
export const GET_BLUEWHALE_NET_EXPOSURE_DATA_DONE =
  "GET_BLUEWHALE_NET_EXPOSURE_DATA_DONE";
export const GET_IS_FOLLOWING_INVESTOR_DONE = "GET_IS_FOLLOWING_INVESTOR_DONE";
export const GET_IS_FOLLOWING_COMPANY_DONE = "GET_IS_FOLLOWING_COMPANY_DONE";
export const GET_DAILYBREAD_DATA_DONE = "GET_DAILYBREAD_DATA_DONE";
export const GET_RESURRECTION_DATA_DONE = "GET_RESURRECTION_DATA_DONE";
export const GET_TRIANGULATION_DATA_DONE = "GET_TRIANGULATION_DATA_DONE";
export const SET_DASH_QUERY_PARAMS = "SET_DASH_QUERY_PARAMS";
export const SET_DASH_TAGS = "SET_DASH_TAGS";
export const SET_BIDS_TAGS = "SET_BIDS_TAGS";
export const SET_CIDS_TAGS = "SET_CIDS_TAGS";
export const SET_COMMON_TAGS = "SET_COMMON_TAGS";
export const CLEAR_SELECTED_COMMON_TAGS = "CLEAR_SELECTED_COMMON_TAGS";
export const GET_WATCHLIST_DATA_DONE = "GET_WATCHLIST_DATA_DONE";
export const POST_WHALEWHATCHING_DONE = "POST_WHALEWHATCHING_DONE";
export const GET_BLUEWHALE_MANAGED_FUNDS_DONE =
  "GET_BLUEWHALE_MANAGED_FUNDS_DONE";
export const BLUEWHALE_PORTFOLIO_BREAKDOWN_DONE =
  "BLUEWHALE_PORTFOLIO_BREAKDOWN_DONE";

export const GET_100BLUEWHALES_DATA_DONE = "GET_100BLUEWHALES_DATA_DONE";
export const GET_UNIVERSE_FUNDS_DATA_DONE = "GET_UNIVERSE_FUNDS_DATA_DONE";
export const GET_SOVEREIGN_WEALTH_FUNDS_DONE =
  "GET_SOVEREIGN_WEALTH_FUNDS_DONE";

export const GET_DAILY_TRADES_DATA_DONE = "GET_DAILY_TRADES_DATA_DONE";
export const GET_DAILYTRADES_ALL_DATA_DONE = "GET_DAILYTRADES_ALL_DATA_DONE";
export const GET_GOAT_DATA_DONE = "GET_GOAT_DATA_DONE";
export const GET_GRIT_DATA_DONE = "GET_GRIT_DATA_DONE";
export const GET_YOLO_DATA_DONE = "GET_YOLO_DATA_DONE";
export const GET_YOLO_BITCOIN_DATA_DONE = "GET_YOLO_BITCOIN_DATA_DONE";
export const GET_YOLO_BITCOIN_VALUE_DONE = "GET_YOLO_BITCOIN_VALUE_DONE";

export const GET_WEBINAR_VIDEOS_DONE = "GET_WEBINAR_VIDEOS_DONE";
export const GET_STOCKS_VIDEOS_DONE = "GET_STOCKS_VIDEOS_DONE";
export const GET_OPTIONS_VIDEOS_DONE = "GET_OPTIONS_VIDEOS_DONE";
export const GET_CRYPTOS_VIDEOS_DONE = "GET_CRYPTOS_VIDEOS_DONE";
export const GET_COSY_VIDEOS_DONE = "GET_COSY_VIDEOS_DONE";
export const GET_COACHING_VIDEOS_DONE = "GET_COACHING_VIDEOS_DONE";
export const GET_MASTERCLASS_VIDEOS_DONE = "GET_MASTERCLASS_VIDEOS_DONE";
export const GET_VIDEO_BY_ID_DONE = "GET_VIDEO_BY_ID_DONE";
export const GET_STRIPE_SUBSCRIPTIONS_DONE = "GET_STRIPE_SUBSCRIPTIONS_DONE";

export const GET_ZOOM_CLASSES_DATA_DONE = "GET_ZOOM_CLASSES_DATA_DONE";

export const SERVER_ERROR_DONE = "SERVER_ERROR_DONE";
export const CLEAR_SERVER_ERROR = "CLEAR_SERVER_ERROR";
export const REDIRECT = "REDIRECT";
export const AUTO_SIGNIN_USER = "AUTO_SIGNIN_USER";
export const POST_WEB_PURCHASE_RECEIPT_DONE = "POST_WEB_PURCHASE_RECEIPT_DONE";
export const POST_DEVICE_TOKEN_SUCCESS = "POST_DEVICE_TOKEN_SUCCESS";
export const AGREE_TO_TERMS_DONE = "AGREE_TO_TERMS_DONE";
export const APP_HARD_SOFT_UPDATE = "APP_HARD_SOFT_UPDATE";
export const SYSTEM_UPDATE = "SYSTEM_UPDATE";

export const GET_SPIKE_CARDS_DATA_DONE = "GET_SPIKE_CARDS_DATA_DONE";
export const GET_SPIKE_OVERVIEW_DONE = "GET_SPIKE_OVERVIEW_DONE";
export const POST_SPIKE_SUCCESS = "POST_SPIKE_SUCCESS";
export const DELETE_SPIKE_SUCCESS = "DELETE_SPIKE_SUCCESS";
export const MAX_SPIKES_REACHED_DATA = "MAX_SPIKES_REACHED_DATA";

export const SET_FUNDAMENTALS_OPTIONS_SCROLL =
  "SET_FUNDAMENTALS_OPTIONS_SCROLL";

export const GET_COURSES_DATA_DONE = "GET_COURSES_DATA_DONE";

export const GET_NOTIFICATION_DATA_BY_ID = "GET_NOTIFICATION_DATA_BY_ID";

export const TEST_STORE_DEVICE_TOKEN = "TEST_STORE_DEVICE_TOKEN";

export const UPGRADE_RESTORE_DONE = "UPGRADE_RESTORE_DONE";

export const VERIFY_EMAIL_DONE = "VERIFY_EMAIL_DONE";

export const CHECK_EMAIL_DONE = "CHECK_EMAIL_DONE";

export const REGISTER_FOR_ZOOM_CLASS_DONE = "REGISTER_FOR_ZOOM_CLASS_DONE";

export const SETTINGS_PATCH_DONE = "SETTINGS_PATCH_DONE";

export const GET_WISDOM_LINK_DONE = "GET_WISDOM_LINK_DONE";

export const RETURN_TO_SPINNER = "RETURN_TO_SPINNER";

export const SET_NOTIFICATION_OFFSET = "SET_NOTIFICATION_OFFSET";

export const LOGOUT_MOBILE = "LOGOUT_MOBILE";

export const POPULAR_INSIDER_DATA_DONE = "POPULAR_INSIDER_DATA_DONE";

export const POPULAR_INSIDER_FUNDS_DATA_DONE =
  "POPULAR_INSIDER_FUNDS_DATA_DONE";

export const POPULAR_INSIDER_SOVEREIGN_DATA_DONE =
  "POPULAR_INSIDER_SOVEREIGN_DATA_DONE";

export const GET_FOLLOWING_INSIDER_DONE = "GET_FOLLOWING_INSIDER_DONE";
export const GET_FOLLOWING_FUNDS_DONE = "GET_FOLLOWING_FUNDS_DONE";
export const GET_FOLLOWING_COMPANIES_DONE = "GET_FOLLOWING_COMPANIES_DONE";
export const GET_TD_FUNDAMENTALS_DONE = "GET_TD_FUNDAMENTALS_DONE";
export const GET_TD_DATA_DONE = "GET_TD_DATA_DONE";

export const SHOW_ANNOUNCEMENT_POPUP = "SHOW_ANNOUNCEMENT_POPUP";

export const GET_ARK_COMPANY_CHART_DATA_DONE =
  "GET_ARK_COMPANY_CHART_DATA_DONE";
export const GET_ARK_TREND_CHART_DATA_DONE = "GET_ARK_TREND_CHART_DATA_DONE";
export const GET_ARK_FUNDAMENTALS_TABLE_DATA_DONE =
  "GET_ARK_FUNDAMENTALS_TABLE_DATA_DONE";
export const GET_ARK_FUNDAMENTALS_METADATA_DONE =
  "GET_ARK_FUNDAMENTALS_METADATA_DONE";
export const GET_ARK_FUNDAMENTAL_BREAKDOWN_DATA_DONE =
  "GET_ARK_FUNDAMENTAL_BREAKDOWN_DATA_DONE";
export const GET_ARK_FUNDS_HOLDINGS_SEARCH_DONE =
  "GET_ARK_FUNDS_HOLDINGS_SEARCH_DONE";
export const GET_ARK_INSIDERS_TABLE_DATA_DONE =
  "GET_ARK_INSIDERS_TABLE_DATA_DONE";
export const GET_ARK_INVESTOR_INSIDERS_TABLE_QUATER_DATA_DONE =
  "GET_ARK_INVESTOR_INSIDERS_TABLE_QUATER_DATA_DONE";
export const GET_ARK_COMPANY_FUNDAMENTALS_DATA_DONE =
  "GET_ARK_COMPANY_FUNDAMENTALS_DATA_DONE";
export const GET_ARK_COMPANY_INSIDER_DATA_DONE =
  "GET_ARK_COMPANY_INSIDER_DATA_DONE";
export const GET_ARK_NEWS_DATA_DONE = "GET_ARK_NEWS_DATA_DONE";
export const GET_ARK_WEIGHT_CHART_DATA_DONE = "GET_ARK_WEIGHT_CHART_DATA_DONE";
export const GET_ARK_PERFORMANCE_CHART_DATA_DONE =
  "GET_ARK_PERFORMANCE_CHART_DATA_DONE";
export const GET_OWNERSHIP_CHART_DATA_DONE = "GET_OWNERSHIP_CHART_DATA_DONE";
export const GET_MOVING_TICKER_DATA_DONE = "GET_MOVING_TICKER_DATA_DONE";

export const GET_FUNDS_HOLDINGS_DATA_DONE = "GET_FUNDS_HOLDINGS_DATA_DONE";
export const GET_FUNDS_METADATA_DONE = "GET_FUNDS_METADATA_DONE";
export const GET_FUNDS_SEARCH_DATA_DONE = "GET_FUNDS_SEARCH_DATA_DONE";
export const GET_FUNDS_INSIDERS_DATA_DONE = "GET_FUNDS_INSIDERS_DATA_DONE";
export const GET_FUNDS_INSIDERS_MORE_DATA_DONE =
  "GET_FUNDS_INSIDERS_MORE_DATA_DONE";
export const GET_FUNDS_TECHNICAL_DATA_DONE = "GET_FUNDS_TECHNICAL_DATA_DONE";
export const GET_FUNDS_COST_AVERAGE_DATA_DONE =
  "GET_FUNDS_COST_AVERAGE_DATA_DONE";
export const GET_FUNDS_COST_AVERAGE_SEARCH_DATA_DONE =
  "GET_FUNDS_COST_AVERAGE_SEARCH_DATA_DONE";

export const GET_TRILLION_DOLLAR_DATA_DONE = "GET_TRILLION_DOLLAR_DATA_DONE";
export const GET_JIM_CRAMER_COMPANIES_DONE = "GET_JIM_CRAMER_COMPANIES_DONE";
export const GET_POLITICIANS_DATA_DONE = "GET_POLITICIANS_DATA_DONE";
export const GET_POLTITCIANS_SEARCH_DATA_DONE =
  "GET_POLTITCIANS_SEARCH_DATA_DONE";
export const GET_CNBC_DATA_DONE = "GET_CNBC_DATA_DONE";

export const GET_OPTIONS_OPEN_INTEREST_DATA_DONE =
  "GET_OPTIONS_OPEN_INTEREST_DATA_DONE";
export const GET_OPTIONS_BLOCK_QUANTITY_DATA_DONE =
  "GET_OPTIONS_BLOCK_QUANTITY_DATA_DONE";
export const GET_OPTIONS_BLOCK_QUALITY_DATA_DONE =
  "GET_OPTIONS_BLOCK_QUALITY_DATA_DONE";
export const GET_IV_CHANGE_DATA_DONE = "GET_IV_CHANGE_DATA_DONE";
export const GET_OPTIONS_AVERAGE_VOLUME_DATA_DONE =
  "GET_OPTIONS_AVERAGE_VOLUME_DATA_DONE";
export const GET_TERRITORY_DATA_DONE = "GET_TERRITORY_DATA_DONE";
export const GET_OPTIONS_INTEREST_DATA_DONE = "GET_OPTIONS_INTEREST_DATA_DONE";
export const GET_MOMENTUM_DATA_DONE = "GET_MOMENTUM_DATA_DONE";
export const GET_EXPOSURE_DATA_DONE = "GET_EXPOSURE_DATA_DONE";
export const GET_OPTIONS_SEARCH_DATA_DONE = "GET_OPTIONS_SEARCH_DATA_DONE";

export const GET_DASHBOARD_BUYBACK_DATA_DONE =
  "GET_DASHBOARD_BUYBACK_DATA_DONE";
export const REFRESH_LOADING = "REFRESH_LOADING";
export const SHOW_FAQ = "SHOW_FAQ";
export const SHOW_LESSON_PREREQUISITE_BANNER =
  "SHOW_LESSON_PREREQUISITE_BANNER";
export const STORE_PREREQUISITE_LESSON = "STORE_PREREQUISITE_LESSON";

export const GET_CRYPTO_OWNERS_DATA_DONE = "GET_CRYPTO_OWNERS_DATA_DONE";
export const GET_CRYPTOS_NEWS_DATA_DONE = "GET_CRYPTOS_NEWS_DATA_DONE";
export const GET_CRYTPOS_NEWS_ALL_DATA_DONE = "GET_CRYTPOS_NEWS_ALL_DATA_DONE";
export const GET_CRYPTO_HEADER_DATA_DONE = "GET_CRYPTO_HEADER_DATA_DONE";
export const GET_CRYPTOS_DAILY_COINS_DATA_DONE =
  "GET_CRYPTOS_DAILY_COINS_DATA_DONE";
export const GET_CRYPTOS_SEARCH_DATA_DONE = "GET_CRYPTOS_SEARCH_DATA_DONE";
export const GET_CRYPTO_OWNERS_TABLE_DATA_DONE =
  "GET_CRYPTO_OWNERS_TABLE_DATA_DONE";

export const GET_CHALLENGE_EVENT_REQUEST = "GET_CHALLENGE_EVENT_REQUEST";
export const GET_CHALLENGE_EVENT_SUCCESS = "GET_CHALLENGE_EVENT_SUCCESS";
export const GET_CHALLENGE_EVENT_FAILURE = "GET_CHALLENGE_EVENT_FAILURE";

export const END_CHALLENGE_EVENT_REQUEST = "END_CHALLENGE_EVENT_REQUEST";
export const END_CHALLENGE_EVENT_SUCCESS = "END_CHALLENGE_EVENT_SUCCESS";
export const END_CHALLENGE_EVENT_FAILURE = "END_CHALLENGE_EVENT_FAILURE";

export const PATCH_DELETE_ACCOUNT_DONE = "PATCH_DELETE_ACCOUNT_DONE";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const TOGGLE_EDIT_PROFILE_REQUEST = "TOGGLE_EDIT_PROFILE_REQUEST";
export const TOGGLE_EDIT_PROFILE_SUCCESS = "TOGGLE_EDIT_PROFILE_SUCCESS";
export const TOGGLE_EDIT_PROFILE_FAILURE = "TOGGLE_EDIT_PROFILE_FAILURE";

export const PATCH_PROFILE_REQUEST = "PATCH_PROFILE_REQUEST";
export const PATCH_PROFILE_SUCCESS = "PATCH_PROFILE_SUCCESS";
export const PATCH_PROFILE_FAILURE = "PATCH_PROFILE_FAILURE";

export const RESET_FOLLOWING_INSIDERS = "RESET_FOLLOWING_INSIDERS";
export const GET_DAILYBREAD_PAST_DATA_DONE = "GET_DAILYBREAD_PAST_DATA_DONE";

export const GET_SUPPORTED_BLOCKCHAINS = "GET_SUPPORTED_BLOCKCHAINS";