import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import isToken from "../../../helpers/token-check";

import PrivateRoute from "../../PrivateRoute/container";
import PublicRoute from "./PublicRoute";
import AffiliateRoute from "./AffiliateRoute";
import EventCalendarContainer from "../../EventCalendar";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const YourReferralLinkNew = React.lazy(() =>
  import("../../Settings/components/Referral/YourReferralLinkNew")
);
const AuthWeChatCallback = React.lazy(() => import("../../AuthWeChatCallback"));
const PaypalSuccessfulRefNew = React.lazy(() =>
  import("../../Settings/components/Referral/PaypalSuccessfulRefNew")
);
const Login = React.lazy(() => import("../../Login/container"));
const Intermediate = React.lazy(() => import("../../Intermediate/container"));
const TermsAndConditions = React.lazy(() =>
  import("../../TermsAndConditions/container")
);
const Home = React.lazy(() => import("../../Home/container"));
const PublicParentTab = React.lazy(() =>
  import("../../PublicFeature/parentTab")
);
const PopularSpikes = React.lazy(() => import("../../PopularSpikes/container"));
const Notification = React.lazy(() => import("../../Notification/container"));
const SingleNotificationContainer = React.lazy(() =>
  import("../../Notification/singleNotificationContainer")
);
const CompanyParentTab = React.lazy(() =>
  import("../../CompanyPage/parentTab")
);
const DashboardParentTab = React.lazy(() =>
  import("../../Dashboard2/parentTab")
);
const Onboard = React.lazy(() => import("../../Onboard"));
const InvestorParentTab = React.lazy(() =>
  import("../../InvestorsPage/parentTab")
);
// const InvestorPage = React.lazy(() => import("../../InvestorsPage/container"
const LeaderboardTable = React.lazy(() =>
  import("../../LeaderboardTable/container")
);
const VideosParentTab = React.lazy(() => import("../../Videos/parentTab"));
const VideoPlay = React.lazy(() => import("../../Videos/videoplayContainer"));
const WebPurchaseComp = React.lazy(() => import("../../WebPurchase/container"));
const PageNotFound = React.lazy(() => import("../../Errors/PageNotFound"));
// TODO Serve other error pages
// const InternetConnectionLost = React.lazy(() => import("../../Errors/InternetConnectionLost"));
const ServerError = React.lazy(() => import("../../Errors/ServerError"));
// const SystemUpdate = React.lazy(() => import("../../Errors/SystemUpdate"
const Baiting = React.lazy(() =>
  import("../../SpikeUniverse/components/BaitingPage/container")
);
const SpikeUniverseParentTab = React.lazy(() =>
  import("../../SpikeUniverse/parentTab")
);
const CreateSpikesWrapper = React.lazy(() =>
  import("../../SpikeUniverse/components/CreateSpikeWrapper/container")
);
const Top100ManagersParentTab = React.lazy(() =>
  import("../../Top100Managers/parentTab")
);
// const Courses = React.lazy(() => import("../../Learn/Courses"
// const Course = React.lazy(() => import("../../Learn/Course"
// const Resource = React.lazy(() => import("../../Learn/Resource"
// const ResourceCategory = React.lazy(() => import("../../Learn/ResourceCategory"
// const ResourceContent = React.lazy(() => import("../../Learn/ResourceContent"
const CheckPrivatePublic = React.lazy(() => import("./checkPrivatePublic"));
const AppleUserDetails = React.lazy(() =>
  import("../../Login/components/appleUserDetails")
);
const Wisdom = React.lazy(() => import("../../Wisdom/container"));
const CheckoutNew = React.lazy(() => import("../../CheckoutNew/container"));
const LogoutContainer = React.lazy(() => import("../../Login/logoutContainer"));
const PopularInsidersParentTab = React.lazy(() =>
  import("../../PopularInsiders/parentTab")
);
const WelcomeVideo = React.lazy(() =>
  import("../../WelcomeVideo/WelcomeVideo")
);
const WelcomeCrypto = React.lazy(() =>
  import("../../WelcomeCrypto/WelcomeVideo")
);
const MembersArea = React.lazy(() => import("../../MembersArea/container"));
const AffiliateRedirect = React.lazy(() => import("./AffiliateRedirect"));
const AdvanceRoute = React.lazy(() => import("./AdvanceRoute"));
const InvestorArkParentTab = React.lazy(() =>
  import("../../ArkFeature/ArkInvestorPage/parentTab")
);
const CompanyArkParentTab = React.lazy(() =>
  import("../../ArkFeature/ArkCompanyPage/parentTab")
);
const Line = React.lazy(() => import("../../Ark/line"));
const Bar = React.lazy(() => import("../../Ark/bar"));
const Tree = React.lazy(() => import("../../Ark/tree"));
const Trends = React.lazy(() => import("../../Ark/trends"));
const InsiderMember = React.lazy(() => import("../../InsiderMember/container"));
const InsiderCheckout = React.lazy(() =>
  import("../../InsiderCheckout/container")
);
const WelcomeInsider = React.lazy(() =>
  import("../../WelcomeMembership/container")
);
const FundsParentTab = React.lazy(() => import("../../Funds"));
const CheckoutGrabPay = React.lazy(() => import("../../CheckoutGrabPay"));
const GrabPayCallback = React.lazy(() => import("../../GrabPayCallback"));
const Calendar = React.lazy(() => import("../../Calendar/container"));
const PaymentRoute = React.lazy(() => import("./PaymentRoute"));
const LayoutRoute = React.lazy(() => import("../../WebPurchase/LayoutRoute"));
const OptionsCompanyParentTab = React.lazy(() =>
  import("../../OptionsDashboard/components/CompanyPage/parentTab")
);
const LessonContainer = React.lazy(() => import("../../Lesson/container"));
const CoinContainer = React.lazy(() => import("../../CryptosDashboard/Coin"));
const ActivityFeed = React.lazy(() =>
  import("../../ActivityFeed/ActivityFeed")
);
const EventHistory = React.lazy(() =>
  import("../../Challenge/components/History/EventHistory")
);
const Challenge = React.lazy(() => import("../../Challenge"));
const ChallengePerformance = React.lazy(() =>
  import("../../Challenge/components/Performance/Me")
);
const ChallengePerformanceComparison = React.lazy(() =>
  import("../../Challenge/components/Performance/Others")
);
const ChallengeLeaderboard = React.lazy(() =>
  import("../../Challenge/components/Leaderboard")
);
const Portfolio = React.lazy(() =>
  import("../../Challenge/components/Portfolio/Portfolio")
);
const Trading = React.lazy(() =>
  import("../../Challenge/components/Trading/Trading")
);
const ChallengeActivity = React.lazy(() =>
  import("../../Challenge/components/Activity")
);
const CopyTradePortfolio = React.lazy(() =>
  import("../../CopyTrade/Portfolio")
);
const DiscoverCopyTrade = React.lazy(() => import("../../CopyTrade/Discover"));
const PersonalInformation = React.lazy(() =>
  import("../../UserProfile/PersonalInfomation")
);
const TradeProfile = React.lazy(() => import("../../UserProfile/TradeProfile"));
const ProfileCalendar = React.lazy(() => import("../../UserProfile/Calendar"));
const ProfileNotification = React.lazy(() =>
  import("../../UserProfile/Notification")
);
const DangerZone = React.lazy(() => import("../../UserProfile/DangerZone"));
const Courses = React.lazy(() => import("../../Courses"));
const CoursesNew = React.lazy(() => import("../../CoursesNew"));
const Billing = React.lazy(() => import("../../UserProfile/Billing"));
const LoginLandingPage = React.lazy(() => import("../../LoginNew/LandingPage"));
const EmailLogin = React.lazy(() => import("../../LoginNew/EmailLogin"));
const ForgotPassword = React.lazy(() =>
  import("../../LoginNew/ForgotPassword")
);
const ResetPassword = React.lazy(() => import("../../LoginNew/ResetPassword"));
const CreateAccount = React.lazy(() => import("../../LoginNew/CreateAccount"));
const RaceTo100 = React.lazy(() => import("../../RaceTo100"));
const Following = React.lazy(() => import("../../UserProfile/Following"));
const TradePortfolio = React.lazy(() =>
  import("../../CopyTrade/TradePortfolio")
);
const TradingDiary = React.lazy(() => import("../../UserProfile/TradingDiary"));
const VideoLibrary = React.lazy(() => import("../../VideoLibrary"));
const Replays = React.lazy(() => import("../../VideoLibrary/Replays"));
const Membership = React.lazy(() => import("../../UserProfile/Membership"));
const TradeHistory = React.lazy(() => import("../../CopyTrade/TradeHistory"));
const MillionDollarTrade = React.lazy(() => import("../../MillionDollarTrade"));
const SpikingUpDown = React.lazy(() => import("../../SpikingUpDown"));
const Discover = React.lazy(() => import("../../Discover"));
const TradeGPT = React.lazy(() => import("../../TradeGPT"));
const BasicCourse = React.lazy(() => import("../../BasicCourse"));
const StocksAI = React.lazy(() => import("../../StocksAI"));
const Disclaimer = React.lazy(() => import("./Disclaimer"));
const OptionsAI = React.lazy(() => import("../../OptionsAI"));
const CryptosAI = React.lazy(() => import("../../CryptosAI"));
const TradeGptSectors = React.lazy(() =>
  import("../../TradeGPT/TradeGptSectors")
);
const TradeGptPrompt = React.lazy(() =>
  import("../../TradeGPT/TradeGPTPrompt")
);
const HolyGrail = React.lazy(() => import("../../HolyGrail"));
const EventsCalendar = React.lazy(() =>
  import("../../Calendar/EventsCalendar")
);
const Workbook = React.lazy(() => import("../../Workbook"));
const NewCryptos = React.lazy(() => import("../../CryptosAI/NewCryptos"));
const CryptosAI2 = React.lazy(() => import("../../CryptosAI-2"));
const Wallet = React.lazy(() => import("../../CryptosAI-2/Wallet"));
const UserWallet = React.lazy(() => import("../../UserProfile/Wallet"));
const Referral = React.lazy(() => import("../../UserProfile/Referral"));
const BlockChain = React.lazy(() => import("../../BlockChain"));
const DiscoverNew = React.lazy(() => import("../../DiscoverNew"));
const Track = React.lazy(() => import("../../Track"));
const Shop = React.lazy(() => import("../../Shop"));
const Journey = React.lazy(() => import("../../Journey"));
const YoutubePlayer = React.lazy(() => import("../../YoutubePlayer"));
const Kickoff = React.lazy(() => import("../../Kickoff"));

const dailytrades_route_group = [
  { path: "/dailytrades", component: PublicParentTab },
  { path: "/watchlist", component: PublicParentTab },
  { path: "/trader", exact: true, component: PublicParentTab },
  // { path: "/whalewatching", component: WhaleWatching },
];

const company_route_group = [
  { path: "/company/fundamentals/:cid", component: CompanyParentTab },
  { path: "/company/insider/:cid", component: CompanyParentTab },
  { path: "/company/alerts/:cid", component: CompanyParentTab },
  { path: "/company/technical/:cid", component: CompanyParentTab },
  { path: "/company/shareholders/:cid", component: CompanyParentTab },
  { path: "/company/100investors/:cid", component: CompanyParentTab },
  { path: "/company/events/:cid", component: CompanyParentTab },
  { path: "/company/options/:cid", component: CompanyParentTab },
  { path: "/company/catchthespikes/:cid", component: CompanyParentTab },
  { path: "/company/earnings/:cid", component: CompanyParentTab },
];

const investor_route_group = [
  { path: "/bluewhale/:bid", component: InvestorParentTab },
  // { path: "/bluewhale/:bid", component: InvestorPage },
];

const dashboard_route_group = [
  { path: "/dailybread", exact: true, component: DashboardParentTab },
  { path: "/resurrection", exact: true, component: DashboardParentTab },
  { path: "/triangulation", exact: true, component: DashboardParentTab },
  { path: "/top100managers", exact: true, component: Top100ManagersParentTab },
  { path: "/100Bluewhales", exact: true, component: DashboardParentTab },
  { path: "/universeoffunds", exact: true, component: Top100ManagersParentTab },
  {
    path: "/sovereignwealthfunds",
    exact: true,
    component: Top100ManagersParentTab,
  },
  { path: "/goat", exact: true, component: DashboardParentTab },
  { path: "/grit", exact: true, component: DashboardParentTab },
  { path: "/yolo", exact: true, component: DashboardParentTab },
];

const courses_route_group_private = [
  { path: "/courses", exact: true, component: CoursesNew },
  { path: "/courses-new", exact: true, component: Courses },
  { path: "/onboarding/:step_id?", exact: true, component: Onboard },
];

// const learn_route_group = [
//   { path: "/learn/courses", exact: true, component: Courses },
//   { path: "/learn/course/", exact: true, component: Course },
//   { path: "/learn/resource", exact: true, component: Resource },
//   { path: "/learn/resourceCategory", exact: true, component: ResourceCategory },
//   { path: "/learn/resourceContent", exact: true, component: ResourceContent },
//   // TODO Change to path with id when there is data
//   // { path: "/learn/course/:id", exact: true, component: Courses },
// ]

const baiting_route_group = [
  { path: "/baiting", exact: true, component: Baiting },
];

const spike_universe_route_group = [
  { path: "/explorespikes", exact: true, component: SpikeUniverseParentTab },
  { path: "/myspikes", exact: true, component: SpikeUniverseParentTab },
  {
    path: "/spikeswhalewatching",
    exact: true,
    component: SpikeUniverseParentTab,
  },
  { path: "/spikealerts", exact: true, component: SpikeUniverseParentTab },
  { path: "/add/explorespikes", exact: true, component: CreateSpikesWrapper },
  { path: "/add/selectspikes", exact: true, component: CreateSpikesWrapper },
  { path: "/add/reviewfinish", exact: true, component: CreateSpikesWrapper },
];

const ark_route_group = [
  {
    path: "/investor/:bid/fundamental",
    exact: true,
    component: InvestorArkParentTab,
  },
  {
    path: "/investor/:bid/insider",
    exact: true,
    component: InvestorArkParentTab,
  },
  {
    path: "/investor/:bid/alerts",
    exact: true,
    component: InvestorArkParentTab,
  },
  {
    path: "/investor/:bid/technical",
    exact: true,
    component: InvestorArkParentTab,
  },
  {
    path: "/investor/:bid/weightranking",
    exact: true,
    component: InvestorArkParentTab,
  },
  {
    path: "/investor/:bid/trends",
    exact: true,
    component: InvestorArkParentTab,
  },
  {
    path: "/investor/:bid/performance",
    exact: true,
    component: InvestorArkParentTab,
  },
  {
    path: "/company/:bid/fundamental/:cid",
    exact: false,
    component: CompanyArkParentTab,
  },
  {
    path: "/company/:bid/insider/:cid",
    exact: true,
    component: CompanyArkParentTab,
  },
  {
    path: "/company/:bid/alerts/:cid",
    exact: true,
    component: CompanyArkParentTab,
  },
  {
    path: "/company/:bid/technical/:cid",
    exact: true,
    component: CompanyArkParentTab,
  },
  {
    path: "/company/:bid/willitspike/:cid",
    exact: true,
    component: CompanyArkParentTab,
  },
  {
    path: "/company/:bid/ownershiptrends/:cid",
    exact: true,
    component: CompanyArkParentTab,
  },
];

const funds_route_group = [
  { path: "/funds/:bid/fundamental", exact: true, component: FundsParentTab },
  { path: "/funds/:bid/insider", exact: true, component: FundsParentTab },
  { path: "/funds/:bid/alerts", exact: true, component: FundsParentTab },
  { path: "/funds/:bid/technical", exact: true, component: FundsParentTab },
  { path: "/funds/:bid", exact: true, component: FundsParentTab },
  { path: "/funds/:bid/:id", exact: true, component: FundsParentTab },

  // { path: "/funds/universe", exact: true, component: FundsParentTab },
  // { path: "/funds/sovereign", exact: true, component: FundsParentTab },
  // { path: "/funds/pension", exact: true, component: FundsParentTab },
  // { path: "/funds/retirement", exact: true, component: FundsParentTab },
  // { path: "/funds/billionaire", exact: true, component: FundsParentTab },
  // { path: "/funds/millionaire", exact: true, component: FundsParentTab },
  // { path: "/funds/whale", exact: true, component: FundsParentTab },
  // { path: "/funds/openinterest", exact: true, component: FundsParentTab },
];

const dashboard_new_route_group = [
  { path: "/dailybread", exact: true, component: StocksAI },
  { path: "/dailybread-new", exact: true, component: StocksAI },
  { path: "/resurrection", exact: true, component: StocksAI },
  { path: "/triangulation", exact: true, component: StocksAI },
  { path: "/goat", exact: true, component: StocksAI },
  { path: "/yolo", exact: true, component: StocksAI },
  { path: "/grit", exact: true, component: StocksAI },
  { path: "/trillion", exact: true, component: StocksAI },
  { path: "/buyback", exact: true, component: StocksAI },

  { path: "/stocks/dailybread", exact: true, component: StocksAI },
  { path: "/stocks/resurrection", exact: true, component: StocksAI },
  { path: "/stocks/triangulation", exact: true, component: StocksAI },
  { path: "/stocks/goat", exact: true, component: StocksAI },
  { path: "/stocks/yolo", exact: true, component: StocksAI },
  { path: "/stocks/grit", exact: true, component: StocksAI },
  { path: "/stocks/trillion", exact: true, component: StocksAI },
  { path: "/stocks/buyback", exact: true, component: StocksAI },
];

const options_new_route_group = [
  { path: "/options/open-interest", exact: true, component: OptionsAI },
  { path: "/options/block-quantity", exact: true, component: OptionsAI },
  { path: "/options/block-quality", exact: true, component: OptionsAI },
  { path: "/options/iv-change", exact: true, component: OptionsAI },
  { path: "/options/avg-volume", exact: true, component: OptionsAI },
  { path: "/options/leaps", exact: true, component: OptionsAI },
  {
    path: "/options/company/territory/:cid",
    exact: true,
    component: OptionsCompanyParentTab,
  },
  {
    path: "/options/company/interests/:cid",
    exact: true,
    component: OptionsCompanyParentTab,
  },
  {
    path: "/options/company/momentum/:cid",
    exact: true,
    component: OptionsCompanyParentTab,
  },
  {
    path: "/options/company/entryexit/:cid",
    exact: true,
    component: OptionsCompanyParentTab,
  },
  {
    path: "/options/company/willitspike/:cid",
    exact: true,
    component: OptionsCompanyParentTab,
  },
];

const lesson_route_group = [
  {
    path: "/lesson/:courseId/content/:lessonId",
    exact: false,
    component: LessonContainer,
  },
  {
    path: "/lesson/:courseId/content",
    exact: false,
    component: LessonContainer,
  },
];

const cryptos_route_group = [
  { path: "/cryptos", exact: true, component: CryptosAI },
  { path: "/cryptos/candle/:cid", exact: true, component: CoinContainer },
  { path: "/cryptos/owners/:cid", exact: true, component: CoinContainer },
  { path: "/cryptos/insiders/:cid", exact: true, component: CoinContainer },
  { path: "/cryptos/news/:cid", exact: true, component: CoinContainer },
];

const cryptos_2_route_group = [
  { path: "/wallet/:walletId", exact: true, component: Wallet },
  { path: "/crypto-coins", exact: true, component: CryptosAI2 },
  { path: "/crypto-coins/:blockchain", exact: true, component: CryptosAI2 },
  { path: "/wallet", exact: true, component: UserWallet },
  { path: "/refer", exact: true, component: Referral },
  { path: "/blockchain", exact: true, component: BlockChain },
  { path: "/spin", exact: true, component: UserWallet },
  { path: "/track", exact: true, component: Track },
  { path: "/shop", exact: true, component: Shop },
];

const activity_feed_route_group = [
  { path: "/feed", exact: true, component: ActivityFeed },
  { path: "/feed/:id", exact: true, component: ActivityFeed },
];

const race_to_100_route_group = [
  { path: "/race-to-100", exact: true, component: RaceTo100 },
];

const grabpay_group = [
  { path: "/checkout/grab-pay", exact: true, component: CheckoutGrabPay },
  { path: "/grab-pay/callback", exact: true, component: GrabPayCallback },
];

const challenge_events = [
  { path: "/challenge/:event_id/trading", exact: true, component: Trading },
  {
    path: "/challenge/:event_id/history",
    exact: true,
    component: EventHistory,
  },
  {
    path: "/challenge/:event_id/portfolio",
    exact: true,
    component: Portfolio,
  },
  {
    path: "/challenge/:event_id/history/:user_id",
    exact: true,
    component: EventHistory,
  },
  {
    path: "/challenge/:event_id/portfolio/:user_id",
    exact: true,
    component: Portfolio,
  },
  { path: "/challenge", exact: true, component: Challenge },
  {
    path: "/challenge/:event_id/performance",
    exact: true,
    component: ChallengePerformance,
  },
  {
    path: "/challenge/:event_id/leaderboard",
    exact: true,
    component: ChallengeLeaderboard,
  },
  {
    path: "/challenge/:event_id/performance/:user_id",
    exact: true,
    component: ChallengePerformanceComparison,
  },
  {
    path: "/challenge/:event_id/activity",
    exact: true,
    component: ChallengeActivity,
  },
  {
    path: "/challenge/:event_id/activity/:post_id",
    exact: true,
    component: ChallengeActivity,
  },
];

const copy_trades_route_group = [
  { path: "/copy-trade-portfolio", exact: true, component: CopyTradePortfolio },
  { path: "/discover", exact: true, component: DiscoverCopyTrade },
  {
    path: "/paper-trade/trade-portfolio",
    exact: true,
    component: TradePortfolio,
  },
  {
    path: "/paper-trade/trade-history",
    exact: true,
    component: TradeHistory,
  },
];

const user_profile = [
  {
    path: "/profile/personal-information",
    exact: true,
    component: PersonalInformation,
  },
  {
    path: "/profile/trade-profile",
    exact: true,
    component: TradeProfile,
  },
  {
    path: "/profile/billing",
    exact: true,
    component: Billing,
  },
  {
    path: "/profile/calendar",
    exact: true,
    component: ProfileCalendar,
  },
  {
    path: "/profile/notification",
    exact: true,
    component: ProfileNotification,
  },
  {
    path: "/profile/danger-zone",
    exact: true,
    component: DangerZone,
  },
  {
    path: "/profile/following",
    exact: true,
    component: Following,
  },
  {
    path: "/profile/trading-diary",
    exact: true,
    component: TradingDiary,
  },
  {
    path: "/profile/membership",
    exact: true,
    component: Membership,
  },
];

const video_library_route_group = [
  {
    path: "/video-library",
    exact: false,
    component: VideoLibrary,
    redirect: "https://spiking.com/help",
  },
  { path: "/replays", exact: true, component: Replays },
];

const million_dollar_trade = [
  {
    path: "/million-dollar-trades",
    exact: true,
    component: MillionDollarTrade,
  },
];

const spiking_upDown_route_group = [
  { path: "/spiking-up-down", exact: true, component: SpikingUpDown },
  { path: "/dividend", exact: true, component: SpikingUpDown },
];

const discover_route_group = [
  { path: "/overview", exact: true, component: Discover },
  { path: "/stocks-ai", exact: true, component: Discover },
  { path: "/options-ai", exact: true, component: Discover },
  { path: "/cryptos-ai", exact: true, component: Discover },
];

const discoverNew_route_group = [
  { path: "/your-future", exact: true, component: Journey },
  { path: "/journey", exact: true, component: DiscoverNew },
  { path: "/support", exact: true, component: Journey },
  { path: "/kickoff", exact: true, component: Kickoff },
];

const other_route_group = [
  { path: "/home", component: Home },
  { path: "/popularspikes", component: PopularSpikes },
  { path: "/popularinsiders", component: PopularInsidersParentTab },
  { path: "/notifications", component: Notification },
  { path: "/notification", component: SingleNotificationContainer },
  { path: "/videos", component: VideosParentTab },
  { path: "/videoplay", component: VideoPlay },
  { path: "/receipt", exact: true, component: WebPurchaseComp },
  { path: "/filling-date", exact: true, component: Calendar },
  { path: "/bluewhale-company/:id", component: LeaderboardTable },
  { path: "/server-error", component: ServerError },
  { path: "/check", exact: true, component: CheckPrivatePublic },
  { path: "/agreetoterms", exact: true, component: TermsAndConditions },
  { path: "/user-details", exact: true, component: AppleUserDetails },
  { path: "/wisdom", exact: true, component: Wisdom },
  { path: "/welcome", exact: true, component: WelcomeVideo },
  { path: "/welcome-crypto", exact: true, component: WelcomeCrypto },
  { path: "/members-area/:product_id?", exact: true, component: MembersArea },
  { path: "/advance", exact: true, component: AdvanceRoute },
  { path: "/advance-cryptos", exact: true, component: AdvanceRoute },
  { path: "/advance-options", exact: true, component: AdvanceRoute },
  { path: "/ark", exact: true, component: Line },
  { path: "/bar", exact: true, component: Bar },
  { path: "/tree", exact: true, component: Tree },
  { path: "/trends", exact: true, component: Trends },
  // { path: "/crypto", exact: false, component: Crypto },
  { path: "/payment", exact: true, component: PaymentRoute },
  { path: "/layout", exact: true, component: LayoutRoute },
  {
    path: "/share",
    exact: true,
    component: YourReferralLinkNew,
  },
  {
    path: "/referral/referral-settings",
    exact: true,
    component: PaypalSuccessfulRefNew,
  },
  { path: "/course/:courseId", component: BasicCourse },
  { path: "/tradegpt", exact: true, component: TradeGPT },
  { path: "/tradegpt-sectors", exact: true, component: TradeGptSectors },
  { path: "/sectors", exact: true, component: TradeGptPrompt },
  { path: "/prompt", exact: true, component: TradeGptPrompt },
  { path: "/prompt-before", exact: true, component: TradeGptPrompt },
  { path: "/holygrail", exact: true, component: HolyGrail },
  { path: "/holygrail-options", exact: true, component: HolyGrail },
  { path: "/holygrail-cryptos", exact: true, component: HolyGrail },
  { path: "/events-calendar", exact: true, component: EventsCalendar },
  { path: "/workbook", exact: true, component: Workbook },
  { path: "/holygrail-wealth", exact: true, component: HolyGrail },

  // { path: "/system-update", component: SystemUpdate },
];

const getPrivateRoutes = () => {
  return [
    ...dailytrades_route_group,
    ...company_route_group,
    // ...dashboard_route_group,
    ...investor_route_group,
    ...baiting_route_group,
    ...spike_universe_route_group,
    // ...learn_route_group,
    ...other_route_group,
    ...funds_route_group,
    ...dashboard_new_route_group,
    ...options_new_route_group,
    ...courses_route_group_private,
    ...lesson_route_group,
    ...activity_feed_route_group,
    ...challenge_events,
    ...user_profile,
    ...copy_trades_route_group,
    ...race_to_100_route_group,
    // ...(isToken() ? video_library_route_group : []),
    ...video_library_route_group,
    ...million_dollar_trade,
    ...spiking_upDown_route_group,
    ...discover_route_group,
    ...discoverNew_route_group,
    ...cryptos_2_route_group,
    ...cryptos_route_group,
  ];
};

const getPublicRoutes = () => {
  return [
    // { path: "/login", exact: true, component: Login },
    { path: "/login", exact: true, component: LoginLandingPage },
    { path: "/login-old", exact: true, component: Login },
    { path: "/email-login", exact: true, component: EmailLogin },
    { path: "/create-account", exact: true, component: CreateAccount },
    { path: "/forgot-password", exact: true, component: ForgotPassword },
    { path: "/reset-password", exact: true, component: ResetPassword },
    { path: "/react", exact: true, component: Intermediate },
    { path: "/logout", exact: true, component: LogoutContainer },
    { path: "/race", exact: true, component: RaceTo100 },
    { path: "/calendar", exact: true, component: EventCalendarContainer },
    { path: "/disclaimer", component: Disclaimer },
    { path: "/btc", exact: true, component: NewCryptos },
    { path: "/youtube/:videoId", exact: true, component: YoutubePlayer },
    {
      path: "/auth/wechat/callback",
      exact: true,
      component: AuthWeChatCallback,
    },
    ...ark_route_group,
    ...grabpay_group,
  ];
};

// const private_route_group = [
//   ...dailytrades_route_group,
//   ...company_route_group,
//   ...dashboard_route_group,
//   ...investor_route_group,
//   ...baiting_route_group,
//   ...spike_universe_route_group,
//   // ...learn_route_group,
//   ...other_route_group,
//   ...funds_route_group,
//   ...dashboard_new_route_group,
//   ...options_new_route_group,
//   ...courses_route_group_private,
//   ...lesson_route_group,
//   ...cryptos_route_group,
//   ...activity_feed_route_group,
//   ...challenge_events,
//   ...user_profile,
//   ...copy_trades_route_group,
//   ...race_to_100_route_group,
//   ...video_library_route_group,
// ];

// const public_route_group = [
//   // { path: "/login", exact: true, component: Login },
//   { path: "/login", exact: true, component: LoginLandingPage },
//   { path: "/login-old", exact: true, component: Login },
//   { path: "/email-login", exact: true, component: EmailLogin },
//   { path: "/create-account", exact: true, component: CreateAccount },
//   { path: "/forgot-password", exact: true, component: ForgotPassword },
//   { path: "/reset-password", exact: true, component: ResetPassword },
//   { path: "/react", exact: true, component: Intermediate },
//   { path: "/logout", exact: true, component: LogoutContainer },
//   { path: "/race", exact: true, component: RaceTo100 },
//   { path: "/library", exact: false, component: VideoLibrary },
//   ...ark_route_group,
//   ...grabpay_group,
// ];

const affiliate_route_group = [
  { path: "/insiders/:insiderId?", exact: true, component: InsiderMember },
  {
    path: "/insiders/checkout/:insider_id?",
    exact: true,
    component: InsiderCheckout,
  },
  { path: "/welcomeInsider/:insider_id", component: WelcomeInsider },
  { path: "/membership/:id", component: CheckoutNew },
  { path: "/courses/:id", component: CheckoutNew },
  { path: "/redirect", component: AffiliateRedirect },
];

const AllRoutes = (props) => {
  // const { user } = props;
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          if (isToken()) return <Redirect to="/check" />;
          else return <Redirect to="/login" />;
        }}
      />
      {getPublicRoutes().map((route, idx) => (
        // <Route
        //   key={idx}
        //   path={route.path}
        //   exact={route.exact}
        //   component={route.component}
        // />
        <PublicRoute
          key={idx}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
      {getPrivateRoutes().map((route, id) => (
        <PrivateRoute
          redirect={route.redirect}
          key={id}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
      {affiliate_route_group.map((route, id) => (
        <AffiliateRoute
          key={id}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
      {/*TODO - Single crypto route*/}
      {/* <Route path={"/crypto"} component={Crypto} /> */}
      <Route component={PageNotFound} />
    </Switch>
  );
};

// AllRoutes.propTypes = {
//   user: PropTypes.object
// }

export default AllRoutes;
