const eventsList = [
  {
    name: "Wealth Masterclass",
    id: "masterclass",
    color: "#9747FF",
    event: true,
    daily: true,
    display: "block",
    priority: 1,
  },
  {
    name: "COSY Live Trading",
    id: "cosy",
    color: "#FEDD00",
    daily: true,
    event: true,
    display: "block",
    priority: 2,
  },
  {
    name: "Trade Through The Night",
    id: "tttn",
    color: "#EE5921",
    event: true,
    display: "block",
    priority: 3,
  },
  {
    name: "Spiking Special Event",
    id: "special_event",
    event: true,
    daily: true,
    color: "#f1948a",
    display: "block",
    priority: 4,
  },
  {
    name: "Spiking Networking Event",
    id: "can_register_exclusive_network_event",
    event: true,
    color: "#22b8cf",
    display: "block",
    priority: 5,
  },
  {
    name: "Consumer Price Index",
    id: "cpi",
    color: "#039be5",
    event: true,
    display: "block",
    priority: 6,
  },
  {
    name: "Producer Price Index",
    id: "ppi",
    color: "#039be5",
    event: true,
    display: "block",
    priority: 7,
  },
  {
    name: "Employment Cost Index",
    id: "eci",
    color: "#039be5",
    event: true,
    display: "block",
    priority: 8,
  },
  {
    name: "FOMC Interest Rate",
    id: "fomc",
    color: "#039be5",
    event: true,
    display: "block",
    priority: 9,
  },
  {
    name: "Earning Announcement",
    id: "earnings",
    color: "#0F9D58",
    event: true,
    display: "auto",
    priority: 10,
  },
  {
    name: "Stock Split",
    id: "stock_split",
    color: "#DB4437",
    event: true,
    display: "auto",
    priority: 11,
  },
  {
    name: "Insider Expose",
    id: "ite",
    color: "#039be5",
    event: true,
    display: "auto",
    priority: 12,
  },
  {
    name: "Economic News",
    id: "economic",
    color: "#039be5",
    event: true,
    display: "auto",
    priority: 13,
  },
  {
    name: "Bitcoin Spot ETF Approval",
    id: "spot_etf",
    color: "#039be5",
    event: true,
    display: "auto",
    priority: 14,
  },
  {
    name: "Initial Public Offering",
    id: "ipo",
    color: "#eaa37c",
    event: true,
    display: "block",
    priority: 15,
  },
  {
    name: "Books to read in 2024",
    id: "books_2024",
    color: "#7986cb",
    event: true,
    display: "block",
    priority: 16,
  },
  {
    name: "Public Holiday",
    id: "nyse",
    color: "#DA4343",
    display: "block",
    priority: 17,
  },
];

export const typeList = [
  "masterclass",
  "cosy",
  "tttn",
  "cpi",
  "ppi",
  "eci",
  "fomc",
  "earnings",
  "stock_split",
  "economic",
  "spot_etf",
  "ipo",
  "preview",
  "special_event",
  "ite",
  "nyse",
  "can_register_exclusive_network_event",
  "books_2024",
];

export default eventsList;
