import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import emailshare from "../../images/emailshare.png";
import Close from "../../images/close.png";
import { useStyles } from "./css";

import Dialog from "@material-ui/core/Dialog";
import { Box, Button, Grid, InputBase, styled, TextField, Typography } from "@material-ui/core";
import { getAffiliateLinks, referralPopupInteract } from "../../store/actions/commonAc";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"
import { isIosBrowser } from "../../helpers/device-check";
import { Link } from "react-router-dom";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  width: '100%',
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 12,
    color: '#8B9299',
    width: '100%',
    borderColor: theme.palette.primary.main,
    padding: '6px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}));


const ReferralPopup = (props) => {
  const { isReferralPopup, user, affiliateLink } = props;
  const classes = useStyles();

  const message = `Hello!

  I’d like to invite you to join the Spiking Masterclass. Click here to join ${affiliateLink}
  
  It takes less than a minute to join and together we’re sharing our stories, experiences, and ideas on generational wealth and wisdom.
  
  I know you’ll love it.
  
  See you soon!`

  const twitterMessage = `Hello!

  I’d like to invite you to join the Spiking Masterclass.
  It takes less than a minute to join and together we’re sharing our stories, experiences, and ideas on generational wealth and wisdom.
  I know you’ll love it.
  Click here to join`

  const iosMessage = `Hi, I’d like to invite you to join the Spiking Masterclass. It takes less than a minute to join and I know you’ll love it. Click here to join ${affiliateLink}`

  return (
    <Dialog
      //   onClose={handleClose}
      open={isReferralPopup}
      maxWidth='xs'
      onBackdropClick={() => props.referralInteract(false)}
      classes={{ paper: classes.dialogueRoot }}
    >
      <Box p={2}>
        <Grid container direction="row">
          <Typography variant="h5">
            <Box mb={2} fontWeight={700} color="black">
              Refer 2 Friends, Get $100
            </Box>
          </Typography>
          <Grid xs={9} item>
            <Box pr={4}>
              <Typography variant="caption">
                <Box mb={2} color="black">
                  Earn $100 for every 2 Friends that sign up for Spiking
                  Masterclass
                </Box>
              </Typography>

            </Box>
          </Grid>
          <Grid xs={3} item>
            <Box p={3} borderRadius={10} bgcolor="#F0F8FF">
              <img src={emailshare} alt="present box" width="100%" style={{ minWidth: 30 }} />
            </Box>
          </Grid>
        </Grid>
        <Box mt={1} mb={1}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={9}>
              <BootstrapInput disabled={true} defaultValue={affiliateLink} />
            </Grid>
            <Grid item xs={3}>
              <Button fullWidth size="small" variant="contained" color="primary" onClick={() => navigator.clipboard.writeText(affiliateLink)} >Copy</Button>
            </Grid>
          </Grid>
        </Box>
        <Grid container justify="space-between" justifyContent="space-between">
          <Grid container xs={9} item>
            <Box pr={2}>
              <EmailShareButton
                url={""}
                subject={`${user?.Firstname + " " + user?.Lastname} invites you to join the Spiking Masterclass`}
                body={isIosBrowser() ? iosMessage : message}
              >
                <EmailIcon size={30} round={true} />
              </EmailShareButton>
            </Box>
            <Box pr={2}>
              <FacebookShareButton quote={message} url={affiliateLink}>
                <FacebookIcon size={30} round={true} />
              </FacebookShareButton>
            </Box>
            {/* <FacebookMessengerShareButton
                  appId="1530465139556203"
                  quote={message}
                  url={referralLink}
                >
                  <FacebookMessengerIcon size={30} round={true} />
                </FacebookMessengerShareButton> */}
            <Box pr={2}>
              <TwitterShareButton title={twitterMessage} url={affiliateLink}>
                <TwitterIcon size={30} round={true} />
              </TwitterShareButton>
            </Box>
            <Box pr={2}>
              <WhatsappShareButton url={message}>
                <WhatsappIcon size={30} round={true} />
              </WhatsappShareButton>
            </Box>
            <Box pr={2}>
              <TelegramShareButton url={message}>
                <TelegramIcon size={30} round={true} />
              </TelegramShareButton>
            </Box>
          </Grid>
          <Grid xs={3} container item justify="flex-end" justifyContent="flex-end">
            <Typography align="right" variant="caption" color="primary">
              <a href="/settings/referral">
                Learn more
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* <img alt="close" style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={() => props.referralInteract(false)} src={Close} /> */}
    </Dialog>
  );
};

ReferralPopup.propTypes = {
  isReferralPopup: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    referralInteract: (state) => dispatch(referralPopupInteract(state)),
  }
}

const mapStateToProps = (state) => {
  return {
    isReferralPopup: state.common.referralPopup,
    affiliateLink: state.common.affiliateLink
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralPopup);
