import React from "react";
import Content from "../components/Content";

const TimeFrame = () => {
  const content = {
    dayTitle: "Decoding Financial Time Frames",
    heading: "You're advancing impressively with Spiking.",
    intro:
      "Every day, you're building a stronger foundation for your financial journey and preparing for long-term success.",
    youtubeVideoId: "bsV1RfjVSPQ",
    about: {
      sectionTitle: "ABOUT Market Timing",
      description:
        "Today, we will focus on decoding financial time frames, exploring how to understand and leverage different market periods for strategic investment.",
    },
    topicsIntro:
      "Your Spiking journey offers a comprehensive exploration of these key topics:",
    topics: [
      {
        title: "Categories of Financial Time Frames",
        description:
          "Understand the different financial time frames, including 1-Day, 1-Month, 1-Quarter, 1-Year, 1-Election, 1-Decade, 1-Lifetime, and Generations. Each time frame offers unique opportunities and strategies.",
      },
      {
        title: "Real-Time Ranking",
        description:
          "Using a combination of Universe Net Purchase and TradeGPT, we rank the best stocks for each time frame, providing you with a curated list of high-potential investments.",
      },
      {
        title: "Catalysts",
        description:
          "Identify key catalysts that drive stock movements, such as earnings announcements, clinical trials approval, sectoral movement, and thematic themes. These catalysts can significantly impact stock performance.",
      },
      {
        title: "Best Selection",
        description:
          "Utilize the AB elimination process to select the best stocks that align with your time commitment in the market. This method helps you focus on high-quality stocks that fit your investment strategy.",
      },
    ],
    nextSteps: {
      title: "NEXT STEPS",
      steps: [
        {
          title: "Spiking Daily Bread",
          description:
            "Daily updates on companies’ quarterly earnings and profitability.",
          actions: [
            {
              label: "View Daily Bread",
              link: "https://spiking.com/dailybread",
              type: "primary",
            },
          ],
        },
        {
          title: "Spiking Calendar",
          description:
            "Stay on top of key dates and events by importing the Spiking Calendar.",
          actions: [
            {
              label: "Import Calendar",
              link: "https://spiking.com/calendar",
              type: "primary",
            },
          ],
        },
        {
          title: "Assess Your Time Commitment",
          description:
            "Participate in our poll on the Facebook Community to determine your time commitment to the market.",
          actions: [
            {
              label: "Join the Poll",
              link: "https://facebook.com/groups/spiking",
              type: "primary",
            },
          ],
        },
      ],
    },
  };

  return <Content content={content} />;
};

export default TimeFrame;
