import React from "react";
import { useSelector } from "react-redux";
import { getAllLiveAndRecordingsClassesData, registerWebinarAttendee } from "../../../../../store/actions/bookcoursesAc";
import { useQuery } from "react-query";

const webinarTag = "W-08-04-2025-Reg";

const UpgradeButton = () => {
  const user = useSelector((state) => state.home.user);

  const isFreeUser = user?.MembershipText === "Free User";
  const isCenturion = user?.IsCenturion;
  const isMars = user?.IsMars;

  const masterclassQuery = useQuery({
    queryKey: ["masterclass-class"],
    queryFn: async () =>
      (await getAllLiveAndRecordingsClassesData("masterclass")).data,
    onError: (error) => {
      console.log("error", error);
    },
  });

  const currentMasterclass = masterclassQuery.data?.data?.find(
    (item) => item.Tag === webinarTag
  );

  const isRegistered = currentMasterclass?.Registered;

  const handleRegisterWebinarAttendee = async () => {
    try {
      const response = await registerWebinarAttendee({
        email: user.Email,
        firstName: user.Firstname,
        lastName: user.Lastname,
        phone: `${user?.MobileExtension}${user?.MobileNumber}`,
        affiliate: "wmsnew2",
        webinar: webinarTag,
        additionalTags: `${webinarTag}, ${`${webinarTag}-${"wmsnew2"}`}`,
      });

      console.log(response);
    } catch (error) {
      console.error("Error registering webinar attendee:", error);
    }
  };

  const MEMBERSHIP_CONFIG = {
    REGISTER_WEBINAR: {
      text: "Free Webinar",
      subText: "Register Now",
      onClick: handleRegisterWebinarAttendee,
    },
    ENTER_WEBINAR: {
      text: "Enter Zoom",
      subText: null,
      url: currentMasterclass?.JoinURL,
    },
    REFERRAL: {
      text: "Refer A Friend",
      subText: null,
      url: "/refer",
    },
    PREMIUM: {
      text: "🔥 Upgrade Now",
      subText: "Mars Unlimited",
      url: "https://spiking.com/purchase/mars-mastermind?discount=MARS",
    },
  };

  const getButtonConfig = () => {
    if (isCenturion || isMars) return MEMBERSHIP_CONFIG.REFERRAL;
    if (!isFreeUser) return MEMBERSHIP_CONFIG.PREMIUM;
    if (isRegistered) return MEMBERSHIP_CONFIG.ENTER_WEBINAR;
    return MEMBERSHIP_CONFIG.REGISTER_WEBINAR;
  };

  const handleClick = () => {
    const { url, onClick } = getButtonConfig();
    if (url) {
      window.location.href = url;
    } else if (onClick) {
      onClick();
    }
  };

  const { text, subText } = getButtonConfig();

  return (
    <button
      onClick={handleClick}
      className="relative inline-flex flex-col items-center justify-center px-3 py-1.5 my-2 text-white rounded-lg transition-all duration-300 shadow-lg hover:shadow-xl"
      style={{
        background:
          "linear-gradient(90deg, #00BCE6 0%, #2962FF 49.17%, #D500F9 98.34%)",
      }}
    >
      <span className="text-base font-semibold whitespace-pre-line">
        {text}
      </span>
      {subText && <span className="text-xs opacity-90 mt-0.5">{subText}</span>}
    </button>
  );
};

export default UpgradeButton;
