import React from "react";
import Content from "../components/Content";

const WallStreetInsidersLesson = () => {
  const content = {
    dayTitle: "Analyzing Wall Street Insiders",
    heading: "You're making fantastic progress with Spiking.",
    intro:
      "By delving deeper into these lessons, you're equipping yourself with the knowledge to make savvy investment choices.",
    youtubeVideoId: "u2-t8evM9uA",
    about: {
      sectionTitle: "ABOUT Insider Investors",
      description:
        "Today, we will focus on analyzing Wall Street insiders and their transactions. Understanding their investment strategies and actions can provide valuable insights for your own trading decisions.",
    },
    topicsIntro:
      "Your Spiking journey will provide an in-depth exploration of these essential topics:",
    topics: [
      {
        title: "Categories of Wall Street Insiders",
        description:
          "Understand the different categories of Wall Street insiders, including the Top 100, Universe, Sovereign Wealth, Pension, Retirement, C-Suite, Politicians, and CNBC. Each category provides unique insights and strategies.",
      },
      {
        title: "Real-Time Tracking",
        description:
          "Learn how we track the buy and sell transactions of these insiders in real-time. This information allows you to make informed investment decisions based on their collective actions.",
      },
      {
        title: "Standing on the Shoulders of Giants",
        description:
          "Embrace the wisdom of Sir Isaac Newton’s quote, “If I have seen further, it is by standing on the shoulders of giants.” By analyzing the transactions of these insiders, you benefit from their extensive due diligence and expertise.",
      },
      {
        title: "Mother of all Metrics",
        description:
          "The Universal Net Purchase metric of these transactions represents the thorough research and analysis conducted by these insiders, providing you with a strong foundation for your investment decisions.",
      },
    ],
    nextSteps: {
      title: "NEXT STEPS",
      steps: [
        {
          title: "Top 100 Funds",
          description:
            "Discover the Top 100 Money Managers, expertly curated by Spiking.",
          actions: [
            {
              label: "View Top 100 Funds",
              link: "https://spiking.com/funds/top100/fundamental",
              type: "primary",
            },
          ],
        },
        {
          title: "Study Warren Buffett",
          description:
            "Gain insights from the investment strategies of Warren Buffett.",
          actions: [
            {
              label: "Learn from Warren Buffett",
              link: "https://spiking.com/bluewhale/67782/fundamentals",
              type: "primary",
            },
          ],
        },
        {
          title: "Study Temasek",
          description: "Explore the investment approaches of Temasek.",
          actions: [
            {
              label: "Learn from Temasek",
              link: "https://spiking.com/bluewhale/99/fundamentals",
              type: "primary",
            },
          ],
        },
      ],
    },
  };

  return <Content content={content} />;
};

export default WallStreetInsidersLesson;
