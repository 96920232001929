import React from "react";
import spSystem from "../../../images/journey/sp-system.png";

const content = {
  welcomeMessage: "Welcome to the Spiking Family!",
  introduction: `We’re excited to have you with us, and your journey toward building an investment legacy begins right here. At Spiking, our mission is simple: to equip you with the knowledge, tools, and support to make smart, confident decisions in the markets.\n
  Every great investor starts with that first step, and you’ve already made yours. Now, let’s guide you through the 5 key levels of support we’ve crafted just for you. If you have any questions for our next live class, feel free to reach out at <a class="text-blue-600" target="_blank" rel="noreferrer" href="https://spi.ke/support">https://spi.ke/support</a>.`,
  supportLevels: [
    {
      title: "<strong>01.</strong> Spiking Onboarding Kick-off",
      description:
        "This is your starting point – where you’ll build a solid foundation. We’ve packed the system with easy-to-follow guides and key strategies to get you started. If anything’s unclear, don’t hesitate to reach out. This is your roadmap to success, and we’re here to guide you along the way.",
      link: "https://spiking.com/onboarding",
    },
    {
      title: "<strong>02.</strong>  WhatsApp Priority Support",
      description:
        "Looking for answers or clarification? Our friendly Angels support team is just a WhatsApp message away! Whether you have a class query, a tool concern, or need guidance on your next step, we’re here to assist you promptly and anytime.",
      link: "https://spi.ke/help",
    },
    {
      title: "<strong>03.</strong>  Facebook Group Global Community",
      description:
        "Join our Facebook group and connect with students from around the world—your very own global investment family. It’s the perfect space to celebrate your wins, ask questions, and learn from others on the same journey. Here, inspiring conversations and shared experiences will empower you to grow faster than you ever imagined.",
      link: "https://www.facebook.com/groups/spiking/",
    },
    {
      title: "<strong>04.</strong>  COSY Live Trading Bi-Monthly Class",
      description:
        "Twice a month, we go live with real trades, bringing the strategies you’ve learned to life. Join us to watch, learn, and trade alongside our team in real time. It’s an outstanding opportunity to sharpen your skills and see how everything aligns in real-world scenarios. Got questions? Visit https://spi.ke/support for live Q&A during class.",
      link: "https://spi.ke/cosy",
    },
    {
      title: "<strong>05.</strong>  Dr. Clemen Chiang Coaching Inner Circle",
      description:
        "This is our highest level of support—exclusive coaching with Dr. Clemen Chiang himself, alongside a select group of elite students. It’s your chance to learn directly from the best, receive personalised guidance, and gain unparalleled insights into the markets. If you’re ready to elevate your investing journey to new heights, this is where you belong.",
      link: "https://spi.ke/membership",
    },
  ],
  closingMessage:
    "Remember, the Spiking support system is here to make sure you’re never alone in this journey. Whether you’re just starting or already diving deep, we’ve got your back every step of the way.",
  signOff: "Here’s to your success!\nThe Spiking Team.",
};

const SupportSystem = () => {
  return (
    <div className="w-full px-4 sm:px-10 py-5 sm:py-14 bg-white rounded-2xl">
      {/* Welcome Title */}
      <h1
        className="text-3xl sm:text-4xl font-bold mb-4 text-center"
        dangerouslySetInnerHTML={{ __html: content.welcomeMessage }}
      />

      {/* Sub Header */}
      <h2
        className="text-xl sm:text-2xl font-bold mb-4 text-center whitespace-pre-line"
        dangerouslySetInnerHTML={{ __html: content.subHeader }}
      />

      {/* Introduction Paragraph */}
      <p
        className="text-base sm:text-lg text-gray-600 mb-6 whitespace-pre-line"
        dangerouslySetInnerHTML={{ __html: content.introduction }}
      />

      {/* Introduction Image */}
      <div className="mb-6 flex-col justify-center">
        <div>
          <img src={spSystem} usemap="#supportMap" alt="Support System" />
          <map name="supportMap">
            <area
              shape="rect"
              coords="120,40,800,120"
              href="https://spi.ke/membership"
              target="_blank"
              alt="Dr. Clemen Chiang Coaching Inner Circle"
            />
            <area
              shape="rect"
              coords="120,140,800,180"
              href="https://spi.ke/cosy"
              target="_blank"
              alt="COSY Live Trading Bi-Monthly Class"
            />
            <area
              shape="rect"
              coords="120,210,800,270"
              href="https://www.facebook.com/groups/spiking/"
              target="_blank"
              alt="Facebook Group Global Community"
            />
            <area
              shape="rect"
              coords="120,270,800,330"
              href="https://spi.ke/help"
              target="_blank"
              alt="WhatsApp Priority Support"
            />
            <area
              shape="rect"
              coords="120,330,800,440"
              href="https://spiking.com/onboarding"
              target="_blank"
              alt="Spiking Onboarding Kick-off"
            />
          </map>
        </div>
      </div>

      {/* Support Levels */}
      <div className="mb-6">
        <h3 className="text-lg sm:text-xl font-semibold mb-4">
          5 Key Levels of Support:
        </h3>
        <ul className="space-y-4 mb-8">
          {content.supportLevels.map((support, index) => (
            <li
              key={index}
              className="p-4 border border-neutral-300 rounded-md shadow-md"
            >
              <h4 className="text-md sm:text-lg text-blue-600 font-semibold mb-4">
                <a
                  href={support.link}
                  target="_blank"
                  rel="noreferrer"
                  dangerouslySetInnerHTML={{ __html: support.title }}
                />
              </h4>
              <p className="text-gray-600 text-sm sm:text-base">
                {support.description}
              </p>
            </li>
          ))}
        </ul>
      </div>

      {/* Closing Message */}
      <div className="mb-6">
        <p
          className="text-gray-700 text-sm sm:text-base text-center mb-4 whitespace-pre-line"
          dangerouslySetInnerHTML={{ __html: content.closingMessage }}
        />
        <p
          className="text-gray-700 text-sm sm:text-base text-center font-bold whitespace-pre-line"
          dangerouslySetInnerHTML={{ __html: content.signOff }}
        />
      </div>
    </div>
  );
};

export default SupportSystem;
