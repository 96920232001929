import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import * as actions from "../../../store/actions/index"

import Dialog from "@material-ui/core/Dialog"
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  image: {
    cursor: "pointer",
  },
  wrapperDiv: {
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    top: 10,
    right: 14,
    color: "#fff",
    cursor: "pointer",
  },
}))

const AnnouncementDialog = (props) => {
  const { showAnnouncement, acShowAnnouncementDialog, user, history } = props
  const classes = useStyles()

  const handleImageClick = (data) => {
    if (data.Hyperlink.includes("https")) window.open(data.Hyperlink, "_blank")
    else history.push(data.Hyperlink)
    acShowAnnouncementDialog(false)
  }

  return (
    <Dialog
      //   classes={{ paper: classes.paper }}
      onClose={() => acShowAnnouncementDialog(false)}
      open={showAnnouncement}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.wrapperDiv}
    >
      {/* {user && user.Announcement[0].ImageURL} */}
      {/* <div className={classes.wrapperDiv}> */}
      <img
        src={
          user &&
          user.Announcement &&
          user.Announcement.length > 0 &&
          user.Announcement[0].ImageURL
        }
        alt={
          user &&
          user.Announcement &&
          user.Announcement.length > 0 &&
          user.Announcement[0].ID
        }
        width="100%"
        height="100%"
        className={classes.image}
        onClick={() => handleImageClick(user.Announcement[0])}
      />
      <CloseIcon
        className={classes.closeIcon}
        onClick={() => acShowAnnouncementDialog(false)}
      />
      {/* </div> */}
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    showAnnouncement: state.common.showAnnouncement,
    user: state.home.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    acShowAnnouncementDialog: (data) =>
      dispatch(actions.showAnnouncementDialog(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AnnouncementDialog))
