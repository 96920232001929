import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  paper: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    backgroundColor: "#1B1F23",
    borderRadius: 8,
    padding: 8,
    margin: 6
  },
  baseFontStyles: {
    fontFamily: "Open Sans",
    color: "rgba(255,255,255,0.87)",
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  closeDialogIcon: {
    position: "absolute",
    top: 24,
    right: 24,
    color: theme.palette.common.white,
  },
  circle: {
    background: "rgba(255,255,255,0.87)",
    width: 2,
    height: 2,
    borderRadius: "50%",
    margin: theme.spacing(0, 1),
  },
  button: {
    fontFamily: "Open Sans",
    fontWeight: 700,
    fontSize: "13px",
    color: theme.palette.common.white,
    padding: theme.spacing(1.125, 4),
    borderRadius: "3px",
    boxShadow: "none",
  },
  closeButton: {
    backgroundColor: "#4F4F5A",
    marginRight: theme.spacing(2.5),
    "&:hover": {
      backgroundColor: "#4f545a",
    },
  },
  updateButton: {
    backgroundColor: "#008DF4",
    "&:hover": {
      backgroundColor: "#007edb",
    },
  },
}))

export { useStyles }
