import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import clsx from "clsx";

import Search from "../../features/Search/container";
import { useStyles } from "./css";
import LanguageDialogue from "../Dialog";
import NavBar from "./NavBar";
import LanguageChange from "./NavBar/components/LanguageChange";
import { isIos } from "../../helpers/device-check";
// import SideDrawer from "./SideDrawer/SideDrawer";
import GuideTour from "../../features/GuideTour/container";
import NotificationDrawer from "./NavBar/components/NotificationDrawer";
import { useRouteMatch } from "react-router-dom";
import SideDrawerNew from "./SideDrawer/SideDrawerNew";

import CssBaseline from "@material-ui/core/CssBaseline";
import Banner from "./AdBanner";
import NPSFeedback from "../NPSFeedback";

const CommonTemplate = (props) => {
  const {
    children,
    referralInteract,
    handleNotiBadgeClick,
    notiData,
    notiLoading,
    toggleSearch,
    toggleLanguageDialogue,
    patchLanguageChange,
    localeData,
    isLanguageDialogueOpen,
    handleNotificationClick,
    handleViewAll,
    user,
    isPopup,
    handleMarkAllAsRead,
    defaultSelectedOption,
    isPublic,
    handleLightMode,
    history,
    selAccordian,
  } = props;
  let isIosDevice = isIos();
  const [popperEl, setPopperEl] = React.useState(null);
  const [isBackdropOpen, setIsBackdropOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState("home");
  const [settingsDrawerOpen, setSettingsDrawer] = useState(false);
  const [isSideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [isDrawerPermanent, setDrawerPermanent] = useState(false);
  let isLightMode =
    localStorage.getItem("isLightMode") === "true" ? true : false;
  const classes = useStyles({
    backdropOpen: isBackdropOpen,
    drawerOpen: isSideDrawerOpen,
    isIosDevice: isIosDevice,
    isLightMode: isLightMode,
  });
  let dialogue = null;
  let sideMenuMode = localStorage.getItem("sideMenuMode");

  let { url } = useRouteMatch();

  const getActivePath = (textToCompare) => {
    let formattedPath = "";
    if (url.endsWith("/")) {
      formattedPath = url.slice(0, -1);
    } else {
      formattedPath = url;
    }
    const isUrlContainTextCompare = formattedPath.includes(textToCompare);
    if (isUrlContainTextCompare) return true;
    return false;
  };

  useEffect(() => {
    if (defaultSelectedOption) {
      setSelectedOption(defaultSelectedOption);
    }
  }, [defaultSelectedOption]);

  useEffect(() => {
    let loc = history.location.pathname;
    if (loc.includes("feed")) {
      setSideDrawerOpen(true);
      setDrawerPermanent(true);
    } else {
      setDrawerPermanent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  const handleSearchClick = () => {
    toggleSearch();
  };

  const handlePopperClose = () => {
    setPopperEl(null);
    setIsBackdropOpen(false);
  };

  const handleNotificationsIconClick = (event) => {
    setPopperEl(event.currentTarget);
    setIsBackdropOpen(true);
    handleNotiBadgeClick();
  };

  const handleNotiClick = (noti) => {
    handlePopperClose();
    handleNotificationClick(noti);
  };

  const handleViewAllNotiClick = () => {
    handlePopperClose();
    handleViewAll();
  };

  const handleLightModeClick = (mode) => {
    localStorage.setItem("isLightMode", mode);
    handleLightMode(mode);
  };

  const handleCommunityClick = () => {
    window.open("https://spiking.com/community", "_blank");
  };

  const handleInsidersClick = () => {
    history.push("/insiders");
  };

  const handleReferalClick = () => {
    referralInteract(true);
  };

  const handleNavigateReferral = () => {
    history.push("/settings/referral");
  };

  const handleCryptoClick = () => {
    if (user && user.IsCryptoMembership) {
      history.push("/cryptos");
    } else {
      window.open("https://spiking.com/ai", "_blank");
    }
  };

  const handleDashboardSelect = (value) => {
    if (value.includes("Stocks")) {
      props.history.push("/dailybread-new");
    } else if (value.includes("Options")) {
      props.history.push("/options/open-interest");
    } else if (value.includes("Cryptos")) {
      props.history.push("/cryptos");
    }
  };

  // const handleGoogleLogoutFailure = () => {
  //   clearStorage();
  //   handleLogout();
  // };

  const manageNotificationSettings = () => {
    handlePopperClose();
    setSettingsDrawer(true);
  };

  const isPopperOpen = Boolean(popperEl);
  const id = isPopperOpen ? "popper-open" : undefined;

  // Props for notifications
  const notificationProps = {
    id,
    handleNotificationsIconClick,
    handleNotiClick,
    handleViewAllNotiClick,
    manageNotificationSettings,
    handleMarkAllNotiAsRead: handleMarkAllAsRead,
    popperEl,
    isPopperOpen,
    isBackdropOpen,
    handlePopperClose,
    notiData,
    notiLoading,
    isPopup,
    user,
  };

  // Props for language
  const languageProps = {
    localeData,
    toggleLanguageDialogue,
  };

  if (isLanguageDialogueOpen) {
    dialogue = (
      <LanguageDialogue
        show={isLanguageDialogueOpen}
        close={toggleLanguageDialogue}
        onLanguageChange={(localeData) => {
          console.log("change");
          patchLanguageChange(localeData);
          toggleLanguageDialogue();
        }}
      />
    );
  }

  return (
    <>
      <div className={classes.root}>
        {/* <Banner /> */}
        <CssBaseline />
        <NavBar
          user={user}
          referralInteract={referralInteract}
          notificationProps={notificationProps}
          languageProps={languageProps}
          isSideDrawerOpen={isSideDrawerOpen}
          setSideDrawerOpen={() => setSideDrawerOpen(!isSideDrawerOpen)}
          handleSearchClick={handleSearchClick}
          isLightMode={isLightMode}
          handleLightModeClick={handleLightModeClick}
          handleCommunityClick={handleCommunityClick}
          handleReferalClick={handleReferalClick}
          handleNavigateReferral={handleNavigateReferral}
          handleInsidersClick={handleInsidersClick}
          handleCryptoClick={handleCryptoClick}
          handleDashboardSelect={handleDashboardSelect}
          isDrawerPermanent={isDrawerPermanent}
        />

        {(sideMenuMode === "new" || !sideMenuMode) && (
          // <SideDrawer
          //   isSideDrawerOpen={isSideDrawerOpen}
          //   setSideDrawerOpen={setSideDrawerOpen}
          //   user={user}
          //   selAccordian={selAccordian}
          //   selectedOption={selectedOption}
          //   isDrawerPermanent={isDrawerPermanent}
          // />
          <SideDrawerNew
            isSideDrawerOpen={isSideDrawerOpen}
            setSideDrawerOpen={setSideDrawerOpen}
            user={user}
            selAccordian={selAccordian}
            selectedOption={selectedOption}
            isDrawerPermanent={isDrawerPermanent}
          />
        )}

        <NotificationDrawer
          open={settingsDrawerOpen}
          handleSettingsClose={() => setSettingsDrawer(false)}
          user={user}
        />

        <main
          className={
            // getActivePath("profile") || getActivePath("courses")
            (clsx(classes.content, {
              [classes.contentShift]: isSideDrawerOpen && isDrawerPermanent,
            }),
            "!p-0 !pt-[64px] !w-full") // padding to match the height of the header
            // : clsx(classes.content, {
            //     [classes.contentShift]: isSideDrawerOpen && isDrawerPermanent,
            //   })
          }
        >
          {children}
        </main>
        {dialogue}
        <div className="md:block hidden">
          <NPSFeedback storageKey="layoutNPS" className="z-10" />
        </div>
        <Search />
        <GuideTour />
      </div>
    </>
  );
};

CommonTemplate.propTypes = {
  handleNotiBadgeClick: PropTypes.func,
  notiData: PropTypes.object,
  notiLoading: PropTypes.bool,
  toggleSearch: PropTypes.func,
  toggleLanguageDialogue: PropTypes.func,
  patchLanguageChange: PropTypes.func,
  localeData: PropTypes.object,
  isLanguageDialogueOpen: PropTypes.bool,
  handleNotificationClick: PropTypes.func,
  user: PropTypes.object,
  handleViewAll: PropTypes.func,
  isPopup: PropTypes.bool,
  handleMarkAllAsRead: PropTypes.func,
  defaultSelectedOption: PropTypes.string,
  isPublic: PropTypes.bool,
  handleSignIn: PropTypes.func,
  testDeviceToken: PropTypes.string,
  handleUpgradeClick: PropTypes.func,
  referralInteract: PropTypes.func,

  acPostInAppPurchaseReceiptLogs: PropTypes.func,
  upgradeRestoreSuccess: PropTypes.object,
  handleLightMode: PropTypes.func,
  selAccordian: PropTypes.string,
};

export default withRouter(CommonTemplate);
