import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import queryString from "query-string";
import Cookies from "universal-cookie";

import isToken from "../../helpers/token-check";
import * as actions from "../../store/actions/index";
import Layout from "../Layout/container";
import UpgradePage from "../../components/UpgradePopup/UpgradePopup";
import AppleUserDetails from "../Login/components/appleUserDetails";
import { isIos } from "../../helpers/device-check";
import LazyLoader from "../../components/LazyLoader";
import {
  postUpdateUserReferrer,
  postUpdateUserSpinToken,
} from "../../store/actions/loginAc";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const cookies = new Cookies();

class PrivateRoute extends Component {
  componentDidMount() {
    if (this.props.path.includes("receipt")) {
      localStorage.setItem("fromReceipt", true);
      if (this.props.location.search !== "") {
        let values = queryString.parse(this.props.location.search);
        localStorage.setItem("webPurchaseReceipt", values.i);
        if (values.return_to)
          localStorage.setItem("sign_up_return_to", values.return_to);
        if (values.sso) localStorage.setItem("sso", values.sso);
        if (values.purchase) localStorage.setItem("purchase", values.purchase);
        if (values.sign_up_affiliate_id)
          localStorage.setItem(
            "sign_up_affiliate_id",
            values.sign_up_affiliate_id
          );
      }
    }
    if (this.props.path.includes("/redirect")) {
      let values = queryString.parse(this.props.location.search);
      if (values.sign_up_affiliate_id)
        localStorage.setItem(
          "sign_up_affiliate_id",
          values.sign_up_affiliate_id
        );
      if (values.template_id)
        localStorage.setItem("template_id", values.template_id);
    }

    if (this.props.path.includes("/wallet")) {
      let values = queryString.parse(this.props.location.search);
      if (values.affiliate)
        localStorage.setItem("sign_up_affiliate_id", values.affiliate);
    }

    if (isToken()) {
      this.props.acGetUserDetails();
    } else if (
      !isToken() &&
      // this.props.path !== "/dailytrades" &&
      this.props.path !== "/whalewatching" &&
      this.props.path !== "/watchlist"
      // makes dailytrades public
      // &&
      // this.props.path !== "/dailytrades" &&
      // this.props.path !== "/whalewatching" &&
      // this.props.path !== "/watchlist"
      // do not remove this code
    ) {
      this.props.history.push("/login");
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (
        !(
          (
            isToken() === false &&
            (this.props.path === "/dailytrades" ||
              this.props.path === "/whalewatching" ||
              this.props.path === "/watchlist")
          )
          // makes dailytrades public
          // &&
          // (this.props.path === "/dailytrades" ||
          //   this.props.path === "/whalewatching" ||
          //   this.props.path === "/watchlist")
          // do not remove this code
        )
      ) {
        this.props.acGetUserDetails();
      }
    }
    return null;
  }

  setAnnouncementBanner = (user) => {
    user.Announcement.forEach((element) => {
      if (!cookies.get(element.ID)) {
        cookies.set(element.ID, element.ID, {
          path: "/",
          expires: new Date(element.Expiry * 1000),
        });
        setTimeout(() => {
          this.props.acShowAnnouncementDialog(true);
        }, 3000);
      }
    });
  };

  render() {
    const { user, path, isWebPurchaseSuccess } = this.props;
    let isUserValid = false;
    let show = false;
    let localComponent = this.props.component;

    // List of patterns to check in the path
    const redirectPaths = [
      "/dailybread",
      "/resurrection",
      "/triangulation",
      "/goat",
      "/yolo",
      "/grit",
      "/trillion",
      "/buyback",
    ];

    const pathRedirectMapping = {
      "/dailybread-new": "/dailybread",
    };

    // Check if the current path includes any of the paths in redirectPaths
    const matchedPath = redirectPaths.find((pattern) =>
      this.props.location.pathname.startsWith(pattern)
    );

    // Redirect to "/stock/<matched-path>" if a match is found
    if (matchedPath) {
      const newPath = `/stocks${matchedPath}`;
      return <Redirect to={newPath} />;
    }

    // Check if the current path is in pathRedirectMapping
    const renderRedirect = (path) => {
      // Check if the current path exists in the mapping
      const redirectPath = pathRedirectMapping[path];
      if (redirectPath) {
        return <Redirect to={redirectPath} />;
      }
      return null;
    };

    let isPurchaseProgress = localStorage.getItem("isWebPurchaseInProgress");
    if (this.props.path.includes("receipt")) {
      if (this.props.location.search !== "") {
        let values = queryString.parse(this.props.location.search);
        // fix for sso and crypto route -> to make it work together
        if (isPurchaseProgress == "false" || !isPurchaseProgress)
          localStorage.setItem("webPurchaseReceipt", values.i);
        if (values.return_to)
          localStorage.setItem("return_to", values.return_to);
        if (values.lang) {
          let patchCalled = localStorage.getItem("isPatchCalled");
          if (!localStorage.getItem("lang") && !patchCalled) {
            localStorage.setItem("lang", values.lang);
          }
        }
        if (values.sso) localStorage.setItem("sso", values.sso);
        if (values.purchase) localStorage.setItem("purchase", values.purchase);
        if (values.sign_up_affiliate_id)
          localStorage.setItem(
            "sign_up_affiliate_id",
            values.sign_up_affiliate_id
          );
      }
    }
    if (user !== null) {
      // ******************old flow start***************
      // web and android
      if (
        user.ZoomEmail &&
        (user.Firstname || user.Lastname) &&
        (user.MobileNumber || cookies.get(`phone_required_${user?.Id}`)) &&
        user.MobileNumber !== "undefined"
      ) {
        // web purchase receipt
        let receipt = window.localStorage.getItem("webPurchaseReceipt");
        if (receipt) {
          localStorage.setItem("isWebPurchaseInProgress", true);
          this.props.acPostWebPurchaseReceipt(receipt);
        }
        if (isWebPurchaseSuccess) localStorage.removeItem("webPurchaseReceipt");
        // signup affiliate id - track free users
        let sign_up_affiliate_id = window.localStorage.getItem(
          "sign_up_affiliate_id"
        );
        if (sign_up_affiliate_id) {
          this.props.acPostSignupAffiliateid({
            sign_up_affiliate_id,
            return_to: localStorage.getItem("sign_up_return_to"),
          });
          localStorage.removeItem("sign_up_affiliate_id");
        }

        // update user referrer
        const referralCode = sessionStorage.getItem("referral_code");
        if (referralCode) {
          postUpdateUserReferrer({ referral_code: referralCode });
          sessionStorage.removeItem("referral_code");
        }

        // Handle specific redirects
        const specificRedirect = renderRedirect(this.location?.pathname);
        if (specificRedirect) {
          return specificRedirect;
        }

        // claim spin token
        const spinToken = localStorage.getItem("spin-to-win-token");
        if (spinToken) {
          const res = postUpdateUserSpinToken({ token: spinToken });
          res
            .then((res) => {
              if (res.status === 200) {
                toast.success(
                  "Your rewards will be automatically credited to your Spiking wallet within 10 minutes",
                  {
                    position: "top-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
                this.props.history.push("/wallet");
              } else {
                toast.error(
                  "You have claimed your reward before. It's a one-time claim.",
                  {
                    position: "top-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
              }
            })
            .catch((err) => {
              toast.error(
                "You have claimed your reward before. It's a one-time claim.",
                {
                  position: "top-right",
                  autoClose: 15000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            });

          localStorage.removeItem("spin-to-win-token");
        }

        // external route code starts here
        let externalRoute = localStorage.getItem("return_to");
        let sso = localStorage.getItem("sso");
        if (externalRoute && !receipt) {
          let url = externalRoute;

          if (localStorage.getItem("fromReceipt") && externalRoute) {
            console.log("from private route --> if block");
            localStorage.removeItem("sso");
            localStorage.removeItem("fromReceipt");
          } else if (!localStorage.getItem("fromReceipt")) {
            // window.open(url, "_self")
            console.log("from private route --> redirecting");
            if (url.includes("https")) window.open(url, "_self");
            else this.props.history.push(url);
            localStorage.removeItem("return_to");
            localStorage.removeItem("sso");
          }
        }
        // external route code ends here

        // free user sees upgrade popup for **stocks
        if (
          !user.IsProBrilliant &&
          !this.props.location.pathname.includes("/cryptos") &&
          !this.props.location.pathname.includes("/options")
        ) {
          isUserValid = false;
          show = true;
          if (
            !path.includes("onboarding") &&
            !path.includes("dailytrades") &&
            !path.includes("check") &&
            !path.includes("settings") &&
            !path.includes("courses") &&
            !path.includes("videos") &&
            !path.includes("notifications") &&
            !path.includes("notification") &&
            !path.includes("explorespikes") &&
            !path.includes("myspikes") &&
            !path.includes("spikealerts") &&
            !path.includes("baiting") &&
            !path.includes("videoplay") &&
            !path.includes("receipt") &&
            !path.includes("learning-community") &&
            !path.includes("advance") &&
            !path.includes("payment") &&
            !path.includes("profile") &&
            !path.includes("dailybread") &&
            !path.includes("resurrection") &&
            !path.includes("triangulation") &&
            !path.includes("/company/technical/") &&
            !path.includes("/company/alerts/") &&
            !path.includes("/company/fundamentals/") &&
            !path.includes("/company/insider/") &&
            !path.includes("/company/100investors/") &&
            !path.includes("/company/shareholders/") &&
            !path.includes("/bluewhale/") &&
            !path.includes("/funds/") &&
            !path.includes("/lesson") &&
            !path.includes("/challenge") &&
            !path.includes("/feed") &&
            !path.includes("/discover") &&
            !path.includes("/copy-trade-portfolio") &&
            !path.includes("/race") &&
            !path.includes("/overview") &&
            !path.includes("/stocks-ai") &&
            !path.includes("/course") &&
            !path.includes("/buyback") &&
            !path.includes("/trillion") &&
            !path.includes("/yolo-new") &&
            !path.includes("/grit-new") &&
            !path.includes("/goat-new") &&
            !path.includes("/replays") &&
            !path.includes("/paper-trade") &&
            !path.includes("/million-dollar-trades") &&
            !path.includes("/spiking-up-down") &&
            !path.includes("/dividend") &&
            !path.includes("/tradegpt") &&
            !path.includes("/company/earnings") &&
            !path.includes("/holygrail") &&
            !path.includes("/prompt") &&
            !path.includes("/prompt-before") &&
            !path.includes("/video-library") &&
            !path.includes("/crypto-coins") &&
            !path.includes("/wallet") &&
            !path.includes("/crypto-signal") &&
            !path.includes("/bitcoin") &&
            !path.includes("/share") &&
            !path.includes("/refer") &&
            !path.includes("/blockchain") &&
            !path.includes("/journey") &&
            !path.includes("/shop") &&
            !path.includes("/track") &&
            !path.includes("/your-future") &&
            !path.includes("/support") &&
            !path.includes("/kickoff")
          ) {
            localComponent = UpgradePage;
          }
        } else if (
          user.IsProBrilliant &&
          !this.props.location.pathname.includes("/cryptos") &&
          !this.props.location.pathname.includes("/options")
        ) {
          isUserValid = true;
          show = true;
        }

        // free user redirects to upgrade page for **crypto
        let isPurchaseProgress = localStorage.getItem(
          "isWebPurchaseInProgress"
        );
        if (
          this.props.location.pathname.split("/")[1] === "cryptos" &&
          !this.props.location.pathname.includes("members-area") &&
          // user.HasAgreeToTerms &&
          !user.IsCryptoMembership &&
          (isPurchaseProgress == "false" || !isPurchaseProgress)
        ) {
          window.open("https://spiking.com/advance-cryptos", "_self");
        }
        // ******************old flow end***************

        show = true;
        isUserValid = true;

        //show announcement popup
        if (user.Announcement.length > 0 && isToken() && !user.IsProBrilliant) {
          this.setAnnouncementBanner(user);
        } else if (
          user.Announcement.length > 0 &&
          isToken() &&
          user.IsProBrilliant
          // && user.HasAgreeToTerms
        ) {
          this.setAnnouncementBanner(user);
        }
      } else {
        // web and android flow
        if (!isIos()) {
          show = true;
          isUserValid = true;
          localComponent = AppleUserDetails;
        }
        // ios flow
        else {
          // other routes
          if (!user.IsProBrilliant) {
            isUserValid = true;
            show = true;
            if (
              !path.includes("dailytrades") &&
              !path.includes("check") &&
              !path.includes("settings") &&
              !path.includes("courses") &&
              !path.includes("videos") &&
              !path.includes("notifications") &&
              !path.includes("notification") &&
              !path.includes("explorespikes") &&
              !path.includes("myspikes") &&
              !path.includes("spikealerts") &&
              !path.includes("baiting") &&
              !path.includes("videoplay") &&
              !path.includes("receipt") &&
              !path.includes("learning-community") &&
              !path.includes("advance") &&
              !path.includes("payment") &&
              !path.includes("profile") &&
              !path.includes("dailybread") &&
              !path.includes("resurrection") &&
              !path.includes("triangulation") &&
              !path.includes("/company/technical/") &&
              !path.includes("/company/alerts/") &&
              !path.includes("/company/fundamentals/") &&
              !path.includes("/company/insider/") &&
              !path.includes("/company/100investors/") &&
              !path.includes("/company/shareholders/") &&
              !path.includes("/bluewhale/") &&
              !path.includes("/funds/") &&
              !path.includes("/lesson") &&
              !path.includes("/challenge") &&
              !path.includes("/feed") &&
              !path.includes("/discover") &&
              !path.includes("/copy-trade-portfolio") &&
              !path.includes("/race") &&
              !path.includes("/overview") &&
              !path.includes("/stocks-ai") &&
              !path.includes("/course") &&
              !path.includes("/buyback") &&
              !path.includes("/trillion") &&
              !path.includes("/yolo-new") &&
              !path.includes("/grit-new") &&
              !path.includes("/goat-new") &&
              !path.includes("/replays") &&
              !path.includes("/paper-trade") &&
              !path.includes("/million-dollar-trades") &&
              !path.includes("/spiking-up-down") &&
              !path.includes("/tradegpt") &&
              !path.includes("/company/earnings")
            ) {
              localComponent = UpgradePage;
            }

            // window.location.href = process.env.REACT_APP_API_URL + "/wealth"
          } else {
            isUserValid = true;
            show = true;
          }
        }
      }
    }

    if (
      user === null &&
      (path === "/dailytrades" ||
        path === "/whalewatching" ||
        path === "/watchlist")
    ) {
      show = true;
      isUserValid = true;
    }

    return show ? (
      isUserValid ? (
        <React.Suspense fallback={<LazyLoader />}>
          {this.props?.redirect ? (
            <Route
              path={this.props.path}
              component={() => {
                window.location.href = this.props.redirect;
                return null;
              }}
            />
          ) : (
            <Route
              path={this.props.path}
              component={localComponent}
              exact={this.props.exact}
            />
          )}
        </React.Suspense>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location },
          }}
        />
      )
    ) : (
      <div>
        <Layout>Loading...</Layout>
      </div>
    );
  }
}

PrivateRoute.propTypes = {
  acGetUserDetails: PropTypes.func,
  user: PropTypes.object,
  loading: PropTypes.bool,
  acClearServerError: PropTypes.func,
  acUserDetailsLoading: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    user: state.home.user,
    loading: state.home.userDetailsLoading,
    isLoggedIn: state.login.isLoggedIn,
    isAgreeToTermsSuccess: state.login.isAgreeToTermsSuccess,
    isWebPurchaseSuccess: PropTypes.bool,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acGetUserDetails: () => dispatch(actions.getUserDetails()),
    acClearServerError: () => dispatch(actions.clearServerError()),
    acUserDetailsLoading: (isLoad) =>
      dispatch(actions.userDetailsLoading(isLoad)),
    acPostTermsAndConditionsDone: (data) =>
      dispatch(actions.postTermsAndConditionsDone(data)),
    acPostWebPurchaseReceipt: (data) =>
      dispatch(actions.postWebPurchaseReceipt(data)),
    acSetReturnToSpinner: (data) => dispatch(actions.setReturnToSpinner(data)),
    acShowAnnouncementDialog: (data) =>
      dispatch(actions.showAnnouncementDialog(data)),
    acPostSignupAffiliateid: (obj) =>
      dispatch(actions.postSignupAffiliateid(obj)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivateRoute));
