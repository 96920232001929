import React from "react"
import PropTypes from "prop-types"
import SystemUpdateModal from "./SystemUpdateModal"

const HardUpdateModal = (props) => {
  const { show, handleUpdateApp } = props

  return <SystemUpdateModal show={show} handleUpdateApp={handleUpdateApp} />
}

HardUpdateModal.propTypes = {
  handleUpdateApp: PropTypes.func,
  show: PropTypes.bool,
}

export default HardUpdateModal
