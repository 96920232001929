import Axios from "axios";

import * as actionTypes from "./actionTypes";
let domainUserDetails = process.env.REACT_APP_API2_URL;

export const getAllCourses = ({ status, category_id }) => {
  return Axios.get(
    `${domainUserDetails}/v3/all-courses?category_id=${category_id}&status=${status}`
  );
};

export const getUsersCourses = () => {
  return Axios.get(domainUserDetails + "/v3/my-courses");
};

export const getUsersProducts = () => {
  return Axios.get(domainUserDetails + "/v3/user-products");
};

export const getStockClassesData = () => {
  return Axios.get(
    domainUserDetails +
      "/v3/zoom_class_limit_offset?type=stocks&limit=20&offset=0"
  );
};

export const getOptionsClassesData = () => {
  return Axios.get(
    domainUserDetails +
      "/v3/zoom_class_limit_offset?type=options&limit=20&offset=0"
  );
};

export const getCosyClassesData = () => {
  return Axios.get(
    domainUserDetails +
      "/v3/zoom_class_limit_offset?type=cosy&limit=20&offset=0"
  );
};

export const getCryptoClassesData = () => {
  return Axios.get(
    domainUserDetails +
      "/v3/zoom_class_limit_offset?type=cryptos&limit=20&offset=0"
  );
};

export const getUpcomingClassesData = (recommend) => {
  if (recommend) {
    return Axios.get(
      `${domainUserDetails}/v3/all_zoom_class_limit_offset?limit=20&offset=0&type=${recommend}`
    );
  }
  return Axios.get(
    `${domainUserDetails}/v3/all_zoom_class_limit_offset?limit=20&offset=0`
  );
};

export const getAllLiveAndRecordingsClassesData = (type) => {
  return Axios.get(
    `${domainUserDetails}/v3/all_live_and_recordings_limit_offset?limit=20&offset=0&type=${type}`
  );
};

export const getPastClassesData = () => {
  return Axios.get(
    domainUserDetails + "/v3/all_past_zoom_class_limit_offset?limit=20&offset=0"
  );
};

export const getViewLink = (productId) => {
  return Axios.get(domainUserDetails + "/v3/user-courses/" + productId);
};

export const postRegisterClass = ({ zoom_meeting_id, type }) => {
  let body = new FormData();
  body.append("zoom_meeting_id", zoom_meeting_id);
  body.append("type", type);
  return Axios.post(domainUserDetails + "/v3/zoom_class", body);
};

export const showPrerequisiteBanner = (value) => {
  return {
    type: actionTypes.SHOW_LESSON_PREREQUISITE_BANNER,
    data: value,
  };
};

export const storePrerequisisteLesson = (obj) => {
  return {
    type: actionTypes.STORE_PREREQUISITE_LESSON,
    data: obj,
  };
};

export const getPastRecordingsData = (obj) => {
  return Axios.get(
    domainUserDetails +
      `/v3/class_videos_limit_offset?type=${obj.type}&limit=${obj.limit}&offset=${obj.offset}`
  );
};

export const postRegisterClassNew = ({ zoom_meeting_id, type }) => {
  let body = new FormData();
  body.append("zoom_meeting_id", zoom_meeting_id);
  body.append("type", type);
  return Axios.post(domainUserDetails + "/v3/live_and_past_zoom_class", body);
};

export const registerWebinarAttendee = async ({
  email,
  firstName,
  lastName,
  phone,
  affiliate,
  twitterAffiliate,
  isGuest,
  referrerEmail,
  referralCode,
  offline = false,
  webinar = "W-12-02-2025-Reg",
  additionalTags = "",
}) => {
  try {
    const response = await Axios.post(
      `${domainUserDetails}/v3/funnel/webinar-register`,
      {
        email,
        first_name: firstName,
        last_name: lastName,
        webinar,
        offline: offline,
        phone_number: phone,
        affiliate: affiliate || twitterAffiliate || "",
        additional_tags: `${additionalTags}, ${isGuest || ""}`,
        referrer_email: referrerEmail,
        referral_code: referralCode || "",
      }
    );

    return response;
  } catch (error) {
    console.error("Error registering webinar attendee:", error);
    throw error;
  }
};
