import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  searchDialogueRoot: {
    paddingTop: "constant(safe-area-inset-top)",
    paddingTop: "env(safe-area-inset-top)",
    backgroundColor: theme.palette.background.default,
  },
  dialogueMain: {
    padding: "2rem",
    width: '50%',
    margin: '0 auto',
    [theme.breakpoints.down("md")]: {
      width: '85%',
      // minHeight: "calc(100vh - 56px)",
    },
    [theme.breakpoints.down("xs")]: {
      width: '100%',
      // minHeight: "calc(100vh - 56px)",
    },
  },
  searchMain: {
    display: "flex",
    flexWrap: "nowrap",
    borderBottom: "1px solid #dedfe0",
    padding: "6px 0",
    position: 'sticky !important',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.dark,
    zIndex: 2,
    alignItems: "center",
    paddingTop: 'constant(safe-area-inset-top)',
      paddingTop: 'env(safe-area-inset-top)',
  },
  rootInput: {
    color: theme.palette.text.primary,
    width: "100%",
  },
  inputInput: {
    padding: "13px 7px 13px 3px !important",
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  cardRoot: {
    maxWidth: 200,
  },
  cardMediaRoot: {
    height: 100,
    backgroundSize: "contain !important",
    margin: 12,
  },
  cardContentRoot: {
    borderTop: "0.5px solid #c5c3c6",
  },
  paperRoot: {
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    // marginBottom: 4,
    cursor: 'pointer',
    borderBottom: "1px solid #424242",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    }
  },
  avatar: {
    width: `${theme.spacing(7)}px !important`,
    height: `${theme.spacing(7)}px !important`,
  },
  displayName: {
    padding: '0 0 28px 0',
    fontWeight: 400,
    borderBottom: "1px solid #424242",
    textTransform: "uppercase"
  },
  imageDiv: {
    display: "inline-flex",
    "& div:nth-child(1)": {
      marginRight: "24px",
    },
    "& div:nth-child(2)": {
      width: "100%",
    }
  },
  arrowColor: {
    color: theme.palette.text.primary
  }
}));

export { useStyles };
