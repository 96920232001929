import React from "react";
import userBg from "../../../images/journey/user-bg.png";
import quesBg from "../../../images/journey/ques-bg.png";
import dbBg from "../../../images/journey/db-bg.png";

const bgImgs = [userBg, dbBg, quesBg];

const Content = ({ content }) => {
  return (
    <div className="w-full px-4 sm:px-10 py-5 sm:py-14 bg-white rounded-2xl">
      <div className="text-[#666666] grid grid-cols-1 md:grid-cols-3 gap-5 sm:gap-14 mb-12 sm:mb-16">
        {/* Left Column */}
        <div className="col-span-2">
          <h1 className="text-[#1E1E1E] text-xl sm:text-4xl font-semibold mb-5">
            {content.dayTitle}
          </h1>
          {/* Sub Header */}
          <p className="sm:text-[18px] mb-6">{content.intro}</p>
          {/* Divider */}
          <div className="border-b border-gray-200 my-10"></div>
          {/* About Section */}
          <div className="mb-6">
            <h2 className="text-[#1E1E1E] text-xl sm:text-3xl font-semibold mb-5" dangerouslySetInnerHTML={{ __html: content.about.sectionTitle }} />
            <p
              className=""
              dangerouslySetInnerHTML={{ __html: content.about.description }}
            />
          </div>
        </div>

        {/* Right Column */}
        <div className="col-span-1">
          {/* Introduction Image */}
          {content.introImage && (
            <div className="mb-6 flex justify-center">
              <img
                className="w-full h-full object-cover rounded-lg"
                src={content.introImage}
                alt="Clemen Chiang"
              />
            </div>
          )}

          {/* YouTube Video */}
          {content.youtubeVideoId && (
            <div className="flex justify-center rounded-2xl overflow-hidden">
              <iframe
                className="w-full h-72 sm:h-96"
                src={`https://www.youtube.com/embed/${content.youtubeVideoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      </div>

      {/* Topics Section */}
      <div className="mb-16">
        <h3 className="text-xl sm:text-3xl font-semibold mb-8">
          {content.topicsIntro}
        </h3>
        <ul className="space-y-10">
          {content.topics.map((topic, index) => (
            <li key={`topic-${index}`} className="relative">
              <div  className="flex gap-6 items-center rounded-md">
                {/* Rounded Number */}
                <div className="text-[#0D8BFF] bg-[#E7F3FF] rounded-full z-10">
                  <div className="w-10 sm:w-20 h-10 sm:h-20 flex justify-center items-center">
                    <span className="font-bold text-4xl">{index + 1}</span>
                  </div>
                </div>

                {/* Topic Content */}
                <div className="flex flex-col gap-2 sm:gap-3">
                  <h4 className="text-lg sm:text-xl font-semibold">
                    {topic.title}
                  </h4>
                  <p
                    dangerouslySetInnerHTML={{ __html: topic.description }}
                    className="text-gray-600 text-base sm:text-lg sm:leading-6"
                  />
                </div>
              </div>

              {/* Fracture Line */}
              {index < content.topics.length - 1 && (
                <div className="absolute left-5 sm:left-10 -bottom-20 w-full h-28 border-l border-dashed border-[#b7dbff]"></div>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Next Steps Section */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-4">
          {content.nextSteps.title}
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
          {content.nextSteps.steps.map((step, index) => (
            <div
              key={index}
              style={{
                backgroundImage: `url(${bgImgs[index]})`,
                backgroundPosition: "right bottom",
                backgroundRepeat: "no-repeat",
              }}
              className="px-4 sm:px-6 py-5 sm:py-8 border border-[#E1E1E1] sm:min-h-96 rounded-2xl flex flex-col justify-between bg-[length:0%] sm:bg-[length:50%]"
            >
              <div className="bg-transparent">
                <h4 className="text-xl text-[#1E1E1E] font-semibold mb-4">
                  {step.title}
                </h4>
                <p className="text-[#666666] mb-4">{step.description}</p>
              </div>
              <div className="mt-auto">
                {step.actions?.map?.((action, actionIndex) => (
                  <a
                    key={actionIndex}
                    href={action.link}
                    className={`w-full block text-center px-4 py-3 rounded-md font-medium ${
                      action.type === "primary"
                        ? "bg-[#0068FF] text-white hover:opacity-75"
                        : "bg-white border border-[#0068FF] text-[#0068FF] hover:bg-blue-50"
                    } ${actionIndex > 0 ? "mt-2" : ""}`}
                  >
                    {action.label}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Content;
