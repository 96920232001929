import { useEffect } from 'react';

function useOutsideClickEffect(ref, callback) {
  const clickListener = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', clickListener);
    return () => document.removeEventListener('click', clickListener);
  }, []);
}

export default useOutsideClickEffect;
